import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import useScript from '../../utils/useScript';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import Encrypt from '../../utils/encrypt';
import Decrypt from '../../utils/decyrpt';


const dwolla = window.dwolla;

const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        minWidth: 275,
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    iavContainer: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    table: {
        minWidth: 220,
        maxWidth: 220,
    },
    tableHeader: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        color: 'grey',
    },
    tableHeaderNarrow: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        fixedHeader: false,
        width: 'auto',
        tableLayout: 'auto',
    },
    tableCell: {
        padding: 2,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        color: 'grey'
    },
    tableCellNarrow: {
        padding: 1,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        fixedHeader: false,
        width: 'auto',
        tableLayout: 'auto',
    },
  });


  export default function DepositChoice(props){
        const classes = useStyles();
        const league = props.league;
        const title = props.title;

        const [ depositOne, setDepositOne ] = React.useState('');
        const [ depositTwo, setDepositTwo ] = React.useState('');

        const handleDepositFromBank = (event) => {
            event.preventDefault();
            window.location.pathname = '/depositfrombank';
        }

        const handleDepositFromPaypal = (event) => {
            event.preventDefault();
            window.location.pathname = '/depositfrompaypal';
        }


        const handleAddBankViaDeposit = (event) => {
            event.preventDefault();
            setOpenVerificationChoice(false);
            //  window.location.pathname = "/addbank";
            setOpenMicroDeposit(true);

        }

        const [ openMicroSent, setOpenMicroSent ] = React.useState(false);
        const [ agreeMicroSent, setAgreeMicroSent ] = React.useState(false);
        const handleAgreeMicroSent = () => {
            setOpenMicroSent(false);
            setAgreeMicroSent(true);
        }

        const [ openMicroDeposit, setOpenMicroDeposit ] = React.useState(false);
        const [ agreeMicroDeposit, setAgreeMicroDeposit ] = React.useState(false);
        const handleAgreeMicroDeposit = () => {
            console.log("code got here");
            let depositDetails = {
                currentUserID: sessionStorage.getItem('userID'),
                accountURL: accountSelectedURL,
                accountName: accountSelectedName,
                amount1: depositOne,
                amount2: depositTwo,
            }

            axios.post('/api/user/validatedeposits', depositDetails, {})
            .then( res => {
                console.log(res)
                setOpenMicroDeposit(false);
                setOpenMicroSent(true);
                
            })
            .catch( error => {
                console.log("Error confirming deposits")
                console.log(error);
            })
        }
        const handleCloseMicroDeposit = () => {
            setOpenMicroDeposit(false);
        }

        const [ openVerificationChoice, setOpenVerificationChoice ] = React.useState(false);
        const handleSeeVerificationChoices = () => {
            setOpenVerificationChoice(true);
        }
        const handleCloseVerification =() => {
            setOpenVerificationChoice(false);
        }

        const [ iavToken, setIavToken ] = React.useState();

        // useScript("https://cdn.dwolla.com/1/dwolla.js")

        const [ openIavContainer, setOpenIavContainer ] = React.useState(false);

        const handleCloseIAV = () => {
            setOpenIavContainer(false);
        }
        
        const handleAddBankViaLogin = async () => {
            setOpenVerificationChoice(false);
            const getIAVtoken = await axios.get('/api/user/iavtoken/' + sessionStorage.getItem('userID'))
            .then( function (res) {
                console.log(res.data);
                setIavToken(res.data);
                setOpenVerificationChoice(false);
                setOpenIavContainer(true);
                
                    
                var iavToken = res.data;
                dwolla.configure('sandbox');
                dwolla.iav.start(iavToken, {
                        container: 'iavContainer',
                        stylesheets: [
                            'https://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext'
                        ],
                        microDeposits: 'true',
                        fallbackToMicroDeposits: 'true' // (fallbackToMicroDeposits.value === 'true')
                        }, async function(err, res) {
                            console.log('Error: ' + JSON.stringify(err) + ' -- Response: ' + JSON.stringify(res));
                            let fundingSource = await res["_links"]["funding-source"]["href"];
                            console.log(fundingSource);

                            let userDetails = {
                                currentUserID: sessionStorage.getItem('userID'),
                                accountURL: fundingSource,
                            }

                            axios.post('/api/user/addbank2', userDetails, { } )
                            .then (res => {
                                console.log("Funding Source Added Successfuly")
                            })
                            .catch( error => {
                                console.log("Error adding bank account")
                                console.log(error);
                            })

                });
                      
            })
            .catch(error => {
                console.log(error);
            })
        }

        // Get all bank accounts Dwolla
        const [ accountSelectedName, setAccountSelectedName ] = React.useState('');
        const [ accountSelectedURL, setAccountSelectedURL ] = React.useState('');
        const [ rows, setRows ] = React.useState([]);
        const [ accountPicked, setAccountPicked ] = React.useState([]);
        useEffect(() => {
            (async () => {
                const array = await axios.get('/api/user/accounts/' + sessionStorage.getItem('userID'))
                    .then(async function(res){
                        if (res.data[0].fundingSourceURLdwolla.length === 0 ) {
                            //  setOpenNoBankYet(true);
                        } else {
                            let accountObjects = await res.data[0].fundingSourceURLdwolla;
                            setAccountSelectedName(accountObjects[0].accountName);
                            setAccountSelectedURL(accountObjects[0].accountURL);
                            return accountObjects;
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
                
                const result = [];
                const map = new Map();  //  This makes sure only posting distinct games
                if ( array !== undefined ){
                    for (const item of array) {
                        if(!map.has(item._id)){
                            map.set(item._id, true); // set any value to Map
                            result.push({
                                _id: item._id,
                                accountName: item.accountName,
                                accountURL: item.accountURL,
                            });
                        }
                    }
                }
                if ( result === undefined ) { console.log('no accounts')} else {
                    setRows(result)
                }
                // console.log(result)
            })();
        }, []);  

        const handleAccountPick = (event, key) => {
            setAccountPicked(key);
            let accountChosen = rows.find(({_id}) => _id === key);
            setAccountSelectedName(accountChosen.accountName);
            setAccountSelectedURL(accountChosen.accountURL);
        }

            // Dialog for no bank yet added
            const [openNoBankYet, setOpenNoBankYet ] = React.useState('');
            const [ agreeNoBankYet, setAgreeNoBankYet ] = React.useState('');
            const handleAgreeNoBankYet = () => {
                setAgreeNoBankYet(true);
                setOpenNoBankYet(false);
            }

    // All code below to add bank account through Authorize.net===================================
        const [ openAddBankAccountAuth, setOpenAddBankAccountAuth ] = React.useState(false);
        const [ agreeAddBankAccountAuth, setAgreeAddBankAccountAuth ] = React.useState(false);
        const handleAgreeAddBankAccountAuth = () => {
            setOpenAddBankAccountAuth(true);
            setAgreeAddBankAccountAuth(true);
        }

        const handleCancelAddBankAuth = () => {
            setOpenAddBankAccountAuth(false);
            setAgreeAddBankAccountAuth(false);
        }

        const [ accountNumberAuth, setAccountNumberAuth ] = React.useState('');
        const [ routingNumberAuth, setRoutingNumberAuth ] = React.useState('');
        const [ accountNicknameAuth, setAccountNicknameAuth ] = React.useState('');

        const [ openIncorrectEntryAuth, setOpenIncorrectEntryAuth ] = React.useState(false);

        const [ openAccountSuccessfullyAdded, setOpenAccountSuccessfullyAdded ] = React.useState(false);
        const [ openErrorSavingYourBankDetails, setOpenErrorSavingYourBankDetails ] = React.useState(false);
        const [ openMustOpenAccountAuth, setOpenMustOpenAccountAuth ] = React.useState(false);
        const [ openUnderDevelopment, setOpenUnderDevelopment ] = React.useState(false);

        const [ openVerifyBankAccountAuth, setOpenVerifyBankAccountAuth ] = React.useState(false);
        const [ agreeVerifyBankAccountAuth, setAgreeVerifyBankAccountAuth ] = React.useState(false);
        const handleAgreeAddBankAccountAuthStep2 = () => {
            setOpenAddBankAccountAuth(false);
            let accountCheck;
            let routingCheck;
            if ( accountNumberAuth.length > 8 ) { accountCheck = true }
            if ( routingNumberAuth.length === 9 ) { routingCheck = true }
            if ( accountCheck === true && routingCheck === true ) {
                setOpenVerifyBankAccountAuth(true);
            } else {
                setOpenIncorrectEntryAuth(true);
            }
        }

        const handleCloseInputErrorMessage = () => {
            setOpenIncorrectEntryAuth(false);
            setAccountNumberAuth('');
            setRoutingNumberAuth('');           
        }

        const handleCancelBankVerificationAuth = () => {
            setOpenVerifyBankAccountAuth(false);
            setAccountNumberAuth('');
            setRoutingNumberAuth('');
        }

        const handleCloseAccountSuccessfullyAdded = () => {
            setOpenAccountSuccessfullyAdded(false);
        }

        const handleCloseErrorSavingBankDetails = () => {
            setOpenErrorSavingYourBankDetails(false);
        }

        const handleCloseMustAddBankAuth = () => {
            setOpenMustOpenAccountAuth(false);
        }

        const handleCloseUnderDevelopment = () => {
            setOpenUnderDevelopment(false);
        }
        
        const handleSubmitBankVerificationAuth = () => {
            setOpenVerifyBankAccountAuth(false);

            let accountEncrypted = Encrypt(accountNumberAuth);
            let routingEncrypted = Encrypt(routingNumberAuth);

            let accountDetails = {
                currentUserID: sessionStorage.getItem('userID'),
                accountName: accountNicknameAuth,
                accountNumberEncrypted: accountEncrypted,
                routingNumberEncrypted: routingEncrypted,
            }

            axios.post('/api/user/authnet-addbank', accountDetails, {})
            .then( res => {
                console.log("Account Details Logged");
                setAccountNicknameAuth('');
                setAccountNumberAuth('');
                setRoutingNumberAuth('');
                setOpenAccountSuccessfullyAdded(true);
                //Add code for Dialog
            })
            .catch( error => {
                console.log("Error Adding bank details");
                setOpenErrorSavingYourBankDetails(true);
            })

        }

    //  Routes to send info to page for Auth.net
    const handleDepositFromBankAuth = async () => {
        //If no accounts, add account first
        let haveAccountCheck = await axios.get('/api/user/authnet-getbanks/' + sessionStorage.getItem('currentUser'))
        .then( function (res) {
            console.log(res.data);
            if ( parseInt(res.data) > 0 ){
                setOpenUnderDevelopment(true);
            } else {
                setOpenMustOpenAccountAuth(true);
            }
        })
        .catch( error => {
            console.log(error);
        })
        //redirect to new page
    }


      return(
          <div>
              <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center' }} gutterBottom>
                                Deposit Options
                        </Typography>
                        <Typography className={classes.title} style={{ textAlign: 'center'}}>
                                iBetU
                        </Typography>
                    </CardContent>
                        
                    {/* <CardActions style={{ justifyContent: 'center'}}>
                        <br/>
                        <div>
                                <Button size="small" variant="contained" className={classes.buttonblue} type='submit' onClick={handleDepositFromBank}>Deposit Via Your Bank</Button>  
                        </div>
                    </CardActions> */}
                    <CardActions style={{ justifyContent: 'center'}}>
                        <br/>
                        <div>
                                <Button size="small" variant="contained" className={classes.buttonblue} type='submit' onClick={handleDepositFromPaypal}>Deposit Via Paypal or Card</Button>
                        </div>
                    </CardActions>

                    <CardActions style={{ justifyContent: 'center'}}>
                        <br/>
                        <div>
                                <Button size="small" variant="contained" className={classes.buttonblue} type='submit' onClick={handleDepositFromBankAuth}>Deposit Via Your Bank</Button>  
                        </div>
                    </CardActions>

                    {/* <CardActions style={{ justifyContent: 'center'}}>
                        <br/>
                        <div>
                                <Button size="small" variant="contained" className={classes.buttonblue} type='submit' onClick={handleSeeVerificationChoices}>Add Bank Dwolla</Button>
                        </div>
                    </CardActions> */}

                    <CardActions style={{ justifyContent: 'center'}}>
                        <br/>
                        <div>
                                <Button size="small" variant="contained" className={classes.buttonblue} type='submit' onClick={handleAgreeAddBankAccountAuth}>Add Bank Info</Button>
                        </div>
                    </CardActions>

                    <Card className={classes.iavContainer} id="iavContainer"></Card>
                    
                    <div>
                    <Dialog 
                    open={openVerificationChoice}
                    onClose={handleCloseVerification}>
                        <Container>
                            <DialogTitle> We need to verify your bank account.  You can do so through a micro-deposit or with your bank credentials.  If you have not yet requested account verification, please select "New".  If you already know your micro-deposit amounts, please select "Return". Otherwise please wait for two small deposits to show in your bank account's transactions. </DialogTitle>
                        
                            <DialogActions>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Button size="small" variant="outlined" className={classes.buttonclear} type='submit' onClick={handleAddBankViaLogin}>New</Button>
                                    </Grid>
                                    <Grid item xs={4}></Grid>
                                    <Grid item xs={4}>
                                        <Button size="small" variant="outlined" className={classes.buttonclear} type='submit' onClick={handleAddBankViaDeposit}>Return</Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Container>
                    </Dialog>
                    </div>

                    <Dialog
                        open={openMicroDeposit}
                        onClose={handleCloseMicroDeposit}
                    >
                        < Container  maxWidth="sm">
                        <Card className={classes.root}>
                        <DialogTitle>
                            First, select the account you want to verify.  Then enter the two micro-amounts deposited into your bank account.  Please note that it usually takes two or three days for the micro-deposits to be reflected on your account.
                        </DialogTitle>
                        <div>
                                <TableContainer component={Paper} align="center">
                                    <Table className={classes.table} aria-label="game table" style={{justifyContent: 'center'}}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" className={classes.tableHeader}>Choose Account</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow 
                                                hover 
                                                key={row._id}
                                                onClick={ (event) => handleAccountPick(event, row._id)}
                                                >
                                                    <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                                                        {row.accountName}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                </div>
                            <br/>
                        <div>
                        <TextField
                            required
                            id="depositOne"
                            label="First Deposit"
                            name="depositOne"
                            variant="outlined"
                            value={depositOne}
                            placeholder="Enter the First Deposit"
                            onChange={ e => setDepositOne(e.target.value) }
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            
                        />
                        </div>
                        <br/>
                        <div>
                        <TextField
                            required
                            id="depositTwo"
                            label="Second Deposit"
                            name="depositTwo"
                            variant="outlined"
                            value={depositTwo}
                            placeholder="Enter the Second Deposit"
                            onChange={ e => setDepositTwo(e.target.value) }
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            
                        />
                        </div>
                        <br/>

                        <DialogActions>
                            <Button size="small" variant="outlined" className={classes.buttonclear} type='submit' onClick={handleAgreeMicroDeposit}>Confirm</Button>
                        </DialogActions>
                        
                        </Card>
                        </Container>
                    </Dialog>

                    <div>
                        <Dialog
                        // open={openNoBankYet}
                        agree={agreeNoBankYet}
                        onClose={handleAgreeNoBankYet}
                        >
                            <DialogTitle align="center">
                                We no longer have a bank linked to your account. Please link a bank account so that we can deposit funds into your account. Click 'OK' below and then select 'Add Bank Account'.
                            </DialogTitle>
                            <DialogActions>
                                <Grid container spacing={1}>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={4}>
                                        <Button onClick={handleAgreeNoBankYet} color="primary" variant="outlined" className={classes.buttonBetBlue}
                                        align="center" autofocus>
                                            OK
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    </div>

                    <div>
                        <Dialog
                        open={openMicroSent}
                        agree={agreeMicroSent}
                        onClose={handleAgreeMicroSent}
                        >
                            <DialogTitle align="center">
                                Your micro-deposits have been sent to process.  You will be notified when your account is verified.
                            </DialogTitle>
                            <DialogActions>
                                <Grid container spacing={1}>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={4}>
                                        <Button onClick={handleAgreeMicroSent} color="primary" variant="outlined" className={classes.buttonBetBlue}
                                        align="center" autofocus>
                                            OK
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    </div>

                    <div>
                        <Dialog
                            open={openAddBankAccountAuth}
                            agree={agreeAddBankAccountAuth}
                            onClose={handleAgreeAddBankAccountAuth}    
                        >
                        
                            <DialogTitle> Enter Your Bank Account Information </DialogTitle>
                            <br />
                            <form noValidate autoComplete="off">
                            <Grid container direction='column' justify="center" alignItems='center'>
                                <TextField
                                    required
                                    id="accountNickname"
                                    label="Account Name"
                                    name="accountNickname"
                                    variant="outlined"
                                    value={accountNicknameAuth}
                                    placeholder="Name Your Account"
                                    onChange={ e => setAccountNicknameAuth(e.target.value)}
                                />
                                <br />
                                <TextField
                                    required
                                    id="accountNumber"
                                    label="Account Number"
                                    name="accountNumber"
                                    variant="outlined"
                                    value={accountNumberAuth}
                                    placeholder="Enter Bank Account Number"
                                    onChange={ e => setAccountNumberAuth(e.target.value)}
                                />
                                <br />
                            
                                <TextField
                                    required
                                    id="routingNumber"
                                    label="Routing Number"
                                    name="routingNumber"
                                    variant="outlined"
                                    value={routingNumberAuth}
                                    placeholder="Enter Bank Routing Number"
                                    onChange={ e => setRoutingNumberAuth(e.target.value)}
                                />
                                <br />
                            </Grid>
                            </form>

                            <DialogActions>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Button size="small" variant="outlined" className={classes.buttonclear} type='submit' onClick={handleCancelAddBankAuth}>Cancel</Button>
                                    </Grid>
                                    <Grid item xs={4}></Grid>
                                    <Grid item xs={4}>
                                        <Button size="small" variant="outlined" className={classes.buttonclear} type='submit' onClick={handleAgreeAddBankAccountAuthStep2}>Submit</Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        
                        </Dialog>
                    </div>

                    <div>
                        <Dialog
                            open={openIncorrectEntryAuth}
                        >
                            <DialogTitle> Please check your details. Your routing number must be 9 digits and your bank account must be at least 9 digits </DialogTitle>
                            <DialogActions>
                                <Grid container spacing={1}>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={4}>
                                        <Button onClick={handleCloseInputErrorMessage} color="primary" variant="outlined" className={classes.buttonclear}
                                        align="center" autofocus>
                                            OK
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    </div>

                    <div>
                        <Dialog
                            open={openVerifyBankAccountAuth}
                        >
                            <DialogTitle> Please confirm that the bank information below is correct: </DialogTitle>

                            <Grid container direction='column' justify="center" alignItems='center'>
                                <TextField
                                    required
                                    id="accountNickname"
                                    label="Account Name"
                                    name="accountNickname"
                                    variant="outlined"
                                    value={accountNicknameAuth}
                    
                                />
                                <br />
                                <TextField
                                    required
                                    id="accountNumber"
                                    label="Account Number"
                                    name="accountNumber"
                                    variant="outlined"
                                    value={accountNumberAuth}
                    
                                />
                                <br />
                            
                                <TextField
                                    required
                                    id="routingNumber"
                                    label="Routing Number"
                                    name="routingNumber"
                                    variant="outlined"
                                    value={routingNumberAuth}

                                />
                                <br />
                            </Grid>

                            <DialogActions>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Button size="small" variant="outlined" className={classes.buttonclear} type='submit' onClick={handleCancelBankVerificationAuth}>Cancel</Button>
                                    </Grid>
                                    <Grid item xs={4}></Grid>
                                    <Grid item xs={4}>
                                        <Button size="small" variant="outlined" className={classes.buttonclear} type='submit' onClick={handleSubmitBankVerificationAuth}>Confirm</Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    </div>

                    <div>
                        <Dialog
                            open={openAccountSuccessfullyAdded}
                        >
                            <DialogTitle> Your account has been updated with your bank details.  All information is encrypted, securely stored, and is not shared with any third parties. </DialogTitle>
                            <DialogActions>
                                <Grid container spacing={1}>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={4}>
                                        <Button onClick={handleCloseAccountSuccessfullyAdded} color="primary" variant="outlined" className={classes.buttonclear}
                                        align="center" autofocus>
                                            OK
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    </div>

                    <div>
                        <Dialog
                            open={openErrorSavingYourBankDetails}
                        >
                            <DialogTitle> There was an error saving your bank details.  Please try again. </DialogTitle>
                            <DialogActions>
                                <Grid container spacing={1}>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={4}>
                                        <Button onClick={handleCloseErrorSavingBankDetails} color="primary" variant="outlined" className={classes.buttonclear}
                                        align="center" autofocus>
                                            OK
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    </div>

                    <div>
                        <Dialog
                            open={openMustOpenAccountAuth}
                        >
                            <DialogTitle> You must add your bank information by clicking the "ADD BANK INFO" button before you can deposit via your bank. </DialogTitle>
                            <DialogActions>
                                <Grid container spacing={1}>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={4}>
                                        <Button onClick={handleCloseMustAddBankAuth} color="primary" variant="outlined" className={classes.buttonclear}
                                        align="center" autofocus>
                                            X
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    </div>

                    <div>
                        <Dialog
                            open={openUnderDevelopment}
                        >
                            <DialogTitle> This functionality is currently unavailable. </DialogTitle>
                            <DialogActions>
                                <Grid container spacing={1}>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={4}>
                                        <Button onClick={handleCloseUnderDevelopment} color="primary" variant="outlined" className={classes.buttonclear}
                                        align="center" autofocus>
                                            X
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    </div>
                    
              </Card>
          </div>

          
      )
  }