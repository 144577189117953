import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';


const useStyles = makeStyles({

  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    paddingTop: 5,
    paddingBottom: 30
    
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 60,
    fontFamily: 'Anton',
    color: '#0510e6',
    fontStyle: 'italic',
  },
  welcome: {
    fontSize: 26,
    fontStyle: 'bold',
    fontFamily: 'Anton'
  },
  pos: {
    marginBottom: 12,
  },
  buttonblue: {
      background: '#0510e6',
      color: 'white'
  },
  buttonclear: {
      color: "#0510e6",
      borderColor: "#0510e6"
  }
});

export default function SimpleCard() {
  const classes = useStyles();
  //  const preventDefault = (event) => event.preventDefault();
  
  return (
      < Container maxWidth="sm">
        <br/>
        <br/>
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                Welcome To
                </Typography>
                <Typography className={classes.title} style={{ textAlign: 'center'}}>
                iBetU
                </Typography>
                <br />
                <Typography variant="body2" component="p" style={{ textAlign: 'center', fontSize: 20}}>
                Bet your friends directly!
                <br />
                {'Or post a bet for anyone to take.'}
                <br />
                {'No Bookie.  No Fee.'}
                <br />
                {'You set the bet!'}
                </Typography>
            </CardContent>
            <CardActions style={{ justifyContent: 'center'}}>
                <Button size="small" variant="contained" className={classes.buttonblue}>
                    <Link href="/login" color="inherit">Login</Link>
                </Button>
                <Button size="small" variant="contained" className={classes.buttonblue}>
                    <Link href="/signup" color="inherit">Sign Up</Link>
                </Button>
            </CardActions>
            <br />
            <CardActions style={{ justifyContent: 'center'}}>
                <Button variant="outlined" size="small" className={classes.buttonclear}>
                    <Link href="/FAQ" >FAQ</Link>
                </Button>
            </CardActions>
        </Card>
    </Container>
  );
}

require('react-dom');
window.React2 = require('react');
console.log(window.React1 === window.React2);