import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import axios from 'axios';

const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        minWidth: 275,
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    table: {
        minWidth: 280,
        maxWidth: 280,
    },
    tableHeader: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
    },
    tableCell: {
        padding: 3,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    tableHeaderName: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        fontSize: 18,
    },
    tableCellName: {
        padding: 8,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        fontSize: 18,
    },
    buttonBetBlue: {
        background: '#0510e6',
        color: 'white',
        minWidth: 80
    },
    formControl: {
        display: "flex",
    },
    buttonGet: {
        background: '#0510e6',
        color: 'white'
    },
});



export default function JoinFriendGroup(){
    let regionRestriction = sessionStorage.getItem('regionRestriction');
    let currentUser = sessionStorage.getItem('currentUser');
    const classes = useStyles();

    const [ groupName, setGroupName ] = React.useState('');
    const [ groupCode, setGroupCode ] = React.useState('');
    const [ serverMessage, setServerMessage ] = React.useState('');

    const handleJoinFriendGroup = async () => {
        let groupDetails = {
            groupName: groupName,
            groupCode: groupCode,
            currentUser: currentUser,
        }

        let joinFriendGroup = await axios.post('/api/group/joinGroup', groupDetails, {} )
        .then( res => {
            setServerMessage(res.data.message);
            if (res.data.success === true ) {
                setOpenJoinedSuccess(true);
            } else {
                setOpenMessageJoinAttempt(true);
            }
        } )
        .catch( err => console.log(err));
    }

    const [ openMessageJoinAttempt, setOpenMessageJoinAttempt ] = React.useState(false);
    const handleCloseMessageJoinAttempt = () => {
        setGroupCode('');
        setOpenMessageJoinAttempt(false);
    }

    const [ openJoinedSuccess, setOpenJoinedSuccess ] = React.useState(false);
    const handleCloseJoinedSuccess = () => {
        setOpenJoinedSuccess(false);
        window.location.pathname ='/home';
    }

    return(
        <div>
            <Card className={classes.root}>
                <CardContent>
                    <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center' }} gutterBottom>
                            Join Group
                    </Typography>
                    <Typography className={classes.title} style={{ textAlign: 'center'}}>
                        iBetU
                    </Typography>
                    <br />
                    <Grid container direction='column' justify="center" alignItems='center'>
                        <TextField
                            required
                            id="groupName"
                            label="Group Name"
                            name="groupName"
                            variant="outlined"
                            value={groupName}
                            placeholder="Enter a name for your group"
                            onChange={ async e => await setGroupName(e.target.value) }
                        />
                        <br/>

                        <TextField
                            required
                            id="groupCode"
                            label="Code"
                            name="groupCode"
                            variant="outlined"
                            value={groupCode}
                            placeholder="Enter code from invitation"
                            onChange={ async e => await setGroupCode(e.target.value) }
                        />
                        <br/>

                        <CardActions>
                            <div>
                                <Button size="medium" variant="contained" className={classes.buttonblue} type='submit' onClick={handleJoinFriendGroup}>Join Group</Button>
                            </div>
                        </CardActions>
                    </Grid>

                    {/* Dialog successful join start ++++++++++++++++ */}
                    <div>
                    <Dialog
                        open={openJoinedSuccess}
                        onClose={handleCloseJoinedSuccess}
                    >
                        <DialogTitle>
                            {serverMessage}
                        </DialogTitle>

                        <DialogActions>
                            <Button onClick={handleCloseJoinedSuccess} color="primary" autoFocus>
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                    </div>
                    {/* Dialog successful join end ------------------ */}

                    {/* Dialog failed join start ++++++++++++++++ */}
                    <div>
                    <Dialog
                        open={openMessageJoinAttempt}
                        onClose={handleCloseMessageJoinAttempt}
                    >
                        <DialogTitle>
                            {serverMessage}
                        </DialogTitle>

                        <DialogActions>
                            <Button onClick={handleCloseMessageJoinAttempt} color="primary" autoFocus>
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                    </div>
                    {/* Dialog failed join end ------------------ */}
                    
                </CardContent>
            </Card>
        </div>
    )
}