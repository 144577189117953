import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import axios from 'axios';

let currentUser = sessionStorage.getItem('currentUser');

const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        minWidth: 275,
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    table: {
        minWidth: 280,
        maxWidth: 280,
    },
    tableHeader: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
    },
    tableCell: {
        padding: 3,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    tableHeaderName: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        fontSize: 18,
    },
    tableCellName: {
        padding: 8,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        fontSize: 18,
    },
    buttonBetBlue: {
        background: '#0510e6',
        color: 'white',
        minWidth: 80
    },
    formControl: {
        display: "flex",
    },
    buttonGet: {
        background: '#0510e6',
        color: 'white'
    },
});

let regionRestriction = sessionStorage.getItem('regionRestriction');

export default function InviteFriend(){
    const classes = useStyles();
    const [ friendEmail, setFriendEmail ] = React.useState('');
    const [ friendPhone, setFriendPhone ] = React.useState('');
    const [ friendCountryCode, setFriendCountryCode ] = React.useState('');
    const [ dialogMessage, setDialogMessage ] = React.useState('');
    const [ openFriendAdded, setOpenFriendAdded ] = React.useState('');

    const CheckNotAllBlank = (input1, input2, input3) => {
        let emailNotBlank = false;
        let ccNotBlank = false;
        let phoneNotBlank = false;
        if ( input1.length > 0 ) { emailNotBlank = true }
        if ( input2.length > 0 ) { ccNotBlank = true }
        if ( input3.length > 0 ) { phoneNotBlank = true }
        let message = "We need at least an email or at least a phone number to invite your friend."
        if ( emailNotBlank || phoneNotBlank ) { return true } else {
            setDialogMessage(message);
            setOpenFriendAdded(true)
        }
    }

    
    const CheckAreaCode  = (input, input2) => {
        if (input2.length > 0 ) { 
            let numberNumOnly = input.replace(/\D/g, '');
            if ( parseInt(numberNumOnly) > 0) { return true } else {
                setDialogMessage("Please enter a valid area code.");
                setOpenFriendAdded(true);
             }
        } else { return true }
    }

    const CheckEmail = (input) => {
        if (input.length > 0 ){
            let atSignCheck = false;
            let periodCheck = false;
            let regex1 = /[@]/;
            let regex2 = /[.]/;

            if ( regex1.test(input) ) { atSignCheck = true }
            if ( regex2.test(input) ) { periodCheck = true }

            if ( atSignCheck && periodCheck ) { return true } else {
                setDialogMessage("Please enter a valid email address.");
                setOpenFriendAdded(true);
            }
        } else { return true }
    }

    const handleCloseFriendAdded = () =>{
        setOpenFriendAdded(false);
        setFriendEmail('');
        setFriendPhone('');
        setFriendCountryCode('');
    }

    const handleAgreeSendInvite = async () => {
        let checkAll = await CheckNotAllBlank( friendEmail, friendCountryCode, friendPhone );
        let checkAC = await CheckAreaCode( friendCountryCode, friendPhone);
        let checkE = await CheckEmail( friendEmail );
        
        if ( checkAll && checkAC && checkE ) {
            let friendPhoneWCC = friendCountryCode + friendPhone;
            let friendPhoneNumOnly = friendPhoneWCC.replace(/\D/g, '');
            let friendDetails = {
                friendPhone: friendPhoneNumOnly,
                friendEmail: friendEmail,
                currentUser: sessionStorage.getItem('currentUser'),
            }

            let inviteFriend = await axios.post('/api/user/inviteFriend', friendDetails, {})
            .then (res => {
                setDialogMessage(res.data);
                setOpenFriendAdded(true);
            })
            .catch( err => { console.log(err)});
        }
    }


    return(
        <div>
            <Card className={classes.root}>
                <CardContent>
                    <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center' }} gutterBottom>
                            Invite a Friend to Join
                    </Typography>

                    <Grid container direction='column' justify="center" alignItems='center'>
                        <Box fontWeight="fontWeightRegular" m={1} fontSize={18}>
                            Games are always more fun when friends are involved!  Invite one of your friends to join iBetU so you can play against them! Please provide your friend's email and/or phone number.
                        </Box>

                        <br/>
                    
                        <TextField
                            required
                            id="friendEmail"
                            label="Friend's Email"
                            name="friendEmail"
                            variant="outlined"
                            value={friendEmail}
                            placeholder="Enter Your Friend's Email"
                            onChange={ async e => await setFriendEmail(e.target.value) }
                        />
                        <br/>

                        <TextField
                            required
                            id="friendCountryCode"
                            label="Country Code for Phone"
                            name="friendCountryCode"
                            variant="outlined"
                            value={friendCountryCode}
                            placeholder="+1"
                            onChange={ async e => await setFriendCountryCode(e.target.value) }
                        />
                        <br/>

                        <TextField
                            required
                            id="friendPhone"
                            label="Friend's Phone Number"
                            name="friendPhone"
                            variant="outlined"
                            value={friendPhone}
                            placeholder="Enter Your Friend's Phone Number"
                            onChange={ async e => await setFriendPhone(e.target.value) }
                        />
                        <br/>

                        <CardActions>
                            <div>
                                <Button size="medium" variant="contained" className={classes.buttonblue} type='submit' onClick={handleAgreeSendInvite}>Send Invitation</Button>
                            </div>
                        </CardActions>
                    </Grid>

                    <div>
                        <Dialog
                            open={openFriendAdded}
                            onClose={handleCloseFriendAdded}
                        >
                            <DialogTitle>{dialogMessage}</DialogTitle>

                            <DialogActions>
                            <Button onClick={handleCloseFriendAdded} color="primary" autoFocus>
                                OK
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </CardContent>

            </Card>
        </div>
    )

}