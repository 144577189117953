import React, { Suspense, lazy } from 'react';

import Container from '@material-ui/core/Container';
import MainAppBar from '../components/MainAppBar';
import PickSport from '../components/PickSport';
import FundsSummary from '../components/FundsSummary';

import Footer from '../components/Footer';
import Copyright from '../components/Copyright';
import checkAuthenticated from '../utils/checkAuthenticated';

// const FundsSummary = React.lazy(() => import('../components/FundsSummary'));


export default function LandingPage() {
  checkAuthenticated();


  return (
    < Container maxWidth="sm">
        <MainAppBar></MainAppBar>
          {/* <div>        
            <Suspense fallback={<div>Loading...</div>}> */}
              <FundsSummary
                fundsAvailable={sessionStorage.getItem('fundsAvailable')}
                fundsAtRisk={sessionStorage.getItem('fundsAtRisk')}
              ></FundsSummary>
              
            {/* </Suspense>
          </div> */}
        <PickSport></PickSport>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
