import React from 'react';

import Container from '@material-ui/core/Container';
import MainAppBar from '../../components/MainAppBar';
import PrivacyPolicy from '../../components/PrivacyPolicy';

import Footer from '../../components/Footer';
import Copyright from '../../components/Copyright';


export default function LandingPage() {

  return (
    < Container maxWidth="sm">
        <MainAppBar></MainAppBar>
        <PrivacyPolicy></PrivacyPolicy>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
