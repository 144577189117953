import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles({

  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    paddingTop: 5,
    paddingBottom: 30
    
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 40,
    fontFamily: 'Anton',
    color: '#0510e6',
    fontStyle: 'italic',
  },
  welcome: {
    fontSize: 22,
    fontStyle: 'bold',
    fontFamily: 'Anton'
  },
  pos: {
    marginBottom: 12,
  },
  buttonblue: {
      background: '#0510e6',
      color: 'white'
  },
  buttonclear: {
      color: "#0510e6",
      borderColor: "#0510e6"
  },
  question: {
      fontSize:  16,
  },
  answer: {
      fontSize: 16,
  },
});

export default function SimpleCard() {
  const classes = useStyles();
  
  return (
      < Container maxWidth="sm">
        <br/>
        <br/>
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                Responsible Gaming
                </Typography>
                <Typography className={classes.title} style={{ textAlign: 'center'}}>
                iBetU
                </Typography>
                <br />
                <Typography components='div'>
                
                  <Box fontWeight="fontWeightBold" m={1}>
                  OUR STANCE
                  </Box>
                  <Box fontWeight="fontWeightRegular" m={1}>
                    At iBetU, we believe that life is more fun with skin in the game and we want it to stay that way. That’s the significance of safer play. It’s important to remember that you should only bet what you can afford and always set reasonable limits for yourself. From the second you open your account to the moment you collect your winnings, our number one priority is to make sure you’re playing safely and responsibly.
                    </Box>
                  <Box fontWeight="fontWeightRegular" m={1}>
                    Stay informed and learn the details of the games you’re playing. If, for any reason, you feel like you need help, we strongly encourage you to contact your local responsible gaming organizations.
                  </Box>
                  <br />
                  <Box fontWeight="fontWeightBold" m={1}>
                  THINGS TO KEEP IN MIND WHEN YOU’RE GAMBLING:
                  </Box>
                  <Box fontWeight="fontWeightRegular" m={1}>
                    Set a realistic budget that you can afford to gamble. Never play beyond your means.
                    Set a time limit on your gambling and stick to it.
                    Don’t place large bets in the hopes of winning big.
                    Don’t try to make money by chasing your losses.
                    Gambling should not be viewed as a pathway to financial gains. Gambling is not a shortcut to financial success.
                    If you feel your gambling is out of control, seek help. Help is available in most states and can be found at the National Center on Problem Gambling
                  </Box>
                <br/>
                <Box fontWeight="fontWeightBold" m={1}>
                  TO PROMOTE RESPONSIBLE GAMING
                </Box>
                <Box fontWeight="fontWeightRegular" m={1}>
                  iBetU will make information available promoting responsible gaming and will provide assistance and resources including a national toll-free helpline number. This information will be available and visible on our websites, apps, retail sportsbooks and in our offices.
                </Box>
                <Box fontWeight="fontWeightRegular" m={1}>
                  iBetU will continually monitor our responsible gaming pages for activity and update with new information when available and necessary.
                </Box>
                <br/>
                <Box fontWeight="fontWeightBold" m={1}>
                  TO PREVENT UNDERAGE GAMBLING
                </Box>
                <Box fontWeight="fontWeightRegular" m={1}>
                  iBetU will take proactive steps to prevent underage gambling.
                  
                  iBetU will communicate the legal age to gamble through messaging on all our platforms and in our promotions. 
                </Box>
                <br/>
                <Box fontWeight="fontWeightBold" m={1}>
                  TO ADVERTISE RESPONSIBLY
                </Box>
                <Box fontWeight="fontWeightRegular" m={1}>
                  iBetU will comply with applicable state and federal advertising standards.
                </Box>
                <Box fontWeight="fontWeightRegular" m={1}>
                  iBetU will not make unfair or deceptive claims or make misleading statements regarding the probabilities of winning or losing at the various games offered. 
                </Box>
                <Box fontWeight="fontWeightRegular" m={1}>
                  iBetU will include a responsible gaming message and/or toll-free helpline number on all our platforms. 
                </Box>
                <Box fontWeight="fontWeightRegular" m={1}>
                  iBetU will comply with industry standard best practices.
                </Box>
                <br/>
                <Box fontWeight="fontWeightBold" m={1}>
                  PLEDGE TO THE PUBLIC
                </Box>
                <Box fontWeight="fontWeightRegular" m={1}>
                  iBetU will equip customers with the tools to gamble and play responsibly.
                </Box>
                <Box fontWeight="fontWeightRegular" m={1}>
                  iBetU will help patrons make informed choices about their gambling by posting information on all our platforms about how to play.
                </Box>
                <Box fontWeight="fontWeightRegular" m={1}>
                  iBetU will support organizations that advocate and promote programs and services that assist problem gamblers and their families.
                </Box>
                <br/>
                <Box fontWeight="fontWeightBold" m={1}>
                  PLEDGE TO OUR EMPLOYEES
                </Box>
                <Box fontWeight="fontWeightRegular" m={1}>
                  iBetU will require all new employees to take responsible gaming training.
                </Box>
                <Box fontWeight="fontWeightRegular" m={1}>
                  iBetU will provide information to new and existing employees about responsible gaming, the company’s policies and practices related to responsible gaming and where to find assistance.
                </Box>
                <Box fontWeight="fontWeightRegular" m={1}>
                  iBetU will provide employees with mandatory annual and/or periodic refresher training on responsible gaming. This training will identify the differences between responsible gaming and gambling that may be problematic.
                </Box>
                <Box fontWeight="fontWeightRegular" m={1}>
                  iBetU will implement communications programs for employees to improve their understanding of responsible gaming and related policies and procedures.
                </Box>
                <Box fontWeight="fontWeightRegular" m={1}>
                  iBetU will provide employees with updates regarding new research and new topics that may be integrated into iBetU’s responsible gaming training programs.
                </Box>

                </Typography>

            </CardContent>
            
        </Card>
    </Container>
  );
}


