import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// import Link from '@material-ui/core/Link';
// import { Redirect } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import axios from 'axios';


const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    question: {
        fontSize: 18,
        fontFamily: 'Anton'
      },
  });


export default function LoginForm() { 
    
    const classes = useStyles();
    const [ email, setEmail ] = React.useState('');
    const [ question, setQuestion ] = React.useState('Security Question');
    const [ answer, setAnswer ] = React.useState('');
    const [ open, setOpen ] = React.useState(false);
    const [ agree, setAgree ] = React.useState(false);

    const handleAgree = () => {
        setAgree(true);
        setOpen(false);
      };

    const [ openEmail, setOpenEmail ] = React.useState(false);
    const [ agreeEmail, setAgreeEmail ] = React.useState(false);

    const handleAgreeEmail = () => {
        setAgreeEmail(true);
        setOpenEmail(false);
    }

    const [ openSentEmail, setOpenSentEmail ] = React.useState(false);
    const [ agreeSentEmail, setAgreeSentEmail ] = React.useState(false);

    const handleAgreeSentEmail = () => {
        setAgreeSentEmail(true);
        setOpenSentEmail(false);
        window.location.pathname = "/login";
    }

    const handleGetQuestion = (event) => {
        event.preventDefault();
        axios.get('/api/user/getquestion/' + email).then(function (res) {
            let response = res.data[0].question;
            if (response === '' ) {
                setOpenEmail(true);
            } else {
                setQuestion(response)
            }
        })
        .catch(error => {
            setOpenEmail(true);
            console.log(error);
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const userDetails = {
            email: email,
            secret: answer,
        }
        console.log( userDetails );

        axios.post('/api/user/requestlogin', userDetails, {
            }).then(response => {
                console.log('login response: ')
                console.log(response)
                if (response.data.error === "Correct Secret") {
                    setOpenSentEmail(true);
                    // update App.js state
                    // this.props.updateUser({
                    //     loggedIn: true,
                    //     username: response.data.username
                    // });
                    // update the state to redirect to home
                    // this.setState({
                    //     redirectTo: '/home'
                    // })
                    // window.location.pathname = "/login";
                } else {
                    setOpen(true);
                    console.log(response.data.message);
                }
            }).catch(error => {
                console.log('login error: ')
                console.log(error);
                setOpen(true);
                // window.location.pathname = "/login";
            })
    }

    return(
        <div>
            < Container maxWidth="sm">
            <br/>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                            Forgotten Login
                        </Typography>
                        <Typography className={classes.title} style={{ textAlign: 'center'}}>
                            iBetU
                        </Typography>
                        <br />
                        
                        <form noValidate autoComplete="off">
                            <Grid container direction='column' justify="center" alignItems='center'>
                                <div>
                                    <TextField
                                        required
                                        id="email"
                                        label="Email"
                                        name="email"
                                        variant="outlined"
                                        value={email}
                                        placeholder="Email"
                                        onChange={ e => setEmail(e.target.value)}
                                        
                                    />
                                </div>
                                
                                <CardActions style={{ justifyContent: 'center'}}>
                                    <div>
                                        <Button size="small" variant="outlined" className={classes.buttonclear}
                                        type='submit'
                                        onClick={handleGetQuestion}
                                        >Get Question</Button>
                                    </div>
                                </CardActions>
                                <br />
                                <div>
                                    <Typography className={classes.question} color='textSecondary' gutterBottom>{question} (Case Sensitive)</Typography>
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="answer"
                                        label="Answer"
                                        name="answer"
                                        variant="outlined"
                                        value={answer}
                                        placeholder="Answer"
                                        onChange={ e => setAnswer(e.target.value) }
                                    />
                                </div>

                                <div>
                                    <Dialog
                                        open={open}
                                        agree={agree}
                                        onClose={handleAgree}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"That combination of email and security question answer is not in the system."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgree} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openEmail}
                                        agree={agreeEmail}
                                        onClose={handleAgreeEmail}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"That email is not in the system."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreeEmail} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openSentEmail}
                                        agree={agreeSentEmail}
                                        onClose={handleAgreeSentEmail}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"An email has been sent to your registered email address.  Please follow instructions to login from there."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreeSentEmail} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <CardActions style={{ justifyContent: 'center'}}>
                                    <div>
                                        <Button size="small" variant="contained" className={classes.buttonblue}
                                        type='submit'
                                        onClick={handleSubmit}
                                        >Submit</Button>
                                    </div>
                                </CardActions>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
        
}


    // constructor() {
    //     super()
    //     this.state = {
    //         username: 'Username',
    //         password: 'Password',
    //         redirectTo: null
    //     }
    //     this.handleSubmit = this.handleSubmit.bind(this);
    //     this.handleChange = this.handleChange.bind(this);
    // }

    // handleChange(event) {
    //     this.setState({
    //         [event.target.name]: event.target.value
    //     })
    // }

    // handleSubmit(event) {
    //     event.preventDefault()
    //     console.log('handleSubmit')

    //     axios
    //         .post('/user/login', {
    //             username: this.state.username,
    //             password: this.state.password
    //         })
    //         .then(response => {
    //             console.log('login response: ')
    //             console.log(response)
    //             if (response.status === 200) {
    //                 // update App.js state
    //                 this.props.updateUser({
    //                     loggedIn: true,
    //                     username: response.data.username
    //                 })
    //                 // update the state to redirect to home
    //                 this.setState({
    //                     redirectTo: '/'
    //                 })
    //             }
    //         }).catch(error => {
    //             console.log('login error: ')
    //             console.log(error);
                
    //         })
    // }