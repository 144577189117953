import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
// import BottomNavigation from '@material-ui/core/BottomNavigation';
// import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import SportsFootballIcon from '@material-ui/icons/SportsFootball';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import SportsHockeyIcon from '@material-ui/icons/SportsHockey';
import CheckRegion from '../utils/checkRegion';
import Grid from '@material-ui/core/Grid';

import axios from 'axios';
import session from 'express-session';

const useStyles = makeStyles({

  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    paddingTop: 5,
    paddingBottom: 30
    
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 60,
    fontFamily: 'Anton',
    color: '#0510e6',
    fontStyle: 'italic',
  },
  welcome: {
    fontSize: 26,
    fontStyle: 'bold',
    fontFamily: 'Anton'
  },
  pos: {
    marginBottom: 12,
  },
  buttonblue: {
      background: '#0510e6',
      color: 'white'
  },
  buttonclear: {
      color: "#0510e6",
      borderColor: "#0510e6"
  },
  buttonRed: {
    background: 'red',
    color: 'white'
},
  sporticon: {
      flexGrow: 1,
  },
  iconButtonLabel: {
    maxWidth: 50,
    fontSize: 16,
    display: 'flex',
    flexDirection: 'column',
  },
});

export default function SimpleCard() {
  
  const classes = useStyles();
  let currentUser = sessionStorage.getItem("loginAttempt");
  const [ checkForFriends, setCheckForFriends ] = React.useState(true);
  const [ haveFriendBet, setHaveFriendBet ] = React.useState(false);
  const [ checkForGroupBet, setCheckForGroupBet ] = React.useState(true);
  const [ haveGroupBet, setHaveGroupBet ] = React.useState(false);
  const [ groupWithBet, setGroupWithBet ] = React.useState();
  const [ checkForPromotions, setCheckForPromotions ] = React.useState(true);
  const [ havePromotions, setHavePromotions ] = React.useState(false);


  // Dialog for bets not legal
  const [ openIllegalBet, setOpenIllegalBet ] = React.useState(false);
  const [ agreeIllegalBet, setAgreeIllegalBet ] = React.useState(false);
  
  const handleAgreeIllegalBet = () => {
    setAgreeIllegalBet(true);
    setOpenIllegalBet(false)
  }

  // Dialog for have friend bets
  const [ openHaveFriendBets, setOpenHaveFriendBets ] = React.useState(false);
  const [ agreeHaveFriendBets, setAgreeHaveFriendBets ] = React.useState(false);

  const handleAgreeHaveFriendBets = () => {
    setAgreeHaveFriendBets(true);
    setOpenHaveFriendBets(false);
    window.location.pathname = '/friendbets';
  }

  const handleIgnoreFriendBets = () => {
    setAgreeHaveFriendBets(false);
    setCheckForFriends(false);
    setOpenHaveFriendBets(false);
  }

// Dialog for promotion available
const [ openHavePromotion, setOpenHavePromotion ] = React.useState(false);
const [ agreeHavePromotion, setAgreeHavePromotion ] = React.useState(false);

const handleAgreeHavePromotion = () => {
  setAgreeHavePromotion(true);
  setOpenHavePromotion(false);
}

const handleIgnorePromotion = () => {
  setAgreeHavePromotion(false);
  setCheckForPromotions(false);
  setOpenHavePromotion(false);
}

  
// Click to different leagues
  const handleGoLigaMX = (event) =>{
    event.preventDefault(event);
    let regionRestriction = sessionStorage.getItem('regionRestriction');
    if (regionRestriction === 'illegal') { setOpenIllegalBet(true) } else {
    window.location.pathname = '/choosepathligamx'
    }
  }

  const handleGoPremier = (event) =>{
    event.preventDefault(event);
    let regionRestriction = sessionStorage.getItem('regionRestriction');
    if (regionRestriction === 'illegal') { setOpenIllegalBet(true) } else {
    window.location.pathname = '/choosepathpremier'
    }
  }

  const handleGoNBA = (event) =>{
    event.preventDefault(event);
    let regionRestriction = sessionStorage.getItem('regionRestriction');
    if (regionRestriction === 'illegal') { setOpenIllegalBet(true) } else {
    window.location.pathname = '/choosepathnba'
    }
  }

  const handleGoUEFA = (event) =>{
    event.preventDefault(event);
    let regionRestriction = sessionStorage.getItem('regionRestriction');
    if (regionRestriction === 'illegal') { setOpenIllegalBet(true) } else {
    window.location.pathname = '/choosepathuefa'
    }
  }

  const handleGoNFL = (event) =>{
    event.preventDefault(event);
    let regionRestriction = sessionStorage.getItem('regionRestriction');
    if (regionRestriction === 'illegal') { setOpenIllegalBet(true) } else {
    window.location.pathname = '/choosepathnfl'
    }
  }

  const handleGoCFB = (event) =>{
    event.preventDefault(event);
    let regionRestriction = sessionStorage.getItem('regionRestriction');
    if (regionRestriction === 'illegal') { setOpenIllegalBet(true) } else {
    window.location.pathname = '/choosepathcfb'
    }
  }

  const handleGoMLB = (event) =>{
    event.preventDefault(event);
    let regionRestriction = sessionStorage.getItem('regionRestriction');
    if (regionRestriction === 'illegal') { setOpenIllegalBet(true) } else {
    window.location.pathname = '/choosepathmlb'
    }
  }

  const handleGoNHL = (event) =>{
    event.preventDefault(event);
    let regionRestriction = sessionStorage.getItem('regionRestriction');
    if (regionRestriction === 'illegal') { setOpenIllegalBet(true) } else {
    window.location.pathname = '/choosepathnhl'
    }
  }

  const handleGoBundesliga = (event) =>{
    event.preventDefault(event);
    let regionRestriction = sessionStorage.getItem('regionRestriction');
    if (regionRestriction === 'illegal') { setOpenIllegalBet(true) } else {
    window.location.pathname = '/choosepathbundesliga'
    }
  }

  const handleGoSerieA = (event) =>{
    event.preventDefault(event);
    let regionRestriction = sessionStorage.getItem('regionRestriction');
    if (regionRestriction === 'illegal') { setOpenIllegalBet(true) } else {
    window.location.pathname = '/choosepathseriea'
    }
  }

  const handleGoLaLiga = (event) =>{
    event.preventDefault(event);
    let regionRestriction = sessionStorage.getItem('regionRestriction');
    if (regionRestriction === 'illegal') { setOpenIllegalBet(true) } else {
    window.location.pathname = '/choosepathlaliga'
    }
  }

  const handleGoFIFAWC = (event) =>{
    event.preventDefault(event);
    let regionRestriction = sessionStorage.getItem('regionRestriction');
    if (regionRestriction === 'illegal') { setOpenIllegalBet(true) } else {
    window.location.pathname = '/choosepathworldcup'
    }
  }

//  Check for Friends & Buttons +++++++++++++++++++++++
if (checkForFriends === true ) {
    axios.get('/api/betsProposed/checkfriendbets/' + currentUser)
    .then( function (res) {
      if ( res.data.data.length > 0 ) {
        // setOpenHaveFriendBets(true);
        setHaveFriendBet(true);
      } else {  console.log("no proposed friend bets") }
    })
    .catch(error => {
      console.log(error)
    })
    setCheckForFriends(false);
  }

const handleGoToFriendBet = () => {
    window.location.pathname = '/friendbets'
}

if (checkForGroupBet === true ) {
    axios.get('/api/betsProposed/checkgroupbets/' + currentUser)
    .then( function (res) {
       if (res.data.length > 0 ){
          setHaveGroupBet(true);
          setGroupWithBet(res.data[0]);
          sessionStorage.setItem('currentGroup', res.data[0]);
       } else { console.log("no bets to groups") }
    })
    .catch( error => {
      console.log(error);
    })
    setCheckForGroupBet(false);
}

const handleGoToGroupBet = () => {
    window.location.pathname = '/groupbetsproposed';
}

//  Check for Promotions +++++++++++++++++++++++++++++++

if (checkForPromotions === true ) {
  let date = new Date();
  let today = date;
  axios.get('/api/promotion/checkpromotions/' + currentUser)
  .then( async function (res) {
    console.log(res.data);
    // let promotionData = res.data.data[0];
    if (res.data.message == "no_data") {
      sessionStorage.setItem('promotionAvailable', false)
    } else if ( res.data.data.length > 0 ) {
        let promotionData = res.data.data[0];
        date.setDate(date.getDate() + promotionData.daysToUseFunds);
        try {
          let update1 = await setHavePromotions(true);
          sessionStorage.setItem('promotionAvailable', true);
        } catch(error) { console.log(error)}
    } else {  sessionStorage.setItem('promotionAvailable', false) };
  })
  .catch(error => {
    console.log(error)
  })
}

const handleGoToPromotion = () => {
  window.location.pathname = "/promotion_offer";
}

//  Get and transfer promotion data to user +++++++++++++++++++++++++++
const handleAcceptPromotion = () => {
  
}

  
  return (
      < Container maxWidth="sm">
        <br/>
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                Pick a Sport
                </Typography>
                <Typography className={classes.title} style={{ textAlign: 'center'}}>
                iBetU
                </Typography>
            </CardContent> 

            <FriendBetButton haveFriendBetProposal={haveFriendBet} onClick={handleGoToFriendBet}/>

            <GroupBetButton haveGroupBetProposal={haveGroupBet} onClick={handleGoToGroupBet}/>

            <PromotionButton havePromotionProposal={havePromotions} onClick={handleGoToPromotion}/>

            <CardActions style={{ justifyContent: 'center'}}>
                <IconButton label="NFL" classes={{root: classes.sporticon, label: classes.iconButtonLabel}} onClick={handleGoNFL}>
                  <SportsFootballIcon />
                  <div>NFL</div>
                </IconButton>
                <IconButton label="Premier League" classes={{root: classes.sporticon, label: classes.iconButtonLabel}} onClick={handleGoPremier}>
                  <SportsSoccerIcon />
                  <div>Premier League</div>
                </IconButton>
                <IconButton label="College Football" classes={{root: classes.sporticon, label: classes.iconButtonLabel}} onClick={handleGoCFB}>
                  <SportsFootballIcon />
                  <div>College Football</div>
                </IconButton>
            </CardActions>
            <CardActions style={{ justifyContent: 'center'}}>
                <IconButton label="NBA" classes={{root: classes.sporticon, label: classes.iconButtonLabel}} onClick={handleGoNBA}>
                  <SportsBasketballIcon />
                  <div>NBA</div>
                </IconButton>
                <IconButton label="LigaMX" classes={{root: classes.sporticon, label: classes.iconButtonLabel}} onClick={handleGoLigaMX}>
                  <SportsSoccerIcon />
                  <div>LigaMX</div>
                </IconButton>
                <IconButton label="UEFA Champions League" classes={{root: classes.sporticon, label: classes.iconButtonLabel}} onClick={handleGoUEFA}>
                  <SportsSoccerIcon />
                  <div>UEFA Champions League</div>
                </IconButton>
            </CardActions>
            <CardActions style={{ justifyContent: 'center'}}>
                <IconButton label="MLB" classes={{root: classes.sporticon, label: classes.iconButtonLabel}} onClick={handleGoMLB}>
                  <SportsBaseballIcon />
                  <div>MLB</div>
                </IconButton>
                <IconButton label="NHL" classes={{root: classes.sporticon, label: classes.iconButtonLabel}} onClick={handleGoNHL}>
                  <SportsHockeyIcon />
                  <div>NHL</div>
                </IconButton>
                <IconButton label="Bundesliga" classes={{root: classes.sporticon, label: classes.iconButtonLabel}} onClick={handleGoBundesliga}>
                  <SportsSoccerIcon />
                  <div>Bundesliga</div>
                </IconButton>
            </CardActions>
            <CardActions style={{ justifyContent: 'center'}}>
                <IconButton label="Serie A" classes={{root: classes.sporticon, label: classes.iconButtonLabel}} onClick={handleGoSerieA}>
                  <SportsSoccerIcon />
                  <div>Serie A</div>
                </IconButton>
                <IconButton label="La Liga" classes={{root: classes.sporticon, label: classes.iconButtonLabel}} onClick={handleGoLaLiga}>
                  <SportsSoccerIcon />
                  <div>La Liga</div>
                </IconButton>
                <IconButton label="FIFA World Cup" classes={{root: classes.sporticon, label: classes.iconButtonLabel}} onClick={handleGoFIFAWC}>
                  <SportsSoccerIcon />
                  <div>FIFA World Cup</div>
                </IconButton>
            </CardActions>

            {/* Dialog for cannot bet in Region +++++++++++++++++++++++ */}
            <div>
                <Dialog
                    open={openIllegalBet}
                    agree={agreeIllegalBet}
                    onClose={handleAgreeIllegalBet}
                >
                    <DialogTitle >{"We are sorry, but on-line sports betting is not legal in the region you are in.  Please write your state / province representative."}</DialogTitle>

                    <DialogActions>
                    <Button onClick={handleAgreeIllegalBet} color="primary" autoFocus>
                        OK
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
            {/* Dialog for cannot bet self end ======================= */}

            {/* Dialog for friend bet exists +++++++++++++++++++++++ */}
            <div>
                <Dialog
                    open={openHaveFriendBets}
                    agree={agreeHaveFriendBets}
                >
                    <DialogTitle >{"A friend has proposed a bet to you directly. Do you want to go to this bet proposal?"}</DialogTitle>

                    <DialogActions>
                    <Grid container spacing ={1}>
                        <Grid item xs={4}>
                            <Button onClick={handleIgnoreFriendBets} align="center" color="secondary" variant="outlined" >
                            No
                            </Button>
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <Button onClick={handleAgreeHaveFriendBets} color="primary" variant="outlined" className={classes.buttonBetBlue} 
                            align="center">
                            Yes
                            </Button>
                        </Grid>
                    
                    </Grid>
                    </DialogActions>
                </Dialog>
            </div>
            {/* Dialog for friend bet exists end ======================= */}

            {/* Dialog for promotions exists +++++++++++++++++++++++ */}
            {/* <div>
                <Dialog
                    open={openHavePromotion}
                    agree={agreeHavePromotion}
                >
                    <DialogTitle >{ promotionMessage + " Do you want the promotion?"}</DialogTitle>

                    <DialogActions>
                    <Grid container spacing ={1}>
                        <Grid item xs={4}>
                            <Button onClick={handleIgnorePromotion} align="center" color="secondary" variant="outlined" >
                            No
                            </Button>
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <Button onClick={handleAgreeHavePromotion} color="primary" variant="outlined" className={classes.buttonBetBlue} 
                            align="center">
                            Yes
                            </Button>
                        </Grid>
                    
                    </Grid>
                    </DialogActions>
                </Dialog>
            </div> */}
            {/* Dialog for promotions exists end ======================= */}

        </Card>
        <br />
    </Container>
  );
}

//  Functions for conditional rendering
function FriendBetButton(props){
  const classes = useStyles();
  const haveFriendBetProposal = props.haveFriendBetProposal;
  if ( haveFriendBetProposal === true ) {
    return (
        <CardActions className={classes.root}>
          <Button className={classes.buttonRed} align="center" onClick={props.onClick}>
            See Friend's Bet Proposal
          </Button>
        </CardActions>
      );
  } else { return null }
} 

function GroupBetButton(props){
  const classes = useStyles();
  const haveGroupBetProposal = props.haveGroupBetProposal;
  if ( haveGroupBetProposal === true ) {
    return (
        <CardActions className={classes.root}>
          <Button className={classes.buttonRed} align="center" onClick={props.onClick}>
            See Group Bet Proposals
          </Button>
        </CardActions>
      );
  } else { return null }
} 

function PromotionButton(props){
  const classes = useStyles();
  const havePromotionProposal = props.havePromotionProposal;
  if ( havePromotionProposal === true ) {
    return (
        <CardActions className={classes.root}>
          <Button className={classes.buttonRed} align="center" onClick={props.onClick}>
            See Promotion Available
          </Button>
        </CardActions>
      );
  } else { return null }
} 





      //     <BottomNavigation
      //     value={value}
      //     onChange={(event, newValue) => {
      //       setValue(newValue);
      //     }}
      //     showLabels
      //     className={classes.sporticon}
      // >
      //     <BottomNavigationAction label="NFL" icon={<SportsFootballIcon/>} color='primary' />
      //     <BottomNavigationAction label="NBA" icon={<SportsBasketballIcon />} />
      //     <BottomNavigationAction label="Premier League" icon={<SportsSoccerIcon />} />
      //     <br/>
      // </BottomNavigation>
      // <BottomNavigation
      //     value={value}
      //     onChange={(event, newValue) => {
      //       setValue(newValue);
      //     }}
      //     showLabels
      //     className={classes.sporticon}
      // >
      //     <BottomNavigationAction label="College Football" icon={<SportsFootballIcon />} />
      //     <BottomNavigationAction label="LigaMX" icon={<SportsSoccerIcon />} />
      //     <BottomNavigationAction label="UEFA Champions League" icon={<SportsSoccerIcon />} />
      // </BottomNavigation>