import React, {Component} from 'react';
import {  Grid,  } from "@material-ui/core";
// import { makeStyles, useTheme, withTheme } from "@material-ui/core/styles";
import axios from 'axios';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import LandingPage from "./pages/LandingPage";
import FAQpageNotLogged from "./pages/FAQpageNotLogged";
import FAQPageLoggedIn from "./pages/FAQPageLoggedIn";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import HomePage from "./pages/HomePage";
import ForgottenLoginPage from "./pages/ForgottenLoginPage";
import MyProfilePage from "./pages/MyProfilePage";
import EditProfilePage from './pages/EditProfilePage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import AccountHistoryPage from './pages/AccountHistoryPage';
import DepositChoicePage from './pages/FundingPages/DepositChoicesPage';
import DepositBankPage from './pages/FundingPages/DepositBankPage';
import DepositPayPalPage from './pages/FundingPages/DepositPayPalPage';
import AddBankPage from './pages/FundingPages/AddBankPage';
import WithdrawChoicePage from './pages/FundingPages/WithdrawlChoicesPage';
import WithdrawBankPage from './pages/FundingPages/WithdrawBankPage';
import WithdrawPaypalPage from './pages/FundingPages/WithdrawPaypalPage';
import UnacceptedBetsPage from './pages/UnacceptedBetsPage';
import FriendBetsPage from './pages/FriendBetsPage';
import FriendsLiveBetsPage from './pages/FriendsLiveBetsPage';
import FriendPastBetsPage from './pages/FriendPastBetsPage';
import PublicLiveBetsPage from './pages/PublicLiveBetsPage';
import PublicPastBetsPage from './pages/PublicPastBetsPage';
import RecentPublicBetsPage from './pages/RecentPublicBetsPage';
import MfaPage from './pages/MfaPage';
import ChangeMFAPage from './pages/ChangeMFAPage';
import TermsOfUsePage from './pages/FooterPages/TermsOfUsePage';
import PrivacyPolicyPage from './pages/FooterPages/PrivacyPolicyPage';
import ResponsibleGamingPage from './pages/FooterPages/ResponsibleGamingPage';
import TermsOfUsePageNotLogged from './pages/FooterPages/TermsOfUsePageNotLogged';
import PrivacyPolicyPageNotLogged from './pages/FooterPages/PrivacyPolicyPageNotLogged';
import ResponsibleGamingPageNotLogged from './pages/FooterPages/ResponsibleGamingPageNotLogged';
import ContactPage from './pages/ContactPage';
import UpdatingServerPage from './pages/UpdatingServerPage';
import InviteFriendPage from './pages/InviteFriendPage';
import PromotionCreatePage from './pages/PromotionCreatePage';
import PromotionOfferPage from './pages/PromotionOfferPage';

import CreateGroupPage from './pages/GroupPages/CreateGroupPage';
import JoinGroupPage from './pages/GroupPages/JoinGroupPage';
import YourGroupPage from './pages/GroupPages/YourGroupsPage';
import GroupBetsPage from './pages/GroupPages/GroupBetsMainPage';
import GroupBetsProposedPage from './pages/GroupPages/GroupBetsProposedPage';
import GroupLiveBetsPage from './pages/GroupPages/GroupLiveBetsPage';
import GroupPastBetsPage from './pages/GroupPages/GroupPastBetsPage';

import ChoosePathLigaMXPage from './pages/LigaMXpages/ChoosePathLigaMXpage';
import UpcomingGamesLigaMXPage from './pages/LigaMXpages/UpcomingGamesLigaMXpage';
import ChooseTeamLigaMXPage from './pages/LigaMXpages/ChooseTeamLigaMXpage';
import PostedBetsUpcomingLigaMXPage from './pages/LigaMXpages/PostedBetsUpcomingLigaMXpage';
import PostedBetsByTeamLigaMXPage from './pages/LigaMXpages/PostedBetsByTeamLigaMXpage';
import PostedBetsByDateLigaMXPage from './pages/LigaMXpages/PostedBetsByDateLigaMXpage';

import ChoosePathPremierPage from './pages/PremierPages/ChoosePathPremierPage';
import UpcomingGamesPremierPage from './pages/PremierPages/UpcomingGamesPremierPage';
import ChooseTeamPremierPage from './pages/PremierPages/ChooseTeamPremierPage';
import PostedBetsUpcomingPremierPage from './pages/PremierPages/PostedBetsUpcomingPremierPage';
import PostedBetsByTeamPremierPage from './pages/PremierPages/PostedBetsByTeamPremierPage';
import PostedBetsByDatePremierPage from './pages/PremierPages/PostedBetsByDatePremierPage';

import ChoosePathNBAPage from './pages/NBAPages/ChoosePathNBAPage';
import UpcomingGamesNBAPage from './pages/NBAPages/UpcomingGamesNBAPage';
import ChooseTeamNBAPage from './pages/NBAPages/ChooseTeamNBAPage';
import PostedBetsUpcomingNBAPage from './pages/NBAPages/PostedBetsUpcomingNBAPage';
import PostedBetsByTeamNBAPage from './pages/NBAPages/PostedBetsByTeamNBAPage';
import PostedBetsByDateNBAPage from './pages/NBAPages/PostedBetsByDateNBAPage';

import ChoosePathUEFAPage from './pages/UEFAPages/ChoosePathUEFAPage';
import UpcomingGamesUEFAPage from './pages/UEFAPages/UpcomingGamesUEFAPage';
import ChooseTeamUEFAPage from './pages/UEFAPages/ChooseTeamUEFAPage';
import PostedBetsUpcomingUEFAPage from './pages/UEFAPages/PostedBetsUpcomingUEFAPage';
import PostedBetsByTeamUEFAPage from './pages/UEFAPages/PostedBetsByTeamUEFAPage';
import PostedBetsByDateUEFAPage from './pages/UEFAPages/PostedBetsByDateUEFAPage';

import ChoosePathNFLPage from './pages/NFLPages/ChoosePathNFLPage';
import UpcomingGamesNFLPage from './pages/NFLPages/UpcomingGamesNFLPage';
import ChooseTeamNFLPage from './pages/NFLPages/ChooseTeamNFLPage';
import PostedBetsUpcomingNFLPage from './pages/NFLPages/PostedBetsUpcomingNFLPage';
import PostedBetsByTeamNFLPage from './pages/NFLPages/PostedBetsByTeamNFLPage';
import PostedBetsByDateNFLPage from './pages/NFLPages/PostedBetsByDateNFLPage';

import ChoosePathCFBPage from './pages/CFBPages/ChoosePathCFBPage';
import UpcomingGamesCFBPage from './pages/CFBPages/UpcomingGamesCFBPage';
import ChooseTeamCFBPage from './pages/CFBPages/ChooseTeamCFBPage';
import PostedBetsUpcomingCFBPage from './pages/CFBPages/PostedBetsUpcomingCFBPage';
import PostedBetsByTeamCFBPage from './pages/CFBPages/PostedBetsByTeamCFBPage';
import PostedBetsByDateCFBPage from './pages/CFBPages/PostedBetsByDateCFBPage';

import ChoosePathBundesligaPage from './pages/BundesligaPages/ChoosePathBundesligaPage';
import UpcomingGamesBundesligaPage from './pages/BundesligaPages/UpcomingGamesBundesligaPage';
import ChooseTeamBundesligaPage from './pages/BundesligaPages/ChooseTeamBundesligaPage';
import PostedBetsUpcomingBundesligaPage from './pages/BundesligaPages/PostedBetsUpcomingBundesligaPage';
import PostedBetsByTeamBundesligaPage from './pages/BundesligaPages/PostedBetsByTeamBundesligaPage';
import PostedBetsByDateBundesligaPage from './pages/BundesligaPages/PostedBetsByDateBundesligaPage';

import ChoosePathSerieAPage from './pages/SerieAPages/ChoosePathSerieAPage';
import UpcomingGamesSerieAPage from './pages/SerieAPages/UpcomingGamesSerieAPage';
import ChooseTeamSerieAPage from './pages/SerieAPages/ChooseTeamSerieAPage';
import PostedBetsUpcomingSerieAPage from './pages/SerieAPages/PostedBetsUpcomingSerieAPage';
import PostedBetsByTeamSerieAPage from './pages/SerieAPages/PostedBetsByTeamSerieAPage';
import PostedBetsByDateSerieAPage from './pages/SerieAPages/PostedBetsByDateSerieAPage';

import ChoosePathNHLPage from './pages/NHLPages/ChoosePathNHLPage';
import UpcomingGamesNHLPage from './pages/NHLPages/UpcomingGamesNHLPage';
import ChooseTeamNHLPage from './pages/NHLPages/ChooseTeamNHLPage';
import PostedBetsUpcomingNHLPage from './pages/NHLPages/PostedBetsUpcomingNHLPage';
import PostedBetsByTeamNHLPage from './pages/NHLPages/PostedBetsByTeamNHLPage';
import PostedBetsByDateNHLPage from './pages/NHLPages/PostedBetsByDateNHLPage';

import ChoosePathMLBPage from './pages/MLBPages/ChoosePathMLBPage';
import UpcomingGamesMLBPage from './pages/MLBPages/UpcomingGamesMLBPage';
import ChooseTeamMLBPage from './pages/MLBPages/ChooseTeamMLBPage';
import PostedBetsUpcomingMLBPage from './pages/MLBPages/PostedBetsUpcomingMLBPage';
import PostedBetsByTeamMLBPage from './pages/MLBPages/PostedBetsByTeamMLBPage';
import PostedBetsByDateMLBPage from './pages/MLBPages/PostedBetsByDateMLBPage';

import ChoosePathLaLigaPage from './pages/LaLigaPages/ChoosePathLaLigaPage';
import UpcomingGamesLaLigaPage from './pages/LaLigaPages/UpcomingGamesLaLigaPage';
import ChooseTeamLaLigaPage from './pages/LaLigaPages/ChooseTeamLaLigaPage';
import PostedBetsUpcomingLaLigaPage from './pages/LaLigaPages/PostedBetsUpcomingLaLigaPage';
import PostedBetsByTeamLaLigaPage from './pages/LaLigaPages/PostedBetsByTeamLaLigaPage';
import PostedBetsByDateLaLigaPage from './pages/LaLigaPages/PostedBetsByDateLaLigaPage';

import ChoosePathWorldCupPage from './pages/WorldCupPages/ChoosePathWorldCupPage';
import UpcomingGamesWorldCupPage from './pages/WorldCupPages/UpcomingGamesWorldCupPage';
import ChooseTeamWorldCupPage from './pages/WorldCupPages/ChooseTeamWorldCupPage';
import PostedBetsUpcomingWorldCupPage from './pages/WorldCupPages/PostedBetsUpcomingWorldCupPage';
import PostedBetsByTeamWorldCupPage from './pages/WorldCupPages/PostedBetsByTeamWorldCupPage';
import PostedBetsByDateWorldCupPage from './pages/WorldCupPages/PostedBetsByDateWorldCupPage';

import "./App.css";
import session from 'express-session';
//  As a Class =============(was working before)

class App extends Component {
  constructor() {
    super()
    this.state = {
      loggedIn: false,
      username: null
    }

    this.getUser = this.getUser.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
    this.updateUser = this.updateUser.bind(this)
  }

  componentDidMount() {
    this.getUser()
  }

  updateUser (userObject) {
    this.setState(userObject)
  }

  getUser() {
    let countryClaimed = sessionStorage.getItem('countryClaimed');
    axios.get('/api/user/').then(response => {
      if (response.data.user) {
        // console.log('Get User: There is a user saved in the server session: ')
        this.setState({
          loggedIn: true,
          username: response.data.user.username
        })
        sessionStorage.setItem("currentUser", response.data.user.username);
        sessionStorage.setItem("userID", response.data.user._id);
        sessionStorage.setItem("currentCountry", response.data.currentCountry);
        sessionStorage.setItem("currentRegion", response.data.currentRegion);
        sessionStorage.setItem("regionRestriction", response.data.regionRestriction);
        sessionStorage.setItem("fundsAvailable", response.data.fundsAvailable);
        sessionStorage.setItem("fundsAtRisk", response.data.fundsAtRisk);
        sessionStorage.setItem("firstName", response.data.firstName);
        sessionStorage.setItem("lastName", response.data.lastName);
        console.log("CURRENT IP: " + response.data.currentIP);
      } else {
        // console.log('Get user: no user');
        this.setState({
          loggedIn: false,
          username: null
        })
        sessionStorage.removeItem("currentUser");
        sessionStorage.removeItem("userID");
        sessionStorage.removeItem("currentCountry");
        sessionStorage.removeItem("currentRegion");
        sessionStorage.removeItem("regionRestriction");
        sessionStorage.removeItem("fundsAvailable");
        sessionStorage.removeItem("fundsAtRisk");
        sessionStorage.removeItem("firstName");
        sessionStorage.removeItem("lastName");
        sessionStorage.removeItem("updating");
        sessionStorage.removeItem("countryClaimed");
        sessionStorage.removeItem("currentGroup");
        sessionStorage.removeItem("updating");
      }
    })
  }


  render(){
    return (
      <Router>
        <div>
            <Grid container fluid>
              <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route exact path="/FAQ" component={FAQpageNotLogged} />
                <Route exact path="/FAQlogged" component={FAQPageLoggedIn} />
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/signup" component={SignUpPage} />
                <Route exact path="/home" component={HomePage} />
                <Route exact path="/forgotlogin" component={ForgottenLoginPage} />
                <Route exact path="/myprofile" component={MyProfilePage} />
                <Route exact path="/editprofile" component={EditProfilePage} />
                <Route exact path="/changepassword" component={ChangePasswordPage} />
                <Route exact path="/accounthistory" component={AccountHistoryPage} />
                <Route exact path="/depositchoice" component={DepositChoicePage} />
                <Route exact path ="/depositfrombank" component={DepositBankPage} />
                <Route exact path ="/depositfrompaypal" component={DepositPayPalPage} />
                <Route exact path="/addbank" component={AddBankPage} />
                <Route exact path="/withdrawchoice" component={WithdrawChoicePage} />
                <Route exact path="/withdrawbank" component={WithdrawBankPage} />
                <Route exact path="/withdrawtopaypal" component={WithdrawPaypalPage} />
                <Route exact path='/unacceptedbets' component={UnacceptedBetsPage} />
                <Route exact path="/friendbets" component={FriendBetsPage} />
                <Route exact path="/friendslivebets" component={FriendsLiveBetsPage} />
                <Route exact path="/friendpastbets" component={FriendPastBetsPage} />
                <Route exact path="/publiclivebets" component={PublicLiveBetsPage} />
                <Route exact path="/publicpastbets" component={PublicPastBetsPage} />
                <Route exact path="/recentpublicbets" component={RecentPublicBetsPage} />
                <Route exact path="/mfa" component={MfaPage} />
                <Route exact path="/changemfa" component={ChangeMFAPage} />
                <Route exact path="/termsofuse" component={TermsOfUsePage} />
                <Route exact path="/privacypolicy" component={PrivacyPolicyPage} />
                <Route exact path="/responsiblegaming" component={ResponsibleGamingPage} />
                <Route exact path="/termsofuse2" component={TermsOfUsePageNotLogged} />
                <Route exact path="/privacypolicy2" component={PrivacyPolicyPageNotLogged} />
                <Route exact path="/responsiblegaming2" component={ResponsibleGamingPageNotLogged} />
                <Route exact path="/contact-us" component={ContactPage} />
                <Route exact path="/updating_server" component={UpdatingServerPage} />
                <Route exact path="/invite_friend" component={InviteFriendPage} />
                <Route exact path="/promotion_create" component={PromotionCreatePage} />
                <Route exact path="/promotion_offer" component={PromotionOfferPage} />

                <Route exact path="/create_group" component={CreateGroupPage} />
                <Route exact path="/join_group" component={JoinGroupPage} />
                <Route exact path="/your_groups" component={YourGroupPage} />
                <Route exact path="/group_bets" component={GroupBetsPage} />
                <Route exact path='/groupbetsproposed' component={GroupBetsProposedPage} />
                <Route exact path='/grouplivebets' component={GroupLiveBetsPage} />
                <Route exact path='/grouppastbets' component={GroupPastBetsPage} />
                
                <Route exact path="/choosepathligamx" component={ChoosePathLigaMXPage} />
                <Route exact path="/upcominggamesligamx" component={UpcomingGamesLigaMXPage} />
                <Route exact path="/chooseteamligamx" component={ChooseTeamLigaMXPage} />
                <Route exact path="/postedbetsligamx" component={PostedBetsUpcomingLigaMXPage} />
                <Route exact path="/postedbetsbyteamligamx" component={PostedBetsByTeamLigaMXPage} />
                <Route exact path="/postedbetsbydateligamx" component={PostedBetsByDateLigaMXPage} />
              
                <Route exact path="/choosepathpremier" component={ChoosePathPremierPage} />
                <Route exact path="/upcominggamespremier" component={UpcomingGamesPremierPage} />
                <Route exact path="/chooseteampremier" component={ChooseTeamPremierPage} />
                <Route exact path="/postedbetspremier" component={PostedBetsUpcomingPremierPage} />
                <Route exact path="/postedbetsbyteampremier" component={PostedBetsByTeamPremierPage} />
                <Route exact path="/postedbetsbydatepremier" component={PostedBetsByDatePremierPage} />
              
                <Route exact path="/choosepathnba" component={ChoosePathNBAPage} />
                <Route exact path="/upcominggamesnba" component={UpcomingGamesNBAPage} />
                <Route exact path="/chooseteamnba" component={ChooseTeamNBAPage} />
                <Route exact path="/postedbetsnba" component={PostedBetsUpcomingNBAPage} />
                <Route exact path="/postedbetsbyteamnba" component={PostedBetsByTeamNBAPage} />
                <Route exact path="/postedbetsbydatenba" component={PostedBetsByDateNBAPage} />

                <Route exact path="/choosepathuefa" component={ChoosePathUEFAPage} />
                <Route exact path="/upcominggamesuefa" component={UpcomingGamesUEFAPage} />
                <Route exact path="/chooseteamuefa" component={ChooseTeamUEFAPage} />
                <Route exact path="/postedbetsuefa" component={PostedBetsUpcomingUEFAPage} />
                <Route exact path="/postedbetsbyteamuefa" component={PostedBetsByTeamUEFAPage} />
                <Route exact path="/postedbetsbydateuefa" component={PostedBetsByDateUEFAPage} />
              
                <Route exact path="/choosepathnfl" component={ChoosePathNFLPage} />
                <Route exact path="/upcominggamesnfl" component={UpcomingGamesNFLPage} />
                <Route exact path="/chooseteamnfl" component={ChooseTeamNFLPage} />
                <Route exact path="/postedbetsnfl" component={PostedBetsUpcomingNFLPage} />
                <Route exact path="/postedbetsbyteamnfl" component={PostedBetsByTeamNFLPage} />
                <Route exact path="/postedbetsbydatenfl" component={PostedBetsByDateNFLPage} />

                <Route exact path="/choosepathcfb" component={ChoosePathCFBPage} />
                <Route exact path="/upcominggamescfb" component={UpcomingGamesCFBPage} />
                <Route exact path="/chooseteamcfb" component={ChooseTeamCFBPage} />
                <Route exact path="/postedbetscfb" component={PostedBetsUpcomingCFBPage} />
                <Route exact path="/postedbetsbyteamcfb" component={PostedBetsByTeamCFBPage} />
                <Route exact path="/postedbetsbydatecfb" component={PostedBetsByDateCFBPage} />

                <Route exact path="/choosepathbundesliga" component={ChoosePathBundesligaPage} />
                <Route exact path="/upcominggamesbundesliga" component={UpcomingGamesBundesligaPage} />
                <Route exact path="/chooseteambundesliga" component={ChooseTeamBundesligaPage} />
                <Route exact path="/postedbetsbundesliga" component={PostedBetsUpcomingBundesligaPage} />
                <Route exact path="/postedbetsbyteambundesliga" component={PostedBetsByTeamBundesligaPage} />
                <Route exact path="/postedbetsbydatebundesliga" component={PostedBetsByDateBundesligaPage} />
              
                <Route exact path="/choosepathseriea" component={ChoosePathSerieAPage} />
                <Route exact path="/upcominggamesseriea" component={UpcomingGamesSerieAPage} />
                <Route exact path="/chooseteamseriea" component={ChooseTeamSerieAPage} />
                <Route exact path="/postedbetsseriea" component={PostedBetsUpcomingSerieAPage} />
                <Route exact path="/postedbetsbyteamseriea" component={PostedBetsByTeamSerieAPage} />
                <Route exact path="/postedbetsbydateseriea" component={PostedBetsByDateSerieAPage} />

                <Route exact path="/choosepathnhl" component={ChoosePathNHLPage} />
                <Route exact path="/upcominggamesnhl" component={UpcomingGamesNHLPage} />
                <Route exact path="/chooseteamnhl" component={ChooseTeamNHLPage} />
                <Route exact path="/postedbetsnhl" component={PostedBetsUpcomingNHLPage} />
                <Route exact path="/postedbetsbyteamnhl" component={PostedBetsByTeamNHLPage} />
                <Route exact path="/postedbetsbydatenhl" component={PostedBetsByDateNHLPage} />

                <Route exact path="/choosepathmlb" component={ChoosePathMLBPage} />
                <Route exact path="/upcominggamesmlb" component={UpcomingGamesMLBPage} />
                <Route exact path="/chooseteammlb" component={ChooseTeamMLBPage} />
                <Route exact path="/postedbetsmlb" component={PostedBetsUpcomingMLBPage} />
                <Route exact path="/postedbetsbyteammlb" component={PostedBetsByTeamMLBPage} />
                <Route exact path="/postedbetsbydatemlb" component={PostedBetsByDateMLBPage} />

                <Route exact path="/choosepathlaliga" component={ChoosePathLaLigaPage} />
                <Route exact path="/upcominggameslaliga" component={UpcomingGamesLaLigaPage} />
                <Route exact path="/chooseteamlaliga" component={ChooseTeamLaLigaPage} />
                <Route exact path="/postedbetslaliga" component={PostedBetsUpcomingLaLigaPage} />
                <Route exact path="/postedbetsbyteamlaliga" component={PostedBetsByTeamLaLigaPage} />
                <Route exact path="/postedbetsbydatelaliga" component={PostedBetsByDateLaLigaPage} />

                <Route exact path="/choosepathworldcup" component={ChoosePathWorldCupPage} />
                <Route exact path="/upcominggamesworldcup" component={UpcomingGamesWorldCupPage} />
                <Route exact path="/chooseteamworldcup" component={ChooseTeamWorldCupPage} />
                <Route exact path="/postedbetsworldcup" component={PostedBetsUpcomingWorldCupPage} />
                <Route exact path="/postedbetsbyteamworldcup" component={PostedBetsByTeamWorldCupPage} />
                <Route exact path="/postedbetsbydateworldcup" component={PostedBetsByDateWorldCupPage} />
              
              </Switch>
            </Grid>
        </div>
      </Router>
    );
    }
}

//  Tried to build as a function ===========================================
// function App () {
//   const [ loggedIn, setLoggedIn ] = React.useState(false);
//   const [ username, setUsername ] = React.useState(null);
//   // const [ updateUser, setUpdateUser ] = React.useState(null);

//   const getUser = () => {
//     axios.get('/api/user/').then(response => {
//       if (response.data.user) {
//         console.log('Get User: There is a user saved in the server session: ')
//         setLoggedIn(true);
//         setUsername(response.data.user.username);
//         sessionStorage.setItem("currentUser", response.data.user.username)
//       } else {
//         console.log('Get user: no user');
//         setLoggedIn(false);
//         setUsername(null);
//         sessionStorage.removeItem("currentUser");
//       }
//     })
//   }

//   getUser()

//     return (
//       <Router>
//         <div>
//             <Grid container fluid>
//               <Switch>
//                 <Route exact path="/" component={LandingPage} />
//                 <Route exact path="/FAQ" component={FAQpageNotLogged} />
//                 <Route exact path="/login" component={LoginPage} />
//                 <Route exact path="/signup" component={SignUpPage} />
//                 <Route exact path="/home" component={HomePage} />
//                 <Route exact path="/forgotlogin" component={ForgottenLoginPage} />
//                 <Route exact path="/myprofile" component={MyProfilePage} />
//                 <Route exact path="/editprofile" component={EditProfilePage} />
//                 <Route exact path="/changepassword" component={ChangePasswordPage} />
//               </Switch>
//             </Grid>
//         </div>
//       </Router>
//     );
//   }    
export default (App);


