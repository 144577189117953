import React from 'react';

import Container from '@material-ui/core/Container';
import MainAppBar from '../../components/MainAppBar';
import DepositPayPal from '../../components/fundingComponents/depositPaypal';

import Footer from '../../components/Footer';
import Copyright from '../../components/Copyright';
import checkAuthenticated from '../../utils/checkAuthenticated';


export default function LandingPage() {
  checkAuthenticated();


  return (
    < Container maxWidth="sm">
        <MainAppBar></MainAppBar>
        <DepositPayPal></DepositPayPal>
        <br/>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
