import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
// import Container from '@material-ui/core/Container';
// import useScript from '../../utils/useScript';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';

const dwolla = window.dwolla;
const paypal = window.paypal;



const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    note: {
        fontSize: 11,
        // fontFamily: 'Anton'
      },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        minWidth: 275,
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    iavContainer: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    table: {
        minWidth: 220,
        maxWidth: 220,
    },
    tableHeader: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        color: 'grey',
    },
    tableHeaderNarrow: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        fixedHeader: false,
        width: 'auto',
        tableLayout: 'auto',
    },
    tableCell: {
        padding: 2,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        color: 'grey'
    },
    tableCellNarrow: {
        padding: 1,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        fixedHeader: false,
        width: 'auto',
        tableLayout: 'auto',
    },
  });

  let minimumDeposit = 5;
  let maxDeposit = 10000;
  let maxDepositString = "10,000";


  export default function PayPalDeposit() {
    const classes = useStyles();
  
    const [depositAmount, setDepositAmount ] = React.useState('');
    let orderID;

    const [ processingMessage, setProcessingMessage ] = React.useState('');

    const [ transactionError, setTransactionError ] = React.useState('');
    const [ agreeTransactionError, setAgreeTransactionError ] = React.useState('');
    const handleAgreeTransactionError = () => {
        setAgreeTransactionError(true);
        setTransactionError(false);
        window.location.pathname = '/depositchoice';
    }

    const [ openMinDeposit, setOpenMinDeposit ] = React.useState('');
    const [ agreeOpenMinDeposit, setAgreeOpenMinDeposit ] = React.useState('');
    const handleAgreeOpenMinDeposit = () => {
        setAgreeOpenMinDeposit(true);
        setOpenMinDeposit(false);
        window.location.pathname = '/depositfrompaypal';
    }

    const handleSetAmount = () =>{
        
        if (parseFloat(depositAmount) < minimumDeposit || parseFloat(depositAmount ) > maxDeposit ) {
            setOpenMinDeposit(true);
        } else {

            let valueOrder = depositAmount;

            
            let url = paypal.Buttons({
                
                createOrder: function(data, actions) {
                    // This function sets up the details of the transaction, including the amount and line item details.
                    return actions.order.create({
                        purchase_units: [{
                        amount: {
                            value: valueOrder
                        }
                        }]
                    });
                },
                onApprove: function(data, actions) {
                    // This function captures the funds from the transaction.
                    
                    return actions.order.capture()
                        .then(function(details) {
                        // This function shows a transaction success message to your buyer.
                            console.log(details);
                            if (details.error === 'INSTRUMENT_DECLINED') {
                                alert("There was an issue processing your transaction. Please try again.");

                                return actions.restart();
                            } else {
                                setProcessingMessage(true);
                                // alert('Transaction successfully completed by ' + details.payer.name.given_name + ' ' + details.payer.name.surname );
                                

                                let orderDetails = {
                                    orderID: details.id,
                                    payerEmail: details.payer.email_address,
                                    paymentID: details.purchase_units[0].payments.captures[0].id,
                                    paymentStatus: details.purchase_units[0].payments.captures[0].status,
                                    paymentAmount: valueOrder,
                                    currentUserID: sessionStorage.getItem('userID'),
                                    username: sessionStorage.getItem('currentUser'),
                                    depositAmount: depositAmount,
                                }
                                
                                setTimeout( function() {
                                    console.log("Timeout");
                                    axios.post('/api/user/paypaldeposit', orderDetails, {})
                                    .then( res => {
                                        console.log(res);
                                        setProcessingMessage(false);
                                        alert('Transaction successfully completed by ' + details.payer.name.given_name + ' ' + details.payer.name.surname );
                                        window.location.pathname = '/home';
                                    })
                                    .catch( err => { 
                                        console.log(err);
                                        setTransactionError(true);
                                    })
                                }, 1000)
                                // window.location.pathname = '/home';

                            }
                        })
                        
                }

            }).render('#buttonHangar');

            const scriptTwo = document.createElement('script');
            scriptTwo.src = url;
            scriptTwo.async = true;
            scriptTwo.id = 'paypal_button';
            document.body.appendChild(scriptTwo);
        
            // return () => {
            // document.body.removeChild(scriptTwo);
            
            // }
        
        }

    }
    

    return(
        <div>
            <Card className={classes.root}>
                <CardContent>
                    <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center' }} gutterBottom>
                            PayPal Deposit
                    </Typography>
                    <Typography className={classes.title} style={{ textAlign: 'center'}}>
                            iBetU
                    </Typography>
                    <form noValidate autoComplete="off">
                        <Grid container direction='column' justify="center" alignItems='center'>
                            <div>
                                <TextField
                                    required
                                    id="depositAmount"
                                    label="Amount to Deposit (USD)"
                                    name="depositAmount"
                                    variant="outlined"
                                    value={depositAmount}
                                    placeholder="Enter Amount to Deposit"
                                    onChange={ e => setDepositAmount((e.target.value).toString()) }
                                    InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            $
                                          </InputAdornment>
                                        ),
                                      }}
                                />
                            </div>
                        </Grid>
                    </form>
                </CardContent>
                <CardContent id="buttonHangar"></CardContent>
                <CardActions style={{ justifyContent: 'center'}}>
                    <br/>
                    <div>
                        <Button size="small" variant="contained" className={classes.buttonblue} type='submit' onClick={handleSetAmount}>Set Amount</Button>  
                    </div>
                </CardActions>
                <CardContent>
                    <Typography className={classes.note}>
                        * Please note that PayPal's processing fees are higher than those of an ACH payment, however, you will have the funds available to you immediately, in most cases.  Your account will be credited with the value of your deposit amount less any fees charged by PayPal for processing. Given the variance of their fees, particularly for different countries, we cannot provide an exact estimate of your small fee. It is typically a fixed fee of 20 to 30 cents plus ~3% of the deposit amount.  Currently, you can only withdraw money into your PayPal account.  We are working on integrating other methods for you to withdraw your money in your jurisdiction. 
                    </Typography>
                    <br/>
                </CardContent>
            </Card>

            <Dialog
                open={processingMessage}
            >
                <DialogTitle align="center">
                    Processing...
                </DialogTitle>
            </Dialog>

            <Dialog
                open={transactionError}
                agree={agreeTransactionError}
                onClose={handleAgreeTransactionError}
            >
                <DialogTitle align="center">
                    There was an error processing your transaction.  If you tried using a credit card thru PayPal, try using another method.
                </DialogTitle>
                <DialogActions>
                    <Grid container spacing={1}>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={4}>
                            <Button onClick={handleAgreeTransactionError} color="primary" variant="outlined" className={classes.buttonBetBlue}
                            align="center" autofocus>
                                OK
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openMinDeposit}
                agree={agreeOpenMinDeposit}
                onClose={handleAgreeOpenMinDeposit}
            >
                <DialogTitle align="center">
                    You must deposit at least ${minimumDeposit} US Dollars.  Your current maximum allowable deposit is ${maxDepositString} US Dollars.
                </DialogTitle>
                <DialogActions>
                    <Grid container spacing={1}>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={4}>
                            <Button onClick={handleAgreeOpenMinDeposit} color="primary" variant="outlined" className={classes.buttonBetBlue}
                            align="center" autofocus>
                                OK
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>

        
    )

  }





    // // Start - Old Button Integration ===============================
    // paypal = window.paypal
    // useEffect(() => {
    //     let url = paypal.Buttons({

    //         createOrder: function(data, actions) {
    //             // This function sets up the details of the transaction, including the amount and line item details.
    //             return actions.order.create({
    //               purchase_units: [{
    //                 amount: {
    //                   value: depositAmount
    //                 }
    //               }]
    //             });
    //           },
    //           onApprove: function(data, actions) {
    //             // This function captures the funds from the transaction.
    //             return actions.order.capture().then(function(details) {
    //               // This function shows a transaction success message to your buyer.
    //               alert('Transaction completed by ' + details.payer.name.given_name);
    //               console.log(details);
    //             });
    //           }

    //     }).render('#buttonHangar');

    //     const scriptTwo = document.createElement('script');
    //     scriptTwo.src = url;
    //     scriptTwo.async = true;
    //     scriptTwo.id = 'paypal_button';
    //     document.body.appendChild(scriptTwo);
    
    //     return () => {
    //     document.body.removeChild(scriptTwo);
        
    //     }
    // }, []);
    // // End - Old Button Integaration ================================