import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
// import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
//  import MuiPhoneNumber from 'material-ui-phone-number';


import axios from 'axios';


const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    formControl: {
        margin: 1,
        minWidth: 220,
        maxWidth: 220,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
      },
  });

const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 2,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#0510e6',
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

let currentUserID = sessionStorage.getItem('userID');

export default function ChangeMFA() {
    const classes = useStyles();
   

    useEffect(() => {
        (async () => {
            const array = await axios.get('/api/user/mfastatus/' + currentUserID )
                .then( function(res) {
                    console.log(res.data.MFAon);
                    let mfaStatus = res.data.MFAon;
                    setMfaOn({ checkedC: mfaStatus});
                })
                .catch(error => {
                    console.log(error);
                })
        })();
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        const userDetails = {
            userID: currentUserID,
            MFAon: mfaOn.checkedC,
        }
        axios.post('/api/user/mfachangestatus', userDetails, {})
        .then( response => {
            console.log(response);
            setOpenSuccess(true);
        })
    }

    // For Switch for MFA
    const [mfaOn, setMfaOn] = React.useState({
        checkedC: false,
      });

    const handleChange = (event) => {
        setMfaOn({ ...mfaOn, [event.target.name]: event.target.checked });
      };

    // For Dialog PopUp on Successful Update
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [agreeSuccess, setAgreeSuccess] = React.useState(false);

    const handleAgreeSuccess = () => {
        setAgreeSuccess(true);
        setOpenSuccess(false);
        window.location.pathname = "/home";
        };
        

    return(
        <div>
            < Container maxWidth="sm">
            <br/>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                            Turn Multi-Factor Authentication On/Off
                        </Typography>
                        <Typography className={classes.title} style={{ textAlign: 'center'}}>
                            iBetU
                        </Typography>
                        <br />
                        <form noValidate autoComplete="off">
                            <Grid container direction='column' justify="center" alignItems='center'>
                                
                                <FormGroup>
                                    <div>
                                    <Typography  style={{ textAlign: 'left'}}>
                                        Multi-Factor Authentication:
                                    </Typography>
                                      
                                    <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item>Off</Grid>
                                        <Grid item>
                                            <AntSwitch checked={mfaOn.checkedC} onChange={handleChange} name="checkedC" />
                                        </Grid>
                                        <Grid item>On</Grid>
                                        </Grid>
                                    </div>
                                    <br />
                                </FormGroup>


                                <div>
                                    <Dialog
                                        open={openSuccess}
                                        agree={agreeSuccess}
                                        onClose={handleAgreeSuccess}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"You have successfully changed your Multi-Factor Authentication setting."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreeSuccess} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <CardActions style={{ justifyContent: 'center'}}>
                                    <Button size="small" variant="contained" className={classes.buttonblue}
                                    type='submit'
                                    onClick={handleSubmit}
                                    >Submit</Button>
                                </CardActions>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </div>
    )

}