import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import axios from 'axios';

let currentUser = sessionStorage.getItem('currentUser');

const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        minWidth: 275,
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    table: {
        minWidth: 280,
        maxWidth: 280,
    },
    tableHeader: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
    },
    tableHeaderNarrow: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        fixedHeader: false,
        width: 'auto',
        tableLayout: 'auto',
    },
    tableCell: {
        padding: 2,
        whiteSpace: 'normal',
        wordWrap: 'break-word'
    },
    tableCellNarrow: {
        padding: 1,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        fixedHeader: false,
        width: 'auto',
        tableLayout: 'auto',
    },
    buttonBetBlue: {
        background: '#0510e6',
        color: 'white',
        minWidth: 80
    },
    formControl: {
        display: "flex",
    },
    buttonGet: {
        background: '#0510e6',
        color: 'white'
    },
});

let regionRestriction = sessionStorage.getItem('regionRestriction');
let currentUserID = sessionStorage.getItem('userID');

export default function PostedBets(props) {
    const classes = useStyles();

    // const league1 = props.league;
    // const title = props.title;
    const abbreviations = props.abbreviations;
    const [ rows, setRows ] = React.useState([]);


    //  Get all posted Bets not Posted by the current User
    useEffect(() => {
        (async () => {
            const array = await axios.get('/api/betsProposed/recentpublicbets/' + regionRestriction)
                .then(function (res) {
                    if ( res.data.data.length === 0) {
                        console.log(res);
                        console.log("No posted bets - create dialog")
                        return res.data.data;
                    } else {
                        let postedBets = res.data.data;
                        return postedBets;
                    }
                })
                .catch(error => {
                    console.log(error);
                })

            const result = [];
            const map = new Map();
            if (array.length > 0) {
                for (const item of array) {
                    if(!map.has(item._id)){
                        map.set(item._id, true);
                        // Find abbreviations of Teams
                        let homeTeamShortObj = await abbreviations.find( ({ name }) => name === item.homeTeam );
                        let awayTeamShortObj = await abbreviations.find( ({ name }) => name === item.awayTeam );
                        let winnerOfferShortObj = await abbreviations.find( ({ name }) => name === item.winnerOffer );

                        //Push to new Array
                        result.push({
                            _id: item._id,
                            dateProposed: item.dateProposed,
                            dateMatch: item.dateMatch,
                            dateMatchDB: item.dateMatchDB,
                            dateTimeDB: item.dateTimeDB,
                            dateShort: item.dateShort,
                            dateShortOUS: item.dateShortOUS,
                            dateDate: item.dateDate,
                            league: item.league,
                            usernameOffer: item.usernameOffer,
                            userIDoffer: item.userIDoffer,
                            amountBet: item.amountBet,
                            winnerOffer: item.winnerOffer,
                            spreadOffer: item.spreadOffer,
                            homeTeam: item.homeTeam,
                            awayTeam: item.awayTeam,
                            oddsNumeratorOffer: item.oddsNumeratorOffer,
                            oddsDenominatorOffer: item.oddsDenominatorOffer,
                            offerMaxRisk: item.offerMaxRisk,
                            offerFundsAtRisk: item.offerFundsAtRisk,
                            publicBet: item.publicBet,
                            partialTake: item.partialTake,
                            friend: item.friend,
                            friendUsername: item.friendUsername,
                            gameID: item.gameID,
                            group: item.group,
                            groupName: item.groupName,
                            homeTeamShort: homeTeamShortObj.abbreviation,
                            awayTeamShort: awayTeamShortObj.abbreviation,
                            winnerOfferShort: winnerOfferShortObj.abbreviation,
                        })
                    }
                }
            } else { console.log('Did not get array'); setOpen(true) }

            if ( result === undefined ) { console.log("No Posted Bets") } else {
                setRows(result)
            }
            
        })();
    }, []);


    //  Get max betting capacity of current user
    const [ maxRisk, setMaxRisk ] = React.useState('');
    const [ fundsAtRisk, setFundsAtRisk ] = React.useState('');

    useEffect(() => {
        (async () => {
            const result = await axios.get('/api/user/maxrisk/' + currentUser )
                .then(function (res) {
                    if (res.data[0].fundsAvailable === null ) {
                        console.log("User does not exist - consider Dialog")
                    } else {
                        return {
                            fundsAvailable: (1 * parseFloat(res.data[0].fundsAvailable)).toFixed(2),
                            fundsAtRisk: (1 * parseFloat(res.data[0].fundsAtRisk)).toFixed(2),
                        }
                    }
                })
                .catch( error => { console.log(error )});
            setMaxRisk(result.fundsAvailable);
            setFundsAtRisk(result.fundsAtRisk);
        })();
    }, []);

    //  For dialog on No Posted Bets Upcoming
    const [open, setOpen] = React.useState(false);
    const [agree, setAgree] = React.useState(false);

    const handleAgree = () => {
        setAgree(true);
        setOpen(false);
        window.location.pathname = '/home';
    };

    // Selecting and Accepting Bet Code
    const [ betPicked, setBetPicked ] = React.useState();
    const [ selected, setSelected ] = React.useState([]);
    const [ dateShort, setDateShort ] = React.useState();
    const [ dateShortOUS, setDateShortOUS ] = React.useState();
    const [ dateDate, setDateDate ] = React.useState();
    const [ amountBet, setAmountBet ] = React.useState();
    const [ winnerOffer, setWinnerOffer ] = React.useState();
    const [ spreadOffer, setSpreadOffer ] = React.useState();
    const [ homeTeam, setHomeTeam ] = React.useState();
    const [ awayTeam, setAwayTeam ] = React.useState();
    const [ oddsNumeratorOffer, setOddsNumeratorOffer ] = React.useState();
    const [ oddsDenominatorOffer, setOddsDenominatorOffer ] = React.useState();
    const [ partialTake, setPartialTake ] = React.useState();
    const [ gameID, setGameID ] = React.useState();
    const [ messageSummary, setMessageSummary ] = React.useState(false);
    const [ loserOffer, setLoserOffer ] = React.useState();
    const [ offerPay, setOfferPay ] = React.useState();
    const [ acceptPay, setAcceptPay ] = React.useState();
    const [ partialAmountBet, setPartialAmountBet ] = React.useState();
    const [ winnerOfferText, setWinnerOfferText ] = React.useState();
    const [ loserOfferText, setLoserOfferText ] = React.useState();
    const [ group, setGroup ] = React.useState();
    const [ groupName, setGroupName ] = React.useState();
    
    const [ dateProposed, setDateProposed ] = React.useState();
    const [ dateMatch, setDateMatch ] = React.useState();
    const [ dateMatchDB, setDateMatchDB ] = React.useState();
    const [ dateTimeDB, setDateTimeDB ] = React.useState();
    const [ league, setLeague ] = React.useState();
    const [ usernameOffer, setUsernameOffer ] = React.useState();
    const [ userIDoffer, setUserIDoffer ] = React.useState();
    const [ publicBet, setPublicBet ] = React.useState();
    const [ friend, setFriend ] = React.useState();
    const [ friendUsername, setFriendUsername ] = React.useState();

    const [ usernameAccept, setUsernameAccept ] = React.useState();
    const [ userIDaccept, setUserIDaccept ] = React.useState();
    const [ dateAccepted, setDateAccepted ] = React.useState();
    const [ winnerOfBet, setWinnerOfBet ] = React.useState('not yet played');
    const [ loserOfBet, setLoserOfBet ] = React.useState('not yet played');
    const [ regionLimit, setRegionLimit ] = React.useState();



    const handleBetPick = (event, key) => {
        setBetPicked(key);
        let betChosen = rows.find(({_id}) => _id === key);
        // let realOfferPay = ((betChosen.amountBet * betChosen.oddsNumeratorOffer)/betChosen.oddsDenominatorOffer).toFixed(2);
        // let realAcceptPay = (betChosen.amountBet * 1.0).toFixed(2);
        setSelected(betChosen);
        setDateShort(betChosen.dateShort);
        setDateShortOUS(betChosen.dateShortOUS);
        setDateDate(betChosen.dateDate);
        setAmountBet(betChosen.amountBet);
        setWinnerOffer(betChosen.winnerOffer);
        setSpreadOffer(betChosen.spreadOffer);
        setHomeTeam(betChosen.homeTeam);
        setAwayTeam(betChosen.awayTeam);
        setOddsNumeratorOffer(betChosen.oddsNumeratorOffer);
        setOddsDenominatorOffer(betChosen.oddsDenominatorOffer);
        setPartialTake(betChosen.partialTake);
        setGameID(betChosen.gameID);
        setOfferPay(((betChosen.amountBet * betChosen.oddsNumeratorOffer)/betChosen.oddsDenominatorOffer).toFixed(2));
        setAcceptPay((betChosen.amountBet * 1.0 ).toFixed(2));
        setDateProposed(betChosen.dateProposed);
        setDateMatch(betChosen.dateMatch);
        setDateMatchDB(betChosen.dateMatchDB);
        setDateTimeDB(betChosen.dateTimeDB);
        setLeague(betChosen.league);
        setUsernameOffer(betChosen.usernameOffer);
        setUserIDoffer(betChosen.userIDoffer);
        setPublicBet(betChosen.publicBet);
        setFriend(betChosen.friend);
        setFriendUsername(betChosen.friendUsername);
        setGroup(betChosen.group);
        setGroupName(betChosen.group);

        setUsernameAccept(sessionStorage.getItem('currentUser'));
        setUserIDaccept(sessionStorage.getItem('userID'));
        setRegionLimit(betChosen.regionLimit);

        let losingTeamOffer1;
        if ( betChosen.winnerOffer === betChosen.homeTeam ) { setLoserOffer(betChosen.awayTeam); losingTeamOffer1 = betChosen.awayTeam } else { setLoserOffer(betChosen.homeTeam); losingTeamOffer1 = betChosen.homeTeam };

        if ( betChosen.league === "nba" || betChosen.league === "nfl" || betChosen.league === "nhl" || betChosen.league === "mlb") {
            setWinnerOfferText("the " + betChosen.winnerOffer);
            setLoserOfferText("the " + losingTeamOffer1);
        } else {
            setWinnerOfferText(betChosen.winnerOffer);
            setLoserOfferText(losingTeamOffer1);
        }

        if ( betChosen.partialTake === true ) {
            setMessageSummary("The counterparty is betting up to $");
            setOpenBetSummary(true);
        } else { 
            setMessageSummary("The counterparty is betting $");
            setOpenBetSummaryFull(true); 
        };  


    }


    //  Dialog that summarizes bet
    const [ openBetSummary, setOpenBetSummary ] = React.useState(false);
    const [ openBetSummaryFull, setOpenBetSummaryFull ] = React.useState(false); // this is for better that doesn't accept partial
    const [ agreeBetFull, setAgreeBetFull ] = React.useState(false);
    const [ agreeBetPartial, setAgreeBetPartial ] = React.useState(false);
    

    const handleAgreeBetFull = () => {
        setAgreeBetFull(true);
        setOpenBetSummary(false);
        setOpenConfirmFullBet(true);
        //insert code to trigger confirmation
    }

    const handleAgreeBetPartial = () => {
        setAgreeBetPartial(true);
        setOpenBetSummary(false);
        setOpenPartialBetSet(true);
    }

    const handleCancelSummary = () => {
        setAgreeBetPartial(false);
        setAgreeBetFull(false);
        setOpenBetSummary(false);
        setOpenBetSummaryFull(false);
        setOpenPartialBetSet(false);
    }


    //Dialog for partial bet amount
    const [ openPartialBetSet, setOpenPartialBetSet ] = React.useState(false);
    const [ agreePartialBetSet, setAgreePartialBetSet ] = React.useState(false);

    const handleAgreePartialBetSet = () => {
        setAgreePartialBetSet(true);
        setOpenPartialBetSet(false);
        setOpenConfirmPartialBet(true);
    }

    //  Dialog for Confirm Full Bet
    const [ openConfirmFullBet, setOpenConfirmFullBet ] = React.useState(false);
    const [ agreeConfirmFullBet, setAgreeConfirmFullBet ] = React.useState(false);

    const handleCancelConfirmFullBet = () => {
        setAgreeConfirmFullBet(false);
        setOpenConfirmFullBet(false);
    }

    const handleConfirmFullBet = () => {
        if ( usernameOffer === usernameAccept ) { setOpenCannotBetSelf(true) }
        else if ( amountBet > maxRisk ) { setOpenNotEnough(true) }
        else {
            setAgreeConfirmFullBet(true);
            let timeStamp = new Date();
            setDateAccepted(timeStamp);
            let partialSum;
            if (parseFloat(partialAmountBet) > 0 ) { partialSum = (1 * parseFloat(partialAmountBet)).toFixed(2) } else { partialSum = (1 * amountBet).toFixed(2) }
            const betDetails = {
                dateProposed: dateProposed,
                dateMatch: dateMatch,
                dateMatchDB: dateMatchDB,
                dateTimeDB: dateTimeDB,
                dateShort: dateShort,
                dateShortOUS: dateShortOUS,
                dateDate: dateDate,
                league: league,
                usernameOffer: usernameOffer,
                userIDoffer: userIDoffer,
                amountBet: amountBet,
                winnerOffer: winnerOffer,
                spreadOffer: spreadOffer,
                oddsNumeratorOffer: oddsNumeratorOffer,
                oddsDenominatorOffer: oddsDenominatorOffer,
                homeTeam: homeTeam,
                awayTeam: awayTeam,
                publicBet: publicBet,
                partialTake: partialTake,
                friend: friend,
                friendUsername: friendUsername,
                gameID: gameID,
                usernameAccept: usernameAccept,
                userIDaccept: userIDaccept,
                dateAccepted: timeStamp,
                winnerOfBet: winnerOfBet,
                loserOfBet: loserOfBet,
                offerPay: offerPay,
                acceptPay: acceptPay,
                group: group,
                groupName: groupName,
                partialAmountBet: partialSum,
                betProposedPicked: betPicked,
                acceptMaxRisk: maxRisk,
                acceptFundsAtRisk: fundsAtRisk
            }
            //Route to accept bet
            axios.post('/api/betsAccepted/acceptbet', betDetails, {
            })
            .then( res => {
                console.log("Bet acceptance registered");
                setOpenSuccessBet(true);
                //  Add code to say success
            })
            .catch( error => {
                console.log("Error registering bet acceptance")
                console.log(error);
            })

            //Add user route to reduce funds available after accept
            axios.post('/api/user/adjustFundsAtAccept', betDetails, { })
            .then( res => {
                setMaxRisk(maxRisk - acceptPay);
                setFundsAtRisk(fundsAtRisk + acceptPay);
            })
            .catch( error => {
                console.log("Error adjusting Funds at accept");
                console.log(error);
            })

            //Route to reduce amount of bet in the proposed section
            axios.post('/api/betsProposed/adjustAmountBetByAccepted', betDetails, {})
            .then( res => {
                console.log(res.data)
                console.log('Proposed bet amount reduced')
            })
            .catch( error => {
                console.log(error);
                console.log(("Did not adjust proposed bet amount"))
            })

            setOpenConfirmFullBet(false);
            setOpenBetSummary(false);
        }

    }

    // Dialog confirm and make the partial bet
    const [ openConfirmPartialBet, setOpenConfirmPartialBet ] = React.useState(false);
    const [ agreeConfirmPartialBet, setAgreeConfirmPartialBet ] = React.useState(false);

    const handleCancelConfirmPartialBet = () => {
        setAgreeConfirmPartialBet(false);
        setOpenConfirmPartialBet(false);
    }

    const handleConfirmPartialBet = () => {
        if ( usernameOffer === usernameAccept ) { setOpenCannotBetSelf(true) }
        else if ( parseFloat(partialAmountBet) > parseFloat(amountBet) ){
            setOpenPartialMoreThanFull(true)
        } else if ( parseFloat(partialAmountBet) > parseFloat(maxRisk) ) { setOpenNotEnough(true) }
        else {
            setAgreeConfirmPartialBet(true);
            let timeStamp = new Date();
            let acceptPay2;
            let offerPay2;
            setDateAccepted(timeStamp);
            let partialSum;
            if (parseFloat(partialAmountBet) > 0 ) { 
                partialSum = parseFloat(partialAmountBet);
                acceptPay2 = (partialSum).toFixed(2);
                offerPay2 = ((partialSum * oddsNumeratorOffer) / oddsDenominatorOffer).toFixed(2);
                setAcceptPay(acceptPay2);
                setOfferPay(offerPay2); 
            } else { 
                partialSum = amountBet;
                acceptPay2 = acceptPay;
                offerPay2 = offerPay;
            }
            const betDetails = {
                dateProposed: dateProposed,
                dateMatch: dateMatch,
                dateMatchDB: dateMatchDB,
                dateTimeDB: dateTimeDB,
                dateShort: dateShort,
                dateShortOUS: dateShortOUS,
                dateDate: dateDate,
                league: league,
                usernameOffer: usernameOffer,
                userIDoffer: userIDoffer,
                amountBet: amountBet,
                winnerOffer: winnerOffer,
                spreadOffer: spreadOffer,
                oddsNumeratorOffer: oddsNumeratorOffer,
                oddsDenominatorOffer: oddsDenominatorOffer,
                homeTeam: homeTeam,
                awayTeam: awayTeam,
                publicBet: publicBet,
                partialTake: partialTake,
                friend: friend,
                friendUsername: friendUsername,
                gameID: gameID,
                usernameAccept: usernameAccept,
                userIDaccept: userIDaccept,
                dateAccepted: timeStamp,
                winnerOfBet: winnerOfBet,
                loserOfBet: loserOfBet,
                offerPay: offerPay2,
                acceptPay: acceptPay2,
                group: group,
                groupName, groupName,
                partialAmountBet: partialSum,
                betProposedPicked: betPicked,
                acceptMaxRisk: maxRisk,
                acceptFundsAtRisk: fundsAtRisk
            }
            //Route to accept bet
            axios.post('/api/betsAccepted/acceptbet', betDetails, {
            })
            .then( res => {
                console.log("Bet acceptance registered");
                setOpenSuccessBet(true);
                //  Add code to say success
            })
            .catch( error => {
                console.log("Error registering bet acceptance")
                console.log(error);
            })

            //Add user route to reduce funds available after accept
            axios.post('/api/user/adjustFundsAtAccept', betDetails, { })
            .then( res => {
                setMaxRisk(maxRisk - acceptPay2);
                setFundsAtRisk(fundsAtRisk + acceptPay2);
            })
            .catch( error => {
                console.log("Error adjusting Funds at accept");
                console.log(error);
            })

            //Route to reduce amount of bet in the proposed section
            axios.post('/api/betsProposed/adjustAmountBetByAccepted', betDetails, {})
            .then( res => {
                console.log(res.data)
                console.log('Proposed bet amount reduced')
            })
            .catch( error => {
                console.log(error);
                console.log(("Did not adjust proposed bet amount"))
            })

            setOpenConfirmPartialBet(false);
            setOpenBetSummary(false);
        }

    }

    // For Dialog of Bet accepted successfully
    const [ openSuccessBet, setOpenSuccessBet ] = React.useState(false);
    const [ agreeSuccessBet, setAgreeSuccessBet ] = React.useState(false);

    const handleAgreeSuccessBet = () => {
        setAgreeSuccessBet(true);
        setOpenSuccessBet(false);
        window.location.reload();
    }

    // For Dialog of Partial can't be more than full amount
    const [ openPartialMoreThanFull, setOpenPartialMoreThanFull ] = React.useState(false);
    const [ agreePartialMoreThanFull, setAgreePartialMoreThanFull ] = React.useState(false);

    const handleAgreePartialMoreThanFull = () => {
        setAgreePartialMoreThanFull(true);
        setOpenPartialMoreThanFull(false);
    }

    //  For Dialog on Not Enough Funds
    const [ openNotEnough, setOpenNotEnough ] = React.useState(false);
    const [ agreeAlterBet, setAgreeAlterBet ] = React.useState(false);
    const [ agreeDeposit, setAgreeDeposit ] = React.useState(false);

    const handleCancelNotEnough = () => {
        setOpenNotEnough(false);
    }

    const handleAgreeAlterBet = () => {
        setAgreeAlterBet(true);
        setOpenNotEnough(false);
        setPartialAmountBet('');
        setOpenPartialBetSet(true);
    };

    const handleAgreeDeposit = () => {
        setAgreeDeposit(true);
        setOpenNotEnough(false);
        window.location.pathname = '/depositchoice';
    }

    // For Dialog of can't bet yourself
    const [ openCannotBetSelf, setOpenCannotBetSelf ] = React.useState(false);
    const [ agreeCannotBetSelf, setAgreeCannotBetSelf ] = React.useState(false);
    const handleAgreeCannotBetSelf = () => {
        setAgreeCannotBetSelf(true);
        setOpenCannotBetSelf(false);
        setOpenConfirmFullBet(false);
        setOpenConfirmPartialBet(false);
    }


    return(
        <div>
              <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center' }} gutterBottom>
                                Recent Public Bet Proposals
                        </Typography>

                    </CardContent>

                    <div>
                        <Dialog
                            open={open}
                            agree={agree}
                            onClose={handleAgree}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"There are no recent bet proposals currently posted.  If you feel this is an error, please email help@ibetu.co"}</DialogTitle>

                            <DialogActions>
                            <Button onClick={handleAgree} color="primary" autoFocus>
                                OK
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    
                <TableContainer component={Paper} align="center">
                    <Table className={classes.table} aria-label="game table" style={{ justifyContent: 'center'}}>
                        <TableHead>
                        <TableRow>
                            <TableCell align="center" className={classes.tableHeader}>Date</TableCell>
                            <TableCell align="center" className={classes.tableHeader}>Game</TableCell>
                            <TableCell align="center" className={classes.tableHeader}>Winner</TableCell>
                            <TableCell align="center" className={classes.tableHeader} >Spread</TableCell>
                            <TableCell align="center" className={classes.tableHeader}>Odds</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows.map((row) => (
                            <TableRow 
                            hover
                            key={row._id}
                            onClick={ (event) => handleBetPick(event, row._id)}
                            >
                                <TableCell component="th" scope="row" align="center" className={classes.tableCell}
                                >
                                    {row.dateShort}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCell} 
                                >
                                    {row.awayTeamShort} @ {row.homeTeamShort}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCell}
                                >
                                    {row.winnerOfferShort}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCell} 
                                >
                                    {row.spreadOffer}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCell} 
                                >
                                    {row.oddsNumeratorOffer} : {row.oddsDenominatorOffer}
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Bet Summary Dialog Start++++++++++++++++++++++++++++++++++++++ */}
                <Dialog open={openBetSummary}>
                    <DialogTitle id="confirm-bet">
                        {messageSummary}{amountBet} dollars that {winnerOfferText} will beat {loserOfferText} by at least {spreadOffer} with the odds at {oddsNumeratorOffer} to {oddsDenominatorOffer}.  This means the counterparty will pay you ${offerPay} dollars if {winnerOfferText} fails to win by at least {spreadOffer}, and you will pay ${acceptPay} dollars if {winnerOfferText} wins by at least {spreadOffer}.  If you accept this bet, please select "All" to accept the full amount offered, or select "Some" to bet a lesser amount. 
                    </DialogTitle>
                    <DialogActions>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Button onClick={handleCancelSummary} align="center" color="secondary" variant="outlined" autoFocus>
                                    X
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <Button onClick={handleAgreeBetPartial} color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                    Some
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <Button onClick={handleAgreeBetFull} color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                    All
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
                {/* Bet Summary Dialog Over======================================== */}

                {/* Bet Summary Full Dialog Start+++++++++++++++++++++++++++++++++ */}
                <Dialog open={openBetSummaryFull}>
                    <DialogTitle id="confirm-bet">
                        {messageSummary}{amountBet} dollars that {winnerOfferText} will beat {loserOfferText} by at least {spreadOffer} with the odds at {oddsNumeratorOffer} to {oddsDenominatorOffer}.  This means the counterparty will pay you ${offerPay} dollars if {winnerOfferText} fails to win by at least {spreadOffer}, and you will pay ${acceptPay} dollars if {winnerOfferText} wins by at least {spreadOffer}.  If you accept this bet, please select "Accept" below. 
                    </DialogTitle>
                    <DialogActions>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Button onClick={handleCancelSummary} align="center" color="secondary" variant="outlined" autoFocus>
                                    X
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <Button onClick={handleAgreeBetFull} color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                    Accept
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
                {/* Bet Summary Full  Dialog Over================================= */}

                {/* Set Partial Bet Dialog Start+++++++++++++++++++++++++++++++++ */}
                <Dialog open={openPartialBetSet}>
                    <DialogTitle id="confirm-bet">
                        The counterparty has offered as much as ${amountBet} dollars.  How much would you like to bet?
                    </DialogTitle>
                    <br/>
                    <TextField
                            required
                            id="partialAmountBet"
                            label="Bet Amount in USD"
                            name="partialAmountBet"
                            variant="outlined"
                            value={partialAmountBet}
                            placeholder="Enter Bet Amount in USD"
                            onChange={ async e => await setPartialAmountBet(e.target.value) }
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                        />
                        <br/>

                    <DialogActions>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Button onClick={handleCancelSummary} align="center" color="secondary" variant="outlined" autoFocus>
                                    X
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <Button onClick={handleAgreePartialBetSet} color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                    Accept
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
                {/* Set Partial Bet Dialog Over================================= */}

                {/* Dialog to Confirm full Bet start++++++++++++++++++ */}
                <Dialog open={openConfirmFullBet}>
                    <DialogTitle>
                        Please confirm that you want to make this bet.
                    </DialogTitle>
                    <br/>
                    <DialogActions>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Button onClick={handleCancelConfirmFullBet} align="center" color="secondary" variant="outlined" autoFocus>
                                    X
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <Button onClick={handleConfirmFullBet} color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                    Confirm
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </DialogActions>
                    
                </Dialog>
                {/* Dialog to Confirm Full bet end =================== */}

                {/* Dialog to Confirm Partial Bet start++++++++++++++++++ */}
                <Dialog open={openConfirmPartialBet}>
                    <DialogTitle>
                        Please confirm that you want to make this bet.
                    </DialogTitle>
                    <br/>
                    <DialogActions>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Button onClick={handleCancelConfirmPartialBet} align="center" color="secondary" variant="outlined" autoFocus>
                                    X
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <Button onClick={handleConfirmPartialBet} color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                    Confirm
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </DialogActions>
                    
                </Dialog>
                {/* Dialog to Confirm Partial bet end =================== */}

                {/* Dialog for successful bet start ++++++++++++++++++++++++++++++ */}
                <div>
                    <Dialog
                        open={openSuccessBet}
                        agree={agreeSuccessBet}
                        onClose={handleAgreeSuccessBet}
                    >
                        <DialogTitle id="alert-dialog-title">{"Your bet has been entered successfully"}</DialogTitle>

                        <DialogActions>
                        <Button onClick={handleAgreeSuccessBet} color="primary" autoFocus>
                            OK
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                {/* Dialog for successful bet end =========================== */}

                {/* Dialog for partial more than full start +++++++++++++++++++++++ */}
                <div>
                    <Dialog
                        open={openPartialMoreThanFull}
                        agree={agreePartialMoreThanFull}
                        onClose={handleAgreePartialMoreThanFull}
                    >
                        <DialogTitle id="alert-dialog-title">{"Your partial bet must be less than the full amount bet by the counterparty"}</DialogTitle>

                        <DialogActions>
                        <Button onClick={handleAgreePartialMoreThanFull} color="primary" autoFocus>
                            OK
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                {/* Dialog for partial more than full end ======================= */}

                {/* Not enough funds in account dialog start+++++++++++++++++++++ */}
                <Dialog open={openNotEnough}>
                    <DialogTitle id="confirm-bet">
                                You are betting ${partialAmountBet} dollars with {oddsNumeratorOffer} to {oddsDenominatorOffer} odds.  That means you are risking ${acceptPay} dollars.  However, you only have ${maxRisk} dollars available in your account. Please deposit more funds to make this bet.  Alternatively, you can decrease the bet amount or decrease the odds. 
                    </DialogTitle>
                    <DialogActions>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Button onClick={handleCancelNotEnough} align="center" color="secondary" variant="outlined" autoFocus>
                                    X
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <Button onClick={handleAgreeAlterBet} color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                    Modify
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <Button onClick={handleAgreeDeposit} color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                    Deposit
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
                {/* Not enough funds in account dialog end======================= */}

                {/* Dialog for cannot bet self start +++++++++++++++++++++++ */}
                <div>
                    <Dialog
                        open={openCannotBetSelf}
                        agree={agreeCannotBetSelf}
                        onClose={handleAgreeCannotBetSelf}
                    >
                        <DialogTitle id="alert-dialog-title">{"This bet was posted by you.  You cannot bet yourself.  Please select another bet."}</DialogTitle>

                        <DialogActions>
                        <Button onClick={handleAgreeCannotBetSelf} color="primary" autoFocus>
                            OK
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                {/* Dialog for cannot bet self end ======================= */}

            </Card>
        </div>
    )
}