import React from 'react';

import Container from '@material-ui/core/Container';
import SignUpAppBar from '../components/SignUpAppBar';
import ForgottenLogin from '../components/ForgottenLogin';
import Footer from '../components/FooterNotLogged';
import Copyright from '../components/Copyright';
import checkNotAuthenticated from '../utils/checkNotAuthenticated';


export default function LandingPage() {
  checkNotAuthenticated();

  return (
    < Container maxWidth="sm">
        <SignUpAppBar></SignUpAppBar>
        <ForgottenLogin></ForgottenLogin>
        <br/>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
