import React from 'react';

import Container from '@material-ui/core/Container';
import MainAppBar from '../../components/MainAppBar';
import BetsByTeam from '../../components/gameComponents/PostedBetsByTeam';

import Footer from '../../components/Footer';
import Copyright from '../../components/Copyright';
import checkAuthenticated from '../../utils/checkAuthenticated';
import {abbreviations} from '../../utils/abbreviations';


export default function LandingPage() {
  checkAuthenticated();
  let league = 'nba';
  let title = 'NBA';
  
  let names = [
    'Atlanta Hawks',
    'Boston Celtics',	
    'Brooklyn Nets',
    'Charlotte Hornets',
    'Chicago Bulls',
    'Cleveland Cavaliers',
    'Dallas Mavericks',
    'Denver Nuggets',
    'Detroit Pistons',	
    'Golden State Warriors',
    'Houston Rockets',
    'Indiana Pacers',
    'Los Angeles Clippers',	
    'Los Angeles Lakers',
    'Memphis Grizzlies',	
    'Miami Heat',
    'Milwaukee Bucks',
    'Minnesota Timberwolves',
    'New Orleans Pelicans',
    'New York Knicks',
    'Oklahoma City Thunder',
    'Orlando Magic',
    'Philadelphia 76ers',
    'Phoenix Suns',
    'Portland Trail Blazers',
    'Sacramento Kings',
    'San Antonio Spurs',
    'Toronto Raptors',
    'Utah Jazz',
    'Washington Wizards'
  ]



  return (
    < Container maxWidth="sm">
        <MainAppBar></MainAppBar>
        <BetsByTeam
          league={league}
          title={title}
          abbreviations={abbreviations}
          names={names}
        ></BetsByTeam>
        <br/>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
