import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles({

  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    paddingTop: 5,
    paddingBottom: 30
    
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 40,
    fontFamily: 'Anton',
    color: '#0510e6',
    fontStyle: 'italic',
  },
  welcome: {
    fontSize: 22,
    fontStyle: 'bold',
    fontFamily: 'Anton'
  },
  pos: {
    marginBottom: 12,
  },
  buttonblue: {
      background: '#0510e6',
      color: 'white'
  },
  buttonclear: {
      color: "#0510e6",
      borderColor: "#0510e6"
  },
  question: {
      fontSize:  16,
  },
  answer: {
      fontSize: 16,
  },
});

export default function SimpleCard() {
  const classes = useStyles();
  
  return (
      < Container maxWidth="sm">
        <br/>
        <br/>
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                Privacy Policy
                </Typography>
                <Typography className={classes.title} style={{ textAlign: 'center'}}>
                iBetU
                </Typography>
                <br />

                  <p>
                    Effective: January 1, 2020
                  </p>
                  <h2><strong>OUR COMMITMENT TO PRIVACY – iBetU</strong></h2>
                  <p>
                    iBetU strives to offer sports fans the most innovative and entertaining games
                    found anywhere on the Internet. To ensure the trustworthiness of our platform
                    and the quality of your experience, we collect and process information about our
                    users. We are committed to handling that information with respect, because your
                    privacy is very important to us. iBetU Inc. (“<strong>iBetU</strong>,”
                    "<strong>we,</strong>" "<strong>us,</strong>” or “<strong>our</strong>”) has
                    prepared this notice to explain how we collect, use, and share information from
                    users of our website located at http://www.ibetu.co
                    ("<strong>Website</strong>"), our mobile applications
                    ("<strong>Application(s)</strong>"), and our online sports wagering related
                    services available through the Website and the Applications
                    ("<strong>Services</strong>"). This privacy notice does not apply to websites,
                    applications, or services that do not display or link to this notice or that
                    display or link to different privacy statements.
                  </p>
                  <p>
                    By using our Website, Application(s), or Services, you consent to this notice.
                    If you object to the collection, use, and sharing practices described in this
                    notice, you may not use our Website, Applications, or Services. If you are a
                    California resident, please see Section 12 below for additional information
                    regarding your <strong>California Privacy Rights.</strong>
                  </p>
                  <h2><strong>TABLE OF CONTENTS</strong></h2>
                  <ul>
                  <li>THE PERSONAL INFORMATION WE COLLECT</li>
                  </ul>
                  <ul>
                  <li>Information You Provide To Us</li>
                  <li>Information Collected via Technology</li>
                  <li>Information Collected From or About Friends</li>
                  <li>Information Collected from Other Sources</li>
                  </ul>
                  <ul>
                  <li>INTERACTION WITH THIRD-PARTY SITES AND SERVICES</li>
                  <li>ADDITIONAL USES OF PERSONAL INFORMATION</li>
                  <li>ADDITIONAL DISCLOSURES OF PERSONAL INFORMATION</li>
                  <li>SECURITY AND RETENTION OF PERSONAL INFORMATION</li>
                  <li>YOUR CHOICES REGARDING PERSONAL INFORMATION</li>
                  <li>HOW TO ACCESS OR CORRECT PERSONAL INFORMATION</li>
                  <li>HOW WE RESPOND TO DO NOT TRACK SIGNALS</li>
                  <li>PROHIBITED USERS</li>
                  <li>CHILDREN</li>
                  <li>NOTE TO INTERNATIONAL USERS</li>
                  <li>CALIFORNIA PRIVACY RIGHTS</li>
                  <li>HOW TO CONTACT US</li>
                  <li>CHANGES AND UPDATES TO THIS PRIVACY NOTICE</li>
                  </ul>
                  <h2><strong>1. THE PERSONAL INFORMATION WE COLLECT</strong></h2>
                  <p>
                  <strong>a. Information You Provide to Us</strong>
                  </p>
                  <ul>
                  <li>You may choose voluntarily to provide certain personal information to us.
                  Personal information is information that can be used to identify you, such as
                  your name, address, email address, phone number, credit card information,
                  billing information, driver’s license number, and social security number.</li>
                  <li>For example, you may decide to share your first and last name, date of
                  birth, location, email address, phone number, username and password, and the
                  country and state in which you reside when you create an account to log in to
                  our network ("<strong>Account</strong>"). We need this information to set up
                  your Account, verify your identity, and provide you with our Services.</li>
                  <li>When you use our Website, Applications, or Services, or deposit or withdraw
                  funds from your Account, we may collect all information necessary to complete
                  the transaction and provide you with the Services, including your name, address,
                  email address, phone number, credit or debit card information and other billing
                  information, social security number, tax documents, and records of the products
                  and Services you purchased, obtained, or considered.</li>
                  <li>If you provide us feedback or contact us via email, we will collect your
                  name and email address, as well as any other content provided in the email, in
                  order to send you a reply, provide customer and technical support, improve and
                  optimize our Services, satisfy our legal obligations, enforce our contracts, and
                  address any existing or anticipated disputes.</li>
                  <li>We will collect the profile photo you upload and user name you choose for
                  your public profile page ("<strong>Profile</strong>"), to give you a
                  personalized experience of our Services. When you participate in any contest,
                  game or other fantasy sports-related product through the Website, Applications,
                  or Services (each, a "<strong>Contest</strong>" and, collectively,
                  "<strong>Contests</strong>" ), other participants in the Contest (each, a
                  "<strong>Player</strong>") will be able to see the information you selected for
                  your Profile.</li>
                  <li>If you tell us where you are (e.g., by allowing your mobile device to send
                  us your location or by otherwise permitting us to geolocate you), we may store
                  and use that information to verify your eligibility to enter certain Contests,
                  to comply with relevant laws, and for fraud prevention purposes.</li>
                  <li>When you participate in one of our surveys, we may collect additional
                  information about your profile, activity, usage, and preferences related to the
                  Website, Applications and Services, so we can evaluate your interest in and the
                  quality of our products, Services, and customer and technical support.</li>
                  <li>If you participate in a sweepstakes, Contest, or giveaway through our
                  Services, we may collect your email address and phone number, to notify you if
                  you win or not. We may also ask for users’ first and last names and addresses,
                  in order to verify their identities. In some situations we may need to collect
                  other information about user preferences as part of the entry process, such as a
                  prize selection, or in connection with the award of prizes over a certain dollar
                  amount. Such sweepstakes and Contests are voluntary. And we recommend that you
                  read the rules for each sweepstakes and contest before you enter it.
                  </li>
                  </ul>
                  <p>
                  <strong>b. Information Collected via Technology</strong>
                  </p>
                  <ul>
                  <li><strong>Information Collected by our Services. </strong>In order to improve,
                  personalize, and optimize our Services and meet our legal obligations, we may
                  automatically track certain information about your visits to and usage of our
                  Website, Applications, and Services, including your browser type, operating
                  system, Internet Protocol (IP) address (a number that is automatically assigned
                  to your computer when you use the Internet, which may vary from session to
                  session), device identifier, domain name, clickstream data, referring/exist
                  pages, geolocation, and a date/time stamp for your visit. We also use this
                  information to analyze trends, administer the Website, gather demographic
                  information about our user base, deliver advertising, perform research, provide
                  technical and customer support, inform our product development efforts, protect
                  our legal rights, address disputes, and prevent our Services from being used to
                  commit harmful or unlawful activities, including fraud. Some of this information
                  may also be collected so that when you use the Website, Applications, or
                  Services again, we will be able to recognize you and optimize your experience
                  accordingly.</li>
                  <li><strong>Device Information.</strong> We may collect information about the
                  devices you use to access our Services, including the hardware models, operating
                  systems and versions, software, file names and versions, preferred languages,
                  unique device identifiers, advertising identifiers, serial numbers, device
                  motion information, and mobile network information. If you have downloaded our
                  mobile Application(s), we may also collect certain information from your mobile
                  device, including information about how you use the Application(s). This
                  information is generally used to help us deliver relevant information to you. In
                  the event our Application crashes on your mobile device, we will also receive
                  information about your mobile device model software version and device carrier,
                  which allows us to identify and fix bugs, provide other technical support, and
                  improve and optimize the performance of our Application(s).</li>
                  <li><strong>Cookies. </strong>Like many online services, we use “cookies” to
                  collect technical information. Cookies are small pieces of information that a
                  website sends to your computer’s hard drive while you are viewing the website.
                  We may use both session cookies (which expire once you close your web browser)
                  and persistent cookies (which stay on your computer until you delete them) to
                  provide you with a more personal and interactive experience on our Website,
                  Application(s), and Services. We may use cookies to collect information about
                  your IP address, browser type, operating system, device type and identifiers,
                  platform type, as well as your usage of and activity on our Website and
                  Applications and the performance of our Services. These types of information are
                  collected to make the Website, Applications, and Services more useful to you and
                  to tailor your online experience to meet your interests and expectations. You
                  can use the options in your web browser to notify you when you receive a cookie
                  or you may change your cookie preferences in your internet browser or with third
                  parties. You can control and/or delete cookies used by our Services at any time.
                  For instructions on how to delete cookies using your particular internet
                  browser, visit https://www.aboutcookies.org/. You can delete all existing
                  cookies on your computer and adjust settings for most browsers to prevent
                  cookies from being received.</li>
                  <li><strong>Pixel Tags. </strong>In addition, we use "pixel tags" (also referred
                  to as clear gifs, web beacons, or web bugs). Pixel tags are tiny graphic images
                  with a unique identifier, similar in function to cookies, that are used to track
                  online movements of web users. In contrast to cookies, which are stored on a
                  computer’s hard drive or web browser, pixel tags are embedded invisibly in web
                  pages. Pixel tags are often used in combination with cookies, trigger the
                  placing of cookies, and transmit information to us or our service providers.
                  This enables two websites to share information. The resulting connection can
                  include information such as a device’s IP address, the time a person viewed the
                  pixel, an identifier associated with the browser or device, the type of browser
                  being used and the URL of the web page from which the pixel was viewed. Pixel
                  tags also allow us to send email messages in a format that users can read, tell
                  us whether emails have been opened, and help to ensure we are sending only
                  messages that may be of interest to our users. We may also use this information
                  to reduce or eliminate messages sent to a user.</li>
                  <li><strong>Threat Detection. </strong>We use certain service providers to
                  provide threat detection, user authentication, and fraud prevention services.
                  These service providers may automatically collect certain information about your
                  visits to the Website and Applications, as well as the device you use to connect
                  to our Services, in order to monitor for potential threats or bad actors. This
                  information may include, among other things, your IP address and your device’s
                  unique identifiers.</li>
                  <li><strong>Collection of Data in Connection with Advertising. </strong>We may
                  use certain vendors to serve ads on the Website and Applications. In connection
                  with the delivery of advertising, certain vendors may automatically collect
                  information about your visits to this and other websites, including your IP
                  address, internet service provider, unique personal identifiers, and the browser
                  you use to visit our Website. They do this by using cookies, beacons, pixel
                  tags, clear gifs, or other technologies. The information collected may be used,
                  among other reasons, to deliver advertising targeted to your interests and to
                  better understand your usage of, preferences on, and visitation of our Website,
                  Applications, and Services and other sites tracked by these vendors. This notice
                  does not apply to, and we are not responsible for, cookies, pixel tags and like
                  other technologies used in connection with other parties’ ads, and we encourage
                  you to check the privacy policies of advertisers and ad services to learn about
                  their use of such technologies.</li>
                  <li><strong>Third Party Analytics. </strong>We may use a number of service
                  providers, to help analyze how people use our Services ("<strong>Analytics
                  Companies</strong>"). Analytics Companies use cookies and similar technologies
                  to collect information pertaining to how people use our Website and
                  Applications, what pages they visit, and what other sites they may have used
                  prior to using our Services. We use the information we get from Analytics
                  Companies to improve and optimize our Website, Applications, and Services.</li>
                  <li><strong>Referral Affiliates. </strong>We may enter into agreements with
                  companies that refer users to our Services via hyperlinks available on the
                  referring party’s website. We may automatically collect certain information,
                  such as your IP address, other unique personal identifiers, and the identity of
                  the referring website via cookies, pixel tags and similar technologies that are
                  provided to us in connection with your use of the referring party’s website.</li>
                  <li><strong>Location Information. </strong>We may collect your location
                  information, including your geolocation records, for purposes of verifying
                  whether you are eligible to participate in a Contest, sweepstakes, or giveaway
                  or to determine if you are otherwise permitted to use our Services. We may also
                  use such information to comply with our legal obligations, protect our legal
                  rights, and address existing or anticipated disputes.
                  </li>
                  </ul>
                  <p>
                  <strong>c. Information Collected From or About Friends</strong>
                  </p>
                  <ul>
                  <li><strong>Refer-a-Friend. </strong>Our Services may enable you to invite your
                  friends to join in the fun. We enable users to invite friends in two ways:</li>
                  </ul>
                  <ul>
                  <li>You may invite a friend to register on our Services by providing us with
                  their email address. We will collect any email address you so provide in order
                  to facilitate the invitation. In this case, we rely upon you to obtain any
                  consents from the third party that may be required by law to allow us to access
                  and upload the third party’s name and email address as required. If you are
                  using one of our Applications to refer-a-friend, the Application may also
                  collect your phone number, which we may use for fraud prevention, user
                  authentication, and to provide our Services. You or the third party may contact
                  us at help@ibetu.co to request the removal of this information from our
                  database.</li>
                  <li>We may enable you to send your friends an invite to register on our Services
                  via text message. We employ various techniques in order to enable you to invite
                  your friends via text message, including offering contact importer tools to
                  facilitate adding your contacts, including contacts in your address book, so
                  that you can more readily ask your contacts to join and communicate with you
                  through the Services. With your permission, we will access your address book,
                  and import your contacts’ names, email addresses, phone numbers, images,
                  geolocation details and any other information associated with your contacts to
                  determine which of your friends have not registered an Account. The text message
                  that is sent to your friends will come from your phone number so that your
                  friends know that you want to invite them to visit the Website or Applications.
                  During this process, we also collect your phone number (to send you a text
                  message for account verification purposes), some information on your settings
                  that help us interpret your data (such as language and keyboard settings that
                  you set), and the identity of your phone’s carrier, mobile network code, and
                  mobile country code. Such information is collected in order to facilitate your
                  friends’ use of our Website, Application(s), and Services.
                  </li>
                  </ul>
                  <ul>
                  <li><strong>Find-a-Friend. </strong>Our Services may also enable you to invite
                  your friends who already have an Account to enter a Contest. In order to invite
                  friends with Accounts to join a Contest, you may: (i) enter your friend’s
                  username associated with their account to find and locate them; or (ii) in the
                  event you are using our Application(s), use our contact importer tool to import
                  and search through your contacts, including contacts in your address book, in
                  order to match those of your friends who are also registered users of the
                  Services. With your permission, we will access your address book, and import
                  your contacts’ names, e-mail addresses, phone numbers, images, geographic
                  locations, and any other information associated with your contact in order to
                  facilitate automatic connection with your friends.
                  </li>
                  </ul>
                  <p>
                  <strong>d. Information Collected from Other Sources</strong>
                  </p>
                  <p>
                  We may supplement the information we have about you with information received
                  from other sources including from our pages on certain Social Networking Sites,
                  the Social Networking Sites themselves, and from commercially available sources
                  (e.g., data brokers and public databases). Your activity on other websites and
                  applications may be associated with your personal information in order to
                  improve and customize our Services and the ads delivered on our Website and
                  Applications. The information we receive may include demographic data such as
                  age, gender and income level, your preferences, interests, associations,
                  commercial information, including records of products or services purchased,
                  obtained, or considered and other purchasing or consuming histories or
                  tendencies, as well as information regarding your interaction with various
                  websites and applications, including the Services. This information is used to
                  create and deliver advertising, improve and optimize our marketing efforts,
                  perform user verification, authentication and fraud detection, and comply with
                  our legal obligations.
                  </p>
                  <h2><strong>2. INTERACTION WITH THIRD-PARTY SITES AND SERVICES</strong></h2>
                  <p>
                  Certain products, services, or other materials displayed on the Website and
                  Application(s) may integrate, be integrated into, or be provided in connection
                  with certain third-party services and content. We do not control those services
                  and content and our Terms of Use and this notice do not apply to those services
                  and content. You should read the terms of use agreements and privacy policies
                  that apply to such services and content. iBetU is not responsible for the
                  practices used by any third-party websites or services linked to or from our
                  Website or Application(s), including, without limitation, the information or
                  content contained within them. By using our Website, Application(s), or
                  Services, you acknowledge and agree that we are not responsible for and do not
                  have control over any third-party services and content that you authorize to
                  access information you provide to them. If you are using a third-party website
                  or service and allow it to access your personal information, you do so at your
                  own risk.
                  </p>
                  <h2><strong>3. ADDITIONAL USES OF PERSONAL INFORMATION</strong></h2>
                  <p>
                  <strong>User Feedback. </strong>We may post user feedback on the Website and
                  Application(s) from time to time. We will share your feedback with your first
                  name and last initial only. If we choose to post your first and last name along
                  with your feedback, we will obtain your consent prior to posting. If you make
                  any comments on a blog or forum associated with the Website, Applications, or
                  Services, you should be aware that any personal information you submit there can
                  be read, collected, or used by other users of the blog or forum and might be
                  used to send you unsolicited messages. We are not responsible for any personal
                  information you choose to submit in these blogs and forums.
                  </p>
                  <p>
                  <strong>Creation of Anonymous or De-Identified Data. </strong>We may create
                  aggregated, anonymized or de-identified data (i.e., data that is not reasonably
                  capable of being associated with or linked to you) from personal information we
                  collected from or about you. We use such data to analyze request and usage
                  patterns, so that we may improve, optimize, or enhance our Services and improve
                  our users’ experience with and ability to navigate our Website and Applications.
                  We reserve the right to use such aggregated, anonymized, or de-identified data
                  for any purpose and disclose it to third parties for any reason.
                  </p>
                  <h2><strong>4. ADDITIONAL DISCLOSURES OF PERSONAL INFORMATION</strong></h2>
                  <p>
                  In addition to the sharing practices discussed above, we may disclose your
                  personal information as follows:
                  </p>
                  <ul>
                  <li><strong>Parties You Share With. </strong>When you use the Website,
                  Application(s), or Services, the personal information you openly provide with
                  others will be shared with parties you allow to receive such information. For
                  example, you may elect to communicate personal information with other Players
                  who are participating in the same Contest as you.</li>
                  <li><strong>Service Providers. </strong>We may share your personal information
                  with certain service providers to: provide you the Services we offer through our
                  Website and Applications; conduct quality assurance testing; facilitate the
                  creation of accounts; provide account authentication and user verification
                  services; provide technical and customer support; geolocate users; personalize,
                  customize, and improve your use and experience of the Services; process payments
                  to you and other financial transactions; send email and push notifications;
                  enable security support and technical maintenance; send you newsletters,
                  surveys, messages, and promotional materials related the Services or on behalf
                  of other companies; verify your identity; prevent harmful or unlawful uses of
                  our Services, including fraud; deliver you customized or personalized content,
                  information, and advertising; understand when users have installed our
                  Application(s); monitor the health and performance of code on users’ devices;
                  request user feedback; send users links to download our Application(s); monitor
                  the health of our servers; manage search engine optimization; and improve and
                  optimize the performance of our Services.</li>
                  <li><strong>Internal Revenue Service and Other Taxing Authorities. </strong>If
                  your earnings from Contests exceed a certain threshold, in accordance with our
                  interpretation of applicable law, we will disclose the amount of your earnings
                  and related information to the United States Internal Revenue Service and other
                  appropriate taxing authorities.</li>
                  <li><strong>Professional Financial and Legal Advisers.</strong> From time to
                  time, we may also need to share your information with professional advisers,
                  including lawyers, auditors, bankers, insurers and consultants, in order to
                  obtain advice or other professional services from them. Such advisers are bound
                  by legal and/or contractual duties of confidentiality and other prohibitions
                  against disclosure of personal information.</li>
                  <li><strong>Co-Branded Partners. </strong>Co-Branded Partners are companies with
                  whom we may jointly offer a service or feature. You can tell when you are
                  accessing a service or feature offered by a CoBranded Partner because the
                  Co-Branded Partner's name will be featured prominently. You may be asked to
                  provide information about yourself to register for a service offered by a
                  CoBranded Partner. In doing so, you may be providing your information to both us
                  and the CoBranded Partner, or we may share your information with the Co-Branded
                  Partner. Please note that the Co-Branded Partner's privacy policy may also apply
                  to its use of your information. iBetU is not responsible for the practices used
                  by Co-Branded Partners. In addition, by using our Website, Application(s), and
                  Services, you acknowledge and agree that we are not responsible for and do not
                  have control over any Co-Branded Partners.</li>
                  <li><strong>iBetU Promotions. </strong>When you participate in a Contest, we may
                  use your name, likeness, voice, opinions, and biographical information for
                  publicity, advertising, trade, or promotional purposes without further payment,
                  consideration, notice, or approval.</li>
                  <li><strong>Social Networking Sites. </strong>Our Website, Application(s), and
                  Services may enable you to post content to Social Networking Services (each, a
                  "<strong>SNS</strong>") (e.g., Facebook). If you choose to do this, we will
                  provide information to such SNSs in accordance with your elections. In such
                  event, you acknowledge and agree that you are solely responsible for your use of
                  those websites and that it is your responsibility to review the terms of use and
                  privacy policy of the companies providing such SNSs. We will not be responsible
                  or liable for: (i) the availability or accuracy of any SNS’s terms of use or
                  privacy policy; (ii) the content, products or services on or availability of
                  such SNSs; or (iii) your use of any such SNSs.</li>
                  <li><strong>Corporate Affiliates. </strong>We may share some or all of your
                  personal information with our corporate affiliates, including, without
                  limitation, our subsidiaries, joint ventures and other companies under a common
                  control ("Affiliates"), in which case we will require our Affiliates to respect
                  this notice.</li>
                  <li><strong>Corporate Restructuring. </strong>We may share some or all of your
                  personal information in connection with or during negotiation of any merger,
                  financing, acquisition, corporate divestiture, dissolution transaction, or other
                  proceeding involving the sale, transfer, divestiture or disclosure of all or a
                  portion of our business or assets. In the event of an insolvency, bankruptcy, or
                  receivership, your personal information may also be transferred as a business
                  asset.</li>
                  <li><strong>Other Disclosures. </strong>Regardless of the choices you make
                  regarding your personal information (as described below), iBetU may disclose
                  personal information if it in good faith believes that such disclosure is
                  necessary (i) to comply with relevant laws or to respond to discovery requests,
                  subpoenas, or warrants served on iBetU; (ii) in connection with any legal
                  investigation; (iii) to protect or defend the rights or property of iBetU or
                  users of the Website, Application(s), or Services; (iv) to investigate or assist
                  in preventing any violation or potential violation of law, this notice, or our
                  Terms of Use; or (v) if we believe that an emergency involving the danger of
                  death or serious physical injury to any person requires or justifies disclosure
                  of information.</li>
                  <li><strong>Advertising Alliances Opt-out: </strong>In addition to adjusting the
                  appropriate settings in your browser, many advertising companies that may
                  collect information for targeted advertising purposes are also members of the
                  Digital Advertising Alliance or the Network Advertising Initiative. Both of
                  these organizations provide directions on how individuals can opt-out from
                  targeted advertising by their members. You can find more information on these
                  opt-out capabilities on <a
                  href="http://www.aboutads.info/">www.aboutads.info</a> and <a
                  href="http://www.networkadvertising.org/">www.networkadvertising.org</a>.
                  </li>
                  </ul>
                  <h2><strong>5. SECURITY AND RETENTION OF PERSONAL INFORMATION</strong></h2>
                  <ul>
                  <li>The security of your information is important to us. We use reasonable
                  administrative, technical, and physical procedures, practices, and safeguards
                  designed to protect personal information we collect from unauthorized access,
                  use, alteration, exfiltration, or destruction. Additionally, we will keep your
                  information for only as long as it is needed to achieve its purpose or meet our
                  legal obligations.</li>
                  <li>We make concerted, good faith efforts to maintain the security of your
                  information. Although we work hard to ensure the integrity and security of our
                  systems, it must be recognized that no information system is 100% secure and,
                  therefore, we cannot guarantee the security of such information. Outages,
                  attacks, human error, system failure, unauthorized use, or other factors may
                  compromise the security of user information at any time.
                  </li>
                  </ul>
                  <h2><strong>6. YOUR CHOICES REGARDING PERSONAL INFORMATION</strong></h2>
                  <ul>
                  <li><strong>Email Communication. </strong>We will periodically send you free
                  newsletters and emails that contain information about our various products and
                  Services, or other products and services we feel may be of interest to you. At
                  any time you can easily indicate your preference to stop receiving such
                  communications from us, and you may opt out of receiving further marketing from
                  iBetU, by emailing us at help@ibetu.co or by following the unsubscribe
                  instructions provided in the email you receive. Despite your indicated email
                  preferences, we may send you service related communications, including notices
                  of any updates to our Terms of Use and this privacy notice.</li>
                  <li><strong>Changing or Deleting Your Personal Information. </strong>You may
                  change any of your personal information in your Account by simply editing it
                  within your Account or by requesting changes by emailing us at <strong><span
                  >help@ibetu.co</span></strong>. You may
                  request deletion of certain personal information maintained by us, and we will
                  use commercially reasonable efforts to honor your request, but please note that
                  we may be legally or otherwise required to keep such information and not delete
                  it (or to keep this information for a certain time, in which case we will comply
                  with your deletion request only after we have fulfilled such requirements).
                  Deletion requests may be made by emailing us at help@ibetu.co or by submitting a
                  privacy request through the “Contact Us” web form located at
                  https://www.ibetu.co/contact-us. When we delete any information, it will be
                  deleted from the active database, but may remain in our archives. We may also
                  retain your information to prevent fraud or other unlawful activity or for
                  similar purposes.</li>
                  <li><strong>Applications. </strong>You may stop all collection of information by
                  our Application(s) by uninstalling the Application(s). You may use the standard
                  uninstall processes as may be available as part of your mobile device or via the
                  mobile application marketplace or network.</li>
                  </ul>
                  <h2><strong>7. HOW TO ACCESS OR CORRECT PERSONAL INFORMATION</strong></h2>
                  <p>
                  You may request a copy of personal information that iBetU has collected about
                  you through the Services by contacting our privacy team via email addressed to
                  help@ibetu.co or through the “Contact Us” web form located at <a
                  href="https://www.ibetu.co/contact-us">https://www.ibetu.co/contact-us</a>.
                  You may also request that iBetU correct any factual inaccuracies in your
                  personal information by contacting help@ibetu.co.
                  </p>
                  <h2><strong>8. HOW WE RESPOND TO DO NOT TRACK SIGNALS</strong></h2>
                  <p>
                  Various browsers, including Internet Explorer, Firefox and Safari, currently
                  offer a “Do Not Track” or “DNT” option that relies on a technology known as a
                  DNT header, which sends a signal to websites visited by the user about the
                  user’s browser DNT preference setting. Please note that, at this time, we do not
                  honor “DNT” signals or other mechanisms that may allow you to opt out of the
                  collection of information across networks of websites and online services as
                  there is no standard for how online services should respond to such signals. As
                  standards evolve, we may develop policies for responding to DNT signals that
                  would be described in future versions of this notice. For more information on
                  DNT options, please visit www.allaboutdnt.org.
                  </p>
                  <h2><strong>9. PROHIBITED USERS</strong></h2>
                  <p>
                  As indicated in our terms of use, employees, operators, or consultants of a
                  sports governing body are prohibited from participating in certain Contests
                  where such employee, operator, or consultant is prohibited from participating in
                  Contests by such governing body ("<strong>Prohibited Users</strong>"). For
                  clarity, examples of sports governing bodies are the National Football League,
                  Major League Baseball, and the National Basketball Association. We may report
                  any participation in the Services or in any Contests by any Prohibited Users to
                  the applicable sports governing body.
                  </p>
                  <h2><strong>10. CHILDREN</strong></h2>
                  <p>
                  The Website and Application(s) are not directed toward individuals under the age
                  of thirteen (13), and we require that such individuals not provide us with
                  personal information. We do not knowingly collect or maintain personal
                  information from anyone under the age of 13 through the Website or Applications.
                  If we become aware that a child under 13 has provided us with personal
                  information through the Website, Application(s), or Services, we will delete the
                  information from our files. If you are the parent or legal guardian of a child
                  under 13 who you believe has provided personal information to iBetU through the
                  Website, Applications or Services, please contact our privacy team at
                  help@ibetu.co.
                  </p>
                  <h2><strong>11. NOTE TO INTERNATIONAL USERS</strong></h2>
                  <p>
                  The Website and Applications are hosted in the United States and are intended
                  for and directed to users in the United States and Canada. If you are a user
                  accessing the Services from the European Union, Asia, or any other region with
                  laws or regulations governing personal data collection, use, and disclosure that
                  differ from United States laws, please be advised that through your continued
                  use of the Website or Applications, which are governed by U.S. law, this notice,
                  and our Terms of Use, you are transferring your personal information to the
                  United States and you consent to that transfer. The Website and Applications are
                  not intended to subject us to the laws or jurisdiction of any state, country or
                  territory other than those of the United States. Your information may be stored
                  and processed in any country where we have facilities or in which we engage
                  service providers and, by using the Website, Application(s), or Services, you
                  consent to the transfer of information to countries outside of your country of
                  residence, including the United States, which may have different data protection
                  rules than those of your country.
                  </p>
                  <h2><strong>12. CALIFORNIA PRIVACY RIGHTS</strong></h2>
                  
                  <p><strong>California Consumer Privacy Act<br/></strong>California
                  residents (“CA customers”) have certain rights concerning their personal
                  information, including under the California Consumer Privacy Act of 2018
                  (“CCPA”). The preceding sections of this notice describe iBetU’s practices
                  regarding the collection, use, disclosure, and sale of personal information.
                  This section supplements the preceding sections by describing CA customers’
                  additional rights with respect to their personal information and explaining how
                  to exercise such rights.<br/>As discussed above, we collect and use the
                  following categories of personal information stated in the CCPA for the business
                  and commercial purposes described in this notice: real name; username; postal
                  address; email address; telephone number; social security number; driver’s
                  license, state identification, and passport numbers; date of birth and age;
                  profile photographs; credit card, debit card, and bank account numbers and other
                  payment and financial information; tax information and documents; commercial
                  information, including records of products or services purchased, obtained, or
                  considered; information on users’ interaction with the Website and
                  Application(s) and other websites and applications, including information on
                  preferences, usage, communications, subscriptions, SNS usernames, associations,
                  likes, and public profile information; and inferences drawn from such
                  information. We collect this personal information from the categories of sources
                  described above. We may disclose the foregoing categories of personal
                  information for our business and commercial purposes to the extent permitted by
                  applicable law.</p>
                  <p><strong>Right to Know About Personal Information Collected, Used, Shared, or
                  Sold.<br/></strong>CA customers have the right to request that we disclose
                  the following information for the period covering the 12 months preceding their
                  request:</p>
                  
                  <ul>
                  <li>the categories of personal information we collected about you;</li>
                  <li>the categories of sources from which we collected personal information about
                  you;</li>
                  <li>the categories of personal information that we have disclosed about you for
                  our business purposes and the categories of suppliers to whom the personal
                  information was disclosed;</li>
                  <li>the categories of personal information that we sold to third parties and the
                  categories of third parties to whom the personal information was sold, if any;</li>
                  <li>the business or commercial purpose(s) for which personal information about
                  you was collected, shared or sold; and</li>
                  <li>the specific pieces of personal information we collected about you.
                  </li>
                  </ul>
                  
                  <p><strong>Right to Request Deletion of Personal
                  Information.<br/></strong>CA customers have the right to request that
                  iBetU delete the personal information we maintain about them, subject to certain
                  exceptions.</p>
                  <p><strong>Right to Opt-Out of the Sale of Personal
                  Information.<br/></strong>CA customers have the right to opt-out of a
                  business’s sale of their personal information. Under the CCPA, the terms “sell,”
                  “selling,” or “sale” mean selling, renting, releasing, disclosing,
                  disseminating, making available, transferring, or otherwise communicating
                  orally, in writing, or by electronic or other means, a CA customer’s personal
                  information to another business or third party for monetary or other valuable
                  consideration. The CCPA provides that a business does not sell personal
                  information when:</p>
                  
                  <ul>
                  <li>the customer uses or directs the business to intentionally disclose his or
                  her personal information or uses the business to intentionally interact with a
                  third party, as long as the third party does not also sell the personal
                  information; or</li>
                  <li>the business uses or shares with a service provider personal information of
                  a customer that is necessary to perform a business purpose, as long as (i) the
                  business has provided appropriate notice that personal information is being used
                  or shared and (ii) the service provider does not further collect, sell, or use
                  the personal information of the customer except as necessary to perform the
                  business purpose.
                  </li>
                  </ul>
                  <ul>
                  <li>As described above, we allow certain companies to place tracking
                  technologies on our sites, which allow those companies to receive information
                  about your activity on our sites that is associated with your browser or device.
                  Based on our understanding of the CCPA, iBetU believes this is not a “sale” of
                  personal information and that we have not sold the personal information of CA
                  customers, including minors under 16 years of age, within the preceding 12
                  months, nor will we do so going forward.</li>
                  <li><strong>Right to Non-Discrimination for Exercising Privacy
                  Rights.<br/></strong>iBetU will not discriminate against CA customers for
                  exercising their rights under the CCPA.</li>
                  <li><strong>How To Exercise Your Rights.<br/></strong>You may submit a
                  request by emailing us at help@ibetu.co or by sending us a privacy request
                  through the Contact Us web form located at <a
                  href="https://www.ibetu.co/contact-us">https://www.ibetu.co/contact-us</a>.
                  Please note that iBetU may deny or fulfill a request only in part, based on our
                  legal rights and obligations. Please also note that, for purposes of these
                  rights, personal information does not include information about job applicants,
                  employees and other of our personnel or information about employees and other
                  representatives of third-party entities we may interact with.<br/>When we
                  receive a request, we will verify the identity of the requestor and undertake to
                  respond within the periods set by California law. If a CA customer has a
                  password-protected account with iBetU, we will verify their identity through
                  iBetU’s existing authentication practices and will require him or her to
                  re-authenticate themselves before disclosing or deleting the customer’s data. If
                  a CA customer does not have a password-protected account, we will take
                  reasonable steps to verify their identity in accordance with the CCPA prior to
                  responding any request. The verification steps may vary depending on the
                  sensitivity of the personal information.  If we cannot verify a requestor’s
                  identity using these methods, we may use an appropriate third-party verification
                  service.</li>
                  <li><strong>Designating an Authorized Agent to Submit Privacy
                  Requests.<br/></strong>CA customers may use an authorized agent to submit
                  a Request to Know or a Request to Delete. An authorized agent is a person or
                  entity registered with the California Secretary of State that a CA customer has
                  authorized to act on his or her behalf. If you choose to submit a privacy
                  request to iBetU through an authorized agent, we may require that you provide
                  the agent with written permission to do so and that the agent verify their own
                  identity with iBetU. If your privacy request is submitted by an agent without
                  proof that they have been authorized by you to act on your behalf, we may deny
                  the request.</li>
                  <li><strong>“Shine the Light” Law<br/></strong>A CA customer who has
                  provided personal information to a business with whom he has established a
                  business relationship for personal, family, or household purposes is entitled to
                  request information about whether the business has disclosed personal
                  information to any third parties for the third parties’ direct marketing
                  purposes. In general, if the business has made such a disclosure of personal
                  information, upon receipt of a request by a CA customer, the business is
                  required to provide a list of all third parties to whom personal information was
                  disclosed in the preceding calendar year, as well as a list of the categories of
                  personal information that were disclosed.<br/>However, under the law, a
                  business is not required to provide the above-described lists if the business
                  adopts and discloses to the public (in its privacy policy or notice) a policy of
                  not disclosing customer’s personal information to third parties for their direct
                  marketing purposes unless the business gives users a mechanism to opt out of
                  having their personal information disclosed to third parties for their direct
                  marketing purposes. Rather, the business may comply with the law by notifying
                  the customer of his or her right to prevent disclosure of personal information
                  and providing a cost-free means to exercise the right.<br/>We have opted
                  for this alternative approach. As stated in this notice, we give customers a
                  mechanism to opt out of having their personal information disclosed to third
                  parties for their direct marketing purposes. Therefore, we are not required to
                  maintain or disclose a list of third parties that received your personal
                  information for marketing purposes. If you wish to opt-out of sharing your
                  personal information with third parties for their direct marketing purposes,
                  please email us at help@ibetu.co or send us a privacy request through the
                  Contact Us web form located at <a
                  href="https://www.ibetu.co/contact-us">https://www.ibetu.co/contact-us</a>
                  and describe your request as "Re:Opt-out”.
                  </li>
                  </ul>
                  <h2><strong>13. HOW TO CONTACT US</strong></h2>
                  <p>
                  Any inquiries or comments regarding your personal information and privacy
                  rights, our data privacy practices, this privacy notice, or any reports
                  regarding suspected or actual security violations should be directed to our
                  privacy team via email to help@ibetu.co or by sending a message via the Contact
                  Us web form at <a
                  href="https://www.ibetu.co/contact-us">https://www.ibetu.co/contact-us</a>.
                  </p>
                  <h2><strong>14. CHANGES AND UPDATES TO THIS PRIVACY NOTICE</strong></h2>
                  <p>
                  This privacy notice is effective as of January 1, 2020. It may be revised
                  periodically. If we make any material changes in the way we use your personal
                  information, we will notify you. Unless specified otherwise, any future changes
                  to this privacy notice will be effective thirty (30) days following notice of
                  the changes posted on our Website and Application(s) or delivered via email .
                  Such changes will be effective immediately for new users of the Services and for
                  all users who expressly acknowledge that this notice has been changed. Please
                  note that at all times you are responsible for updating your personal
                  information to provide us with your most current email address. If the last
                  email address you provided us is not valid or for any reason is not capable of
                  delivering to you the notice(s) described above, then in the event we elect to
                  provide you notice via email, our dispatch of the email containing such notice
                  will constitute effective notification of the changes described in the revised
                  notice. If you do not wish to permit changes in our use of your personal
                  information, you must notify us prior to the effective date of the changes that
                  you wish to deactivate your Account with us. Your continued use of the Website,
                  Application(s), or Services following notice of such changes will indicate your
                  acknowledgement of and consent to such changes and to be bound by the terms of
                  such changes, with the understanding that the modified terms apply to all of
                  your personal information, including the personal information collected before
                  the change went into effect.
                  </p>
                  <p>
                  Last Updated: December 20, 2019
                  </p>
                  <p>
                  Effective: January 1, 2020
                  </p>
                    
            
            </CardContent>
            
        </Card>
    </Container>
  );
}
