import React from 'react';

import Container from '@material-ui/core/Container';
import MainAppBar from '../../components/MainAppBar';
import BetsByTeam from '../../components/gameComponents/PostedBetsByTeam';

import Footer from '../../components/Footer';
import Copyright from '../../components/Copyright';
import checkAuthenticated from '../../utils/checkAuthenticated';
import {abbreviations} from '../../utils/abbreviations';


export default function LandingPage() {
  checkAuthenticated();
  let league = 'uefa';
  let title = 'UEFA Champions League';
  
  let names = []  
  for ( const item of abbreviations) {
    if (item.league === league) {
      names.push( item.name );
    }
  }
  // let names = [
  //   'Ajax',
  //   'Atalanta',
  //   'Atlético Madrid',
  //   'Barcelona',
  //   'Bayern Munich',
  //   'Benfica',
  //   'Chelsea',
  //   'Club Brugge',
  //   'Dinamo Zagreb',
  //   'Dortmund',
  //   'Galatasaray',
  //   'Genk',
  //   'Inter',
  //   'Juventus',
  //   'Leverkusen',
  //   'Lille',
  //   'Liverpool',
  //   'Loko Moscow',
  //   'Lyon',
  //   'Manchester City',
  //   'Napoli',
  //   'Olympiacos',
  //   'Paris S-G',
  //   'RB Leipzig',
  //   'RB Salzburg',
  //   'Real Madrid',
  //   'Red Star',
  //   'Shakhtar',
  //   'Slavia Prague',
  //   'Tottenham',
  //   'Valencia',
  //   'Zenit',
  // ]



  return (
    < Container maxWidth="sm">
        <MainAppBar></MainAppBar>
        <BetsByTeam
          league={league}
          title={title}
          abbreviations={abbreviations}
          names={names}
        ></BetsByTeam>
        <br/>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
