import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import axios from 'axios';

const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 3,
      paddingBottom: 3
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 40,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        minWidth: 260,
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    table: {
        minWidth: 280,
        maxWidth: 280,
    },
    tableHeader: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        fontSize: 18,
    },
    tableCell: {
        padding: 3,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    tableHeaderName: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        fontSize: 18,
    },
    tableCellName: {
        padding: 8,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        fontSize: 18,
    },
    buttonBetBlue: {
        background: '#0510e6',
        color: 'white',
        minWidth: 80
    },
    formControl: {
        display: "flex",
    },
    buttonGet: {
        background: '#0510e6',
        color: 'white'
    },
    message: {
        fontSize: 18,
    },
});


export default function SeePromotion() {
    let currentUserID = sessionStorage.getItem('userID');
    let currentUsername = sessionStorage.getItem('currentUser');
    let promotionAvailable = sessionStorage.getItem('promotionAvailable');
    
    const classes = useStyles();
    const [ promotionName, setPromotionName ] = React.useState('');
    const [ promotionCode, setPromotionCode ] = React.useState('');
    const [ perUserAmount, setPerUserAmount ] = React.useState('');
    const [ newAcceptsNeeded, setNewAcceptsNeeded ] = React.useState('');
    const [ lastDayToUse, setLastDayToUse ] = React.useState('');
    const [ availableToWithdraw, setAvailableToWithdraw ] = React.useState('');
    const [ promoExpirationDate, setPromoExpirationDate ] = React.useState('');
    const [ promotionMessage, setPromotionMessage ] = React.useState('');

    //  Get current promotion from server ++++++++++++++++++++
    useEffect(() => {
        (async () => {
            let date = new Date();
            let today = date;
            if ( promotionAvailable == 'true' ) {
                axios.get('/api/promotion/checkpromotions/' + currentUsername)
                .then( async function (res) {
                    let promotionData = res.data.data[0];
                    if ( res.data.data.length > 0 ) {
                        date.setDate(date.getDate() + promotionData.daysToUseFunds);
                        setPromotionName(promotionData.promotionName);
                        setPromotionCode(promotionData.promotionCode);
                        setPerUserAmount(promotionData.perUserAmount);
                        setNewAcceptsNeeded(promotionData.newAcceptsNeeded);
                        setLastDayToUse(date);
                        setAvailableToWithdraw(promotionData.availableToWithdraw);
                        setPromoExpirationDate(promotionData.promoExpirationDate);
                        setPromotionMessage(promotionData.message);

                    } else {  
                        console.log("no promotions");
                        window.location.pathname = '/home';
                    };
                })
                .catch(error => {
                    console.log(error)
                })
            } else {
                window.location.pathname = '/home';
            }
        })();
    }, [])

    const handleDeclineOffer = () => {
        window.location.pathname ='/home';
    }

    const handleAcceptOffer = () => {
        let promotionDetails = {
            promotionName: promotionName,
            promotionCode: promotionCode,
            perUserAmount: perUserAmount,
            newAcceptsNeeded: newAcceptsNeeded,
            lastDayToUse: lastDayToUse,
            availableToWithdraw: availableToWithdraw,
            promoExpirationDate: promoExpirationDate,
            originalUsername: currentUsername,
        }

        axios.post('/api/promotion/promotionaddedtouser', promotionDetails)
        .then( async function (res) {
            console.log("Successfully Accepted Promotion");
            console.log(res.data);
            if (res.data.message == 'update successful') {
                setOpenSuccessfulAccept(true);
            } else {
                setOpenErrorAccept(true);
            }
        })
        .catch( error => {
            console.log(error);
            setOpenErrorAccept(true);
        })
    }

    //  Dialog for successfull accept
    const [ openSuccessfulAccept, setOpenSuccessfulAccept ] = React.useState(false);

    const handleAgreeSuccessfulAccept = () => {
        setOpenSuccessfulAccept(false);
        window.location.pathname = '/home';
    }

    //  Dialog for problem accepting
    const [ openErrorAccept, setOpenErrorAccept ] = React.useState(false);

    const handleAgreeErrorAccept = () => {
        setOpenErrorAccept(false);
        window.location.pathname = '/home';
    }

    return(
        <div>
        < Container maxWidth="sm">
            <Card className={classes.root}>
                <CardContent>
                    <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center' }} gutterBottom>
                            Current Promotion
                    </Typography>
                    <Typography className={classes.title} style={{ textAlign: 'center'}}>
                            {promotionName}
                    </Typography>
                </CardContent>              
            </Card>

        
            <br/>
            <Card className={classes.root}>
                <CardContent>
                    <Typography className={classes.message} style={{ textAlign: 'center'}}>
                            {promotionMessage}
                    </Typography>
                </CardContent>

                <div>
                    <Dialog
                        open={openSuccessfulAccept}
                        onClose={handleAgreeSuccessfulAccept}
                    >
                        <DialogTitle>{"You have successfully received the promotion. Your funds available to play have increased."}</DialogTitle>

                        <DialogActions>
                        <Button onClick={handleAgreeSuccessfulAccept} color="primary">
                            OK
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>

                <div>
                    <Dialog
                        open={openErrorAccept}
                        onClose={handleAgreeErrorAccept}
                    >
                        <DialogTitle>{"There was an error while processing your request.  Please try again later."}</DialogTitle>

                        <DialogActions>
                        <Button onClick={handleAgreeErrorAccept} color="primary">
                            OK
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>

                <CardActions>
                    <Grid container spacing={1}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={4}>
                            <Button  align="center" color="primary" variant="outlined" onClick={ e => handleDeclineOffer() }>
                                Decline
                            </Button>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={4}>
                            <Button  align="center" color="secondary" variant="outlined" onClick={ e => handleAcceptOffer() }>
                                Accept
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Container>
        </div>



    )

}