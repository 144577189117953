import React from 'react';

import Container from '@material-ui/core/Container';
import MainAppBar from '../../components/MainAppBar';
import UpcomingGames from '../../components/gameComponents/UpcomingGames';

import Footer from '../../components/Footer';
import Copyright from '../../components/Copyright';
import checkAuthenticated from '../../utils/checkAuthenticated';


export default function LandingPage() {
  checkAuthenticated();
  let league = 'nfl';
  let title = 'NFL';

  return (
    < Container maxWidth="sm">
        <MainAppBar></MainAppBar>
        <UpcomingGames
          league={league}
          title={title}
        ></UpcomingGames>
        <br/>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
