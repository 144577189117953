import React from 'react';

import Container from '@material-ui/core/Container';
import MainAppBar from '../components/MainAppBar';
import PromotionCreate from '../components/PromotionCreate';

import Footer from '../components/Footer';
import Copyright from '../components/Copyright';
import checkAuthenticated from '../utils/checkAuthenticated';
import checkAdmin from '../utils/checkAdmin';


export default function LandingPage() {
  checkAuthenticated();
  checkAdmin()

  return (
    < Container maxWidth="sm">
        <MainAppBar></MainAppBar>
        <PromotionCreate></PromotionCreate>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
