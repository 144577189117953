
import axios from 'axios';

export default function checkAuthenticated() {
    if (sessionStorage.getItem("updating") === true ) {
      window.location.pathname = "/updating_server";
    } else {

      axios.get('/api/user').then(function(res) {
          if (res.data.update_status === true ) {
            sessionStorage.setItem("updating", res.data.update_status)
            window.location.pathname = "/updating_server";
          } else {
            sessionStorage.setItem("updating", res.data.update_status)
          }

          if (res.data.user === null) {
            sessionStorage.removeItem("currentUser");
            sessionStorage.removeItem("userID");
            sessionStorage.removeItem("currentCountry");
            sessionStorage.removeItem("currentRegion");
            sessionStorage.removeItem("regionRestriction");
            sessionStorage.removeItem("loginAttempt");
            sessionStorage.removeItem("updating");
            window.location.pathname = "/login";
          }
      })
      .catch(error => {
        console.log(error);
      })  

    }
}