import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
// import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import MuiPhoneNumber from 'material-ui-phone-number';


import axios from 'axios';

let  username;
let  email;
let  phoneNumber;
let  firstNameDB;
let  lastNameDB;

const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    formControl: {
        margin: 1,
        minWidth: 220,
        maxWidth: 220,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
      },
  });


export default function SignUpForm() { 
    const classes = useStyles();
    const [ selectedUsername, setSelectedUsername ] = React.useState('');
    const [ selectedEmail, setSelectedEmail ] = React.useState('');
    const [ phone, setPhone ] = React.useState('');
    const [ firstName, setFirstName ] = React.useState('');
    const [ lastName, setLastName ] = React.useState('');
    

    let currentuser = sessionStorage.getItem("currentUser");

    axios.get('/api/user/profile/' + currentuser)
    .then(function(res) {
        if (res.data.user === null) {
            window.location.pathname = "/login";
        } else {
            firstNameDB = res.data.firstName;
            lastNameDB = res.data.lastName;
            username = res.data.username;
            email = res.data.email;
            phoneNumber = res.data.phone;
            setFirstName(firstNameDB);
            setLastName(lastNameDB);
            setSelectedUsername(username);
            setSelectedEmail(email);
            setPhone(phoneNumber);
        }
    })
    .catch(error => {
      console.log(error);
    })
    

    
    const handleSubmit = (event) => {
        event.preventDefault();
        window.location.pathname = "/editprofile"
    }

    const handleChangePassword = (event) => {
        event.preventDefault();
        window.location.pathname = "/changepassword"
    }
    

    return(
        <div>
            < Container maxWidth="sm">
            <br/>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                            My Profile
                        </Typography>
                        <Typography className={classes.title} style={{ textAlign: 'center'}}>
                            iBetU
                        </Typography>
                        <br />
                        <form noValidate autoComplete="off">
                            <Grid container direction='column' justify="center" alignItems='center'>
                                <div>
                                    <Typography
                                        id="name"
                                        label="Full Name"
                                        name="name"
                                        variant="outlined"
                                        
                                    >Full Name: <span></span> {firstName} {lastName} </Typography>
                                </div>
                                <br />
                                <div>
                                    <Typography
                                        id="username"
                                        label="Username"
                                        name="username"
                                        variant="outlined"
                                        
                                    >Username: <span></span> {selectedUsername} </Typography>
                                </div>
                                <br />
                                <div>
                                    <Typography
                                        id="email"
                                        label="Email"
                                        name="email"
                                        variant="outlined"

                                    >Email: <span></span> {selectedEmail} </Typography>
                                </div>
                                <br />
                                <div>
                                    <Typography
                                        id="phone"
                                        label="Phone Number"
                                        name="Phone"
                                        variant="outlined"
                                        
                                    >Phone: <span></span> {phone} </Typography>  
                                </div>
                                <br />

                                <CardActions style={{ justifyContent: 'center'}}>
                                    <Button size="small" variant="contained" className={classes.buttonblue}
                                    type='submit'
                                    onClick={handleSubmit}
                                    >Update Profile</Button>
                                    <Button size="small" variant="contained" className={classes.buttonblue}
                                    type='submit'
                                    onClick={handleChangePassword}
                                    >Change Password</Button>
                                </CardActions>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
        
}

