import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    title: {
        fontSize: 13,
        fontFamily: 'Anton',
        color: '#0510e6',
        fontStyle: 'italic',
      }, 
    basic: {
        fontSize: 13,
    }
})

export default function Copyright() {
    const classes = useStyles();
    return (
    <Typography style={{textAlign: 'center', fontSize: 13}}>
        © iBetU.co - All Rights Reserved - <span className={classes.title}>{"iBetU ™"}</span>
    </Typography>
    );
}