import React from 'react';

import Container from '@material-ui/core/Container';
import MainAppBar from '../../components/MainAppBar';
import ChooseTeam from '../../components/gameComponents/ChooseTeam';

import Footer from '../../components/Footer';
import Copyright from '../../components/Copyright';
import checkAuthenticated from '../../utils/checkAuthenticated';

import {abbreviations} from '../../utils/abbreviations';


export default function LandingPage() {
  checkAuthenticated();

  let league = 'premier';
  let title = "Premier League";
  
  let names = []  
  for ( const item of abbreviations) {
    if (item.league === league) {
      names.push( item.name );
    }
  }

  // let names = [
  //   'Arsenal',
  //   'Aston Villa',	
  //   'Bournemouth',
  //   'Brighton',
  //   'Burnley',
  //   'Chelsea',
  //   'Crystal Palace',
  //   'Everton',
  //   'Leicester City',	
  //   'Liverpool',
  //   'Manchester City',
  //   'Manchester Utd	',
  //   'Newcastle Utd',	
  //   'Norwich City',
  //   'Sheffield Utd',	
  //   'Southampton',
  //   'Tottenham',
  //   'Watford',
  //   'West Ham',
  //   'Wolves',
  // ]


  return (
    < Container maxWidth="sm">
        <MainAppBar></MainAppBar>
        <ChooseTeam
          league={league}
          title={title}
          names={names}
        ></ChooseTeam>
        <br/>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
