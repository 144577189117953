
import axios from 'axios';

export default function checkNotAuthenticated() {
    axios.get('/api/user').then(function(res) {
        if (res.data.user !== null) {
          window.location.pathname = "/home";
        } 
    })
    .catch(error => {
      console.log(error);
    })
}