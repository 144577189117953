import React, { Suspense, lazy } from 'react';

import Container from '@material-ui/core/Container';
import MainAppBar from '../components/MainAppBar';
import HistoryByDate from '../components/HistoryByDate';
import FundsSummary from '../components/FundsSummaryAccount';

import Footer from '../components/Footer';
import Copyright from '../components/Copyright';
import checkAuthenticated from '../utils/checkAuthenticated';
import {abbreviations} from '../utils/abbreviations';

// const FundsSummary = React.lazy(() => import('../components/FundsSummary'));


export default function LandingPage() {
  checkAuthenticated();


  return (
    < Container maxWidth="sm">
        <MainAppBar></MainAppBar>
         
          <FundsSummary
            fundsAvailable={sessionStorage.getItem('fundsAvailable')}
            fundsAtRisk={sessionStorage.getItem('fundsAtRisk')}
          ></FundsSummary>
              
        <br />       
        <HistoryByDate
            abbreviations={abbreviations}
        ></HistoryByDate>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
