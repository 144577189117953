import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import axios from 'axios';

let currentUser = sessionStorage.getItem('currentUser');

const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        minWidth: 275,
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    table: {
        minWidth: 280,
        maxWidth: 280,
    },
    tableHeader: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
    },
    tableCell: {
        padding: 3,
        whiteSpace: 'normal',
        wordWrap: 'break-word'
    },
    tableHeaderName: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        fontSize: 18,
    },
    tableCellName: {
        padding: 8,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        fontSize: 18,
    },
    buttonBetBlue: {
        background: '#0510e6',
        color: 'white',
        minWidth: 80
    },
    formControl: {
        display: "flex",
    },
    buttonGet: {
        background: '#0510e6',
        color: 'white'
    },
    buttonChoiceBlue: {
        background: '#0510e6',
        color: 'white',
        minWidth: 240
    },
  });


  let regionRestriction = sessionStorage.getItem('regionRestriction');

  export default function GameTable(props){
      const classes = useStyles();

      // Code to Choose Team starts here +++++++++++++++++++++++++++++++++++++++
      const names = props.names;
      const league1 = props.league;
      const title = props.title;
      const [ rows, setRows ] = React.useState([]);
      const [ team, setTeam ] = React.useState('');

      useEffect(() => {
        setTeam();
      }, []);


      const handleTeamSelect = (event) => {
          event.preventDefault();
          
            (async () => {
                await setTeam(event.target.value);
                const array = await axios.get('/api/schedule' + league1 + '/teamgames/' + team)
                    .then(function(res){
                        if (res.data.data.length === 0 ) {
                            // console.log("No upcoming games - create Dialog");
                            setOpen(true);
                        } else {
                            // console.log(res);
                            let upcomingGames = res.data.data;
                            return upcomingGames;
                        }
                    })
                    .catch(error => {
                        // console.log(error);
                    });
                
                const result = [];
                const map = new Map();
                if ( array !== undefined ){
                    for (const item of array) {
                        if(!map.has(item.gameID)){
                            map.set(item.gameID, true);    // set any value to Map
                            result.push({
                                _id: item._id,
                                date: item.date,
                                dateOUS: item.dateOUS,
                                dateShort: item.dateShort,
                                dateShortOUS: item.dateShortOUS,
                                dateDB: item.dateDB,
                                dateTimeDB: item.dateTimeDB,
                                dateDate: item.dateDate,
                                week: item.week,
                                winner: item.winner,
                                winnerScore: item.winnerScore,
                                loser: item.loser,
                                loserScore: item.loserScore,
                                winnerDif: item.winnerDif,
                                loserDif: item.loserDif,
                                tie: item.tie,
                                awayTeam: item.awayTeam,
                                homeTeam: item.homeTeam,
                                awayPoints: item.awayPoints,
                                homePoints: item.homePoints,
                                gameID: item.gameID,
                                league: item.league,
                                sport: item.sport,
                                type: item.type
                            });
                        }
                    }
                }
                if ( result === undefined ) { console.log('no games')} else {
                    setRows(result)
                }
            })();  
        } 

    // For Dialog PopUp on No games
    const [open, setOpen] = React.useState(false);
    const [agree, setAgree] = React.useState(false);

    const handleAgree = () => {
        setAgree(true);
        setOpen(false);
        window.location.pathname = '/home';
      };
//  Code to choose team ends ----------------------------------------------

//  Code to make bet starts here+++++++++++++++++++++++++++++++++++++++++++++++++
    
    // Get Maximum Bet Capacity
    const [ maxRisk, setMaxRisk ] = React.useState('');
    const [ fundsAtRisk, setFundsAtRisk ] = React.useState('');

    useEffect(() => {
        (async () => {
            const result = await axios.get('/api/user/maxrisk/' + currentUser )
                .then(function (res) {
                    if (res.data[0].fundsAvailable === null ) {
                        // console.log("User does not exist - consider Dialog")
                    } else {
                        // console.log(res.data[0].fundsAvailable);
                        return {
                            fundsAvailable: parseFloat(res.data[0].fundsAvailable),
                            fundsAtRisk: parseFloat (res.data[0].fundsAtRisk)
                        }
                    }
                })
                .catch( error => { console.log(error )});
            // console.log(result)
            setMaxRisk(result.fundsAvailable);
            setFundsAtRisk(result.fundsAtRisk);
        })();
    }, []);

    //  Set Variables and handle the game that is picked
    const [ gamePicked, setGamePicked ] = React.useState();
    const [ team1, setTeam1 ] = React.useState('');
    const [ team2, setTeam2 ] = React.useState('');
    const [ gameIDBet, setGameIDBet ] = React.useState('');
    const [ league, setLeague ] = React.useState('');
    const [ dateMatch, setDateMatch ] = React.useState('');
    const [ dateMatchDB, setDateMatchDB ] = React.useState('');
    const [ dateTimeDB, setDateTimeDB ] = React.useState('');
    const [ dateShort, setDateShort ] = React.useState('');
    const [ dateDate, setDateDate ] = React.useState('');
    const [ dateShortOUS, setDateShortOUS ] = React.useState('');
    const [ week, setWeek ] = React.useState('');
    const [ winnerBet, setWinnerBet ] = React.useState('');
    const [ loserBet, setLoserBet ] = React.useState('');
    const [ spreadBet, setSpreadBet ] = React.useState('');
    const [ oddsNumerator, setOddsNumerator] = React.useState(1);
    const [ oddsDenominator, setOddsDenominator ] = React.useState(1);
    const [ amountBet, setAmountBet ] = React.useState('');
    const [ offerPay, setOfferPay ] = React.useState('');
    const [ acceptPay, setAcceptPay ] = React.useState('');
    const [ validBet, setValidBet ] = React.useState(false);
    const [ partialTake, setPartialTake ] = React.useState({checked: true});
    const [ regionLimit, setRegionLimit ] = React.useState('none');
    const [ friendBet, setFriendBet ] = React.useState(false);
    const [ friendUsername, setFriendUsername ] = React.useState('');
    const [ friendEmail, setFriendEmail ] = React.useState('');
    const [ friendPhone, setFriendPhone ] = React.useState('');
    const [ friendCountryCode, setFriendCountryCode ] = React.useState('');
    const [ messageSummary, setMessageSummary ] = React.useState('');

    // Variables for Friend List
    const [ openFriendSelector, setOpenFriendSelector ] = React.useState('');
    const [ friendSelectedName, setFriendSelectedName ] = React.useState('');
    const [ friendSelectedUsername, setFriendSelectedUsername ] = React.useState('');
    const [ friendSelectedEmail, setFriendSelectedEmail ] = React.useState('');
    const [ openNoFriendsYet, setOpenNoFriendsYet ] = React.useState('');
    const [ rows2, setRows2 ] = React.useState([]);
    const [ friendPicked, setFriendPicked ] = React.useState([]);
    const [ noFriends, setNoFriends ] = React.useState(false);
    const [ openConfirmFriendSelector, setOpenConfirmFriendSelector ] = React.useState(false);

    // Variables for Group List
    const [ openGroupSelector, setOpenGroupSelector ] = React.useState(false)
    const [ friendGroupSelected, setFriendGroupSelected ] = React.useState('');
    const [ friendGroupSelectedName, setFriendGroupSelectedName ] = React.useState('');
    const [ openNoGroupsYet, setOpenNoGroupsYet ] = React.useState(false);
    const [ rowsGroup, setRowsGroup ] = React.useState([]);
    const [ noGroups, setNoGroups ] = React.useState(false);
    const [ openConfirmGroupSelector, setOpenConfirmGroupSelector ] = React.useState(false);
    const [ groupMembers, setGroupMembers ] = React.useState([]);
    
    // Get all User's Groups
    useEffect(() => {
        (async () => {
            let currentUserID = sessionStorage.getItem('userID');
            const array = await axios.get('/api/user/mygroups/' + currentUserID)
            .then( function (res) {
                if ( res.data.length === 0 ) {
                    let noGroups = []
                    setNoGroups(true);
                    return noGroups;
                } else {
                    return res.data
                }
            })
            .catch(error => { console.log(error)})

            const result = [];
            const map = new Map();
            if (array.length > 0){
                for(const item of array) {
                    if(!map.has(item._id)){
                        map.set(item._id, true);
                        result.push({
                            _id: item._id,
                            friendGroupName: item.friendGroupName,
                        })
                    }
                }
            } else {
                // Enter code for open no groups
            }
            //  Below makes them come out sorted alphabetically
            if ( result === undefined ) { console.log('no friend groups') } else {
                function compare(a, b) {
                    const bandA = a.friendGroupName.toLowerCase();
                    const bandB = b.friendGroupName.toLowerCase();
                    let comparison = 0;
                    if (bandA > bandB) {
                        comparison = 1;
                        } else if (bandA < bandB) {
                        comparison = -1;
                        }
                        return comparison;
                }
                let sortedResult = result.sort(compare);
                setRowsGroup(sortedResult);
            }

        })();
    }, [])

    const handleGroupPick = async (event, key) =>{
        setFriendGroupSelected(key);
        let groupChosen = rowsGroup.find(({_id}) => _id === key);
        let groupChosenName = groupChosen.friendGroupName;
        setFriendGroupSelectedName(groupChosenName);
        let groupDetails = await axios.get('/api/group/' + groupChosenName)
        .then( async function(res) {
            return res.data;
        })
        .catch(error => {
            console.log(error)
        })

        let groupMembersArray = groupDetails.friends;
        setGroupMembers(groupMembersArray);

        setOpenConfirmGroupSelector(true);

    }

    const handleOpenListOfGroups = () => {
        if (noGroups === true ) {
            setOpenNoGroupsYet(true);
        } else {
            setOpenGroupSelector(true);
        }
    }


    // Get all friends=======================
    useEffect(() => {
        (async () => {
            const array = await axios.get('/api/user/accounts/' + sessionStorage.getItem('userID'))
                .then(async function(res){
                    if (res.data[0].friends.length === 0 ) {
                        setNoFriends(true);
                    } else {
                        let friendObjects = await res.data[0].friends;
                        // setFriendSelectedName(friendObjects[0].friendName);
                        // setFriendSelectedUsername(friendObjects[0].friendUsername);
                        // setFriendSelectedEmail(friendObjects[0].friendEmail);
                        return friendObjects;
                    }
                })
                .catch(error => {
                    console.log(error);
                });

            const result = [];
            const map = new Map();
            if ( array !== undefined ){
                for ( const item of array ) {
                    if(!map.has(item._id)){
                        map.set(item._id, true);
                        result.push({
                            _id: item._id,
                            friendName: item.friendName,
                            friendUsername: item.friendUsername,
                            friendEmail: item.friendEmail,
                        })
                    }
                }
            }

            if ( result === undefined ) { console.log('no friends') } else {
                function compare(a, b) {
                    const bandA = a.friendName.toLowerCase();
                    const bandB = b.friendName.toLowerCase();
                    let comparison = 0;
                    if (bandA > bandB) {
                        comparison = 1;
                      } else if (bandA < bandB) {
                        comparison = -1;
                      }
                      return comparison;
                }
                let sortedResult = result.sort(compare);
                let key = 'friendName';
                let uniqueArray = [...new Map(sortedResult.map(item => [item[key],item])).values()];
                setRows2(uniqueArray);
            }

        })();
    }, []);

    const handleOpenListOfFriends = () => {
        if (noFriends === true ) {
            setOpenNoFriendsYet(true);
        } else {
            setOpenFriendSelector(true);
        }
    }

    const handleCloseListOfFriends = () => {
        setOpenFriendSelector(false);
    }

    const handleFriendPick = (event, key) => {
        setFriendPicked(key);
        let friendChosen = rows2.find(({_id}) => _id === key);
        setFriendSelectedName(friendChosen.friendName);
        setFriendSelectedUsername(friendChosen.friendUsername);
        setFriendSelectedEmail(friendChosen.friendEmail);

        setFriendUsername(friendChosen.friendUsername);
        setFriendUsername(friendChosen.friendUsername);
        setFriendEmail(friendChosen.friendEmail);
        setOpenFriendSelector(false);
        setOpenConfirmFriendSelector(true);
    }

    const handleCloseNoFriendsYet = () => {
        setOpenNoFriendsYet(false);
    }



    // Dialog for bets not legal
    const [ openIllegalBet, setOpenIllegalBet ] = React.useState(false);
    const [ agreeIllegalBet, setAgreeIllegalBet ] = React.useState(false);
  
    const handleAgreeIllegalBet = () => {
        setAgreeIllegalBet(true);
        setOpenIllegalBet(false);
        window.location.pathname ='/home';
    }

    const handleGamePick = (key) => {
        if ( regionRestriction === 'illegal') {
            setOpenIllegalBet(true);
        } else {
        setGamePicked(key);
        let match = rows.find(({_id}) => _id === key);
        setTeam1(match.awayTeam);
        setTeam2(match.homeTeam);
        setGameIDBet(match.gameID);
        setLeague(match.league);
        setDateMatch(match.date);
        setDateMatchDB(match.dateDB);
        setDateTimeDB(match.dateTimeDB);
        setDateShort(match.dateShort);
        setDateShortOUS(match.dateShortOUS);
        setDateDate(match.dateDate);
        setWeek(match.week);
        setOpenBet(true);
        }
    }

    const handlePartialTakeChange = (event)=> {
        setPartialTake({ ...partialTake, [event.target.name]: event.target.checked });
    }

    // FOr Dialog Popup on Bet
    const [openBet, setOpenBet] = React.useState(false);
    const [agreeBet, setAgreeBet] = React.useState(false);
    
    
    const handleAgreeBet = async () => {
        setAgreeBet(true);
        let valid = false;
        if ( amountBet > 0 && winnerBet !== '' && spreadBet > 0 ) { 
            valid = true;
            await setValidBet(true) }
        if ( valid === false )  {
            setOpenBet(false);
            setOpenInvalidBet(true);
        } else {
            let amountOffered = await (( amountBet * oddsNumerator ) / oddsDenominator).toFixed(2);
            setOfferPay(((amountBet * oddsNumerator) / oddsDenominator).toFixed(2));
            setAcceptPay((amountBet * 1.0 ).toFixed(2));
            let loser2;
            if ( winnerBet === team1 ) { setLoserBet(team2); loser2=team2 } else { setLoserBet(team1); loser2=team1};

            if ( league1 === 'nba' || league1 === 'nfl' || league1 === 'nhl' || league1 === 'mlb') {
                setMessageSummary("You are betting $" + (1 * amountBet).toFixed(2) + " dollars that the " + winnerBet + " will beat the " + loser2)
            } else {
                setMessageSummary("You are betting $" + (1 * amountBet).toFixed(2) + " dollars that " + winnerBet + " will beat " + loser2)
            }

            if ( amountOffered > maxRisk ) {
                setOpenNotEnough(true);
                console.log("Not enough funds dialog")
            } else {
                setOpenBet(false);
                setOpenConfirmBet(true);
            }   
        }
      };

      const handleCancelBet = () => {
        setAmountBet('');
        setWinnerBet('');
        setLoserBet('');
        setSpreadBet('');
        setOpenBet(false);
      };

    //  For Dialog to confirm bet
    const [openConfirmBet, setOpenConfirmBet] = React.useState(false);
    const [agreePost, setAgreePost] = React.useState(false);
    const [agreeFriend, setAgreeFriend] = React.useState(false);

    const handleAgreePost = () => {
        setAgreePost(true);
        let timeStamp = new Date();
        const betDetails= {
            dateProposed: timeStamp,
            dateMatch: dateMatch,
            dateMatchDB: dateMatchDB,
            dateTimeDB: dateTimeDB,
            dateShort: dateShort,
            dateShortOUS: dateShortOUS,
            dateDate: dateDate,
            league: league,
            firstNameOffer: sessionStorage.getItem('firstName'),
            lastNameOffer: sessionStorage.getItem('lastName'),
            usernameOffer: currentUser,
            userIDoffer: sessionStorage.getItem('userID'),
            amountBet: amountBet,
            winnerOffer: winnerBet,
            spreadOffer: spreadBet,
            oddsNumeratorOffer: oddsNumerator,
            oddsDenominatorOffer: oddsDenominator,
            homeTeam: team2,
            awayTeam: team1,
            offerMaxRisk: maxRisk,
            offerFundsAtRisk: fundsAtRisk,
            publicBet: true,
            partialTake: partialTake.checked,
            friend: false,
            friendUsername: 'null',
            friendEmail: 'null',
            friendPhone: 'null',
            gameID: gameIDBet,
            regionLimit: regionLimit,
            offerPay: offerPay,
            acceptPay: acceptPay,
            group: false,
            groupName: '',
        };
        //Route to post bet on Posted Public Bets
        axios.post('/api/betsProposed/newPublicBet', betDetails, {
        })
        .then( res => {
            console.log("Bet posted publicly");
            setOpenSuccessBet(true);
            setAmountBet('');
            setSpreadBet('');
            setOddsNumerator(1);
        })
        .catch( error => {
            console.log("Error Posting Bet");
            console.log(error);
        })

        // Route to reduce funds available
        axios.post('/api/user/adjustFundsAtPost/', betDetails, { })
        .then( res => {
            console.log(res);
            setMaxRisk(parseFloat(maxRisk) - parseFloat(offerPay));
            setFundsAtRisk(parseFloat(fundsAtRisk) + parseFloat(offerPay));
            console.log("Funds Adjusted")
        })
        .catch( error => {
            console.log("Error Adjusting Funds Bet");
            console.log(error);
        })
        
        setOpenConfirmBet(false);
      };

      const handleAgreeFriend = () => {
        setAgreeFriend(true);
        setOpenConfirmBet(false);
        // setOpenFriendInfo(true);
        setOpenFriendReachChoice(true);
      };

    const handleCancelConfirm = () => {
        setAmountBet('');
        setWinnerBet('');
        setLoserBet('');
        setSpreadBet('');
        setOddsNumerator(1);
        setOddsDenominator(1);
        setOpenConfirmBet(false);
        setOpenFriendInfo(false);
        setOpenFriendReachChoice(false);
        setOpenFriendUsername(false);
        setOpenFriendEmail(false);
        setOpenFriendPhone(false);
        setOpenBet(true);
        setOpenConfirmFriendSelector(false);
      };

    //  For Dialog on Valid Bet
    const [ openInvalidBet, setOpenInvalidBet ] = React.useState(false);
    const [ agreeInvalid, setAgreeInvalid ] = React.useState(false);

    const handleAgreeInvalid = () => {
        setAgreeInvalid(true);
        setOpenInvalidBet(false);
        setAmountBet('');
        setWinnerBet('');
        setLoserBet('');
        setSpreadBet('');
        setOddsNumerator(1);
        setOddsDenominator(1);
        setOpenBet(true);
    }
    
    //  For Dialog on Not Enough Funds
    const [ openNotEnough, setOpenNotEnough ] = React.useState(false);
    const [ agreeAlterBet, setAgreeAlterBet ] = React.useState(false);
    const [ agreeDeposit, setAgreeDeposit ] = React.useState(false);

    const handleCancelNotEnough = () => {
        setOpenNotEnough(false);
        setAmountBet('');
        setWinnerBet('');
        setLoserBet('');
        setSpreadBet('');
        setOddsNumerator(1);
        setOddsDenominator(1);
        setOpenBet(false);
    }

    const handleAgreeAlterBet = () => {
        setAgreeAlterBet(true);
        setOpenNotEnough(false);
        setAmountBet('');
        setWinnerBet('');
        setLoserBet('');
        setSpreadBet('');
        setOddsNumerator(1);
        setOddsDenominator(1);
        setOpenBet(true);
    };

    const handleAgreeDeposit = () => {
        setAgreeDeposit(true);
        setOpenNotEnough(false);
        window.location.pathname = '/depositchoice';
    }

    // For Dialog of Bet entered successfully
    const [ openSuccessBet, setOpenSuccessBet ] = React.useState(false);
    const [ agreeSuccessBet, setAgreeSuccessBet ] = React.useState(false);

    const handleAgreeSuccessBet = () => {
        setAgreeSuccessBet(true);
        setOpenSuccessBet(false);
        window.location.pathname = '/home';
    }    

    const [ openUserNotThere, setOpenUserNotThere ] = React.useState(false);
    const [ agreeUserNotThere, setAgreeUserNotThere ] = React.useState(false);

    const handleAgreeUserNotThere = () => {
        setAgreeUserNotThere(true);
        setOpenUserNotThere(false);
    }

    // For Dialog of entering friend information
    const [ openFriendInfo, setOpenFriendInfo ] = React.useState(false);
    const [ agreeFriendInfo, setAgreeFriendInfo ] = React.useState(false);

    const [ openFriendReachChoice, setOpenFriendReachChoice ] = React.useState(false);
    const [ openFriendUsername, setOpenFriendUsername ] = React.useState(false);
    const [ openFriendEmail, setOpenFriendEmail ] = React.useState(false);
    const [ openFriendPhone, setOpenFriendPhone ] = React.useState(false);
    const [ openCannotBeBlank, setOpenCannotBeBlank ] = React.useState(false);

    
    const handleAgreeFriendBet = async () => {
        setAgreeFriendInfo(true);

        if ( friendUsername === '' && friendEmail === '' && friendPhone ==='' ) {
            setOpenCannotBeBlank(true);
        } else {       
            let validUsername = await axios.get('/api/user/validfriend/' + friendUsername)
            .then (res => {
                if (friendUsername.length > 0 ){
                    if (res.data.username === friendUsername && res.data.username !== currentUser) {
                        return true
                    } else { return false }
                } else {
                    return true;
                }
            })
            .catch( (err) => {console.log(err); return false})

            if ( validUsername === true ) {
                let timeStamp = new Date();
                let friendPhoneWCC = friendCountryCode + friendPhone;
                let friendPhoneNumOnly = friendPhoneWCC.replace(/\D/g, '');
                const betDetails= {
                    dateProposed: timeStamp,
                    dateMatch: dateMatch,
                    dateMatchDB: dateMatchDB,
                    dateTimeDB: dateTimeDB,
                    dateShort: dateShort,
                    dateShortOUS: dateShortOUS,
                    dateDate: dateDate,
                    league: league,
                    firstNameOffer: sessionStorage.getItem('firstName'),
                    lastNameOffer: sessionStorage.getItem('lastName'),
                    usernameOffer: currentUser,
                    userIDoffer: sessionStorage.getItem('userID'),
                    amountBet: amountBet,
                    winnerOffer: winnerBet,
                    spreadOffer: spreadBet,
                    oddsNumeratorOffer: oddsNumerator,
                    oddsDenominatorOffer: oddsDenominator,
                    homeTeam: team2,
                    awayTeam: team1,
                    offerMaxRisk: maxRisk,
                    offerFundsAtRisk: fundsAtRisk,
                    publicBet: false,
                    partialTake: partialTake.checked,
                    friend: true,
                    friendUsername: friendUsername,
                    friendEmail: friendEmail.toLowerCase(),
                    friendPhone: friendPhoneNumOnly,
                    gameID: gameIDBet,
                    regionLimit: regionLimit,
                    offerPay: offerPay,
                    acceptPay: acceptPay,
                    group: false,
                    groupName: '',
                };
                //Route to post bet on Posted Public Bets
                axios.post('/api/betsProposed/newFriendBet', betDetails, {
                })
                .then( res => {
                    // console.log("Bet posted publicly");
                    setOpenSuccessBet(true);
                    setAmountBet('');
                    setSpreadBet('');
                    setFriendUsername('');
                    setFriendEmail('');
                    setFriendPhone('');
                    setOddsNumerator(1);
                })
                .catch( error => {
                    console.log("Error Posting Bet");
                    console.log(error);
                })

                // Route to reduce funds available
                axios.post('/api/user/adjustFundsAtPost/', betDetails, { })
                .then( res => {
                    // console.log(res);
                    setMaxRisk(parseFloat(maxRisk) - parseFloat(offerPay));
                    setFundsAtRisk(parseFloat(fundsAtRisk) + parseFloat(offerPay));
                    // console.log("Funds Adjusted")
                })
                .catch( error => {
                    console.log("Error Adjusting Funds Bet");
                    console.log(error);
                })
            
                setOpenFriendInfo(false);
                setOpenFriendReachChoice(false);
                setOpenFriendUsername(false);
                setOpenFriendEmail(false);
                setOpenFriendPhone(false);
                setOpenConfirmFriendSelector(false);
            } else {
            //Dialog for user not in system
                setOpenUserNotThere(true);
            }  
        }
    };

    const handleAgreeGroupBet = async () => {
        let timeStamp = new Date();
    
        const betDetails= {
            dateProposed: timeStamp,
            dateMatch: dateMatch,
            dateMatchDB: dateMatchDB,
            dateTimeDB: dateTimeDB,
            dateShort: dateShort,
            dateShortOUS: dateShortOUS,
            dateDate: dateDate,
            league: league,
            firstNameOffer: sessionStorage.getItem('firstName'),
            lastNameOffer: sessionStorage.getItem('lastName'),
            usernameOffer: currentUser,
            userIDoffer: sessionStorage.getItem('userID'),
            amountBet: amountBet,
            winnerOffer: winnerBet,
            spreadOffer: spreadBet,
            oddsNumeratorOffer: oddsNumerator,
            oddsDenominatorOffer: oddsDenominator,
            homeTeam: team2,
            awayTeam: team1,
            offerMaxRisk: maxRisk,
            offerFundsAtRisk: fundsAtRisk,
            publicBet: false,
            partialTake: partialTake.checked,
            friend: true,
            friendUsername: '',
            friendEmail: '',
            friendPhone: '',
            gameID: gameIDBet,
            regionLimit: regionLimit,
            offerPay: offerPay,
            acceptPay: acceptPay,
            group: true,
            groupName: friendGroupSelectedName,
            groupMembers: groupMembers,
        };
        //Route to post bet on Posted Public Bets
        axios.post('/api/betsProposed/newGroupBet', betDetails, {
        })
        .then( res => {
            // console.log("Bet posted publicly");
            setOpenSuccessBet(true);
            setAmountBet('');
            setSpreadBet('');
            setFriendUsername('');
            setFriendEmail('');
            setFriendPhone('');
            setOddsNumerator(1);
            setFriendGroupSelectedName('');
            setFriendGroupSelected('');
        })
        .catch( error => {
            console.log("Error Posting Bet");
            console.log(error);
        })

        // Route to reduce funds available
        axios.post('/api/user/adjustFundsAtPost/', betDetails, { })
        .then( res => {
            // console.log(res);
            setMaxRisk(parseFloat(maxRisk) - parseFloat(offerPay));
            setFundsAtRisk(parseFloat(fundsAtRisk) + parseFloat(offerPay));
            // console.log("Funds Adjusted")
        })
        .catch( error => {
            console.log("Error Adjusting Funds Bet");
            console.log(error);
        })

        setOpenConfirmGroupSelector(false);
        setOpenGroupSelector(false);
        setOpenFriendReachChoice(false);
        
    };
    

//  Code to make bet ends here ---------------------------------------------------

      return(
          <div>
              <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center' }} gutterBottom>
                                {title} - Select Team
                        </Typography>

                    </CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="select-team-outlined-label">Select Team</InputLabel>
                                <Select
                                    className={classes.formControl}
                                    required
                                    labelId="select-team-label"
                                    id="select-team"
                                    value={team}
                                    onChange={ e => setTeam(e.target.value)}
                                    label="Select Team"
                                >
                                    {names.map((name) => (
                                        <MenuItem key={name} value={name}>{name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <Button 
                                className={classes.buttonGet}
                                onClick={handleTeamSelect}
                                >Get Games</Button>
                        </Grid>
                    </Grid>

                    <div>
                        <Dialog
                            open={open}
                            agree={agree}
                            onClose={handleAgree}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"There are no upcoming games for this team.  If you feel this is an error, please email help@ibetu.co"}</DialogTitle>

                            <DialogActions>
                            <Button onClick={handleAgree} color="primary" autoFocus>
                                OK
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    
                <TableContainer component={Paper} align="center">
                    <Table className={classes.table} aria-label="game table" style={{ justifyContent: 'center'}}>
                        <TableHead>
                        <TableRow>
                            <TableCell align="center" className={classes.tableHeader}>Away Team</TableCell>
                            <TableCell align="center" className={classes.tableHeader}>Home Team</TableCell>
                            <TableCell align="center" className={classes.tableHeader}>Game Date</TableCell>
                            <TableCell align="center" className={classes.tableHeader}>Select Game</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row._id}>
                            <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                                {row.awayTeam}
                            </TableCell>
                            <TableCell align="center" className={classes.tableCell}>{row.homeTeam}</TableCell>
                            <TableCell align="center">{row.dateShort}</TableCell>
                            <TableCell align="center">
                                <IconButton
                                    value={row._id}
                                    onClick={ e => handleGamePick(e.currentTarget.value) }
                                ><AddCircleIcon color="primary"></AddCircleIcon></IconButton>
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>

        {/* Dialogs for Setting bet start here +++++++++++++++++++++++++++++++++++ */}
            {/* BET DIALOG START+++++++++++++++++++++++++++++++++++++++++++++++++++ */}
                <div>
                    
                    <Dialog
                        open={openBet}
                        agree={agreePost}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                    
                        <DialogTitle className={classes.title} align='center'>{'Set the Bet'}</DialogTitle>
                
                        <TextField
                            required
                            id="amountBet"
                            label="Bet Amount in USD"
                            name="amountBet"
                            variant="outlined"
                            value={amountBet}
                            placeholder="Enter Bet Amount in USD"
                            onChange={ async e => await setAmountBet(e.target.value) }
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                                ),
                            }}
                        />
                        <br/>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel>Select Winner</InputLabel>
                            <Select
                                labelId="select-winner"
                                id="select-team"
                                value={winnerBet}
                                onChange={ e => setWinnerBet(e.target.value)}
                                label="Select Winner"
                            >
                                <MenuItem key={team1} value={team1}>{team1}</MenuItem>
                                <MenuItem key={team2} value={team2}>{team2}</MenuItem>
                            </Select>
                        </FormControl>

                        <br/>
                        <TextField
                            required
                            id="spread"
                            label="Enter Spread"
                            name="spread"
                            variant="outlined"
                            value={spreadBet}
                            placeholder="Enter by at least how much will win"
                            onChange={ async e => await setSpreadBet(e.target.value) }
                            
                        />
                        <br/>
                        <DialogContent>
                            <DialogContentText align='center'>
                                Set Odds You Are Offering:
                            </DialogContentText>
                        </DialogContent>

                        <Grid container spacing={1}>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <TextField
                                required
                                id="numerator"
                                name="numerator"
                                variant="outlined"
                                value={oddsNumerator}
                                onChange={ e => setOddsNumerator(e.target.value) }
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <DialogContent>
                                    <DialogContentText align='center'>
                                    to
                                    </DialogContentText>
                                </DialogContent>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                required
                                id="denominator"
                                name="denominator"
                                variant="outlined"
                                value={oddsDenominator}
                                onChange={ e => setOddsDenominator(e.target.value) }
                                />
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                        <br/>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={partialTake.checked}
                                onChange={handlePartialTakeChange}
                                name="checked"
                                color="primary"
                            />
                            }
                            label="Accept bets for partial amount? (recommended)"
                        />
                        <br/> 
                        <DialogActions>
                            <Grid container spacing ={1}>
                                <Grid item xs={4}>
                                    <Button onClick={handleCancelBet} align="center" color="secondary" variant="outlined" autoFocus>
                                    Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={4}>
                                    <Button onClick={handleAgreeBet} color="primary" variant="outlined" className={classes.buttonBetBlue} align="center" autoFocus>
                                    Next
                                    </Button>
                                </Grid>
                            
                            </Grid>
                        </DialogActions>
                        
                    </Dialog>
                
                </div>
            
            {/* Bet Dialog Over==================================================== */}

            {/* Bet Confirm Dialog Start++++++++++++++++++++++++++++++++++++++++++ */}
                    <Dialog open={openConfirmBet}>
                        <DialogTitle id="confirm-bet">
                            {messageSummary} by at least {spreadBet}.  You set the odds at {oddsNumerator} to {oddsDenominator}, which means you will pay ${offerPay} dollars if you lose, and you will receive ${acceptPay} dollars if you win.  If this is correct, please "Bet a Friend" or "Post" the bet publicly. 
                        </DialogTitle>
                        <DialogActions>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Button onClick={handleCancelConfirm} align="center" color="secondary" variant="outlined" autoFocus>
                                        X
                                    </Button>
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={3}>
                                    <Button onClick={handleAgreePost} color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                        Post
                                    </Button>
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={3}>
                                    <Button onClick={handleAgreeFriend} color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                        {"Friend(s)"}
                                    </Button>
                                </Grid>
                                <Grid item xs={1}></Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>
            {/* Bet Confirm Dialog Over=========================================== */}

            {/* Invalid Bet Dialog Start++++++++++++++++++++++++++++++++++++++++ */}
                <div>
                    <Dialog
                        open={openInvalidBet}
                        agree={agreeInvalid}
                        onClose={handleAgreeInvalid}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Invalid bet.  You must enter a numeric bet amount, select a winner, and enter a numeric spread amount."}</DialogTitle>

                        <DialogActions>
                        <Button onClick={handleAgreeInvalid} color="primary" autoFocus>
                            OK
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            {/* Invalid Bet Dialog Over========================================== */}

            {/* Not enough funds in account dialog start+++++++++++++++++++++++++++ */}
                <Dialog open={openNotEnough}>
                    <DialogTitle id="confirm-bet">
                                You are betting ${amountBet} dollars with {oddsNumerator} to {oddsDenominator} odds.  That means you are risking ${offerPay} dollars.  However, you only have ${maxRisk} dollars available in your account. Please deposit more funds to make this bet.  Alternatively, you can decrease the bet amount or decrease the odds. 
                    </DialogTitle>
                    <DialogActions>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Button onClick={handleCancelNotEnough} align="center" color="secondary" variant="outlined" autoFocus>
                                    X
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <Button onClick={handleAgreeAlterBet} color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                    Modify
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <Button onClick={handleAgreeDeposit} color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                    Deposit
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            {/* Not enough funds in account dialog end============================ */}
                
            {/* Dialog for successful bet start ++++++++++++++++++++++++++++++ */}
                <div>
                    <Dialog
                        open={openSuccessBet}
                        agree={agreeSuccessBet}
                        onClose={handleAgreeSuccessBet}
                    >
                        <DialogTitle id="alert-dialog-title">{"Your bet has been entered successfully"}</DialogTitle>

                        <DialogActions>
                        <Button onClick={handleAgreeSuccessBet} color="primary" autoFocus>
                            OK
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            {/* Dialog for successful bet end =========================== */}

        {/* Dialogs for Setting bet ends here ==================================== */}

        {/* Dialog for cannot bet in Region +++++++++++++++++++++++ */}
        <div>
            <Dialog
                open={openIllegalBet}
                agree={agreeIllegalBet}
                onClose={handleAgreeIllegalBet}
            >
                <DialogTitle >{"We are sorry, but on-line sports betting is not legal in the region you are in.  Please write your state / province representative."}</DialogTitle>

                <DialogActions>
                <Button onClick={handleAgreeIllegalBet} color="primary" autoFocus>
                    OK
                </Button>
                </DialogActions>
            </Dialog>
        </div>
        {/* Dialog for cannot bet self end ======================= */}

        {/* Dialog for entering friend info +++++++++++++++++++++++ */}
        <div>
            <Dialog
                open={openFriendInfo}
                agree={agreeFriendInfo}
            >
                <DialogTitle >{"Pick your friend from your list of friends you have bet with in the past by clicking the 'Friend' button below, or enter your friend's username, email or phone number. We don't need all three, but we need at least one:"}</DialogTitle>

                <TextField
                    required
                    id="friendUserame"
                    label="Friend's Username"
                    name="friendUserame"
                    variant="outlined"
                    value={friendUsername}
                    placeholder="Enter Your Friend's Username"
                    onChange={ async e => await setFriendUsername(e.target.value) }
                />
                <br/>

                <TextField
                    required
                    id="friendEmail"
                    label="Friend's Email"
                    name="friendEmail"
                    variant="outlined"
                    value={friendEmail}
                    placeholder="Enter Your Friend's Email"
                    onChange={ async e => await setFriendEmail(e.target.value) }
                />
                <br/>

                <TextField
                    required
                    id="friendCountryCode"
                    label="Friend's Phone Country Code"
                    name="friendCountryCode"
                    variant="outlined"
                    value={friendCountryCode}
                    placeholder="Enter Your Friend's Country Code"
                    onChange={ async e => await setFriendCountryCode(e.target.value) }
                />
                <br/>

                <TextField
                    required
                    id="friendPhone"
                    label="Friend's Phone Number"
                    name="friendPhone"
                    variant="outlined"
                    value={friendPhone}
                    placeholder="Enter Your Friend's Phone Number"
                    onChange={ async e => await setFriendPhone(e.target.value) }
                />
                <br/>

                <DialogActions>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Button onClick={handleCancelConfirm} align="center" color="secondary" variant="outlined" autoFocus>
                                X
                            </Button>
                        </Grid>
                        
                        <Grid item xs={4}>
                            <Button onClick={handleOpenListOfFriends} align="center" color="primary" variant="outlined" autoFocus>
                                Friend
                            </Button>
                        </Grid>
                       
                        <Grid item xs={4}>
                            <Button onClick={handleAgreeFriendBet} align="center" color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                Bet
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
        {/* Dialog for entering friend info end ======================= */}

        {/* Dialog for Choice of how reach friend start ++++++++++++++++++ */}
        <div>
            <Dialog 
                open={openFriendReachChoice} 
                className={classes.root}
                onClose={async e => await setOpenFriendReachChoice(false)}
                >
                <DialogTitle>{"Select how you would like to reach your friend: "}</DialogTitle>
                <DialogActions style={{ justifyContent: 'center'}}>
                    <Button variant="contained" className={classes.buttonChoiceBlue} align="center" onClick={handleOpenListOfFriends}>
                        Select From Friend List
                    </Button>
                    <br/>
                </DialogActions>

                <DialogActions style={{ justifyContent: 'center'}}>
                    <Button variant="contained" className={classes.buttonChoiceBlue} align="center" onClick={handleOpenListOfGroups}>
                        Select a Friend Group
                    </Button>
                    <br/>
                </DialogActions>


                <DialogActions style={{ justifyContent: 'center'}}>
                    <Button variant="contained" className={classes.buttonChoiceBlue} align="center" onClick={ async e => await setOpenFriendUsername(true)}>
                        Friend's Username
                    </Button>
                    <br/>
                </DialogActions>

                <DialogActions style={{ justifyContent: 'center'}}>
                    <Button variant="contained" className={classes.buttonChoiceBlue} align="center" onClick={ async e => await setOpenFriendEmail(true)}>
                        Friend's Email
                    </Button>
                    <br/>
                </DialogActions>

                <DialogActions style={{ justifyContent: 'center'}}>
                    <Button variant="contained" className={classes.buttonChoiceBlue} align="center" onClick={ async e => await setOpenFriendPhone(true)}>
                        Friend's Phone
                    </Button>
                    <br/>
                </DialogActions>
            </Dialog>

        </div>
        {/* Dialog for Choice of how reach friend end ===================== */}

        {/* Dialog for entering friend username +++++++++++++++++++++++ */}
        <div>
            <Dialog
                open={openFriendUsername}
            >
                <DialogTitle >{"Please enter your friend's username: "}</DialogTitle>

                <TextField
                    required
                    id="friendUserame"
                    label="Friend's Username"
                    name="friendUserame"
                    variant="outlined"
                    value={friendUsername}
                    placeholder="Enter Your Friend's Username"
                    onChange={ async e => await setFriendUsername(e.target.value)}
                />
                <br/>

                <DialogActions>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Button onClick={handleCancelConfirm} align="center" color="secondary" variant="outlined" autoFocus>
                                X
                            </Button>
                        </Grid>
                        
                        <Grid item xs={4}></Grid>
                       
                        <Grid item xs={4}>
                            <Button onClick={handleAgreeFriendBet} align="center" color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                Bet
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
        {/* Dialog for entering friend username end ======================= */}

        {/* Dialog for entering friend email +++++++++++++++++++++++ */}
        <div>
            <Dialog
                open={openFriendEmail}
            >
                <DialogTitle >{"Please enter your friend's email address: "}</DialogTitle>

                <TextField
                    required
                    id="friendEmail"
                    label="Friend's Email"
                    name="friendEmail"
                    variant="outlined"
                    value={friendEmail}
                    placeholder="Enter Your Friend's Email"
                    onChange={ async e => await setFriendEmail(e.target.value) }
                />
                <br/>

                <DialogActions>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Button onClick={handleCancelConfirm} align="center" color="secondary" variant="outlined" autoFocus>
                                X
                            </Button>
                        </Grid>
                        
                        <Grid item xs={4}></Grid>
                       
                        <Grid item xs={4}>
                            <Button onClick={handleAgreeFriendBet} align="center" color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                Bet
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
        {/* Dialog for entering friend email end ======================= */}

        {/* Dialog for entering friend phone +++++++++++++++++++++++ */}
                <div>
            <Dialog
                open={openFriendPhone}
            >
                <DialogTitle >{"Please enter your friend's country code (numeric) and phone number: "}</DialogTitle>

                <TextField
                    required
                    id="friendCountryCode"
                    label="Friend's Phone Country Code"
                    name="friendCountryCode"
                    variant="outlined"
                    value={friendCountryCode}
                    placeholder="Enter Your Friend's Country Code"
                    onChange={ async e => await setFriendCountryCode(e.target.value) }
                />
                <br/>

                <TextField
                    required
                    id="friendPhone"
                    label="Friend's Phone Number"
                    name="friendPhone"
                    variant="outlined"
                    value={friendPhone}
                    placeholder="Enter Your Friend's Phone Number"
                    onChange={ async e => await setFriendPhone(e.target.value) }
                />
                <br/>

                <DialogActions>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Button onClick={handleCancelConfirm} align="center" color="secondary" variant="outlined" autoFocus>
                                X
                            </Button>
                        </Grid>
                        
                        <Grid item xs={4}></Grid>
                       
                        <Grid item xs={4}>
                            <Button onClick={handleAgreeFriendBet} align="center" color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                Bet
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
        {/* Dialog for entering friend phone end ======================= */}

        {/* Dialog for saying can't be blank start +++++++++++++++++++++++ */}
        <div>
            <Dialog
                open={openCannotBeBlank}
            >
                <DialogTitle >{"Your entry cannot be blank"}</DialogTitle>
                <br/>

                <DialogActions>
                    <Grid container spacing={1}>
                        <Grid item xs={4}></Grid>
                        
                        <Grid item xs={4}></Grid>
                       
                        <Grid item xs={4}>
                            <Button onClick={ e => setOpenCannotBeBlank(false)} align="center" color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                OK
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
        {/* Dialog for saying can't be blank end ======================= */}
        
        {/* Dialog for confirming Friend Selector choice +++++++++++++++++++++++ */}
            <div>
            <Dialog
                open={openConfirmFriendSelector}
            >
                <DialogTitle >{"Is this the friend you meant to select: "}</DialogTitle>

                <TextField
                    required
                    id="friendName"
                    label="Friend's Name"
                    name="friendsName"
                    variant="outlined"
                    value={friendSelectedName}
                    placeholder="Enter Your Friend's Name"
                />
                <br/>

                <DialogActions>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Button onClick={handleCancelConfirm} align="center" color="secondary" variant="outlined" autoFocus>
                                X
                            </Button>
                        </Grid>
                        
                        <Grid item xs={4}></Grid>
                       
                        <Grid item xs={4}>
                            <Button onClick={handleAgreeFriendBet} align="center" color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
        {/* Dialog for confirming friend selector choice end ======================= */}


         {/* Dialog for selecting friend using selector start +++++++++++++++++ */}
         <div>
        <Dialog
            open={openFriendSelector}
            onClose={handleCloseListOfFriends}
        >
            <br/>
            <div>
                <TableContainer component={Paper} align="center">
                    <Table className={classes.table} aria-label="game table" style={{justifyContent: 'center'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" className={classes.tableHeaderName}>Select a Friend</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows2.map((row) => (
                                <TableRow 
                                hover 
                                key={row._id}
                                onClick={ (event) => handleFriendPick(event, row._id)}
                                >
                                    <TableCell component="th" scope="row" align="center" className={classes.tableCellName}>
                                        {row.friendName}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
                
        </Dialog>
        </div>
        {/* Dialog for selecting friend using selector end =============== */}

        {/* Dialog for no friends yet start ++++++++++++++++++++++++++++++ */}
                <div>
            <Dialog
                open={openNoFriendsYet}
                onClose={handleCloseNoFriendsYet}
            >
                <DialogTitle>{"There are no friends associated with this account yet. Please close this popup and enter your friends information manually.  After that bet is posted, that friend will automatically be added to your friend list."}</DialogTitle>

                <DialogActions>
                <Button onClick={handleCloseNoFriendsYet} color="primary" autoFocus>
                    X
                </Button>
                </DialogActions>
            </Dialog>
        </div>
        {/* Dialog for no friends yet end =========================== */}

        {/* Dialog for user not in system start +++++++++++++++++++++++ */}
        <div>
            <Dialog
                open={openUserNotThere}
                agree={agreeUserNotThere}
                onClose={handleAgreeUserNotThere}
            >
                <DialogTitle >{"We are sorry, but that username is not in our system.  Check the spelling (case sensitive) or use a phone number or email to bet your friend."}</DialogTitle>

                <DialogActions>
                <Button onClick={handleAgreeUserNotThere} color="primary" autoFocus>
                    OK
                </Button>
                </DialogActions>
            </Dialog>
        </div>
        {/* Dialog for user not in system end ======================= */}     

        {/* Dialog for selecting GROUP using selector start +++++++++++++++++ */}
        <div>
        <Dialog
            open={openGroupSelector}
            onClose={async e => await setOpenGroupSelector(false)}
        >
            <br/>
            <div>
                <TableContainer component={Paper} align="center">
                    <Table className={classes.table} aria-label="game table" style={{justifyContent: 'center'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" className={classes.tableHeaderName}>Select a Group</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowsGroup.map((row) => (
                                <TableRow 
                                hover 
                                key={row._id}
                                onClick={ (event) => handleGroupPick(event, row._id)}
                                >
                                    <TableCell component="th" scope="row" align="center" className={classes.tableCellName}>
                                        {row.friendGroupName}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
                
        </Dialog>
        </div>
        {/* Dialog for selecting Group using selector end =============== */}

        {/* Dialog for confirming Group Selector choice +++++++++++++++++++++++ */}
        <div>
            <Dialog
                open={openConfirmGroupSelector}
            >
                <DialogTitle >{"Is this the group you meant to select: "}</DialogTitle>

                <TextField
                    required
                    id="groupName"
                    label="Group's Name"
                    name="groupName"
                    variant="outlined"
                    value={friendGroupSelectedName}
                    placeholder="Enter Your Group's Name"
                />
                <br/>

                <DialogActions>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Button onClick={async e => await setOpenConfirmGroupSelector(false)} align="center" color="secondary" variant="outlined" autoFocus>
                                X
                            </Button>
                        </Grid>
                        
                        <Grid item xs={4}></Grid>
                       
                        <Grid item xs={4}>
                            <Button onClick={handleAgreeGroupBet} align="center" color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
        {/* Dialog for confirming Group selector choice end ===================== */}

        {/* Dialog for no GROUPS yet start ++++++++++++++++++++++++++++++ */}
        <div>
            <Dialog
                open={openNoGroupsYet}
                onClose={async e => await setOpenNoGroupsYet(false)}
            >
                <DialogTitle>{"There are no groups associated with this account yet. If you wish to create a new group for your friends, please to go to the 'Groups' section of the main menu, and then select 'Create Group'. "}</DialogTitle>

                <DialogActions>
                <Button onClick={async e => await setOpenNoGroupsYet(false)} color="primary" autoFocus>
                    X
                </Button>
                </DialogActions>
            </Dialog>
        </div>
        {/* Dialog for no Groups yet end =========================== */}

              </Card>
          </div>
      )
  }