import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        minWidth: 275,
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    }
  });


  export default function WithrdrawlChoice(props){
        const classes = useStyles();
        const league = props.league;
        const title = props.title;

        const handleWithdrawToBank = (event) => {
            event.preventDefault();
            window.location.pathname = '/withdrawbank';
        }

        const handleWithrdrawToPaypal = (event) => {
            event.preventDefault();
            window.location.pathname = '/withdrawtopaypal';
        }

        // const handleWithdrawWithBankCredentials = (event) => {
        //     event.preventDefault();
        //     window.location.pathname = "/withdrawusingbankcredentials";
        // }



      return(
          <div>
              <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center' }} gutterBottom>
                                {title} Withdrawl Options
                        </Typography>
                        <Typography className={classes.title} style={{ textAlign: 'center'}}>
                                iBetU
                        </Typography>
                    </CardContent>
                        
                    {/* <CardActions style={{ justifyContent: 'center'}}>
                        <br/>
                        <div>
                                <Button size="small" variant="contained" className={classes.buttonblue} type='submit' onClick={handleWithdrawToBank}>Withdraw To Your Bank</Button>  
                        </div>
                    </CardActions> */}
                    <CardActions style={{ justifyContent: 'center'}}>
                        <br/>
                        <div>
                                <Button size="small" variant="contained" className={classes.buttonblue} type='submit' onClick={handleWithrdrawToPaypal}>Withdraw To Paypal</Button>
                        </div>
                    </CardActions>
                    {/* <CardActions style={{ justifyContent: 'center'}}>
                        <br/>
                        <div>
                                <Button size="small" variant="contained" className={classes.buttonblue} type='submit' onClick={handleWithdrawWithBankCredentials}>Withdraw Using Bank Login</Button>
                        </div>
                    </CardActions> */}
                    
              </Card>
          </div>
      )
  }