import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles({

  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    paddingTop: 5,
    paddingBottom: 30
    
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 40,
    fontFamily: 'Anton',
    color: '#0510e6',
    fontStyle: 'italic',
  },
  welcome: {
    fontSize: 22,
    fontStyle: 'bold',
    fontFamily: 'Anton'
  },
  pos: {
    marginBottom: 12,
  },
  buttonblue: {
      background: '#0510e6',
      color: 'white'
  },
  buttonclear: {
      color: "#0510e6",
      borderColor: "#0510e6"
  },
  question: {
      fontSize:  16,
  },
  answer: {
      fontSize: 16,
  },
});

export default function SimpleCard() {
  const classes = useStyles();
  
  return (
      < Container maxWidth="sm">
        <br/>
        <br/>
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                Frequently Asked Questions
                </Typography>
                <Typography className={classes.title} style={{ textAlign: 'center'}}>
                iBetU
                </Typography>
                <br />
                <Typography components='div'>

                {/* <Box fontWeight="fontWeightBold" m={1}>
                    This site is currently under construction.
                </Box> */}


                  <Box fontWeight="fontWeightBold" m={1}>
                    Why is iBetU better than going to an online sports book?
                  </Box>
                  <Box fontWeight="fontWeightRegular" m={1}>
                    Sports books make money by betting AGAINST you!  They set the odds or the spread to a level where they expect to win the majority of the time.  They dedicate entire teams of data scientists to make sure the spreads and odds they give you are NOT in your favor!  This means YOU LOSE THE MAJORITY OF THE TIME!  iBetU allows you to make your own bet with whoever you want.  You set the spread!  You set the odds!  

                    Also, with iBetU you can bet with your friends directly!  Tired of betting with your friends and then not having them pay?  Our platform automatically settles all your bets with your friends. 
                  </Box>

                  <Box fontWeight="fontWeightBold" m={1}>
                    Do I need to deposit money before I can play?
                  </Box>
                  <Box fontWeight="fontWeightRegular" m={1}>
                    Yes.  This assures that all bets are paid.  Your counterpart cannot bet without money in his or her account.
                  </Box>

                  <Box fontWeight="fontWeightBold" m={1}>
                    Can I bet against anyone?
                  </Box>
                  <Box fontWeight="fontWeightRegular" m={1}>
                    This depends on where you live.  Most players are able to bet anyone.  For some players in some states or territories, they may be limited to only being able to bet against other individuals in their state or territory.
                  </Box>

                  <Box fontWeight="fontWeightBold" m={1}>
                    Is this really a free service?
                  </Box>
                  <Box fontWeight="fontWeightRegular" m={1}>
                    Yes.  There may be minor fees for withdrawls or deposits, depending on the type selected, but these fees are charged to us by the bank or processor.  We do not profit from those fees.  
                  </Box>

                  <Box fontWeight="fontWeightBold" m={1}>
                    So is this a sports book?
                  </Box>
                  <Box fontWeight="fontWeightRegular" m={1}>
                    Well, yes and no.  We are not your bookie.  You are your own bookie. You can post any spread or odds you want, or bet your friend directly however you want.  You set the bet.  We just make sure that the counterparty has funds and that the bet settles automatically. 
                  </Box>

                  <Box fontWeight="fontWeightBold" m={1}>
                    Should I constantly withdraw money after every bet?
                  </Box>
                  <Box fontWeight="fontWeightRegular" m={1}>
                    Everyone is free to deposit and withdraw money whenever they want.  However, if you plan to make more bets, we would recommend leaving the money you set for betting on iBetU in your iBetU account.  This has two main benefits.  First, you minimize any fees your bank or processor has on deposits or withdrawls.
                    
                    Second, sometimes deposits cannot be made immediately, or they cannot be verified immediately, so if you don't have money in your account, you may not be able to make a bet you are excited to make, as the system won't allow for you to risk more money than what you have in your account.
                  </Box>

                  <Box fontWeight="fontWeightBold" m={1}>
                    Do bets settle immediately?
                  </Box>
                  <Box fontWeight="fontWeightRegular" m={1}>
                    In most cases, bets are automatically settled by the next morning.  In some cases when our data partners are delayed in updating game information on their end, our bets may not be settled until two days after the game.   
                  </Box>

                  <Box fontWeight="fontWeightBold" m={1}>
                    By when do I have to propose a bet?
                  </Box>
                  <Box fontWeight="fontWeightRegular" m={1}>
                    Players must propose a bet before the game in question begins.  Similarly, the bet must be accepted before the game begins.     
                  </Box>

                  <Box fontWeight="fontWeightBold" m={1}>
                    Why can't I bet after the game started?
                  </Box>
                  <Box fontWeight="fontWeightRegular" m={1}>
                    Since this is a platform for you to bet your peers directly, and not against a casino's sports book, we do not allow accepting a bet after a game started to protect our players.  If we allowed in-game betting, players would have to dynamically change their odds and monitor all of their bets in real time.     
                  </Box>

                  <Box fontWeight="fontWeightBold" m={1}>
                    Is the current selection of sports all you plan to offer?
                  </Box>
                  <Box fontWeight="fontWeightRegular" m={1}>
                    Over time, we plan to expand the sports that you can bet on on iBetU.  We have other additions coming soon.
                  </Box>

                  <Box fontWeight="fontWeightBold" m={1}>
                    Can I bet in my local currency?
                  </Box>
                  <Box fontWeight="fontWeightRegular" m={1}>
                    Today, iBetU is a centralized platform that allows you to bet any counterparty anywhere in the world where on-line sports betting is legal.  Given it's global reach, all bets are done in US Dollars, and all deposits with iBetU are held in US Dollars.  We get the lowest exchange rates and fees we can find for you automatically.  Over time, we plan to offer betting environments limited to your local currency environment to eliminate these fees.  However, it's not a bad idea to have an account with US Dollars!  
                  </Box>

                </Typography>

            </CardContent>
            
        </Card>
    </Container>
  );
}
