import React from 'react';

import Container from '@material-ui/core/Container';
import MainAppBar from '../../components/MainAppBar';
import BetsByTeam from '../../components/gameComponents/PostedBetsByTeam';

import Footer from '../../components/Footer';
import Copyright from '../../components/Copyright';
import checkAuthenticated from '../../utils/checkAuthenticated';
import {abbreviations} from '../../utils/abbreviations';

export default function LandingPage() {
  checkAuthenticated();
  let league = 'ligamx';
  let title = 'LigaMX';

  let names = []  
  for ( const item of abbreviations) {
    if (item.league === league) {
      names.push( item.name );
    }
  }
  // let names = [
  //   'América',
  //   'Atlas',
  //   'Atlético',
  //   'Cruz Azul',
  //   'Guadalajara',
  //   'FC Juárez',
  //   'León',
  //   'Monterrey',
  //   'Morelia',
  //   'Necaxa',	
  //   'Pachuca',
  //   'Puebla',
  //   'Querétaro',
  //   'Santos',
  //   'Tijuana',
  //   'Toluca',
  //   'UANL',
  //   'UNAM',
  //   'Veracruz',
  // ] 



  return (
    < Container maxWidth="sm">
        <MainAppBar></MainAppBar>
        <BetsByTeam
          league={league}
          title={title}
          abbreviations={abbreviations}
          names={names}
        ></BetsByTeam>
        <br/>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
