import React from 'react';

import Container from '@material-ui/core/Container';
import BasicAppBar from '../../components/BasicAppBar';
import TermsOfUse from '../../components/TermsOfUse';

import Footer from '../../components/FooterNotLogged';
import Copyright from '../../components/Copyright';
import checkAuthenticated from '../../utils/checkAuthenticated';


export default function LandingPage() {

  return (
    < Container maxWidth="sm">
        <BasicAppBar></BasicAppBar>
        <TermsOfUse></TermsOfUse>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
