import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
// import useScript from '../../utils/useScript';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

const paypal = window.paypal;



const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    note: {
        fontSize: 11,
        // fontFamily: 'Anton'
      },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        minWidth: 275,
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    iavContainer: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    table: {
        minWidth: 220,
        maxWidth: 220,
    },
    tableHeader: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        color: 'grey',
    },
    tableHeaderNarrow: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        fixedHeader: false,
        width: 'auto',
        tableLayout: 'auto',
    },
    tableCell: {
        padding: 2,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        color: 'grey'
    },
    tableCellNarrow: {
        padding: 1,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        fixedHeader: false,
        width: 'auto',
        tableLayout: 'auto',
    },
    progress: {
        display: 'flex',
        '& > * + *': {
          marginLeft: 2,
        },
      },
  });



export default function PayPalWithdraw() {
    let currentUsername = sessionStorage.getItem('currentUser');
    const classes = useStyles();
    const [ fundsAvailable, setFundsAvailable ] = React.useState('')
    const [ withdrawAmount, setWithdrawAmount ] = React.useState('');
    const [ receiverEmail, setReceiverEmail ] = React.useState('');
    const [ maxWithdraw, setMaxWithdraw ] = React.useState('');
    const [ adjWithdrawl, setAdjWithdrawl ] = React.useState('');
    const [ feeEstimate, setFeeEstimate ] = React.useState('');
    const [ adjWithrawlPlusFees, setAdjWithdrawlPlusFees ] = React.useState('');
    const [ withdrawMessage, setWithdrawMessage ] = React.useState('');

    // Get withold amount fo rcurrent user
    useEffect(() => {
        (async () => {
            axios.get('/api/user/profile/' + currentUsername)
            .then( async function (res){
                console.log("Withold Amount: " + res.data.witholdAmount);
                if ( res.data.witholdAmount == undefined || res.data.witholdAmount == "") {
                    setFundsAvailable(sessionStorage.getItem('fundsAvailable'))
                } else {
                    setFundsAvailable( parseFloat(sessionStorage.getItem('fundsAvailable')) - res.data.witholdAmount);
                }
            })
            .catch ( err => { console.log(err)})
        })();
    },[])

    const [ openProgressCircle, setOpenProgressCircle ] = React.useState('');
    const [ agreeProgressCircle, setAgreeProgressCircle ] = React.useState('');
    const handleAgreeProgressCircle = () => {
        setOpenProgressCircle(false);
    }

    const [ openSuccessfulTransfer, setOpenSuccessfulTransfer ] = React.useState('');
    const [ agreeSuccessfulTransfer, setAgreeSuccessfulTransfer ] = React.useState('');
    const handleAgreeSuccessfulTransfer = () =>{
        setAgreeSuccessfulTransfer(true);
        setOpenSuccessfulTransfer(false);
        window.location.pathname = '/home'
    }

    const [ openErrorTransfer, setOpenErrorTransfer ] = React.useState('');
    const [ agreeErrorTransfer, setAgreeErrorTransfer ] = React.useState('');
    const handleAgreeErrorTransfer = () => {
        setAgreeErrorTransfer(true);
        setOpenErrorTransfer(false);
    }

    // For dialog saying that amount is larger than what you can currently withdraw
    const [ openRequestTooLarge, setOpenRequestTooLarge ] = React.useState('');
    const [ agreeRequestTooLarge, setAgreeRequestTooLarge ] = React.useState('');
    const handleAgreeRequestToolarge = () => {
        setAgreeRequestTooLarge(true);
        setWithdrawAmount('');
        setFeeEstimate('');
        setAdjWithdrawl('');
        setAdjWithdrawlPlusFees('');
        setOpenRequestTooLarge(false);
    }


    const [ openSetAmount, setOpenSetAmount ] = React.useState(false);
    const [ agreeSetAmount, setAgreeSetAmount ] = React.useState(false);
    const handleCancelSetAmount = () => {
        setAgreeSetAmount(false);
        setWithdrawAmount('');
        setFeeEstimate('');
        setAdjWithdrawl('');
        setAdjWithdrawlPlusFees('');
        setOpenSetAmount(false);
    }

    const handleSetAmount = () => {
        let feeExpected;
        let maxAllowed;
        let adjDisplayAmount1;
        let adjDisplayAmount2;
        if ( sessionStorage.getItem('currentCountry') === 'US' ) {
            feeExpected = 0.25;
            maxAllowed = (parseFloat(fundsAvailable) - parseFloat(feeExpected)).toFixed(2);
            setMaxWithdraw(maxAllowed);
            setFeeEstimate(feeExpected);
            adjDisplayAmount1 = (parseFloat(withdrawAmount) - parseFloat(feeExpected)).toFixed(2).toString();
            adjDisplayAmount2 = (parseFloat(withdrawAmount) + parseFloat(feeExpected)).toFixed(2).toString();
        } else {
            feeExpected = 0.25 //  Math.min( 0.02*withdrawAmount, 20 );
            if ( feeExpected === 20 ) { 
                maxAllowed = (parseFloat(fundsAvailable) - 20).toFixed(2);
                setMaxWithdraw(maxAllowed);
                setFeeEstimate(feeExpected);
                adjDisplayAmount1 = (parseFloat(withdrawAmount) - parseFloat(feeExpected)).toFixed(2).toString();
                adjDisplayAmount2 = (parseFloat(withdrawAmount) + parseFloat(feeExpected)).toFixed(2).toString();
            } else {
                maxAllowed = (parseFloat(fundsAvailable) - (0.02 * parseFloat(fundsAvailable))).toFixed(2);
                setMaxWithdraw(maxAllowed);
                setFeeEstimate(feeExpected);
                adjDisplayAmount1 = (parseFloat(withdrawAmount) - parseFloat(feeExpected)).toFixed(2).toString();
                adjDisplayAmount2 = (parseFloat(withdrawAmount) + parseFloat(feeExpected)).toFixed(2).toString();
            }
        }

        if ( parseFloat(withdrawAmount) > parseFloat(fundsAvailable) ) {
            setOpenRequestTooLarge(true);
        } else if ( parseFloat(withdrawAmount) >= parseFloat(maxAllowed) ) {
            console.log('code')
            setAdjWithdrawl(((parseFloat(maxWithdraw) - parseFloat(feeEstimate)).toFixed(2)).toString());
            setAdjWithdrawlPlusFees( fundsAvailable );
            setWithdrawMessage(' you will only see $' + adjDisplayAmount1 + ' in your PayPal account.')
            setOpenSetAmount(true);
            
        } else if ( parseFloat(withdrawAmount) < parseFloat(maxAllowed) ) {
            setAdjWithdrawl( withdrawAmount);
            setAdjWithdrawlPlusFees( ((parseFloat(withdrawAmount) + parseFloat(feeEstimate)).toFixed(2)).toString()  );
            setWithdrawMessage(' you will see your iBetU Funds Available decrease by $' + adjDisplayAmount2 + '.')
            setOpenSetAmount(true);

        }
    }

    const handleAgreeSubmitWithdraw = () => {
        let payoutDetails = {
            receiver: receiverEmail,
            paypalPayout: adjWithdrawl,
            databasePayout: parseFloat(adjWithrawlPlusFees),
            feeEstimate: feeEstimate,
            userID: sessionStorage.getItem('userID'),
            username: sessionStorage.getItem('currentUser'),
        }

        setOpenSetAmount(false);
        setOpenProgressCircle(true);
        axios.post('/api/user/paypalpayout', payoutDetails, {})
        .then( data => {
            console.log(data);
            setWithdrawAmount('');
            setOpenProgressCircle(false);
            setOpenSuccessfulTransfer(true);
            
        })
        .catch( err => {
            console.log(err)
            setOpenProgressCircle(false);
            setOpenErrorTransfer(true);
        });
    }

    return(
        <div>
            <Container maxWidth="sm">
            <br/>
            
            <Card className={classes.root}>
                <CardContent>
                    <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center' }} gutterBottom>
                            PayPal Withdrawl
                    </Typography>
                    <Typography className={classes.title} style={{ textAlign: 'center'}}>
                            iBetU
                    </Typography>
                    <form noValidate autoComplete="off">
                        <Grid container direction='column' justify="center" alignItems='center'>
                            <div>
                                <TextField
                                    required
                                    id="receiverEmail"
                                    label="Email Linked to PayPal"
                                    name="receiverEmail"
                                    variant="outlined"
                                    value={receiverEmail}
                                    placeholder="Enter Your Email Address"
                                    onChange={ e => setReceiverEmail(e.target.value) }
                                    className={classes.inputBox}
                                />
                            </div>
                            <br/>
                            <div>
                                <TextField
                                    required
                                    id="withdrawAmount"
                                    label="Amount to Withdraw (USD)"
                                    name="withdrawAmount"
                                    variant="outlined"
                                    value={withdrawAmount}
                                    placeholder="Enter Amount to Withdraw"
                                    className={classes.inputBox}
                                    onChange={ e => setWithdrawAmount((e.target.value).toString()) }
                                    InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            $
                                          </InputAdornment>
                                        ),
                                      }}
                                />
                            </div>
                        </Grid>
                    </form>
                </CardContent>
                
                <CardActions style={{ justifyContent: 'center'}}>
                    <br/>
                    <div>
                        <Button size="small" variant="contained" className={classes.buttonblue} type='submit' onClick={handleSetAmount}>Withdraw</Button>  
                    </div>
                </CardActions>
                <CardContent>
                    <Typography className={classes.note}>
                        * Please note that PayPal's processing fees are higher than those of an ACH transfer, however, you will have the funds available to you immediately, in most cases.  Your account will be debited with the value of your withdrawl amount plus any fees charged by PayPal for processing.  In some countries, currently only PayPal is available.  Funds available for withdrawl may differ from funds available for betting depending on the status of certain promotions.
                    </Typography>
                    <br/>
                </CardContent>
            </Card>
            <div>
                <Dialog
                open={openRequestTooLarge}
                agree={agreeRequestTooLarge}
                onClose={handleAgreeRequestToolarge}
                >
                    <DialogTitle align="center">
                        Sorry, the amount you are requesting is more than your funds available.  If you are using a promotion, the funds granted to you in that promotion may not be available for withdrawl yet, and may require that a new player accept one of your bets. 
                    </DialogTitle>
                    <DialogActions>
                        <Grid container spacing={1}>
                            <Grid item xs={8}></Grid>
                            <Grid item xs={4}>
                                <Button onClick={handleAgreeRequestToolarge} color="primary" variant="outlined" className={classes.buttonBetBlue}
                                align="center" autofocus>
                                    OK
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog
                open={openSetAmount}
                agree={agreeSetAmount}
                onClose={handleCancelSetAmount}
                >
                    <DialogTitle>
                        You are requesting to withdraw ${withdrawAmount} from iBetU and send it to the PayPal account linked to {receiverEmail}.  Given the fees charged by PayPal for processing, {withdrawMessage} Please only submit this once. It may take up to 10 seconds before you see a success or failure message.
                    </DialogTitle>

                    <DialogActions>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Button onClick={handleCancelSetAmount} align="center" color="secondary" variant="outlined" autoFocus>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={4}>
                                <Button onClick={handleAgreeSubmitWithdraw} color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                    Confirm
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog
                open={openSuccessfulTransfer}
                agree={agreeSuccessfulTransfer}
                onClose={handleAgreeSuccessfulTransfer}
                >
                    <DialogTitle>
                        Your request has been successfully submited.  In most instances, PayPal is near immediate so unless there is a problem with your account (ie. an unconfirmed email address with PayPal), you should receive your money very soon, if you have not received it already.
                    </DialogTitle>

                    <DialogActions>
                        <Grid container spacing={1}>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={4}>
                                <Button onClick={handleAgreeSuccessfulTransfer} color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                    OK
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog
                open={openErrorTransfer}
                agree={agreeErrorTransfer}
                onClose={handleAgreeErrorTransfer}
                >
                    <DialogTitle>
                        There was an error with  your request, please try again.  If the issue persists, please try again later, as it is likely a temporary issue with our processor.
                    </DialogTitle>

                    <DialogActions>
                        <Grid container spacing={1}>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={4}>
                                <Button onClick={handleAgreeErrorTransfer} color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                    OK
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog
                    open={openProgressCircle}
                    agree={agreeProgressCircle}
                    onClose={handleAgreeProgressCircle}
                >
                    <div className={classes.progress}>
                        {/* <CircularProgress /> */}
                        <CircularProgress />
                    </div>
                </Dialog>
            </div>


            </Container>
        </div>

        
    )

}


