import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PolicyIcon from '@material-ui/icons/PolicyTwoTone';
import AssignmentIcon from '@material-ui/icons/AssignmentTwoTone';
import GavelIcon from '@material-ui/icons/GavelTwoTone';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function SimpleBottomNavigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleTermsOfUse = (event) => {
    event.preventDefault(event);
    window.location.pathname = "/termsofuse2";
  }

  const handlePrivacyPolicy = (event) => {
    event.preventDefault(event);
    window.location.pathname = "/privacypolicy2";
  }

  const handleResponsibleGaming = (event) => {
    event.preventDefault(event);
    window.location.pathname = "/responsiblegaming2";
  }

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction label="Terms of Use" icon={<PolicyIcon/>} color='primary' onClick={handleTermsOfUse}/>
      <BottomNavigationAction label="Privacy Policy" icon={<AssignmentIcon />} onClick={handlePrivacyPolicy} />
      <BottomNavigationAction label="Responsible Gaming" icon={<GavelIcon />} onClick={handleResponsibleGaming}/>
    </BottomNavigation>
    
    
    
  );
}
