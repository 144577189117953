import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
//  import MuiPhoneNumber from 'material-ui-phone-number';


import axios from 'axios';

let currentUser = sessionStorage.getItem("currentUser");

const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    formControl: {
        margin: 1,
        minWidth: 220,
        maxWidth: 220,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
      },
  });

let currentUserID = sessionStorage.getItem('userID');

export default function SignUpForm() { 
    const classes = useStyles();
    const [ selectedUsername, setSelectedUsername ] = React.useState(currentUser);
    const [ selectedEmail, setSelectedEmail ] = React.useState('');
    const [ phone, setPhone ] = React.useState('');
    const [ question, setQuestion ] = React.useState('');
    const [ secret, setSecret ] = React.useState('');
    let oldusername;
    let oldemail;
    let oldphone;
    
    axios.get('/api/user/profile/' + currentUser)
    .then(function(res){
        if (res.data.user === null) {
            window.location.pathname = "/login";
        } else {
            oldusername = res.data.username;
            oldemail = res.data.email;
            oldphone = res.data.phone;
        }
    })
    .catch(error => {
        console.log(error);
    })

    // const handlePhoneChange = (value) => {
    //     setPhone({phone: value })
    // }

    // For Dialog Pop Up on Passwords not matching
    const [open, setOpen] = React.useState(false);
    const [agree, setAgree] = React.useState(false);

    const handleAgree = () => {
        setAgree(true);
        setOpen(false);
        let phoneNumOnly = phone.replace(/\D/g, '');

        const userDetails = {
            userID: currentUserID,
            username: selectedUsername,
            email: selectedEmail,
            phone: phoneNumOnly,
            question: question,
            secret: secret,
            oldusername: oldusername,
            oldemail: oldemail,
            oldphone: oldphone
        }
        console.log(userDetails.username);
        if ( CheckEmail(userDetails.email) !== true ) { 
            setOpenValidEmail(true);
        } else {     
            axios.post('/api/user/updateprofile/' + currentUser, userDetails, {
                }).then(response => {
                    console.log(response)
                    if (response.data.error === 'User already exists') {
                        setOpenUserExists(true);
                    } else {
                        setOpenSuccess(true);
                        sessionStorage.setItem("currentUser", selectedUsername);
                    }

                                    
                }).catch(error => {
                    console.log('signup error: ')
                    console.log(error)

                })
        }
      };

    const handleCancel = () => {
        setOpen(false);
      };

    // For Dialog Pop Up on Valid Email
    const [openValidEmail, setOpenValidEmail] = React.useState(false);
    const [agreeValidEmail, setAgreeValidEmail] = React.useState(false);

    const handleAgreeValidEmail = () => {
        setAgreeValidEmail(true);
        setOpenValidEmail(false);
      };

    // For Dialog Pop Up on User Already Exists
    const [openUserExists, setOpenUserExists] = React.useState(false);
    const [agreeUserExists, setAgreeUserExists] = React.useState(false);

    const handleAgreeUserExists = () => {
        setAgreeUserExists(true);
        setOpenUserExists(false);
      };

    // For Dialog PopUp on Successful Update
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [agreeSuccess, setAgreeSuccess] = React.useState(false);

    const handleAgreeSuccess = () => {
        setAgreeSuccess(true);
        setOpenSuccess(false);
        window.location.pathname = "/home";
      };


    const CheckEmail = (input) => {
        let atSignCheck = false;
        let periodCheck = false;
        let regex1 = /[@]/;
        let regex2 = /[.]/;

        if ( regex1.test(input) ) { atSignCheck = true }
        if ( regex2.test(input) ) { periodCheck = true }

        if ( atSignCheck && periodCheck ) { return true }
    }

    const handleCancelUpdate = (event) => {
        event.preventDefault();
        window.location.pathname = "/home"
    }
    
    const handleSubmit = (event) => {
        event.preventDefault();
        setOpen(true);
    }
    

    return(
        <div>
            < Container maxWidth="sm">
            <br/>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                            Enter New Details
                        </Typography>
                        <Typography className={classes.title} style={{ textAlign: 'center'}}>
                            iBetU
                        </Typography>
                        <br />
                        <form noValidate autoComplete="off">
                            <Grid container direction='column' justify="center" alignItems='center'>
                                <div>
                                    <TextField
                                        required
                                        id="username"
                                        label="Username"
                                        name="username"
                                        variant="outlined"
                                        value={selectedUsername}
                                        placeholder="Username"
                                        onChange={ e => setSelectedUsername(e.target.value) }
                                        
                                    />
                                </div>
                                
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="email"
                                        label="Email"
                                        name="email"
                                        variant="outlined"
                                        value={selectedEmail}
                                        placeholder="Email"
                                        onChange={ e => setSelectedEmail(e.target.value) }
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="phone"
                                        label="Phone w/ Country Code"
                                        name="phone"
                                        variant="outlined"
                                        value={phone}
                                        placeholder="Phone - just digits"
                                        onChange={ e => setPhone(e.target.value) }
                                    />
                                </div>
                                {/* <br />
                                <div>
                                    <MuiPhoneNumber
                                        defaultCountry={'us'}
                                        className={classes.formControl}
                                        id="phone"
                                        label="Phone Number"
                                        name="phone"
                                        variant="outlined"
                                        placeholder="Phone Number"
                                        onChange={handlePhoneChange}
                                    />
                                </div> */}
                                <br />
                                <div>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">Security Question</InputLabel>
                                        <Select
                                            required
                                            labelId="demo-simple-select-label"
                                            label="Security Question"
                                            id="security-question-select"
                                            value={question}
                                            onChange={ e => setQuestion(e.target.value)}
                                        >
                                            <MenuItem value={"What is your favorite movie?"}>What is your favorite movie?</MenuItem>
                                            <MenuItem value={"What was the name of your fist pet?"}>What was the name of your fist pet?</MenuItem>
                                            <MenuItem value={"In what city did your parents meet?"}>In what city did your parents meet?</MenuItem>
                                            <MenuItem value={"Fraternal grandfather's first name?"}>Fraternal grandfather's first name?</MenuItem>
                                            <MenuItem value={"Maternal grandmother's first name?"}>Maternal grandmother's first name?</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="secret"
                                        label="Answer to Question"
                                        name="secret"
                                        variant="outlined"
                                        value={secret}
                                        placeholder="Answer to Question"
                                        onChange={ e => setSecret(e.target.value) }
                                    />
                                </div>
                
                                <br />
                            
                                <div>
                                    <Dialog
                                        open={open}
                                        agree={agree}
                                        onClose={handleCancel}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Are you sure you want to update your profile?"}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleCancel} color="primary">
                                            Cancel
                                        </Button>
                                        <Button onClick={handleAgree} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openUserExists}
                                        agree={agreeUserExists}
                                        onClose={handleAgreeUserExists}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Username already exists. Pick a new user name."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreeUserExists} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openValidEmail}
                                        agree={agreeValidEmail}
                                        onClose={handleAgreeValidEmail}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Please enter a valid email address."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreeValidEmail} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openSuccess}
                                        agree={agreeSuccess}
                                        onClose={handleAgreeSuccess}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"You have successfully updated your profile."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreeSuccess} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <CardActions style={{ justifyContent: 'center'}}>
                                    <Button size="small" variant="contained" className={classes.buttonblue}
                                    type='submit'
                                    onClick={handleCancelUpdate}
                                    >Cancel</Button>
                                    <Button size="small" variant="contained" className={classes.buttonblue}
                                    type='submit'
                                    onClick={handleSubmit}
                                    >Submit</Button>
                                </CardActions>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
        
}

