import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
//  import MuiPhoneNumber from 'material-ui-phone-number';


import axios from 'axios';


const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    formControl: {
        margin: 1,
        minWidth: 220,
        maxWidth: 220,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
      },
  });



export default function SignUpForm() { 
    
    const classes = useStyles();
    const [ selectedUsername, setSelectedUsername ] = React.useState('');
    const [ selectedEmail, setSelectedEmail ] = React.useState('');
    const [ phone, setPhone ] = React.useState('');
    const [ message, setMessage ] = React.useState('');

    let currentUser = sessionStorage.getItem("currentUser");
    axios.get('/api/user/profile/' + currentUser)
    .then(function(res) {
        if (res.data.user === null) {
            window.location.pathname = "/login";
        } else {
            let email = res.data.email;
            let phoneNumber = res.data.phone;
            setSelectedUsername(currentUser);
            setSelectedEmail(email);
            setPhone(phoneNumber);
        }
    })
    .catch(error => {
      console.log(error);
    })

    // For Dialog Pop Up on successful message
    const [open, setOpen] = React.useState(false);
    const [agree, setAgree] = React.useState(false);

    const handleAgree = () => {
        setAgree(true);
        setOpen(false);
        window.location.pathname="/home";
        };

    // For Dialog Pop Up on error sending message
    const [errorSend, setErrorSend] = React.useState(false);
    const [agreeError, setAgreeError] = React.useState(false);

    const handleAgreeError = () => {
        setAgreeError(true);
        setErrorSend(false);
        };
    
    const handleSubmit = (event) => {
        event.preventDefault();
        let phoneNumOnly = phone.replace(/\D/g, '');
        const userDetails = {
            username: selectedUsername,
            email: selectedEmail,
            phone: phoneNumOnly,
            message: message,
        }

        axios.post('/api/user/contact-us', userDetails, {
        }).then(response => {
            console.log(response)
            if (response.data.error === 'Error sending message') {
                setErrorSend(true);
            } else {
                setOpen(true);
            }
               
        }).catch(error => {
            console.log('signup error: ')
            console.log(error)

        })
        
    }
    


    return(
        <div>
            < Container maxWidth="sm">
            <br/>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                            Contact Us
                        </Typography>
                        <Typography className={classes.title} style={{ textAlign: 'center'}}>
                            iBetU
                        </Typography>
                        <br />
                        <form noValidate autoComplete="off">
                            <Grid container direction='column' justify="center" alignItems='center'>
                                <div>
                                    <TextField
                                        required
                                        id="username"
                                        label="Your Username"
                                        name="username"
                                        variant="outlined"
                                        value={selectedUsername}
                                        placeholder="Username"
                                        onChange={ e => setSelectedUsername(e.target.value) }
                                        
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="email"
                                        label="Your Email"
                                        name="email"
                                        variant="outlined"
                                        value={selectedEmail}
                                        placeholder="Email"
                                        onChange={ e => setSelectedEmail(e.target.value) }
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="phone"
                                        label="Your Phone w/ Country Code"
                                        name="phone"
                                        variant="outlined"
                                        value={phone}
                                        placeholder="Phone w/ Country Code"
                                        onChange={ e => setPhone(e.target.value) }
                                    />
                                </div>
                                <br />

                                <div>
                                    <TextField
                                        required
                                        id="secret"
                                        label="Type Message Here"
                                        multiline
                                        name="message"
                                        variant="outlined"
                                        rowsMax={80}
                                        value={message}
                                        placeholder="Enter Message"
                                        onChange={ e => setMessage(e.target.value) }
                                    />
                                </div>
                                <br />




                                <div>
                                    <Dialog
                                        open={open}
                                        agree={agree}
                                        onClose={handleAgree}
                                    >
                                        <DialogTitle>{"Message sent successfully."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgree} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={errorSend}
                                        agree={agreeError}
                                        onClose={handleAgreeError}
                                        
                                    >
                                        <DialogTitle>{"There was an error sending your message. Please try emailing us directly at help@ibet.co"}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreeError} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <CardActions style={{ justifyContent: 'center'}}>
                                    <Button size="small" variant="contained" className={classes.buttonblue}
                                    onClick={handleSubmit}
                                    >Submit</Button>
                                </CardActions>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
        
}


