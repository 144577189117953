import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
//  import MuiPhoneNumber from 'material-ui-phone-number';


import axios from 'axios';


const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    formControl: {
        margin: 1,
        minWidth: 220,
        maxWidth: 220,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
      },
  });


export default function AddBankForm() {

    const classes = useStyles();
    const [ routingNumber, setRoutingNumber ] = React.useState('');
    const [ accountNumber, setAccountNumber ] = React.useState('');
    const [ accountType, setAccountType ] = React.useState('');
    const [ accountName, setAccountName ] = React.useState('');

    // For dialogue to verify information
    const [ openVerifyInfo, setOpenVerifyInfo ] = React.useState(false);
    const [ agreeVerifyInfo, setAgreeVerifyInfo ] = React.useState(false);
    const handleAgreeVerifyInfo = () => {
        setAgreeVerifyInfo(false);
        setOpenVerifyInfo(true);

    }

    const handleAgreeWrongDetails = () => {
        setAgreeVerifyInfo(false);
        setOpenVerifyInfo(false);
    }

    //For dialog to tell successfully added
    const [ openAccountAdded, setOpenAccountAdded ] = React.useState(false);
    const [ agreeAccountAdded, setAgreeAccountAdded ] = React.useState(false);
    const handleAgreeAccountAdded = () => {
        setAgreeAccountAdded(true);
        setAccountNumber('');
        setRoutingNumber('');
        setAccountName('');
        setAccountType('');
        setOpenAccountAdded(false);
    }

    //For dialog saying error adding
    const [ openErrorAddingBank, setOpenErrorAddingBank ] = React.useState(false);
    const [ agreeErrorAddingBank, setAgreeErrorAddingBank ] = React.useState(false);
    const handleAgreeErrorAddingBank = () => {
        setAgreeErrorAddingBank(true);
        setOpenErrorAddingBank(false);
    }

    
    const handleSubmit = () => {   
        setAgreeVerifyInfo(true);
        let accountNumString = accountNumber.toString();
        let lastFour = accountNumString.slice(-4);
        let accountNameFormal = accountName + '-' + accountType + "-" + lastFour;
        let accountDetails = {
            routingNumber: routingNumber,
            accountNumber: accountNumber,
            bankAccountType: accountType,
            name: accountNameFormal,
            currentUserID: sessionStorage.getItem('userID'),
        }
        
        axios.post('/api/user/addbank', accountDetails, { })
        .then( res => {
            console.log("Funding Source Added Successfully")
            setOpenVerifyInfo(false);
            setOpenAccountAdded(true);
        })
        .catch( error => {
            console.log("Error adding bank account")
            console.log(error);
            setOpenErrorAddingBank(true);
        })
    }


    return(
        <div>
            < Container maxWidth="sm">
            <br/>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                            Enter Bank Information
                        </Typography>
                        <Typography className={classes.title} style={{ textAlign: 'center'}}>
                            iBetU
                        </Typography>
                        <br />
                        <form noValidate autoComplete="off">
                            <Grid container direction='column' justify="center" alignItems='center'>
                                <div>
                                    <TextField
                                        required
                                        id="accountNumber"
                                        label="Account Number"
                                        name="accountNumber"
                                        variant="outlined"
                                        value={accountNumber}
                                        placeholder="Enter Account Number"
                                        onChange={ e => setAccountNumber(e.target.value) }
                                        
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="routingNumber"
                                        label="Routing Number"
                                        name="routingNumber"
                                        variant="outlined"
                                        value={routingNumber}
                                        placeholder="Enter Routing Number"
                                        onChange={ e => setRoutingNumber(e.target.value) }
                                        
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="accountName"
                                        label="Account Name"
                                        name="accountName"
                                        variant="outlined"
                                        value={accountName}
                                        placeholder="Give Account a Name"
                                        onChange={ e => setAccountName(e.target.value) }
                                        
                                    />
                                </div>
                                {/* <br />
                                <div>
                                    <TextField
                                        required
                                        id="email"
                                        label="Email"
                                        name="email"
                                        variant="outlined"
                                        value={selectedEmail}
                                        placeholder="Email"
                                        onChange={ e => setSelectedEmail(e.target.value) }
                                    />
                                </div> */}
                                <br />
                               

                                <div>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="accountType">Account Type</InputLabel>
                                        <Select
                                            required
                                            labelId="account-type"
                                            label="Type of Account"
                                            id="accountType"
                                            value={accountType}
                                            onChange={ e => setAccountType(e.target.value)}
                                        >
                                            <MenuItem value={"Checking"}>Checking</MenuItem>
                                            <MenuItem value={"Savings"}>Savings</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <br />
                                




                                <div>
                                    <Dialog
                                        open={openVerifyInfo}
                                        agree={agreeVerifyInfo}
                                        onClose={handleAgreeVerifyInfo}
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Please verify your account information:"}
                                        </DialogTitle>

                                        <DialogTitle align="left">
                                            Routing #: { routingNumber }
                                        </DialogTitle>

                                        <DialogTitle align="left">
                                            Account #: { accountNumber }
                                        </DialogTitle>
                                        
                                        <DialogTitle align="left">
                                            Type: { accountType }
                                        </DialogTitle>

                                        <DialogTitle align="left">
                                            Name: { accountName }
                                        </DialogTitle>

                                        <DialogActions>
                                            <Grid container spacing ={1}>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleAgreeWrongDetails} align="center" color="secondary" variant="outlined" autoFocus>
                                                    Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={4}></Grid>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleSubmit} color="primary" variant="outlined" className={classes.buttonBetBlue} 
                                                    type='submit'
                                                    align="center" autoFocus>
                                                    Submit
                                                    </Button>
                                                </Grid>
                                            
                                            </Grid>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                    open={openErrorAddingBank}
                                    agree={agreeErrorAddingBank}
                                    onClose={handleAgreeErrorAddingBank}
                                    >
                                        <DialogTitle align="center">
                                            We were unable to add your bank account. Please check your information is correct or try again later.
                                        </DialogTitle>
                                        <DialogActions>
                                            <Grid container spacing={1}>
                                                <Grid item xs={8}></Grid>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleAgreeErrorAddingBank} color="primary" variant="outlined" className={classes.buttonBetBlue}
                                                    align="center" autofocus>
                                                        OK
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                    open={openAccountAdded}
                                    agree={agreeAccountAdded}
                                    onClose={handleAgreeAccountAdded}
                                    >
                                        <DialogTitle align="center">
                                            Your account was linked successfully. Plese go to the the deposit page to add funds.
                                        </DialogTitle>
                                        <DialogActions>
                                            <Grid container spacing={1}>
                                                <Grid item xs={8}></Grid>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleAgreeAccountAdded} color="primary" variant="outlined" className={classes.buttonBetBlue}
                                                    align="center" autofocus>
                                                        OK
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <CardActions style={{ justifyContent: 'center'}}>
                                    <Button size="small" variant="contained" className={classes.buttonblue}
                                    onClick={handleAgreeVerifyInfo}
                                    >Add Account</Button>
                                </CardActions>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
}