import React, { Suspense, lazy } from 'react';

import Container from '@material-ui/core/Container';
import MainAppBar from '../components/MainAppBar';
import UpdatingServer from '../components/UpdatingServer';

import Footer from '../components/Footer';
import Copyright from '../components/Copyright';
// import checkAuthenticated from '../utils/checkAuthenticated';

// const FundsSummary = React.lazy(() => import('../components/FundsSummary'));


export default function LandingPage() {
  // checkAuthenticated();


  return (
    < Container maxWidth="sm">
        <MainAppBar></MainAppBar>
        <UpdatingServer></UpdatingServer>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
