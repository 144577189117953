import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// import Link from '@material-ui/core/Link';
// import { Redirect } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckRegion from '../utils/checkRegion';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import axios from 'axios';


const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    formControl: {
        margin: 1,
        minWidth: 220,
        maxWidth: 220,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
      },
  });

const AntSwitch = withStyles((theme) => ({
root: {
    width: 28,
    height: 16,
    padding: 2,
    display: 'flex',
},
switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
    transform: 'translateX(12px)',
    color: theme.palette.common.white,
    '& + $track': {
        opacity: 1,
        backgroundColor: '#0510e6',
        borderColor: theme.palette.primary.main,
    },
    },
},
thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
},
track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
},
checked: {},
}))(Switch);


export default function LoginForm() { 
    
    const classes = useStyles();
    const [ username, setUsername ] = React.useState('');
    const [ password, setPassword ] = React.useState('');
    const [ open, setOpen ] = React.useState(false);
    const [ agree, setAgree ] = React.useState(false);
    const [ location, setLocation ] = React.useState('');

    const handleAgree = () => {
        setAgree(true);
        setOpen(false);
      };

    const handleForgot = (event) => {
        event.preventDefault();
        window.location.pathname = "/forgotlogin";
    }

    const [ openMust18, setOpenMust18 ] = React.useState(false);
    const [ agreeMust18, setAgreeMust18 ] = React.useState(false);
    const handleAgreeMust18 = () => {
        setAgreeMust18(true);
        setOpenMust18(false);
    }

    const [ openLegalLocation, setOpenLegalLocation ] = React.useState(false);
    const [ agreeLegalLocation, setAgreeLegalLocation ] = React.useState(false);
    const handleAgreeLegalLocation = () => {
        setAgreeLegalLocation(true);
        setOpenLegalLocation(false);
    }

    const [ openUsLocation, setOpenUsLocation ] = React.useState(false);
    const [ agreeUsLocation, setAgreeUsLocation ] = React.useState(false);
    const handleAgreeUsLocation = () => {
        setAgreeUsLocation(true);
        setOpenUsLocation(false);
        const userDetails = {
            username: username,
            password: password,
        }
        console.log( userDetails );
        sessionStorage.setItem('loginAttempt', username );
        axios.post('/api/user/login', userDetails, {
            }).then( response => {
                console.log('login response: ')
                console.log(response)
                if (response.status === 200) {

                    if (response.data.MFAon === true ) {
                        window.location.pathname = "/mfa"
                    } else { 
                        sessionStorage.setItem('fundsAvailable', response.data.fundsAvailable);
                        sessionStorage.setItem('fundsAtRisk', response.data.fundsAtRisk);
                        window.location.pathname = "/home"; }

                } else {
                    setOpen(true);
                    console.log(response.data.message);
                }
            }).catch(error => {
                console.log('login error: ')
                console.log(error);
                setOpen(true);
                // window.location.pathname = "/login";
            })
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        let countrySelected;
        if ( location === "MX" ) { countrySelected = "MX" } else if ( location === "Other Country") { countrySelected = "UK" } else if (location === "UK") { countrySelected = "UK" } else { countrySelected = "US" }
        sessionStorage.setItem('countryClaimed', countrySelected);

        if ( over18.checkedC === true ) {
            if ( location === "US" ) {
                setOpenUsLocation(true);
             } else {
                if ( location !== "" ) {
                    const userDetails = {
                        username: username,
                        password: password,
                    }
                    console.log( userDetails );
                    sessionStorage.setItem('loginAttempt', username );
                    axios.post('/api/user/login', userDetails, {
                        }).then( response => {
                            console.log('login response: ')
                            console.log(response)
                            if (response.status === 200) {

                                if (response.data.MFAon === true ) {
                                    window.location.pathname = "/mfa"
                                } else { 
                                    sessionStorage.setItem('fundsAvailable', response.data.fundsAvailable);
                                    sessionStorage.setItem('fundsAtRisk', response.data.fundsAtRisk);
                                    window.location.pathname = "/home"; }

                            } else {
                                setOpen(true);
                                console.log(response.data.message);
                            }
                        }).catch(error => {
                            console.log('login error: ')
                            console.log(error);
                            setOpen(true);
                            // window.location.pathname = "/login";
                        })
                }   else { setOpenLegalLocation(true) }
             }
        } else { setOpenMust18(true) }
    }

    // For Switch for Legal Age
    const [over18, setOver18] = React.useState({
        checkedC: false,
        });

        const handleChange = (event) => {
        setOver18({ ...over18, [event.target.name]: event.target.checked });
        };

    return(
        <div>
            < Container maxWidth="sm">
            <br/>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                            Login
                        </Typography>
                        <Typography className={classes.title} style={{ textAlign: 'center'}}>
                            iBetU
                        </Typography>
                        <br />
                        
                        <form noValidate autoComplete="off">
                            <Grid container direction='column' justify="center" alignItems='center'>
                                <div>
                                    <TextField
                                        required
                                        id="username"
                                        label="Username"
                                        name="username"
                                        variant="outlined"
                                        value={username}
                                        placeholder="Username"
                                        onChange={ e => setUsername(e.target.value)}
                                        
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="password"
                                        label="Password"
                                        name="password"
                                        type="password"
                                        variant="outlined"
                                        value={password}
                                        placeholder="Password"
                                        onChange={ e => setPassword(e.target.value) }
                                    />
                                </div>
                                <br />
                                <div>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">Location</InputLabel>
                                        <Select
                                            required
                                            label="Location"
                                            id="location-select"
                                            value={location}
                                            onChange={ e => setLocation(e.target.value)}
                                        >
                                            <MenuItem value={"UK"}>United Kingdom</MenuItem>
                                            <MenuItem value={"US"}>USA</MenuItem>
                                            <MenuItem value={"MX"}>Mexico</MenuItem>
                                            <MenuItem value={"Other Country"}>Other Country</MenuItem>
                                            {/* <MenuItem value={"NV"}>Nevada</MenuItem>
                                            <MenuItem value={"PA"}>Pennsylvania</MenuItem>
                                            <MenuItem value={"RI"}>Rhode Island</MenuItem>
                                            <MenuItem value={"IN"}>Indiana</MenuItem>
                                            <MenuItem value={"WV"}>West Virginia</MenuItem>
                                            <MenuItem value={"IA"}>Iowa</MenuItem>
                                            <MenuItem value={"NJ"}>New Jersey</MenuItem> */}
                                            
                                        </Select>
                                    </FormControl>
                                </div>

                                <br />
                                <FormGroup>
                                    <div>
                                    <Typography  style={{ textAlign: 'left'}}>
                                        Are you at least 18 years old?:
                                    </Typography>
                                      
                                    <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item>No</Grid>
                                        <Grid item>
                                            <AntSwitch checked={over18.checkedC} onChange={handleChange} name="checkedC" />
                                        </Grid>
                                        <Grid item>Yes</Grid>
                                        </Grid>
                                    </div>
                                    <br />
                                </FormGroup>

                                <div>
                                    <Dialog
                                        open={open}
                                        agree={agree}
                                        onClose={handleAgree}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"That combination of username and password is not in the system."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgree} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openMust18}
                                        agree={agreeMust18}
                                        onClose={handleAgreeMust18}
                                    >
                                        <DialogTitle >{"We are sorry. You must be 18 or older to play."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreeMust18} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openLegalLocation}
                                        agree={agreeLegalLocation}
                                        onClose={handleAgreeLegalLocation}
                                    >
                                        <DialogTitle >{"We are sorry, but on-line sports betting is not allowed in your region or state, or we do not yet have a permit to operate there.  Please check back soon."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreeLegalLocation} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openUsLocation}
                                        agree={agreeUsLocation}
                                        onClose={handleAgreeUsLocation}
                                    >
                                        <DialogTitle >{"Please note that while you may sign up and login, social gaming laws will restrict you from placing a bet in any of the following states: AR, GA, ID, IL, IN, IA, KS, MD, MI, MS, MO, NE, NH, NC, OK, RI, SD, TN, UT, WV, WI"}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreeUsLocation} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>


                                <CardActions style={{ justifyContent: 'center'}}>
                                    <div>
                                        <Button size="small" variant="contained" className={classes.buttonblue}
                                        type='submit'
                                        onClick={handleSubmit}
                                        >Login</Button>
                                    </div>
                                    
                                </CardActions>
                                    <br/>
                                    <div>
                                        <Button size="small" variant="outlined" className={classes.buttonclear}
                                        type='submit'
                                        onClick={handleForgot}
                                        >Forgot</Button>
                                    </div>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
        
}


    // constructor() {
    //     super()
    //     this.state = {
    //         username: 'Username',
    //         password: 'Password',
    //         redirectTo: null
    //     }
    //     this.handleSubmit = this.handleSubmit.bind(this);
    //     this.handleChange = this.handleChange.bind(this);
    // }

    // handleChange(event) {
    //     this.setState({
    //         [event.target.name]: event.target.value
    //     })
    // }

    // handleSubmit(event) {
    //     event.preventDefault()
    //     console.log('handleSubmit')

    //     axios
    //         .post('/user/login', {
    //             username: this.state.username,
    //             password: this.state.password
    //         })
    //         .then(response => {
    //             console.log('login response: ')
    //             console.log(response)
    //             if (response.status === 200) {
    //                 // update App.js state
    //                 this.props.updateUser({
    //                     loggedIn: true,
    //                     username: response.data.username
    //                 })
    //                 // update the state to redirect to home
    //                 this.setState({
    //                     redirectTo: '/'
    //                 })
    //             }
    //         }).catch(error => {
    //             console.log('login error: ')
    //             console.log(error);
                
    //         })
    // }