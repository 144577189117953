import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import axios from 'axios';

const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        minWidth: 260,
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    table: {
        minWidth: 280,
        maxWidth: 280,
    },
    tableHeader: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        fontSize: 18,
    },
    tableCell: {
        padding: 3,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    tableHeaderName: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        fontSize: 18,
    },
    tableCellName: {
        padding: 8,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        fontSize: 18,
    },
    buttonBetBlue: {
        background: '#0510e6',
        color: 'white',
        minWidth: 80
    },
    formControl: {
        display: "flex",
    },
    buttonGet: {
        background: '#0510e6',
        color: 'white'
    },
});


export default function SeeYourGroups() {
    let currentUserID = sessionStorage.getItem('userID');
    let currentUsername = sessionStorage.getItem('currentUser');
    sessionStorage.setItem('currentGroup', 'noGroupSelected');
    
    const classes = useStyles();
    const [ rows, setRows ] = React.useState([]);
    const [ rowsFriends, setRowsFriends ] = React.useState([]);
    const [ openFriendsInGroup, setOpenFriendsInGroup ] = React.useState(false);


    // Get all User's Groups
    useEffect(() => {
        (async () => {
            const array = await axios.get('/api/user/mygroups/' + currentUserID)
            .then( function (res) {
                if ( res.data.length === 0 ) {
                    let noGroups = []
                    return noGroups;
                } else {
                    return res.data
                }
            })
            .catch(error => { console.log(error)})

            const result = [];
            const map = new Map();
            if (array.length > 0){
                for(const item of array) {
                    if(!map.has(item._id)){
                        map.set(item._id, true);
                        result.push({
                            _id: item._id,
                            friendGroupName: item.friendGroupName,
                        })
                    }
                }
            } else {
                // Enter code for open no groups
            }
            //  Below makes them come out sorted alphabetically
            if ( result === undefined ) { console.log('no friend groups') } else {
                function compare(a, b) {
                    const bandA = a.friendGroupName.toLowerCase();
                    const bandB = b.friendGroupName.toLowerCase();
                    let comparison = 0;
                    if (bandA > bandB) {
                        comparison = 1;
                      } else if (bandA < bandB) {
                        comparison = -1;
                      }
                      return comparison;
                }
                let sortedResult = result.sort(compare);
                setRows(sortedResult);
            }

        })();
    }, [])



    const [ groupPicked, setGroupPicked ] = React.useState('');
    const [ groupPickedName, setGroupPickedName ] = React.useState('');

    const handleGroupPick = async (event, key) =>{
        console.log("CODE GOT to GROUP PICk")
        setGroupPicked(key);
        let groupChosen = rows.find(({_id}) => _id === key);
        let groupChosenName = groupChosen.friendGroupName;
        setGroupPickedName(groupChosenName);
        let groupDetails = await axios.get('/api/group/' + groupChosenName)
        .then( async function(res) {
            return res.data;
        })
        .catch(error => {
            console.log(error)
        })
        
        let groupMembersArray = groupDetails.friends;

        const result = [];
        const map = new Map();
        if ( groupMembersArray !== undefined ){
            for ( const item of groupMembersArray ) {
                if(!map.has(item._id)){
                    map.set(item._id, true);
                    result.push({
                        _id: item._id,
                        friendName: item.friendName,
                        friendUsername: item.friendUsername,
                        friendEmail: item.friendEmail,
                        friendPhone: item. friendPhone
                    })
                }
            }
        }

        if ( result === undefined ) { console.log('no friend in group') } else {
            function compare(a, b) {
                const bandA = a.friendName.toLowerCase();
                const bandB = b.friendName.toLowerCase();
                let comparison = 0;
                if (bandA > bandB) {
                    comparison = 1;
                  } else if (bandA < bandB) {
                    comparison = -1;
                  }
                  return comparison;
            }
            let sortedResult = result.sort(compare);
            let key = 'friendName';
            let uniqueArray = [...new Map(sortedResult.map(item => [item[key],item])).values()];
            setRowsFriends(uniqueArray);
        }

        setOpenFriendsInGroup(true);


    }

    const handleGoToGroupProposed = () => {
        sessionStorage.setItem('currentGroup', groupPickedName);
        window.location.pathname = '/groupbetsproposed';
    }

    const handleGoToGroupLive = () => {
        sessionStorage.setItem('currentGroup', groupPickedName);
        window.location.pathname = '/grouplivebets';
    }

    const handleGoToGroupPast = () => {
        sessionStorage.setItem('currentGroup', groupPickedName);
        window.location.pathname = '/grouppastbets';
    }


    return(
        <div>
            <Card className={classes.root}>
                <CardContent>
                    <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center' }} gutterBottom>
                            Select A Friend Group
                    </Typography>

                </CardContent>

                <TableContainer component={Paper} align="center">
                    <Table className={classes.table} aria-label="game table" style={{ justifyContent: 'center'}}>

                        <TableBody>
                            {rows.map((row) =>(
                                <TableRow
                                    hover
                                    key={row._id}
                                    onClick={ e => handleGroupPick(e, row._id)}
                                >
                                    <TableCell component="th" scope="row" align="center" className={classes.tableCellName}>
                                        {row.friendGroupName}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                </TableContainer>

                <div>
                <Dialog
                    open={openFriendsInGroup}
                >
                    <DialogActions>
                    <div>
                        <Button size="medium" variant="contained" className={classes.buttonblue} type='submit' onClick={handleGoToGroupProposed}>Group's Bet Proposals</Button>
                    </div>
                    </DialogActions>
                    
                    <DialogActions>
                    <div>
                        <Button size="medium" variant="contained" className={classes.buttonblue} type='submit' onClick={handleGoToGroupLive}>Group's Live Bets</Button>
                    </div>
                    </DialogActions>

                    <DialogActions>
                    <div>
                        <Button size="medium" variant="contained" className={classes.buttonblue} type='submit' onClick={handleGoToGroupPast}>Group's Past Bet Results</Button>
                    </div>
                    </DialogActions>
                    <br/>

                    <TableContainer component={Paper} align="center">
                        <Table className={classes.table} aria-label="game table" style={{ justifyContent: 'center'}}>
                            <TableHead>
                            <TableRow>
                                <TableCell align="center" className={classes.tableHeader}>Friends in This Group</TableCell>
                            </TableRow>
                            </TableHead>

                            <TableBody>
                                {rowsFriends.map((row) =>(
                                    <TableRow
                                    hover
                                    key={row._id}>
                                        <TableCell component="th" scope="row" align="center" className={classes.tableCellName}>
                                            {row.friendName}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </TableContainer>

                    <DialogActions>
                    <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <Button onClick={async e => await setOpenFriendsInGroup(false)} align="center" color="secondary" variant="outlined">
                                    X
                                </Button>
                            </Grid>
                            {/* <Grid item xs={2}></Grid>
                            <Grid item xs={8}>
                                <Button onClick={async e => await setOpenConfirmLeaveGroup(true)} align="center" color="secondary" variant="outlined">
                                    Leave Group
                                </Button>
                            </Grid> */}
                        </Grid>                        
                    </DialogActions>
                </Dialog>
                </div>   


            </Card>
        </div>
    )

}

