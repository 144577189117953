import React from 'react';

import Container from '@material-ui/core/Container';
import MainAppBar from '../components/MainAppBar';
import PublicLiveBets from '../components/PublicLiveBets';

import Footer from '../components/Footer';
import Copyright from '../components/Copyright';
import checkAuthenticated from '../utils/checkAuthenticated';
import {abbreviations} from '../utils/abbreviations';

//  Add abbreviations as add mor leagues
export default function FriendBetsPage() {
  checkAuthenticated();

  return (
    < Container maxWidth="sm">
        <MainAppBar></MainAppBar>
        <PublicLiveBets
          abbreviations={abbreviations}
        ></PublicLiveBets>
        <br/>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
