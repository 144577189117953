import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
// import MuiPhoneNumber from 'material-ui-phone-number';


import axios from 'axios';

let currentUser = sessionStorage.getItem("currentUser");
let currentUserID = sessionStorage.getItem('userID');

const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    formControl: {
        margin: 1,
        minWidth: 220,
        maxWidth: 220,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
      },
  });


export default function SignUpForm() { 
    const classes = useStyles();
    const [ currentPassword, setCurrentPassword ] = React.useState('');
    const [ selectedPassword, setSelectedPassword ] = React.useState('');
    const [ confirmPassword, setConfirmPassword ] = React.useState('');
       
    
    // Functions to Check registration details==================================
    const CheckPasswordReq = (inputtxt) => {
        let lengthCheck = false;
        let characterCheck = false;
        let numberCheck = false;
        let regex3 = /[!@#$%^&*]/;

        if ( inputtxt.length > 7) { lengthCheck = true }
        if ( /\d/.test(inputtxt) && /[a-zA-Z]/.test(inputtxt) ) { numberCheck = true }
        if ( regex3.test(inputtxt) ) { characterCheck = true }

        if (lengthCheck && characterCheck && numberCheck) {
            return true;
        }
    }

    

    // For Dialog Pop Up on Incorrect Old Password
    const [openOldCheck, setOpenOldCheck] = React.useState(false);
    const [agreeOldCheck, setAgreeOldCheck] = React.useState(false);

    const handleAgreeOldCheck = () => {
        setAgreeOldCheck(true);
        setOpenOldCheck(false);
      };

    // For Dialog Pop Up Confirming Passwords Match
    const [openPasswordMatch, setOpenPasswordMatch] = React.useState(false);
    const [agreePasswordMatch, setAgreePasswordMatch] = React.useState(false);

    const handleAgreePasswordMatch = () => {
        setAgreePasswordMatch(true);
        setOpenPasswordMatch(false);
      };

    // For Dialog Pop Up on Password requirements
    const [openPasswordReq, setOpenPasswordReq] = React.useState(false);
    const [agreePasswordReq, setAgreePasswordReq] = React.useState(false);

    const handleAgreePasswordReq = () => {
        setAgreePasswordReq(true);
        setOpenPasswordReq(false);
      };


    // For Dialog Pop Up Confirming Change in password & axios post
    const [open, setOpen] = React.useState(false);
    const [agree, setAgree] = React.useState(false);

    const handleAgree = () => {
        setAgree(true);
        setOpen(false);

        const userDetails = {
            userID: currentUserID,
            username: currentUser,
            currentPassword: currentPassword,
            password: selectedPassword
        }

        
        const oldUserDetails = {
            username: currentUser,
            password: currentPassword
        }
        axios.post('/api/user/checkoldpassword', oldUserDetails, {    
        }).then( res => {
            console.log(res);
            console.log(res.data.message);
            if (res.data.message === 'match') {
                if (CheckPasswordReq(userDetails.password) !==true) {
                    setOpenPasswordReq(true);
                } else {
                    if (confirmPassword !== selectedPassword) {
                        setOpenPasswordMatch(true);
                    } else {
                        axios.post('/api/user/changepassword/' + currentUser, userDetails, {
                            }).then(response => {
                                console.log(response)
                                setOpenSuccess(true);
                            }).catch(error => {
                                console.log('new password error: ')
                                console.log(error)
    
                            })
                    }
                }
            } else {
                setOpenOldCheck(true);
            }
        })
        .catch(error => {
            console.log(error);
        })
        

          
            
        
    };

    const handleCancel = () => {
        setOpen(false);
      };



    // For Dialog PopUp on Successful Update
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [agreeSuccess, setAgreeSuccess] = React.useState(false);

    const handleAgreeSuccess = () => {
        setAgreeSuccess(true);
        setOpenSuccess(false);
        window.location.pathname = "/home";
      };


    const handleCancelUpdate = (event) => {
        event.preventDefault();
        window.location.pathname = "/home"
    }
    
    const handleSubmit = (event) => {
        event.preventDefault();
        setOpen(true);
    }
    

    return(
        <div>
            < Container maxWidth="sm">
            <br/>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                            Update Password
                        </Typography>
                        <Typography className={classes.title} style={{ textAlign: 'center'}}>
                            iBetU
                        </Typography>
                        <br />
                        <form noValidate autoComplete="off">
                            <Grid container direction='column' justify="center" alignItems='center'>
                                <div>
                                    <TextField
                                        required
                                        id="currentPassword"
                                        label="Current Password"
                                        name="currentPassword"
                                        type="password"
                                        variant="outlined"
                                        value={currentPassword}
                                        placeholder="Current Password"
                                        onChange={ e => setCurrentPassword(e.target.value) }
                                        
                                    />
                                </div>
                                
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="password"
                                        label="New Password"
                                        name="password"
                                        type="password"
                                        variant="outlined"
                                        value={selectedPassword}
                                        placeholder="New Password"
                                        onChange={ e => setSelectedPassword(e.target.value) }
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="confirmPassword"
                                        label="Confirm New Password"
                                        name="confirmPassword"
                                        type="password"
                                        variant="outlined"
                                        value={confirmPassword}
                                        placeholder="Confirm New Password"
                                        onChange={ e => setConfirmPassword(e.target.value) }
                                    />
                                </div>
                
                                <br />
                            
                                <div>
                                    <Dialog
                                        open={open}
                                        agree={agree}
                                        onClose={handleCancel}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Are you sure you want to update your password?"}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleCancel} color="primary">
                                            Cancel
                                        </Button>
                                        <Button onClick={handleAgree} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openOldCheck}
                                        agree={agreeOldCheck}
                                        onClose={handleAgreeOldCheck}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"You have entered an incorrect value for the current password."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreeOldCheck} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openPasswordMatch}
                                        agree={agreePasswordMatch}
                                        onClose={handleAgreePasswordMatch}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Two new password entries do not match."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreePasswordMatch} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openPasswordReq}
                                        agree={agreePasswordReq}
                                        onClose={handleAgreePasswordReq}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Password must contain more than 7 characters, at least one letter, at least one number, and at least one special character."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreePasswordReq} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openSuccess}
                                        agree={agreeSuccess}
                                        onClose={handleAgreeSuccess}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"You have successfully changed your password."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreeSuccess} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <CardActions style={{ justifyContent: 'center'}}>
                                    <Button size="small" variant="contained" className={classes.buttonblue}
                                    type='submit'
                                    onClick={handleCancelUpdate}
                                    >Cancel</Button>
                                    <Button size="small" variant="contained" className={classes.buttonblue}
                                    type='submit'
                                    onClick={handleSubmit}
                                    >Submit</Button>
                                </CardActions>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
        
}

