
import axios from 'axios';

export default function checkAdmin() {

    let currentUserID = sessionStorage.getItem("userID");  

    if (sessionStorage.getItem("updating") === true ) {
      window.location.pathname = "/updating_server";
    } else {
      console.log(currentUserID);
      axios.get('/api/promotion/findAdmin/' + currentUserID).then(function(res){
        
        if (res.data.admin) {
          console.log("Admin Approved");
        } else {
          console.log("Didn't find Admin")
          window.location.pathname ="/home";
        }
      })
      .catch(error => {
        console.log(error);
      })  

    }

}