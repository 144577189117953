import React from 'react';

import Container from '@material-ui/core/Container';
import BasicAppBar from '../../components/BasicAppBar';
import PrivacyPolicy from '../../components/PrivacyPolicy';

import Footer from '../../components/FooterNotLogged';
import Copyright from '../../components/Copyright';


export default function LandingPage() {

  return (
    < Container maxWidth="sm">
        <BasicAppBar></BasicAppBar>
        <PrivacyPolicy></PrivacyPolicy>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
