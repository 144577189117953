import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import NumberFormat from 'react-number-format';

import axios from 'axios';

let currentUserID = sessionStorage.getItem('userID');

const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        minWidth: 275,
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    table: {
        minWidth: 280,
        maxWidth: 280,
    },
    tableHeader: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
    },
    tableHeaderNarrow: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        fixedHeader: false,
        width: 'auto',
        tableLayout: 'auto',
    },
    tableCell: {
        padding: 2,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        fontSize: 12,
    },
    tableCellNarrow: {
        padding: 1,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        fixedHeader: false,
        width: 'auto',
        tableLayout: 'auto',
    },
    buttonBetBlue: {
        background: '#0510e6',
        color: 'white',
        minWidth: 80
    },
    formControl: {
        display: "flex",
    },
    buttonGet: {
        background: '#0510e6',
        color: 'white',
    },
    answer: {
        fontSize: 14,
        fontFamily: 'Anton',
        color: 'grey',
        display: 'inline-block'
    },
});

let regionRestriction = sessionStorage.getItem('regionRestriction');

function ConvertDateToNumber(date) {
    let today = date;
    let yearNum = today.getFullYear();
    let monthNum = today.getMonth()+1;
    let dayNum = today.getDate();
    let year = yearNum.toString();
    let month;
    if ( monthNum < 10 ) { month = "0" + monthNum.toString() } else { month = monthNum.toString() }
    let day;
    if ( dayNum < 10 ) { day = "0" + dayNum.toString() } else { day = dayNum.toString() }
    let todayShort = parseInt(year + month + day); 
    return todayShort;
}

export default function HistoryByDates(props) {
    const classes = useStyles();
    // Code for date picker and getting aggregate history=================
    const today = new Date();
    const startOfCurrentYear = new Date( today.getFullYear(), 0, 1);
    const [ startDate, setStartDate ] = React.useState(startOfCurrentYear);
    const [ endDate, setEndDate ] = React.useState(today);
    const [ amountWon, setAmountWon ] = React.useState();
    const [ countWon, setCountWon ] = React.useState();
    const [ amountLost, setAmountLost ] = React.useState();
    const [ countLost, setCountLost ] = React.useState();


    const handleStartDateChange = (date) => {
        setStartDate(date);
    }
    const handleEndDateChange = (date) => {
        setEndDate(date);
    }

    const handleGetHistory = async () => {
        let startDateNum = await ConvertDateToNumber(startDate);
        let endDateNum = await ConvertDateToNumber(endDate);
        const requestDetails = {
            currentUserID: currentUserID,
            startDate: startDateNum,
            endDate: endDateNum
        }
        const userHistory = await axios.post('/api/betsAccepted/historybydates', requestDetails, {})
        .then( res => {
            console.log(res);
            setAmountWon(res.data.betsWonTotal);
            setCountWon(res.data.betsWonCount);
            setAmountLost(res.data.betsLostTotal);
            setCountLost(res.data.betsLostCount);
        })
        .catch( error => {
            console.log("Error getting history")
            console.log(error);
        })

        const array = await axios.post('/api/betsAccepted/allpastbets/', requestDetails, {})
        .then(function (res) {
            if ( res.data.data.length === 0) {
                console.log(res);
                console.log("No posted bets - create dialog")
                return res.data.data;
            } else {
                let acceptedBets = res.data.data;
                return acceptedBets;
            }
        })
        .catch(error => {
            console.log(error);
        })

        const result = [];
        const map = new Map();
        if (array.length > 0) {
            for (const item of array) {
                if(!map.has(item._id)){
                    map.set(item._id, true);
                    
                    // Find abbreviations of Teams
                    let homeTeamShortObj = abbreviations.find( ({ name }) => name === item.homeTeam );
                    let awayTeamShortObj = abbreviations.find( ({ name }) => name === item.awayTeam );
                    let winnerOfferShortObj = abbreviations.find( ({ name }) => name === item.winnerOffer );
                    let otherTeamShort;
                    if ( homeTeamShortObj.abbreviation === winnerOfferShortObj.abbreviation) { otherTeamShort = awayTeamShortObj.abbreviation } else { otherTeamShort = homeTeamShortObj.abbreviation };

                    // Determine if won or lost
                    let userPerformance;
                    let winningTeamShort;
                    if ( currentUserID === item.winnerOfBet ) { 
                        userPerformance = 'Won';
                    } else { userPerformance = 'Lost' }
                    
                    let performanceAmount;
                    if ( currentUserID === item.userIDoffer ) { 
                        if ( userPerformance === 'Won' ) {
                            performanceAmount = "$" + item.acceptPay.toString();
                            winningTeamShort = winnerOfferShortObj.abbreviation;
                        } else {
                            performanceAmount = "-$" + item.offerPay.toString();
                            winningTeamShort = otherTeamShort;
                        }
                    } else {
                        if ( userPerformance === 'Won' ){
                            performanceAmount = "$" + item.offerPay.toString();
                            winningTeamShort = otherTeamShort;
                        } else {
                            performanceAmount = "-$" + item.acceptPay.toString();
                            winningTeamShort = winnerOfferShortObj.abbreviation;
                        }
                    }
                    // console.log(performanceAmount);
                    // console.log(item.acceptPay)

                    //Push to new Array
                    result.push({
                        _id: item._id,
                        dateProposed: item.dateProposed,
                        dateMatch: item.dateMatch,
                        dateMatchDB: item.dateMatchDB,
                        dateTimeDB: item.dateTimeDB,
                        dateShort: item.dateShort,
                        daetShortOUS: item.dateShortOUS,
                        league: item.league,
                        usernameOffer: item.usernameOffer,
                        userIDoffer: item.userIDoffer,
                        amountBet: item.amountBet,
                        winnerOffer: item.winnerOffer,
                        spreadOffer: item.spreadOffer,
                        homeTeam: item.homeTeam,
                        awayTeam: item.awayTeam,
                        oddsNumeratorOffer: item.oddsNumeratorOffer,
                        oddsDenominatorOffer: item.oddsDenominatorOffer,
                        offerMaxRisk: item.offerMaxRisk,
                        offerFundsAtRisk: item.offerFundsAtRisk,
                        publicBet: item.publicBet,
                        partialTake: item.partialTake,
                        friend: item.friend,
                        friendUsername: item.friendUsername,
                        gameID: item.gameID,
                        usernameAccept: item.usernameAccept,
                        userIDaccept: item.userIDaccept,
                        dateAccepted: item.dateAccepted,
                        winnerOfBet: item.winnerOfBet,
                        loserOfBet: item.loserOfBet,
                        offerPay: item.offerPay,
                        acceptPay: item.acceptPay,
                        partialAmountBet: item.partialAmountBet,
                        homeTeamShort: homeTeamShortObj.abbreviation,
                        awayTeamShort: awayTeamShortObj.abbreviation,
                        winningTeamShort: winningTeamShort,
                        // winnerOfferShort: winnerOfferShortObj.abbreviation,
                        userPerformance: userPerformance,
                        performanceAmount: performanceAmount,
                    })
                }
            }
        } else { console.log('Did not get array'); setOpen(true) }

        if ( result === undefined ) { console.log("No Accepted Bets") } else {
            setRows(result)
        }
    }

    //  Code required for getting all past bets ============================
    const abbreviations = props.abbreviations;
    const [ rows, setRows ] = React.useState([]);



    //  For dialog on No Accepted Bets Upcoming
    const [open, setOpen] = React.useState(false);
    const [agree, setAgree] = React.useState(false);

    const handleAgree = () => {
        setAgree(true);
        setOpen(false);
        window.location.pathname = '/home';
    };

    // Selecting and Accepted Bet Code
    const [ betPicked, setBetPicked ] = React.useState();
    const [ selected, setSelected ] = React.useState([]);
    const [ dateShort, setDateShort ] = React.useState();
    const [ dateShortOUS, setDateShortOUS ] = React.useState();
    const [ amountBet, setAmountBet ] = React.useState();
    const [ winnerOffer, setWinnerOffer ] = React.useState();
    const [ spreadOffer, setSpreadOffer ] = React.useState();
    const [ homeTeam, setHomeTeam ] = React.useState();
    const [ awayTeam, setAwayTeam ] = React.useState();
    const [ oddsNumeratorOffer, setOddsNumeratorOffer ] = React.useState();
    const [ oddsDenominatorOffer, setOddsDenominatorOffer ] = React.useState();
    const [ partialTake, setPartialTake ] = React.useState();
    const [ gameID, setGameID ] = React.useState();
    const [ messageSummary, setMessageSummary ] = React.useState();
    const [ loserOffer, setLoserOffer ] = React.useState();
    const [ offerPay, setOfferPay ] = React.useState();
    const [ acceptPay, setAcceptPay ] = React.useState();
    const [ partialAmountBet, setPartialAmountBet ] = React.useState();
    
    const [ dateProposed, setDateProposed ] = React.useState();
    const [ dateMatch, setDateMatch ] = React.useState();
    const [ dateMatchDB, setDateMatchDB ] = React.useState();
    const [ dateTimeDB, setDateTimeDB ] = React.useState();
    const [ league, setLeague ] = React.useState();
    const [ usernameOffer, setUsernameOffer ] = React.useState();
    const [ userIDoffer, setUserIDoffer ] = React.useState();
    const [ publicBet, setPublicBet ] = React.useState();
    const [ friend, setFriend ] = React.useState();
    const [ friendUsername, setFriendUsername ] = React.useState();

    const [ usernameAccept, setUsernameAccept ] = React.useState();
    const [ userIDaccept, setUserIDaccept ] = React.useState();
    const [ dateAccepted, setDateAccepted ] = React.useState();
    const [ winnerOfBet, setWinnerOfBet ] = React.useState();
    const [ loserOfBet, setLoserOfBet ] = React.useState();
    const [ regionLimit, setRegionLimit ] = React.useState();

    const handleBetPick = (event, key) => {
        setBetPicked(key);
        let loserOffer2;
        let betChosen = rows.find(({_id}) => _id === key);
        setSelected(betChosen);
        setDateShort(betChosen.dateShort);
        setDateShortOUS(betChosen.dateShortOUS);
        setAmountBet(betChosen.amountBet);
        setWinnerOffer(betChosen.winnerOffer);
        setSpreadOffer(betChosen.spreadOffer);
        setHomeTeam(betChosen.homeTeam);
        setAwayTeam(betChosen.awayTeam);
        setOddsNumeratorOffer(betChosen.oddsNumeratorOffer);
        setOddsDenominatorOffer(betChosen.oddsDenominatorOffer);
        setPartialTake(betChosen.partialTake);
        setGameID(betChosen.gameID);
        setOfferPay(betChosen.offerPay);
        setAcceptPay(betChosen.acceptPay);
        setDateProposed(betChosen.dateProposed);
        setDateMatch(betChosen.dateMatch);
        setDateMatchDB(betChosen.dateMatchDB);
        setDateTimeDB(betChosen.dateTimeDB);
        setLeague(betChosen.league);
        setUsernameOffer(betChosen.usernameOffer);
        setUserIDoffer(betChosen.userIDoffer);
        setPublicBet(betChosen.publicBet);
        setFriend(betChosen.friend);
        setFriendUsername(betChosen.friendUsername);

        setUsernameAccept(betChosen.usernameAccept);
        setUserIDaccept(betChosen.userIDaccept);
        setRegionLimit(betChosen.regionLimit);

        if ( betChosen.winnerOffer === betChosen.homeTeam ) { 
            loserOffer2 = betChosen.awayTeam;
            setLoserOffer(betChosen.awayTeam) 
        } else { 
            loserOffer2 = betChosen.homeTeam;
            setLoserOffer(betChosen.homeTeam) 
        };

        if ( betChosen.league === "nba" || betChosen.league === "nfl" || betChosen.league === "nhl" || betChosen.league === "mlb"){ 
            //this is for teams that have "the" in front like "the San Antonio Spurs"
            if ( betChosen.userIDoffer === currentUserID ) {
                // Code that has current User as offer
                setMessageSummary("You bet " + betChosen.usernameAccept + " that the " + betChosen.winnerOffer + " would beat the " + loserOffer2 + " by at least " + betChosen.spreadOffer + " points.");
                setOpenBetSummary(true)
            } else {
                // Code that has current User as accept
                setMessageSummary("Your friend, " + betChosen.usernameOffer + ", bet you that the " + betChosen.winnerOffer + " would beat the " + loserOffer2 + " by at least " + betChosen.spreadOffer + " points.");
                setOpenBetSummary(true)
            }
        } else {
            // this is for teams that don't have "the" in front like "Arsenal"
            if ( betChosen.userIDoffer === currentUserID ) {
                // Code that has current User as offer
                setMessageSummary("You bet " + betChosen.usernameAccept + " that " + betChosen.winnerOffer + " would beat " + loserOffer2 + " by at least " + betChosen.spreadOffer + " points.");
                setOpenBetSummary(true)
            } else {
                // Code that has current User as accept
                setMessageSummary("Your friend, " + betChosen.usernameOffer + ", bet you that " + betChosen.winnerOffer + " would beat " + loserOffer2 + " by at least " + betChosen.spreadOffer + " points.");
                setOpenBetSummary(true)
            }
        }
    }

    //  Dialog that summarizes bet
    const [ openBetSummary, setOpenBetSummary ] = React.useState(false);
    const handleCancelSummary = () => {
        setOpenBetSummary(false);
    }



    return(
        <div>
            <Card className={classes.root}>
                {/* <CardContent>
                    <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center' }} >
                        Account History
                    </Typography>
                </CardContent> */}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="start-date-picker-dialog"
                                label="Select Start Date"
                                format="MM/dd/yyyy"
                                value={startDate}
                                onChange={handleStartDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="end-date-picker-dialog"
                                label="Select End Date"
                                format="MM/dd/yyyy"
                                value={endDate}
                                onChange={handleEndDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />  
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={7}></Grid>
                        <Grid item xs={5}>
                            <Button 
                            className={classes.buttonGet}
                            onClick={handleGetHistory}
                                >Get History
                            </Button>   
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
                <br />
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography className={classes.answer} color="textSecondary" style={{ textAlign: 'left'}}>
                             Money Won: &nbsp; &nbsp;
                        </Typography><NumberFormat 
                            value={amountWon}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={2}
                            prefix={'$'}
                            className={classes.answer}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.answer} color="textSecondary" style={{ textAlign: 'left'}}>
                            # of Bets Won: &nbsp; &nbsp;
                        </Typography><NumberFormat 
                            value={countWon}
                            displayType={'text'}
                            thousandSeparator={true}
                            className={classes.answer}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography className={classes.answer} color="textSecondary" style={{ textAlign: 'left'}}>
                             Money Lost: &nbsp; &nbsp;
                        </Typography><NumberFormat 
                            value={amountLost}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={2}
                            prefix={'$'}
                            className={classes.answer}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.answer} color="textSecondary" style={{ textAlign: 'left'}}>
                            # of Bets Lost: &nbsp; &nbsp;
                        </Typography><NumberFormat 
                            value={countLost}
                            displayType={'text'}
                            thousandSeparator={true}
                            className={classes.answer}
                        />
                    </Grid>
                </Grid>
                <br />
                <TableContainer component={Paper} align="center">
                    <Table className={classes.table} aria-label="game table" style={{ justifyContent: 'center'}}>
                        <TableHead>
                        <TableRow>
                            <TableCell align="center" className={classes.tableHeader}>Date</TableCell>
                            <TableCell align="center" className={classes.tableHeader}>Game</TableCell>
                            <TableCell align="center" className={classes.tableHeader}>Winner</TableCell>
                            <TableCell align="center" className={classes.tableHeader}>Bet</TableCell>
                            <TableCell align="center" className={classes.tableHeader}>$</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows.map((row) => (
                            <TableRow 
                            hover
                            key={row._id}
                            onClick={ (event) => handleBetPick(event, row._id)}
                            >
                                <TableCell component="th" scope="row" align="center" className={classes.tableCell}
                                >
                                    {row.dateShort}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCell} 
                                >
                                    {row.awayTeamShort} @ {row.homeTeamShort}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCell}
                                >
                                    {row.winningTeamShort}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCell} 
                                >
                                    {row.userPerformance}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCell} 
                                >
                                    <NumberFormat 
                                    value={row.performanceAmount}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    fixedDecimalScale={2}
                                    prefix={'$'}
                                    />
                                    {/* {row.performanceAmount} */}
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <div>
                        <Dialog
                            open={open}
                            agree={agree}
                            onClose={handleAgree}
                        >
                            <DialogTitle id="alert-dialog-title">{"You do not yet have any bets with friends that have already settled.  If you feel this is an error, please email help@ibetu.co"}</DialogTitle>

                            <DialogActions>
                            <Button onClick={handleAgree} color="primary" autoFocus>
                                OK
                            </Button>
                            </DialogActions>
                        </Dialog>
                </div>

                {/* Bet Summary Dialog Start++++++++++++++++++++++++++++++++++++++ */}
                <Dialog open={openBetSummary}>
                    <DialogTitle id="confirm-bet">
                        {messageSummary}
                    </DialogTitle>
                    <DialogActions>
                        <Grid container spacing={1}>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <Button onClick={handleCancelSummary} align="center" color="secondary" variant="outlined" autoFocus>
                                    X
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
                {/* Bet Summary Dialog Over======================================== */}


            </Card>
        </div>
    )

}