import React from 'react';

import Container from '@material-ui/core/Container';
import BasicAppBar from '../components/BasicAppBar';
import SignUpForm from '../components/SignUpForm';
import Footer from '../components/FooterNotLogged';
import Copyright from '../components/Copyright';
import checkNotAuthenticated from '../utils/checkNotAuthenticated';


export default function LandingPage() {
  checkNotAuthenticated();
  return (
    < Container maxWidth="sm">
        <BasicAppBar></BasicAppBar>
        <SignUpForm></SignUpForm>
        <br/>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
