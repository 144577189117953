import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import session from 'express-session';

export default function AlertDialog() {
  const [open, setOpen] = React.useState(false);
  const [agree, setAgree] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    setAgree(true);
    setOpen(false);

    const userDetails = {
      userID: sessionStorage.getItem("userID")
    }

    axios.post('/api/user/logout', userDetails, {}).then(response => {
        console.log(response.data)
        if (response.status === 200) {
            sessionStorage.removeItem("currentUser");
            sessionStorage.removeItem("userID");
            sessionStorage.removeItem("currentCountry");
            sessionStorage.removeItem("currentRegion");
            sessionStorage.removeItem("regionRestriction");
            sessionStorage.removeItem("loginAttempt");
            sessionStorage.removeItem("fundsAvailable");
            sessionStorage.removeItem("fundsAtRisk");
            sessionStorage.removeItem('firstName');
            sessionStorage.removeItem("lastName");
            sessionStorage.removeItem("promotionAvailable");
            window.location.pathname="/login"
        }
        }).catch(error => {
            console.log('Logout error')
        })

  };

  return (
    <div>
      <Button color="inherit" size="small" onClick={handleClickOpen}>
        Logout
      </Button>
      <Dialog
        open={open}
        agree={agree}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to logout?"}</DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure?
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
