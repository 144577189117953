import React from 'react';

import Container from '@material-ui/core/Container';
import MainAppBar from '../../components/MainAppBar';
import BetsByTeam from '../../components/gameComponents/PostedBetsByDate';

import Footer from '../../components/Footer';
import Copyright from '../../components/Copyright';
import checkAuthenticated from '../../utils/checkAuthenticated';
import {abbreviations} from '../../utils/abbreviations';

export default function LandingPage() {
  checkAuthenticated();
  let league = 'laliga';
  let title = "La Liga";





  return (
    < Container maxWidth="sm">
        <MainAppBar></MainAppBar>
        <BetsByTeam
          league={league}
          title={title}
          abbreviations={abbreviations}
        ></BetsByTeam>
        <br/>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
