import React from 'react';

export const abbreviations = [
        { name: 'Arsenal', abbreviation: 'ARS', league: 'premier'},
        { name: 'Aston Villa', abbreviation: 'AVA', league: 'premier'},
        { name: 'Bournemouth', abbreviation: 'BOU', league: 'premier'},
        { name: 'Brentford', abbreviation: 'BRE', league: 'premier'},	
        { name: 'Brighton', abbreviation: 'BRH', league: 'premier'},
        { name: 'Burnley', abbreviation: 'BUR', league: 'premier'},
        { name: 'Chelsea', abbreviation: 'CHE', league: 'premier'},
        { name: 'Crystal Palace', abbreviation: 'CRY', league: 'premier'},
        { name: 'Everton', abbreviation: 'EVE', league: 'premier'},
        { name: 'Fulham', abbreviation: 'FUL', league: 'premier'},
        { name: 'Leeds United', abbreviation: 'LU', league: 'premier'},
        { name: 'Leicester City', abbreviation: 'LEI', league: 'premier'},
        { name: 'Liverpool', abbreviation: 'LIV', league: 'premier'},
        { name: 'Manchester City', abbreviation: 'MCI', league: 'premier'},
        { name: 'Manchester Utd', abbreviation: 'MUN', league: 'premier'},
        { name: 'Newcastle Utd', abbreviation: 'NEW', league: 'premier'},
        { name: 'Norwich City', abbreviation: 'NOR', league: 'premier'}, 
        { name: "Nott'ham Forest", abbreviation: 'NOT', league: 'premier'},
        { name: 'Sheffield Utd', abbreviation: 'SHU', league: 'premier'},
        { name: 'Southampton', abbreviation: 'SOU', league: 'premier'},
        { name: 'Tottenham', abbreviation: 'TOT', league: 'premier'},
        { name: 'Watford', abbreviation: 'WAT', league: 'premier'},
        { name: 'West Brom', abbreviation: 'WBA', league: 'premier'},
        { name: 'West Ham', abbreviation: 'WHU', league: 'premier'},
        { name: 'Wolves', abbreviation: 'WLV', league: 'premier'},
    
        { name: 'Atlanta Hawks', abbreviation: 'ATL', league: 'nba'},
        { name: 'Boston Celtics', abbreviation: 'BOS', league: 'nba'},
        { name: 'Brooklyn Nets', abbreviation: 'BKN', league: 'nba'},	
        { name: 'Charlotte Hornets', abbreviation: 'CHA', league: 'nba'},
        { name: 'Chicago Bulls', abbreviation: 'CHI', league: 'nba'},
        { name: 'Cleveland Cavaliers', abbreviation: 'CLE', league: 'nba'},
        { name: 'Dallas Mavericks', abbreviation: 'DAL', league: 'nba'},
        { name: 'Denver Nuggets', abbreviation: 'DEN', league: 'nba'},
        { name: 'Detroit Pistons', abbreviation: 'DET', league: 'nba'},
        { name: 'Golden State Warriors', abbreviation: 'GSW', league: 'nba'},
        { name: 'Houston Rockets', abbreviation: 'HOU', league: 'nba'},
        { name: 'Indiana Pacers', abbreviation: 'IND', league: 'nba'},
        { name: 'Los Angeles Clippers', abbreviation: 'LAC', league: 'nba'},
        { name: 'Los Angeles Lakers', abbreviation: 'LAL', league: 'nba'}, 
        { name: 'Memphis Grizzlies', abbreviation: 'MEM', league: 'nba'},
        { name: 'Miami Heat', abbreviation: 'MIA', league: 'nba'},
        { name: 'Milwaukee Bucks', abbreviation: 'MIL', league: 'nba'},
        { name: 'Minnesota Timberwolves', abbreviation: 'MIN', league: 'nba'},
        { name: 'New Orleans Pelicans', abbreviation: 'NOP', league: 'nba'},
        { name: 'New York Knicks', abbreviation: 'NYK', league: 'nba'},
        { name: 'Oklahoma City Thunder', abbreviation: 'OKC', league: 'nba'},
        { name: 'Orlando Magic', abbreviation: 'ORL', league: 'nba'},
        { name: 'Philadelphia 76ers', abbreviation: 'PHI', league: 'nba'},
        { name: 'Phoenix Suns', abbreviation: 'PHX', league: 'nba'},
        { name: 'Portland Trail Blazers', abbreviation: 'POR', league: 'nba'},
        { name: 'Sacramento Kings', abbreviation: 'SAC', league: 'nba'},
        { name: 'San Antonio Spurs', abbreviation: 'SAN', league: 'nba'},
        { name: 'Toronto Raptors', abbreviation: 'TOR', league: 'nba'},
        { name: 'Utah Jazz', abbreviation: 'UTA', league: 'nba'},
        { name: 'Washington Wizards', abbreviation: 'WSH', league: 'nba'},
    
        { name: 'América', abbreviation: 'AME', league: 'ligamx'},
        { name: 'Atlas', abbreviation: 'ATS', league: 'ligamx'},
        { name: 'Atlético', abbreviation: 'AUR', league: 'ligamx'},	
        { name: 'Cruz Azul', abbreviation: 'CAZ', league: 'ligamx'},
        { name: 'Guadalajara', abbreviation: 'CHI', league: 'ligamx'},
        { name: 'FC Juárez', abbreviation: 'JUA', league: 'ligamx'},
        { name: 'León', abbreviation: 'LEO', league: 'ligamx'},
        { name: 'Mazatlán', abbreviation: 'MAZ', league: 'ligamx'},
        { name: 'Monterrey', abbreviation: 'MON', league: 'ligamx'},
        { name: 'Morelia', abbreviation: 'MOR', league: 'ligamx'},
        { name: 'Necaxa', abbreviation: 'NEC', league: 'ligamx'},
        { name: 'Pachuca', abbreviation: 'PAC', league: 'ligamx'},
        { name: 'Puebla', abbreviation: 'PUE', league: 'ligamx'},
        { name: 'Querétaro', abbreviation: 'QRO', league: 'ligamx'}, 
        { name: 'Santos', abbreviation: 'SAN', league: 'ligamx'},
        { name: 'Tijuana', abbreviation: 'TIJ', league: 'ligamx'},
        { name: 'Toluca', abbreviation: 'TOL', league: 'ligamx'},
        { name: 'UANL', abbreviation: 'UAN', league: 'ligamx'},
        { name: 'UNAM', abbreviation: 'PUM', league: 'ligamx'},
        { name: 'Veracruz', abbreviation: 'VER', league: 'ligamx'},

        { name: 'Ajax', abbreviation: 'AJA', league: 'uefa'},
        { name: 'Atalanta',  abbreviation: 'ATA', league: 'uefa'},
        { name: 'Atlético Madrid', abbreviation: 'ATM', league: 'uefa'},
        { name: 'Barcelona', abbreviation: 'BAR', league: 'uefa'},
        { name: 'Başakşehir', abbreviation: 'BKS', league: 'uefa'},
        { name: 'Bayern Munich', abbreviation: 'MUN', league: 'uefa'},
        { name: 'Beşiktaş', abbreviation: "BES", league: 'uefa'},
        { name: 'Benfica', abbreviation: 'BEN', league: 'uefa'},
        { name: 'Celtic', abbreviation: 'CEL', league: 'uefa'},
        { name: 'Chelsea', abbreviation: 'CHE', league: 'uefa'},
        { name: 'Club Brugge', abbreviation: 'BRU', league: 'uefa'},
        { name: 'Dinamo Zagreb', abbreviation: 'DZA', league: 'uefa'},
        { name: 'Dortmund', abbreviation: 'DOR', league: 'uefa'},
        { name: 'Dynamo Kyiv', abbreviation: 'KYV', league: 'uefa'},
        { name: 'Eint Frankfurt', abbreviation: 'SGE', league: 'uefa'},
        { name: 'FC Copenhagen', abbreviation: 'COP', league: 'uefa'},
        { name: 'Ferencváros', abbreviation: 'FER', league: 'uefa'},
        { name: 'Galatasaray', abbreviation: 'GAL', league: 'uefa'},
        { name: 'Genk', abbreviation: 'GNK', league: 'uefa'},
        { name: 'Inter', abbreviation: 'INT', league: 'uefa'},
        { name: 'Juventus', abbreviation: 'JUV', league: 'uefa'},
        { name: 'Krasnodar', abbreviation: 'KRA', league: 'uefa'},
        { name: 'Lazio', abbreviation: 'LAZ', league: 'uefa'},
        { name: 'Leverkusen', abbreviation: 'LEV', league: 'uefa'},
        { name: 'Lille', abbreviation: 'LIL', league: 'uefa'},
        { name: 'Liverpool', abbreviation: 'LIV', league: 'uefa'},
        { name: 'Loko Moscow', abbreviation: 'LMO', league: 'uefa'},
        { name: 'Lyon', abbreviation: 'LYO', league: 'uefa'},
        { name: "M'Gladbach", abbreviation: 'BMG', league: 'uefa'},
        { name: 'Maccabi Haifa', abbreviation: 'HAI', league: 'uefa'},
        { name: "Malmö", abbreviation: "MAL", league: 'uefa'},
        { name: 'Manchester City', abbreviation: 'MCI', league: 'uefa'},
        { name: 'Manchester Utd', abbreviation: 'MUN', league: 'uefa'},
        { name: 'Marseille', abbreviation: 'MAR', league: 'uefa'},
        { name: 'Midtjylland', abbreviation: 'MID', league: 'uefa'},
        { name: 'Milan', abbreviation: 'MIL', league: 'uefa'},
        { name: 'Napoli', abbreviation: 'NAP', league: 'uefa'},
        { name: 'Olympiacos', abbreviation: 'OLY', league: 'uefa'},
        { name: 'Paris S-G', abbreviation: 'PSG', league: 'uefa'},
        { name: 'Porto', abbreviation: 'POR', league: 'uefa'},
        { name: 'Rangers', abbreviation: 'QPR', league: 'uefa'},
        { name: 'RB Leipzig', abbreviation: 'RBL', league: 'uefa'},
        { name: 'RB Salzburg', abbreviation: 'RBS', league: 'uefa'},
        { name: 'Real Madrid', abbreviation: 'MAD', league: 'uefa'},
        { name: 'Rennes', abbreviation: 'REN', league: 'uefa'},
        { name: 'Red Star', abbreviation: 'RSB', league: 'uefa'},
        { name: 'Sevilla', abbreviation: 'SEV', league: 'uefa'},
        { name: 'Shakhtar', abbreviation: 'SHK', league: 'uefa'},
        { name: 'Sheriff Tiraspol', abbreviation: "SHF", league: 'uefa'},
        { name: 'Slavia Prague', abbreviation: 'SLP', league: 'uefa'},
        { name: 'Sporting CP', abbreviation: 'SCP', league: 'uefa' },
        { name: 'Tottenham', abbreviation: 'TOT', league: 'uefa'},
        { name: 'Valencia', abbreviation: 'VAL', league: 'uefa'},
        { name: 'Viktoria Plzeň', abbreviation: 'VIK', league: 'uefa'},
        { name: "Villarreal", abbreviation: 'VIL', league: 'uefa'},
        { name: "Wolfsburg", abbreviation: 'WOB', league: 'uefa'},
        { name: 'Young Boys', abbreviation: 'YGB', league: 'uefa'},
        { name: 'Zenit', abbreviation: 'ZEN', league: 'uefa'},
        

        { name: 'Arizona Cardinals', abbreviation: 'ARI', league: 'nfl'},
        { name: 'Atlanta Falcons', abbreviation: 'ATL', league: 'nfl'},
        { name: 'Baltimore Ravens', abbreviation: 'BAL', league: 'nfl'},
        { name: 'Buffalo Bills', abbreviation: 'BUF', league: 'nfl'},
        { name: 'Carolina Panthers', abbreviation: 'CAR', league: 'nfl'},
        { name: 'Chicago Bears', abbreviation: 'CHI', league: 'nfl'},
        { name: 'Cincinnati Bengals', abbreviation: 'CIN', league: 'nfl'},
        { name: 'Cleveland Browns', abbreviation: 'CLE', league: 'nfl'},
        { name: 'Dallas Cowboys', abbreviation: 'DAL', league: 'nfl'},
        { name: 'Denver Broncos', abbreviation: 'DEN', league: 'nfl'},
        { name: 'Detroit Lions', abbreviation: 'DET', league: 'nfl'},
        { name: 'Green Bay Packers', abbreviation: 'GB', league: 'nfl'},
        { name: 'Houston Texans', abbreviation: 'HOU', league: 'nfl'},
        { name: 'Indianapolis Colts', abbreviation: 'IND', league: 'nfl'},
        { name: 'Jacksonville Jaguars', abbreviation: 'JAX', league: 'nfl'},
        { name: 'Kansas City Chiefs', abbreviation: 'KC', league: 'nfl'},
        { name: 'Las Vegas Raiders', abbreviation: 'LV', league: 'nfl'},
        { name: 'Los Angeles Chargers', abbreviation: 'LAC', league: 'nfl'},
        { name: 'Los Angeles Rams', abbreviation: 'LAR', league: 'nfl'},
        { name: 'Miami Dolphins', abbreviation: 'MIA', league: 'nfl'},
        { name: 'Minnesota Vikings', abbreviation: 'MIN', league: 'nfl'},
        { name: 'New England Patriots', abbreviation: 'NE', league: 'nfl'},
        { name: 'New Orleans Saints', abbreviation: 'NO', league: 'nfl'},
        { name: 'New York Giants', abbreviation: 'NYG', league: 'nfl'},
        { name: 'New York Jets', abbreviation: 'NYJ', league: 'nfl'},
        { name: 'Philadelphia Eagles', abbreviation: 'PHI', league: 'nfl'},
        { name: 'Pittsburgh Steelers', abbreviation: 'PIT', league: 'nfl'},
        { name: 'San Francisco 49ers', abbreviation: 'SF', league: 'nfl'},
        { name: 'Seattle Seahawks', abbreviation: 'SEA', league: 'nfl'},
        { name: 'Tampa Bay Buccaneers', abbreviation: 'TB', league: 'nfl'},
        { name: 'Tennessee Titans', abbreviation: 'TEN', league: 'nfl'},
        { name: 'Washington Commanders', abbreviation: 'WAS', league: 'nfl'},

        { name: 'Abilene Christian', abbreviation: 'ABC', league: 'cfb'},
      { name: 'Air Force', abbreviation: 'AF', league: 'cfb'},
      { name: 'Akron', abbreviation: 'AKR', league: 'cfb'},
      { name: 'Alabama', abbreviation: 'ALB', league: 'cfb'},
      { name: 'Alabama State', abbreviation: 'ABS', league: 'cfb'},
      { name: 'Alabama-Birmingham', abbreviation: 'ABH', league: 'cfb'},
      { name: 'Albany', abbreviation: 'ABY', league: 'cfb'},
      { name: 'Alcorn State', abbreviation: 'ALS', league: 'cfb'},
      { name: 'Appalachian State', abbreviation: 'APP', league: 'cfb'},
      { name: 'Arizona', abbreviation: 'ARZ', league: 'cfb'},
      { name: 'Arizona State', abbreviation: 'ASU', league: 'cfb'},
      { name: 'Arkansas', abbreviation: 'ARK', league: 'cfb'},
      { name: 'Arkansas State', abbreviation: 'AKS', league: 'cfb'},
      { name: 'Arkansas-Pine Bluff', abbreviation: 'AKB', league: 'cfb'},
      { name: 'Army', abbreviation: 'ARM', league: 'cfb'},
      { name: 'Auburn', abbreviation: 'AUB', league: 'cfb'},
      { name: 'Ball State', abbreviation: 'BAL', league: 'cfb'},
      { name: 'Baylor', abbreviation: 'BAY', league: 'cfb'},
      { name: 'Bethune-Cookman', abbreviation: 'BET', league: 'cfb'},
      { name: 'Boise State', abbreviation: 'BS', league: 'cfb'},
      { name: 'Boston College', abbreviation: 'BC', league: 'cfb'},
      { name: 'Bowling Green State', abbreviation: 'BOW', league: 'cfb'},
      { name: 'Brigham Young', abbreviation: 'BYU', league: 'cfb'},
      { name: 'Bucknell', abbreviation: 'BUK', league: 'cfb'},
      { name: 'Buffalo', abbreviation: 'BUF', league: 'cfb'},
      { name: 'Cal Poly', abbreviation: 'CALP', league: 'cfb'},
      { name: 'California', abbreviation: 'CAL', league: 'cfb'},
      { name: 'California-Davis', abbreviation: 'CALD', league: 'cfb'},
      { name: 'Campbell', abbreviation: 'CMP', league: 'cfb'},
      { name: 'Central Arkansas', abbreviation: 'CAK', league: 'cfb'},
      { name: 'Central Connecticut State', abbreviation: 'CCS', league: 'cfb'},
      { name: 'Central Florida', abbreviation: 'CFU', league: 'cfb'},
      { name: 'Central Michigan', abbreviation: 'CMI', league: 'cfb'},
      { name: 'Charleston Southern', abbreviation: 'CSO', league: 'cfb'},
      { name: 'Charlotte', abbreviation: 'CHAR', league: 'cfb'},
      { name: 'Chattanooga', abbreviation: 'CHAT', league: 'cfb'},
      { name: 'Cincinnati', abbreviation: 'CIN', league: 'cfb'},
      { name: 'Citadel', abbreviation: 'CIT', league: 'cfb'},
      { name: 'Clemson', abbreviation: 'CLE', league: 'cfb'},
      { name: 'Coastal Carolina', abbreviation: 'CCU', league: 'cfb'},
      { name: 'Colgate', abbreviation: 'CGT', league: 'cfb'},
      { name: 'Colorado', abbreviation: 'COL', league: 'cfb'},
      { name: 'Colorado State', abbreviation: 'COS', league: 'cfb'},
      { name: 'Connecticut', abbreviation: 'UCON', league: 'cfb'},
      { name: 'Delaware', abbreviation: 'DEL', league: 'cfb'},
      { name: 'Duke', abbreviation: 'DUK', league: 'cfb'},
      { name: 'East Carolina', abbreviation: 'ECAR', league: 'cfb'},
      { name: 'East Tennessee State', abbreviation: 'ETEN', league: 'cfb'},
      { name: 'Eastern Illinois', abbreviation: 'EIL', league: 'cfb'},
      { name: 'Eastern Kentucky', abbreviation: 'EKEN', league: 'cfb'},
      { name: 'Eastern Michigan', abbreviation: 'EMI', league: 'cfb'},
      { name: 'Eastern Washington', abbreviation: 'EWA', league: 'cfb'},
      { name: 'Elon', abbreviation: 'ELO', league: 'cfb'},
      { name: 'Florida', abbreviation: 'FL', league: 'cfb'},
      { name: 'Florida A&M', abbreviation: 'FAM', league: 'cfb'},
      { name: 'Florida Atlantic', abbreviation: 'FAU', league: 'cfb'},
      { name: 'Florida International', abbreviation: 'FIU', league: 'cfb'},
      { name: 'Florida State', abbreviation: 'FSU', league: 'cfb'},
      { name: 'Fordham', abbreviation: 'FORD', league: 'cfb'},
      { name: 'Fresno State', abbreviation: 'FRES', league: 'cfb'},
      { name: 'Furman', abbreviation: 'FUR', league: 'cfb'},
      { name: 'Gardner-Webb', abbreviation: 'GAW', league: 'cfb'},
      { name: 'Georgia', abbreviation: 'GA', league: 'cfb'},
      { name: 'Georgia Southern', abbreviation: 'GAS', league: 'cfb'},
      { name: 'Georgia State', abbreviation: 'GSU', league: 'cfb'},
      { name: 'Georgia Tech', abbreviation: 'GTECH', league: 'cfb'},
      { name: 'Grambling State', abbreviation: 'GRAM', league: 'cfb'},
      { name: 'Hampton', abbreviation: 'HAMP', league: 'cfb'},
      { name: 'Hawaii', abbreviation: 'HA', league: 'cfb'},
      { name: 'Holy Cross', abbreviation: 'HC', league: 'cfb'},
      { name: 'Houston', abbreviation: 'HOU', league: 'cfb'},
      { name: 'Houston Baptist', abbreviation: 'HOUB', league: 'cfb'},
      { name: 'Howard', abbreviation: 'HOW', league: 'cfb'},
      { name: 'Idaho', abbreviation: 'IDA', league: 'cfb'},
      { name: 'Idaho State', abbreviation: 'IDS', league: 'cfb'},
      { name: 'Illinois', abbreviation: 'ILL', league: 'cfb'},
      { name: 'Illinois State', abbreviation: 'ILS', league: 'cfb'},
      { name: 'Incarnate Word', abbreviation: 'IWU', league: 'cfb'},
      { name: 'Indiana', abbreviation: 'IND', league: 'cfb'},
      { name: 'Indiana State', abbreviation: 'INS', league: 'cfb'},
      { name: 'Iowa', abbreviation: 'IOW', league: 'cfb'},
      { name: 'Iowa State', abbreviation: 'ISU', league: 'cfb'},
      { name: 'Jackson State', abbreviation: 'JACK', league: 'cfb'},
      { name: 'James Madison', abbreviation: 'JM', league: 'cfb'},
      { name: 'Kansas', abbreviation: 'KAS', league: 'cfb'},
      { name: 'Kansas State', abbreviation: 'KS', league: 'cfb'},
      { name: 'Kennesaw State', abbreviation: 'KENN', league: 'cfb'},
      { name: 'Kent State', abbreviation: 'KENT', league: 'cfb'},
      { name: 'Kentucky', abbreviation: 'KY', league: 'cfb'},
      { name: 'Lamar', abbreviation: 'LAM', league: 'cfb'},
      { name: 'Liberty', abbreviation: 'LIB', league: 'cfb'},
      { name: 'Louisiana', abbreviation: 'LOU', league: 'cfb'},
      { name: 'Louisiana State', abbreviation: 'LSU', league: 'cfb'},
      { name: 'Louisiana Tech', abbreviation: 'LTECH', league: 'cfb'},
      { name: 'Louisiana-Monroe', abbreviation: 'LMON', league: 'cfb'},
      { name: 'Louisville', abbreviation: 'LOUI', league: 'cfb'},
      { name: 'Maine', abbreviation: 'ME', league: 'cfb'},
      { name: 'Marshall', abbreviation: 'MARSH', league: 'cfb'},
      { name: 'Maryland', abbreviation: 'MD', league: 'cfb'},
      { name: 'Massachusetts', abbreviation: 'MASS', league: 'cfb'},
      { name: 'McNeese State', abbreviation: 'MNS', league: 'cfb'},
      { name: 'Memphis', abbreviation: 'MEM', league: 'cfb'},
      { name: 'Mercer', abbreviation: 'MER', league: 'cfb'},
      { name: 'Miami (FL)', abbreviation: 'MIA', league: 'cfb'},
      { name: 'Miami (OH)', abbreviation: 'MIAOH', league: 'cfb'},
      { name: 'Michigan', abbreviation: 'MICH', league: 'cfb'},
      { name: 'Michigan State', abbreviation: 'MSU', league: 'cfb'},
      { name: 'Middle Tennessee State', abbreviation: 'MTEN', league: 'cfb'},
      { name: 'Minnesota', abbreviation: 'MINN', league: 'cfb'},
      { name: 'Mississippi', abbreviation: 'MS', league: 'cfb'},
      { name: 'Mississippi State', abbreviation: 'MSS', league: 'cfb'},
      { name: 'Missouri', abbreviation: 'MO', league: 'cfb'},
      { name: 'Missouri State', abbreviation: 'MOS', league: 'cfb'},
      { name: 'Monmouth', abbreviation: 'MON', league: 'cfb'},
      { name: 'Montana', abbreviation: 'MT', league: 'cfb'},
      { name: 'Montana State', abbreviation: 'MNTS', league: 'cfb'},
      { name: 'Morgan State', abbreviation: 'MORG', league: 'cfb'},
      { name: 'Murray State', abbreviation: 'MUR', league: 'cfb'},
      { name: 'Navy', abbreviation: 'NAV', league: 'cfb'},
      { name: 'Nebraska', abbreviation: 'NEB', league: 'cfb'},
      { name: 'Nevada', abbreviation: 'NV', league: 'cfb'},
      { name: 'Nevada-Las Vegas', abbreviation: 'NVLV', league: 'cfb'},
      { name: 'New Hampshire', abbreviation: 'NH', league: 'cfb'},
      { name: 'New Mexico', abbreviation: 'NM', league: 'cfb'},
      { name: 'New Mexico State', abbreviation: 'NMS', league: 'cfb'},
      { name: 'Nicholls State', abbreviation: 'NIC', league: 'cfb'},
      { name: 'Norfolk State', abbreviation: 'NOR', league: 'cfb'},
      { name: 'North Carolina', abbreviation: 'UNC', league: 'cfb'},
      { name: 'North Carolina A&T', abbreviation: 'NCAT', league: 'cfb'},
      { name: 'North Carolina State', abbreviation: 'NCS', league: 'cfb'},
      { name: 'North Texas', abbreviation: 'NT', league: 'cfb'},
      { name: 'Northern Arizona', abbreviation: 'NA', league: 'cfb'},
      { name: 'Northern Colorado', abbreviation: 'NC', league: 'cfb'},
      { name: 'Northern Illinois', abbreviation: 'NIL', league: 'cfb'},
      { name: 'Northern Iowa', abbreviation: 'NIO', league: 'cfb'},
      { name: 'Northwestern', abbreviation: 'NW', league: 'cfb'},
      { name: 'Northwestern State', abbreviation: 'NWS', league: 'cfb'},
      { name: 'Notre Dame', abbreviation: 'ND', league: 'cfb'},
      { name: 'Ohio', abbreviation: 'OH', league: 'cfb'},
      { name: 'Ohio State', abbreviation: 'OSU', league: 'cfb'},
      { name: 'Oklahoma', abbreviation: 'OKL', league: 'cfb'},
      { name: 'Oklahoma State', abbreviation: 'OSU', league: 'cfb'},
      { name: 'Old Dominion', abbreviation: 'DOM', league: 'cfb'},
      { name: 'Oregon', abbreviation: 'ORE', league: 'cfb'},
      { name: 'Oregon State', abbreviation: 'ORS', league: 'cfb'},
      { name: 'Penn State', abbreviation: 'PENN', league: 'cfb'},
      { name: 'Pittsburgh', abbreviation: 'PITT', league: 'cfb'},
      { name: 'Portland State', abbreviation: 'PORT', league: 'cfb'},
      { name: 'Prairie View A&M', abbreviation: 'PVAM', league: 'cfb'},
      { name: 'Purdue', abbreviation: 'PRD', league: 'cfb'},
      { name: 'Rhode Island', abbreviation: 'RI', league: 'cfb'},
      { name: 'Rice', abbreviation: 'RICE', league: 'cfb'},
      { name: 'Richmond', abbreviation: 'RICH', league: 'cfb'},
      { name: 'Robert Morris', abbreviation: 'RM', league: 'cfb'},
      { name: 'Rutgers', abbreviation: 'RUT', league: 'cfb'},
      { name: 'Sacramento State', abbreviation: 'SAC', league: 'cfb'},
      { name: 'Sam Houston State', abbreviation: 'SHS', league: 'cfb'},
      { name: 'Samford', abbreviation: 'SAM', league: 'cfb'},
      { name: 'San Diego State', abbreviation: 'SDS', league: 'cfb'},
      { name: 'San Jose State', abbreviation: 'SJS', league: 'cfb'},
      { name: 'South Alabama', abbreviation: 'SA', league: 'cfb'},
      { name: 'South Carolina', abbreviation: 'SC', league: 'cfb'},
      { name: 'South Carolina State', abbreviation: 'SCS', league: 'cfb'},
      { name: 'South Dakota', abbreviation: 'SD', league: 'cfb'},
      { name: 'South Dakota State', abbreviation: 'SDS', league: 'cfb'},
      { name: 'South Florida', abbreviation: 'SFU', league: 'cfb'},
      { name: 'Southeast Missouri State', abbreviation: 'SMS', league: 'cfb'},
      { name: 'Southeastern Louisiana', abbreviation: 'SLU', league: 'cfb'},
      { name: 'Southern', abbreviation: 'SOU', league: 'cfb'},
      { name: 'Southern California', abbreviation: 'USC', league: 'cfb'},
      { name: 'Southern Illinois', abbreviation: 'SIL', league: 'cfb'},
      { name: 'Southern Methodist', abbreviation: 'SMU', league: 'cfb'},
      { name: 'Southern Mississippi', abbreviation: 'SMIS', league: 'cfb'},
      { name: 'Southern Utah', abbreviation: 'SU', league: 'cfb'},
      { name: 'Stanford', abbreviation: 'STAN', league: 'cfb'},
      { name: 'Stephen F. Austin', abbreviation: 'SFA', league: 'cfb'},
      { name: 'Stony Brook', abbreviation: 'SB', league: 'cfb'},
      { name: 'Syracuse', abbreviation: 'SYR', league: 'cfb'},
      { name: 'Temple', abbreviation: 'TEM', league: 'cfb'},
      { name: 'Tennessee', abbreviation: 'TENN', league: 'cfb'},
      { name: 'Tennessee State', abbreviation: 'TENS', league: 'cfb'},
      { name: 'Tennessee Tech', abbreviation: 'TENT', league: 'cfb'},
      { name: 'Tennessee-Martin', abbreviation: 'TENM', league: 'cfb'},
      { name: 'Texas', abbreviation: 'UT', league: 'cfb'},
      { name: 'Texas A&M', abbreviation: 'TA&M', league: 'cfb'},
      { name: 'Texas Christian', abbreviation: 'TCU', league: 'cfb'},
      { name: 'Texas Southern', abbreviation: 'TSO', league: 'cfb'},
      { name: 'Texas State', abbreviation: 'TXS', league: 'cfb'},
      { name: 'Texas Tech', abbreviation: 'TT', league: 'cfb'},
      { name: 'Texas-El Paso', abbreviation: 'UTEP', league: 'cfb'},
      { name: 'Texas-San Antonio', abbreviation: 'UTSA', league: 'cfb'},
      { name: 'Toledo', abbreviation: 'TOL', league: 'cfb'},
      { name: 'Towson', abbreviation: 'TOW', league: 'cfb'},
      { name: 'Troy', abbreviation: 'TROY', league: 'cfb'},
      { name: 'Tulane', abbreviation: 'TUL', league: 'cfb'},
      { name: 'Tulsa', abbreviation: 'TULS', league: 'cfb'},
      { name: 'UCLA', abbreviation: 'UCLA', league: 'cfb'},
      { name: 'Utah', abbreviation: 'UTAH', league: 'cfb'},
      { name: 'Utah State', abbreviation: 'UTS', league: 'cfb'},
      { name: 'Vanderbilt', abbreviation: 'VAN', league: 'cfb'},
      { name: 'Virginia', abbreviation: 'VIR', league: 'cfb'},
      { name: 'Virginia Military Institute', abbreviation: 'VIMI', league: 'cfb'},
      { name: 'Virginia Tech', abbreviation: 'VTEC', league: 'cfb'},
      { name: 'Wagner', abbreviation: 'WAG', league: 'cfb'},
      { name: 'Wake Forest', abbreviation: 'WF', league: 'cfb'},
      { name: 'Washington', abbreviation: 'WAS', league: 'cfb'},
      { name: 'Washington State', abbreviation: 'WS', league: 'cfb'},
      { name: 'Weber State', abbreviation: 'WEB', league: 'cfb'},
      { name: 'West Virginia', abbreviation: 'WVU', league: 'cfb'},
      { name: 'Western Carolina', abbreviation: 'WCAR', league: 'cfb'},
      { name: 'Western Illinois', abbreviation: 'WILL', league: 'cfb'},
      { name: 'Western Kentucky', abbreviation: 'WKY', league: 'cfb'},
      { name: 'Western Michigan', abbreviation: 'WMI', league: 'cfb'},
      { name: 'William & Mary', abbreviation: 'W&M', league: 'cfb'},
      { name: 'Wisconsin', abbreviation: 'WIS', league: 'cfb'},
      { name: 'Wofford', abbreviation: 'WOF', league: 'cfb'},
      { name: 'Wyoming', abbreviation: 'WY', league: 'cfb'},

      { name: "Arminia", abbreviation: 'BIE', league: 'bundesliga'},
      { name: "Augsburg", abbreviation: 'AUG', league: 'bundesliga'},
      { name: "Bayern Munich", abbreviation: 'BAY', league: 'bundesliga'},
      { name: "Bochum", abbreviation: 'BOC', league: 'bundesliga'},
      { name: "Dortmund", abbreviation: 'DOR', league: 'bundesliga'},
      { name: "Eint Frankfurt", abbreviation: 'SGE', league: 'bundesliga'},
      { name: "Freiburg", abbreviation: 'SCF', league: 'bundesliga'},
      { name: "Greuther Fürth", abbreviation: 'GFU', league: 'bundesliga'},
      { name: "Hertha BSC", abbreviation: 'BSC', league: 'bundesliga'},
      { name: "Hoffenheim", abbreviation: 'TSG', league: 'bundesliga'},
      { name: "Köln", abbreviation: 'CGN', league: 'bundesliga'},
      { name: "Leverkusen", abbreviation: 'B04', league: 'bundesliga'},
      { name: "M'Gladbach", abbreviation: 'BMG', league: 'bundesliga'},
      { name: "Mainz 05", abbreviation: 'MAI', league: 'bundesliga'},
      { name: "RB Leipzig", abbreviation: 'RBL', league: 'bundesliga'},
      { name: "Schalke 04", abbreviation: 'S04', league: 'bundesliga'},
      { name: "Stuttgart", abbreviation: 'STU', league: 'bundesliga'},
      { name: "Union Berlin", abbreviation: 'UNB', league: 'bundesliga'},
      { name: "Werder Bremen", abbreviation: 'SVW', league: 'bundesliga'},
      { name: "Wolfsburg", abbreviation: 'WOB', league: 'bundesliga'},

      { name: "Atalanta", abbreviation: 'ATT', league: 'seriea'},
      { name: "Benevento", abbreviation: 'BEN', league: 'seriea'},
      { name: "Bologna", abbreviation: 'BGN', league: 'seriea'},
      { name: "Cagliari", abbreviation: 'CAG', league: 'seriea'},
      { name: "Cremonese", abbreviation: 'CRE', league: 'seriea'},
      { name: "Crotone", abbreviation: 'CRO', league: 'seriea'},
      { name: "Empoli", abbreviation: 'EMP', league: 'seriea'},
      { name: "Fiorentina", abbreviation: 'FIO', league: 'seriea'},
      { name: "Genoa", abbreviation: 'GEN', league: 'seriea'},
      { name: "Hellas Verona", abbreviation: 'HEL', league: 'seriea'},
      { name: "Inter", abbreviation: 'INT', league: 'seriea'},
      { name: "Juventus", abbreviation: 'JUV', league: 'seriea'},
      { name: "Lazio", abbreviation: 'LAZ', league: 'seriea'},
      { name: "Lecce", abbreviation: 'LEC', league: 'seriea'},
      { name: "Milan", abbreviation: 'MIL', league: 'seriea'},
      { name: "Monza", abbreviation: 'MZA', league: 'seriea'},
      { name: "Napoli", abbreviation: 'NAP', league: 'seriea'},
      { name: "Parma", abbreviation: 'PRM', league: 'seriea'},
      { name: "Roma", abbreviation: 'ROM', league: 'seriea'},
      { name: "Salernitana", abbreviation: 'SAL', league: 'seriea'},
      { name: "Sampdoria", abbreviation: 'SAM', league: 'seriea'},
      { name: "Sassuolo", abbreviation: 'SAS', league: 'seriea'},
      { name: "Spezia", abbreviation: 'SPZ', league: 'seriea'},
      { name: "Torino", abbreviation: 'TOR', league: 'seriea'},
      { name: "Udinese", abbreviation: 'UDI', league: 'seriea'},
      { name: "Venezia", abbreviation: 'VNZ', league: 'seriea'},

      { name: 'Anaheim Ducks', abbreviation: 'ANA', league: 'nhl'},
      { name: 'Arizona Coyotes', abbreviation: 'ARI', league: 'nhl'},
      { name: 'Boston Bruins', abbreviation: 'BOS', league: 'nhl'},
      { name: 'Buffalo Sabres', abbreviation: 'BUF', league: 'nhl'},
      { name: 'Calgary Flames', abbreviation: 'CGY', league: 'nhl'},
      { name: 'Carolina Hurricanes', abbreviation: 'CAR', league: 'nhl'},
      { name: 'Chicago Blackhawks', abbreviation: 'CHI', league: 'nhl'},
      { name: 'Colorado Avalanche', abbreviation: 'COL', league: 'nhl'},
      { name: 'Columbus Blue Jackets', abbreviation: 'CBJ', league: 'nhl'},
      { name: 'Dallas Stars', abbreviation: 'DAL', league: 'nhl'},
      { name: 'Detroit Red Wings', abbreviation: 'DET', league: 'nhl'},
      { name: 'Edmonton Oilers', abbreviation: 'EDM', league: 'nhl'},
      { name: 'Florida Panthers', abbreviation: 'FLA', league: 'nhl'},
      { name: 'Los Angeles Kings', abbreviation: 'LAK', league: 'nhl'},
      { name: 'Minnesota Wild', abbreviation: 'MIN', league: 'nhl'},
      { name: 'Montreal Canadiens', abbreviation: 'MTL', league: 'nhl'},
      { name: 'Nashville Predators', abbreviation: 'NSH', league: 'nhl'},
      { name: 'New Jersey Devils', abbreviation: 'NJD', league: 'nhl'},
      { name: 'New York Islanders', abbreviation: 'NYI', league: 'nhl'},
      { name: 'New York Rangers', abbreviation: 'NYR', league: 'nhl'},
      { name: 'Ottawa Senators', abbreviation: 'OTT', league: 'nhl'},
      { name: 'Philadelphia Flyers', abbreviation: 'PHI', league: 'nhl'},
      { name: 'Pittsburgh Penguins', abbreviation: 'PIT', league: 'nhl'},
      { name: 'San Jose Sharks', abbreviation: 'SJS', league: 'nhl'},
      { name: 'Seattle Kraken', abbreviation: 'SEA', league: 'nhl'},
      { name: 'St. Louis Blues', abbreviation: 'STL', league: 'nhl'},
      { name: 'Tampa Bay Lightning', abbreviation: 'TBL', league: 'nhl'},
      { name: 'Toronto Maple Leafs', abbreviation: 'TOR', league: 'nhl'},
      { name: 'Vancouver Canucks', abbreviation: 'VAN', league: 'nhl'},
      { name: 'Vegas Golden Knights', abbreviation: 'VGK', league: 'nhl'},
      { name: 'Washington Capitals', abbreviation: 'WSH', league: 'nhl'},
      { name: 'Winnipeg Jets', abbreviation: 'WIN', league: 'nhl'},

      { name: "Arizona D'Backs", abbreviation: 'ARI', league: 'mlb'},
      { name: "Atlanta Braves", abbreviation: 'ATL', league: 'mlb'},
      { name: "Baltimore Orioles", abbreviation: 'BAL', league: 'mlb'},
      { name: "Boston Red Sox", abbreviation: 'BOS', league: 'mlb'},
      { name: "Chicago Cubs", abbreviation: 'CHC', league: 'mlb'},
      { name: "Chicago White Sox", abbreviation: 'CWS', league: 'mlb'},
      { name: "Cincinnati Reds", abbreviation: 'CIN', league: 'mlb'},
      { name: "Cleveland Guardians", abbreviation: 'CLE', league: 'mlb'},
      { name: "Colorado Rockies", abbreviation: 'COL', league: 'mlb'},
      { name: "Detroit Tigers", abbreviation: 'DET', league: 'mlb'},
      { name: "Houston Astros", abbreviation: 'HOU', league: 'mlb'},
      { name: "Kansas City Royals", abbreviation: 'KC', league: 'mlb'},
      { name: "Los Angeles Angels", abbreviation: 'LAA', league: 'mlb'},
      { name: "Los Angeles Dodgers", abbreviation: 'LAD', league: 'mlb'},
      { name: "Miami Marlins", abbreviation: 'MIA', league: 'mlb'},
      { name: "Milwaukee Brewers", abbreviation: 'MIL', league: 'mlb'},
      { name: "Minnesota Twins", abbreviation: 'MIN', league: 'mlb'},
      { name: "New York Mets", abbreviation: 'NYM', league: 'mlb'},
      { name: "New York Yankees", abbreviation: 'NYY', league: 'mlb'},
      { name: "Oakland Athletics", abbreviation: 'OAK', league: 'mlb'},
      { name: "Philadelphia Phillies", abbreviation: 'PHI', league: 'mlb'},
      { name: "Pittsburgh Pirates", abbreviation: 'PIT', league: 'mlb'},
      { name: "San Diego Padres", abbreviation: 'SD', league: 'mlb'},
      { name: "San Francisco Giants", abbreviation: 'SF', league: 'mlb'},
      { name: "Seattle Mariners", abbreviation: 'SEA', league: 'mlb'},
      { name: "St.Louis Cardinals", abbreviation: 'STL', league: 'mlb'},
      { name: "Tampa Bay Rays", abbreviation: 'TB', league: 'mlb'},
      { name: "Texas Rangers", abbreviation: 'TEX', league: 'mlb'},
      { name: "Toronto Blue Jays", abbreviation: 'TOR', league: 'mlb'},
      { name: "Washington Nationals", abbreviation: 'WSH', league: 'mlb'},

      { name: "Alavés", abbreviation: 'ALV', league: 'laliga'},
      { name: "Almería", abbreviation: 'ALM', league: 'laliga'},
      { name: "Athletic Club", abbreviation: 'BIL', league: 'laliga'},
      { name: "Atlético Madrid", abbreviation: 'ATM', league: 'laliga'},
      { name: "Barcelona", abbreviation: 'BAR', league: 'laliga'},
      { name: "Betis", abbreviation: 'BET', league: 'laliga'},
      { name: "Cádiz", abbreviation: 'CAD', league: 'laliga'},
      { name: "Celta Vigo", abbreviation: 'CLV', league: 'laliga'},
      { name: "Eibar", abbreviation: 'EIB', league: 'laliga'},
      { name: "Elche", abbreviation: 'ELC', league: 'laliga'},
      { name: "Espanyol", abbreviation: 'ESP', league: 'laliga'},
      { name: "Getafe", abbreviation: 'GET', league: 'laliga'},
      { name: "Girona", abbreviation: 'GIR', league: 'laliga'},
      { name: "Granada", abbreviation: 'GRA', league: 'laliga'},
      { name: "Huesca", abbreviation: 'HUE', league: 'laliga'},
      { name: "Levante", abbreviation: 'LVT', league: 'laliga'},
      { name: "Mallorca", abbreviation: 'MAL', league: 'laliga'},
      { name: "Osasuna", abbreviation: 'OSA', league: 'laliga'},
      { name: "Rayo Vallecano", abbreviation: 'RAY', league: 'laliga'},
      { name: "Real Madrid", abbreviation: 'MAD', league: 'laliga'},
      { name: "Real Sociedad", abbreviation: 'SOC', league: 'laliga'},
      { name: "Sevilla", abbreviation: 'SEV', league: 'laliga'},
      { name: "Valencia", abbreviation: 'VAL', league: 'laliga'},
      { name: "Valladolid", abbreviation: 'REV', league: 'laliga'},
      
      { name: "Argentina", abbreviation: 'ARG', league: 'worldcup'},
      { name: "Australia", abbreviation: 'AUS', league: 'worldcup'},
      { name: "Belgium", abbreviation: 'BEL', league: 'worldcup'},
      { name: "Brazil", abbreviation: 'BRL', league: 'worldcup'},
      { name: "Cameroon", abbreviation: 'CAM', league: 'worldcup'},
      { name: "Canada", abbreviation: 'CAN', league: 'worldcup'},
      { name: "Costa Rica", abbreviation: 'CRA', league: 'worldcup'},
      { name: "Croatia", abbreviation: 'CRO', league: 'worldcup'},
      { name: "Denmark", abbreviation: 'DEN', league: 'worldcup'},
      { name: "Ecuador", abbreviation: 'ECU', league: 'worldcup'},
      { name: "England", abbreviation: 'ENG', league: 'worldcup'},
      { name: "France", abbreviation: 'FRA', league: 'worldcup'},
      { name: "Germany", abbreviation: 'GER', league: 'worldcup'},
      { name: "Ghana", abbreviation: 'GHA', league: 'worldcup'},
      { name: "IR Iran", abbreviation: 'IRN', league: 'worldcup'},
      { name: "Japan", abbreviation: 'JAP', league: 'worldcup'},
      { name: "Korea Republic", abbreviation: 'KOR', league: 'worldcup'},
      { name: "Mexico", abbreviation: 'MEX', league: 'worldcup'},
      { name: "Morocco", abbreviation: 'MOR', league: 'worldcup'},
      { name: "Netherlands", abbreviation: 'HOL', league: 'worldcup'},
      { name: "Poland", abbreviation: 'POL', league: 'worldcup'},
      { name: "Portugal", abbreviation: 'POR', league: 'worldcup'},
      { name: "Qatar", abbreviation: 'QTR', league: 'worldcup'},
      { name: "Saudi Arabia", abbreviation: 'SAU', league: 'worldcup'},
      { name: "Senegal", abbreviation: 'SEN', league: 'worldcup'},
      { name: "Serbia", abbreviation: 'SRB', league: 'worldcup'},
      { name: "Spain", abbreviation: 'SPA', league: 'worldcup'},
      { name: "Switzerland", abbreviation: 'SWI', league: 'worldcup'},
      { name: "Tunisia", abbreviation: 'TUN', league: 'worldcup'},
      { name: "United States", abbreviation: 'USA', league: 'worldcup'},
      { name: "Uruguay", abbreviation: 'URG', league: 'worldcup'},
      { name: "Wales", abbreviation: 'WAL', league: 'worldcup'},
      
      //  redeploy app
      ];

export default{ 
    abbreviations,
};