/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Route, MemoryRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

const breadcrumbNameMap = {
    '/home': 'Pick a Sport',
    '/profile': 'Profile',
    '/profile/viewprofile': 'View Profile',
    '/profile/editprofile': 'Edit Profile',
    '/profile/changepassword': 'Change Password',
    '/profile/multifactorauth': 'Multi-Factor Authentication',
    '/myaccount': 'My Account',
    '/myaccount/viewaccount': 'View Account',
    '/myaccount/addfunds': 'Add Funds',
    '/myaccount/withdraw': 'Withdraw Funds',
    '/invitefriend': 'Invite a Friend',
    '/unacceptedbets': 'Your Unaccepted Bets',
    '/groups': 'Groups',
    '/groups/yourgroups': 'Your Groups',
    '/groups/groupbets': 'Group Bets',
    '/groups/create': 'Create Group',
    '/groups/join': 'Join Group',
    '/withfriends': 'Bets With Friends',
    '/withfriends/newbetsreceived': 'New Bet Proposals',
    '/withfriends/openbets': 'Accepted Bets Yet to Play',
    '/withfriends/pastbets': 'Past Bets',
    '/publicbets': 'Public Bets',
    '/publicbets/allpostedpublic': 'Recently Proposed Bets',
    '/publicbets/yourlivepublic': 'Your Accepted Bets',
    '/publicbets/yourpastpublic': 'Your Past Bets',
    '/FAQ': 'FAQ',
    '/contact-us': 'Contact Us'
};

function ListItemLink(props) {
  const { to, open, ...other } = props;
  const primary = breadcrumbNameMap[to];

  return (
    <li>
      <ListItem button component={RouterLink} to={to} {...other}>
        <ListItemText primary={primary} />
        {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  open: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxwidth: 150,
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  logoColor: {
    color: "#0510e6",
    fontFamily: 'Anton',
    fontStyle: 'bold' && 'italic',
    marginLeft: theme.spacing(2),
  },
}));

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

export default function RouterBreadcrumbs() {
  const classes = useStyles();
  const [openProfile, setOpenProfile] = React.useState(false);
  const [openAccount, setOpenAccount] = React.useState(false);
  const [openFriends, setOpenFriends] = React.useState(false);
  const [openPublic, setOpenPublic] = React.useState(false);
  const [openGroups, setOpenGroups] = React.useState(false);

  const handleProfile = () => {
    setOpenProfile((prevOpenProfile) => !prevOpenProfile);
  };
  const handleAccount = () => {
    setOpenAccount((prevOpenAccount) => !prevOpenAccount);
  };
  const handleFriends = () => {
    setOpenFriends((prevOpenFriends) => !prevOpenFriends);
  };
  const handlePublic = () => {
    setOpenPublic((prevOpenPublic) => !prevOpenPublic);
  };
  const handleGroups = () => {
    setOpenGroups((prevOpenGroups) => !prevOpenGroups)
  }

  //  Functions to redirect pages
  const handlePickSport = () => {
      window.location.pathname = "/home"
  }
  const handleViewProfile = () => {
      window.location.pathname = "/myprofile"
  }
  const handleEditProfile = () => {
    window.location.pathname = "/editprofile"
  }
  const handleChangePassword = () => {
    window.location.pathname = '/changepassword'
  }
  const handleChangeMFA = () => {
    window.location.pathname = '/changemfa'
  }
  const handleViewAccountHistory = () => {
    window.location.pathname = '/accounthistory'
  }
  const handleDepositChoices = () => {
    window.location.pathname = '/depositchoice'
  }
  const handleWithdrawChoices = () => {
    window.location.pathname = '/withdrawchoice'
  }
  const handleInviteFriend = () => {
    window.location.pathname = '/invite_friend'
  }
  const handleUnacceptedBets = () => {
    window.location.pathname = '/unacceptedbets'
  }
  const handleFriendProposals = () => {
    window.location.pathname = '/friendbets'
  }
  const handleFriendsLiveBets = () =>{
    window.location.pathname = '/friendslivebets'
  }
  const handleFriendPastBets = () => {
    window.location.pathname = '/friendpastbets'
  }
  const handleAllPublicProposals = () => {
    window.location.pathname = '/recentpublicbets'
  }
  const handlePublicLiveBets = () => {
    window.location.pathname = '/publiclivebets'
  }
  const handlePublicPastBets = () => {
    window.location.pathname = '/publicpastbets'
  }
  const handleFAQ = () => {
    window.location.pathname = '/FAQlogged'
  }
  const handleContactUs = () => {
    window.location.pathname = '/contact-us'
  }
  const handleYourGroups = () => {
    window.location.pathname = '/your_groups'
  }
  const handleGroupBets = () => {
    window.location.pathname = '/group_bets'
  }
  const handleCreateGroup = () => {
    window.location.pathname = '/create_group'
  }
  const handleJoinGroup = () => {
    window.location.pathname = '/join_group'
  }

  return (
    <MemoryRouter initialEntries={['/inbox']} initialIndex={0}>
      <div className={classes.root}>
        <Route>
          {({ location }) => {
            const pathnames = location.pathname.split('/').filter((x) => x);

            return (
              <Breadcrumbs aria-label="breadcrumb">
                <LinkRouter className={classes.logoColor} to="/">
                   iBetU
                </LinkRouter>
                {pathnames.map((value, index) => {
                  const last = index === pathnames.length - 1;
                  const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                  return last ? (
                    <Typography color="textPrimary" key={to}>
                      {breadcrumbNameMap[to]}
                    </Typography>
                  ) : (
                    <LinkRouter color="inherit" to={to} key={to}>
                      {breadcrumbNameMap[to]}
                    </LinkRouter>
                  );
                })}
              </Breadcrumbs>
            );
          }}
        </Route>
        <nav className={classes.lists} aria-label="mailbox folders">
          <List>
            <ListItemLink to="/home" onClick={handlePickSport} />
            <ListItemLink to="/profile" open={openProfile} onClick={handleProfile} />
                <Collapse component="li" in={openProfile} timeout="auto" unmountOnExit>
                <List disablePadding>
                    <ListItemLink to="/profile/viewprofile" onClick={handleViewProfile} className={classes.nested} />
                    <ListItemLink to="/profile/editprofile" onClick={handleEditProfile} className={classes.nested} />
                    <ListItemLink to="/profile/changepassword" onClick={handleChangePassword} className={classes.nested} />
                    <ListItemLink to="/profile/multifactorauth" onClick={handleChangeMFA} className={classes.nested} />
                </List>
                </Collapse>
            <ListItemLink to="/myaccount" open={openAccount} onClick={handleAccount} />
                <Collapse component="li" in={openAccount} timeout="auto" unmountOnExit>
                <List disablePadding>
                    <ListItemLink to="/myaccount/viewaccount" className={classes.nested} onClick={handleViewAccountHistory}/>
                    <ListItemLink to="/myaccount/addfunds" className={classes.nested} onClick={handleDepositChoices}/>
                    <ListItemLink to="/myaccount/withdraw" className={classes.nested} onClick={handleWithdrawChoices}/>
                </List>
                </Collapse>
            <ListItemLink to="/invitefriend" onClick={handleInviteFriend} />
            <ListItemLink to="/unacceptedbets" onClick={handleUnacceptedBets} />
            <ListItemLink to="/groups" open={openGroups} onClick={handleGroups} />
                <Collapse component="li" in={openGroups} timeout="auto" unmountOnExit>
                <List disablePadding>
                    <ListItemLink to="/groups/yourgroups" onClick={handleYourGroups} className={classes.nested} />
                    <ListItemLink to="/groups/groupbets" onClick={handleGroupBets} className={classes.nested} />
                    <ListItemLink to="/groups/create" onClick={handleCreateGroup} className={classes.nested} />
                    <ListItemLink to="/groups/join" onClick={handleJoinGroup} className={classes.nested} />
                </List>
                </Collapse>
            <ListItemLink to="/withfriends" open={openFriends} onClick={handleFriends} />
                <Collapse component="li" in={openFriends} timeout="auto" unmountOnExit>
                <List disablePadding>
                    <ListItemLink to="/withfriends/newbetsreceived" className={classes.nested} onClick={handleFriendProposals}/>
                    <ListItemLink to="/withfriends/openbets" className={classes.nested} onClick={handleFriendsLiveBets}/>
                    <ListItemLink to="/withfriends/pastbets" className={classes.nested} onClick={handleFriendPastBets} />
                </List>
                </Collapse>
            <ListItemLink to="/publicbets" open={openPublic} onClick={handlePublic} />
                <Collapse component="li" in={openPublic} timeout="auto" unmountOnExit>
                <List disablePadding>
                    <ListItemLink to="/publicbets/allpostedpublic" className={classes.nested} onClick={handleAllPublicProposals} />
                    <ListItemLink to="/publicbets/yourlivepublic" className={classes.nested} onClick={handlePublicLiveBets}/>
                    <ListItemLink to="/publicbets/yourpastpublic" className={classes.nested} onClick={handlePublicPastBets} />
                </List>
                </Collapse>

            <ListItemLink to="/FAQ" onClick={handleFAQ}/>
            <ListItemLink to="/contact-us" onClick={handleContactUs} />
          </List>
        </nav>
      </div>
    </MemoryRouter>
  );
}
