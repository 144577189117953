import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import NumberFormat from 'react-number-format';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
//  import MuiPhoneNumber from 'material-ui-phone-number';


import axios from 'axios';


const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    formControl: {
        margin: 1,
        minWidth: 220,
        maxWidth: 220,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
      },
      answer: {
        fontSize: 14,
        fontFamily: 'Anton',
        color: 'grey',
        display: 'inline-block'
    },
    table: {
        minWidth: 220,
        maxWidth: 220,
    },
    tableHeader: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        color: 'grey',
    },
    tableHeaderNarrow: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        fixedHeader: false,
        width: 'auto',
        tableLayout: 'auto',
    },
    tableCell: {
        padding: 2,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        color: 'grey'
    },
    tableCellNarrow: {
        padding: 1,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        fixedHeader: false,
        width: 'auto',
        tableLayout: 'auto',
    },
});

let cushion = 0.01;

export default function DepositFromBank(props) {
    const classes = useStyles();
    const currentFundsAvailable = props.fundsAvailable;
    const currentFundsAtRisk = props.fundsAtRisk;

    let possibleMaxDeposit = 5000;

    const [ depositAmount, setDepositAmount ] = React.useState('');
    const [ depositRequestDate, setDepositRequestDate ] = React.useState('');
    const [ depositType, setDepositType ] = React.useState('dwolla');
    const [ feeEstimate, setFeeEstimate ] = React.useState('');
    const [ maxDeposit, setMaxDeposit ] = React.useState(possibleMaxDeposit);
    const [ fundsAvailable, setFundsAvailable ] = React.useState(currentFundsAvailable);
    const [ fundsAtRisk, setFundsAtRisk ] = React.useState(currentFundsAtRisk);
    const [ accountSelectedName, setAccountSelectedName ] = React.useState('');
    const [ accountSelectedURL, setAccountSelectedURL ] = React.useState('');
    const [ adjDepositAmount, setAdjDepositAmount ] = React.useState('');
    const [ rows, setRows ] = React.useState([]);
    const [ accountPicked, setAccountPicked ] = React.useState([]);


    // For dialog confirming amount
    const [ openVerifyDeposit, setOpenVerifyDeposit ] = React.useState('');
    const [ agreeVerifyDeposit, setAgreeVerifyDeposit ] = React.useState('');
    const handleAgreeVerifyDeposit = () => {
        setAgreeVerifyDeposit(true);
        setOpenVerifyDeposit(false);
        // Add code to move to backend
        
        let depositDetails = {
            username: sessionStorage.getItem('currentUser'),
            userID: sessionStorage.getItem('userID'),
            depositRequestDate: new Date(),
            typeOfDeposit: depositType,
            feeEstimate: feeEstimate,
            depositAmount: depositAmount,
            depositAmountPlusFees: adjDepositAmount,
            dwollaAccountURL: accountSelectedURL,
            dwollaAccountName: accountSelectedName,
            cushion: cushion,
        }

        if ( adjDepositAmount > maxDeposit ) {
            setOpenRequestTooLarge(true);
        } else {
            axios.post('/api/user/depositfrombank', depositDetails, {})
            .then( res => {
                console.log("Deposit was successful");
                setOpenSuccessfulRequest(true);
            })
            .catch( error => {
                console.log("Error making deposit")
                console.log(error);
                setOpenErrorDepositing(true);
                setDepositAmount('');
            })
        }
    }

    const handleNotAgreeDeposit = () => {
        setAgreeVerifyDeposit(false);
        setOpenVerifyDeposit(false);
    }

    const handleDepositFundsRequest = () =>{
        let lowerBound = Math.max(0.01 + cushion, depositAmount * .005 + cushion).toFixed(2);
        let fee = 0 // Math.min(lowerBound, 5 + cushion).toFixed(2);  ***make deposits free
        console.log(fee);
        console.log(depositAmount);
        let depositPlusFee = (parseFloat(depositAmount) + parseFloat(fee)).toFixed(2);
        console.log(depositPlusFee);
        setFeeEstimate(fee);
        setAdjDepositAmount(depositPlusFee);
        setOpenVerifyDeposit(true);
        console.log(accountSelectedName);
    }

    // For dialog saying there was an error
    const [ openErrorDepositing, setOpenErrorDepositing ] = React.useState('');
    const [ agreeErrorDepositing, setAgreeErrorWithDrwaing ] = React.useState('');
    const handleAgreeErrorDepositing = () => {
        setAgreeErrorWithDrwaing(true);
        setOpenErrorDepositing(false);
        window.location.pathname = '/depositchoice';
    }

    // For dialog saying successful deposit request
    const [ openSuccessfulRequest, setOpenSuccessfulRequest ] = React.useState('');
    const [ agreeSuccessfulRequest, setAgreeSuccessfulRequest ] = React.useState('');
    const handleAgreeSuccessfulRequest = () => {
        setAgreeSuccessfulRequest(true);
        setOpenSuccessfulRequest(false);
        window.location.pathname = '/home'
    }

    // For dialog saying that amount is larger than what you can currently deposit
    const [ openRequestTooLarge, setOpenRequestTooLarge ] = React.useState('');
    const [ agreeRequestTooLarge, setAgreeRequestTooLarge ] = React.useState('');
    const handleAgreeRequestToolarge = () => {
        setAgreeRequestTooLarge(true);
        setOpenRequestTooLarge(false);
    }

    // Dialog for no bank yet added
    const [openNoBankYet, setOpenNoBankYet ] = React.useState('');
    const [ agreeNoBankYet, setAgreeNoBankYet ] = React.useState('');
    const handleAgreeNoBankYet = () => {
        setAgreeNoBankYet(true);
        setOpenNoBankYet(false);
        window.location.pathname = '/depositchoice'
    }

    //  Gets all bank accounts
    useEffect(() => {
        (async () => {
            const array = await axios.get('/api/user/accounts/' + sessionStorage.getItem('userID'))
                .then(async function(res){
                    if (res.data[0].fundingSourceURLdwolla.length === 0 ) {
                        setOpenNoBankYet(true);
                    } else {
                        let accountObjects = await res.data[0].fundingSourceURLdwolla;
                        setAccountSelectedName(accountObjects[0].accountName);
                        setAccountSelectedURL(accountObjects[0].accountURL);
                        return accountObjects;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            
            const result = [];
            const map = new Map();  //  This makes sure only posting distinct games
            if ( array !== undefined ){
                for (const item of array) {
                    if(!map.has(item._id)){
                        map.set(item._id, true); // set any value to Map
                        result.push({
                            _id: item._id,
                            accountName: item.accountName,
                            accountURL: item.accountURL,
                        });
                    }
                }
            }
            if ( result === undefined ) { console.log('no accounts')} else {
                setRows(result)
            }
            // console.log(result)
        })();
    }, []);   

    const handleAccountPick = (event, key) => {
        setAccountPicked(key);
        let accountChosen = rows.find(({_id}) => _id === key);
        setAccountSelectedName(accountChosen.accountName);
        setAccountSelectedURL(accountChosen.accountURL);
    }

    

    return(
        <div>
            < Container maxWidth="sm">
            <br/>

                <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                            Deposit Via Bank ACH
                        </Typography>

                        <br />
                        <form noValidate autoComplete="off">
                            <Grid container direction='column' justify="center" alignItems='center'>
                                <div>
                                    <TextField
                                        required
                                        id="depositAmount"
                                        label="Amount to Deposit (USD)"
                                        name="depositAmount"
                                        variant="outlined"
                                        value={depositAmount}
                                        placeholder="Enter Amount to Deposit"
                                        onChange={ e => setDepositAmount(e.target.value) }
                                        InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                $
                                              </InputAdornment>
                                            ),
                                          }}
                                        
                                    />
                                </div>
                                <br />
                           

                                
                                <div>
                                <TableContainer component={Paper} align="center">
                                    <Table className={classes.table} aria-label="game table" style={{justifyContent: 'center'}}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" className={classes.tableHeader}>Choose Account</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow 
                                                hover 
                                                key={row._id}
                                                onClick={ (event) => handleAccountPick(event, row._id)}
                                                >
                                                    <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                                                        {row.accountName}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                </div>



                                <div>
                                    <Dialog
                                        open={openVerifyDeposit}
                                        agree={agreeVerifyDeposit}
                                        onClose={handleAgreeVerifyDeposit}
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            Depending your geography, our processor may charge a very small fee for each deposit. 
                                        </DialogTitle>
                                        {/* <DialogTitle id="alert-dialog-title">
                                            Depending your geography, our processor may charge a very small fee for each deposit.  Though you are depositing {<NumberFormat 
                                                value={depositAmount}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                fixedDecimalScale={2}
                                                prefix={'$'}
                                            ></NumberFormat>}, you will see {<NumberFormat 
                                                value={adjDepositAmount}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                fixedDecimalScale={2}
                                                prefix={'$'}
                                            ></NumberFormat>} withdrawn from your bank account.
                                        </DialogTitle> */}

                                        <DialogActions>
                                            <Grid container spacing ={1}>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleNotAgreeDeposit} align="center" color="secondary" variant="outlined" autoFocus>
                                                    Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={4}></Grid>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleAgreeVerifyDeposit} color="primary" variant="outlined" className={classes.buttonBetBlue} 
                                                    type='submit'
                                                    align="center" autoFocus>
                                                    OK
                                                    </Button>
                                                </Grid>
                                            
                                            </Grid>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                    open={openErrorDepositing}
                                    agree={agreeErrorDepositing}
                                    onClose={handleAgreeErrorDepositing}
                                    >
                                        <DialogTitle align="center">
                                            We were unable to transfer funds from your your bank account at this time. Please try again later, and if the problem persists, please email help@ibetu.co.  You likely have not verified this account. Try going to "Add Bank", then select "Return" and confirm your micro-deposits.
                                        </DialogTitle>
                                        <DialogActions>
                                            <Grid container spacing={1}>
                                                <Grid item xs={8}></Grid>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleAgreeErrorDepositing} color="primary" variant="outlined" className={classes.buttonBetBlue}
                                                    align="center" autofocus>
                                                        OK
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                    open={openSuccessfulRequest}
                                    agree={agreeSuccessfulRequest}
                                    onClose={handleAgreeSuccessfulRequest}
                                    >
                                        <DialogTitle align="center">
                                            We have registered your deposit request.  It may take a few days for the request to process and be reflected in your "Funds Available".  For future deposits, consider Paypal for faster processing, but note that their transfer fees will be higher.
                                        </DialogTitle>
                                        <DialogActions>
                                            <Grid container spacing={1}>
                                                <Grid item xs={8}></Grid>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleAgreeSuccessfulRequest} color="primary" variant="outlined" className={classes.buttonBetBlue}
                                                    align="center" autofocus>
                                                        OK
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                    open={openNoBankYet}
                                    agree={agreeNoBankYet}
                                    onClose={handleAgreeNoBankYet}
                                    >
                                        <DialogTitle align="center">
                                            We no longer have a bank linked to your account. Please link a bank account so that we can deposit funds into your account. Click 'OK' below and then select 'Add Bank Account'.
                                        </DialogTitle>
                                        <DialogActions>
                                            <Grid container spacing={1}>
                                                <Grid item xs={8}></Grid>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleAgreeNoBankYet} color="primary" variant="outlined" className={classes.buttonBetBlue}
                                                    align="center" autofocus>
                                                        OK
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <CardActions style={{ justifyContent: 'center'}}>
                                    <Button size="small" variant="contained" className={classes.buttonblue}
                                    onClick={handleDepositFundsRequest}
                                    >Deposit</Button>
                                </CardActions>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
}