import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import axios from 'axios';



const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        minWidth: 275,
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    table: {
        minWidth: 280,
        maxWidth: 280,
    },
    tableHeader: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
    },
    tableCell: {
        padding: 3,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    tableHeaderName: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        fontSize: 18,
    },
    tableCellName: {
        padding: 8,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        fontSize: 18,
    },
    buttonBetBlue: {
        background: '#0510e6',
        color: 'white',
        minWidth: 80
    },
    formControl: {
        display: "flex",
    },
    buttonGet: {
        background: '#0510e6',
        color: 'white'
    },
});



export default function CreateFriendGroup(){
    let regionRestriction = sessionStorage.getItem('regionRestriction');
    let currentUser = sessionStorage.getItem('currentUser');
    const classes = useStyles();
    const [ groupName, setGroupName ] = React.useState('');
    const [ groupCode, setGroupCode ] = React.useState('');
    const [ friendUsername, setFriendUsername ] = React.useState('');
    const [ friendEmail, setFriendEmail ] = React.useState('');
    const [ friendPhone, setFriendPhone ] = React.useState('');
    const [ friendCountryCode, setFriendCountryCode ] = React.useState('');
    
    // Variables for Friend List
    const [ openFriendSelector, setOpenFriendSelector ] = React.useState('');
    const [ friendSelectedName, setFriendSelectedName ] = React.useState('');
    const [ friendSelectedUsername, setFriendSelectedUsername ] = React.useState('');
    const [ friendSelectedEmail, setFriendSelectedEmail ] = React.useState('');
    const [ openNoFriendsYet, setOpenNoFriendsYet ] = React.useState('');
    const [ rows2, setRows2 ] = React.useState([]);
    const [ friendPicked, setFriendPicked ] = React.useState([]);
    const [ noFriends, setNoFriends ] = React.useState(false);

    // Get all friends=======================
    useEffect(() => {
        (async () => {
            const array = await axios.get('/api/user/accounts/' + sessionStorage.getItem('userID'))
                .then(async function(res){
                    if (res.data[0].friends.length === 0 ) {
                        setNoFriends(true);
                    } else {
                        let friendObjects = await res.data[0].friends;
                        return friendObjects;
                    }
                })
                .catch(error => {
                    console.log(error);
                });

            const result = [];
            const map = new Map();
            if ( array !== undefined ){
                for ( const item of array ) {
                    if(!map.has(item._id)){
                        map.set(item._id, true);
                        result.push({
                            _id: item._id,
                            friendName: item.friendName,
                            friendUsername: item.friendUsername,
                            friendEmail: item.friendEmail,
                        })
                    }
                }
            }
            if ( result === undefined ) { console.log('no friends') } else {
                function compare(a, b) {
                    const bandA = a.friendName.toLowerCase();
                    const bandB = b.friendName.toLowerCase();
                    let comparison = 0;
                    if (bandA > bandB) {
                        comparison = 1;
                      } else if (bandA < bandB) {
                        comparison = -1;
                      }
                      return comparison;
                }
                let sortedResult = result.sort(compare);
                let key = 'friendName';
                let uniqueArray = [...new Map(sortedResult.map(item => [item[key],item])).values()];
                setRows2(uniqueArray);
            }

        })();
    }, []);

    const handleOpenListOfFriends = () => {
        if (noFriends === true ) {
            setOpenNoFriendsYet(true);
        } else {
            setOpenFriendSelector(true);
        }
    }

    const handleCloseListOfFriends = () => {
        setOpenFriendSelector(false);
    }

    const handleFriendPick = (event, key) => {
        setFriendPicked(key);
        let friendChosen = rows2.find(({_id}) => _id === key);
        setFriendSelectedName(friendChosen.friendName);
        setFriendSelectedUsername(friendChosen.friendUsername);
        setFriendSelectedEmail(friendChosen.friendEmail);

        setFriendUsername(friendChosen.friendUsername);
        setFriendEmail(friendChosen.friendEmail);
        setOpenFriendSelector(false);
    }

    const handleCloseNoFriendsYet = () => {
        setOpenNoFriendsYet(false);
    }

    const [ openUserNotThere, setOpenUserNotThere ] = React.useState(false);
    const [ agreeUserNotThere, setAgreeUserNotThere ] = React.useState(false);

    const handleAgreeUserNotThere = () => {
        setAgreeUserNotThere(true);
        setOpenUserNotThere(false);
    }

    const [ openInviteMoreFriends, setOpenInviteMoreFriends ] = React.useState(false);
    const handleAddMoreFriends = () => {
        setFriendUsername('');
        setFriendEmail('');
        setFriendCountryCode('');
        setFriendPhone('');
        setOpenInviteMoreFriends(false);
    }
    const handleDoneInvitingFriends = () => {
        setFriendUsername('');
        setFriendEmail('');
        setFriendCountryCode('');
        setFriendPhone('');
        setOpenInviteMoreFriends(false);
        setOpenAddFriendsToGroup(false);
        window.location.pathname ='/home';
    }


    // Create Friend Group
    const [ openNameAlreadyUsed, setOpenNameAlreadyUsed ] = React.useState(false);
    const handleCloseNameAlreadyUsed = () => {
        setGroupName('');
        setOpenNameAlreadyUsed(false)
    }

    const [ openAddFriendsToGroup, setOpenAddFriendsToGroup ] = React.useState(false);
    const handleCloseAddFriendsToGroup = () => {
        setOpenAddFriendsToGroup(false);
    }

    const handleCreateFriendGroup = async () => {
        let groupDetails = {
            currentUser: currentUser,
            groupName: groupName,
        }
        let checkOrCreateGroup = await axios.post('/api/group/checkCreate', groupDetails, {} )
        .then( res => { 
            if ( res.data === 'Group Name Already Exists') {
                setOpenNameAlreadyUsed(true);
            } else if (res.data.groupName === groupName) {
                setGroupCode(res.data.groupCode);
                setOpenAddFriendsToGroup(true);
            }
        })
        .catch( err => { console.log(err)} )
    }

    const handleAgreeInviteFriendToGroup = async () => {
        let validUsername = await axios.get('/api/user/validfriend/' + friendUsername)
        .then (res => {
            if (friendUsername.length > 0 ){
                if (res.data.username === friendUsername && res.data.username !==currentUser) {
                    return true
                } else { return false }
            } else {
                return true;
            }
        })
        .catch( (err) => {console.log(err); return false})

        if (validUsername === true ){
            let friendPhoneWCC = friendCountryCode + friendPhone;
            let friendPhoneNumOnly = friendPhoneWCC.replace(/\D/g, '');
            let friendDetails = {
                friendUsername: friendUsername,
                friendEmail: friendEmail,
                friendPhone: friendPhoneNumOnly,
                currentUser: currentUser,
                groupName: groupName,
                groupCode: groupCode,
            }

            let inviteFriendToGroup = await axios.post('/api/group/inviteToGroup', friendDetails, {} )
            .then( res => {
                setOpenInviteMoreFriends(true);
                
            })
            .catch( err => console.log(err))
        } else {
            setOpenUserNotThere(true);
        }
    }


    return(
        <div>
            <Card className={classes.root}>
                <CardContent>
                    <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center' }} gutterBottom>
                            Create Group
                    </Typography>

                    <Grid container direction='column' justify="center" alignItems='center'>
                        <Box fontWeight="fontWeightRegular" m={1} fontSize={18}>
                            Here you can create a group of friends so that you can make bets posted to a group of friends, as opposed to only one friend or to the whole iBetU community.
                        </Box>

                        <br/>
                    
                        <TextField
                            required
                            id="groupName"
                            label="Group Name"
                            name="groupName"
                            variant="outlined"
                            value={groupName}
                            placeholder="Enter a name for your group"
                            onChange={ async e => await setGroupName(e.target.value) }
                        />
                        <br/>

                        <CardActions>
                            <div>
                                <Button size="medium" variant="contained" className={classes.buttonblue} type='submit' onClick={handleCreateFriendGroup}>Create Group</Button>
                            </div>
                        </CardActions>
                    </Grid>
                    {/* Dialog to say name already used ++++++++++++++++ */}
                    <div>
                        <Dialog
                            open={openNameAlreadyUsed}
                            onClose={handleCloseNameAlreadyUsed}
                        >
                            <DialogTitle>
                                That group name is already in use by another group.
                            </DialogTitle>

                            <DialogActions>
                            <Button onClick={handleCloseNameAlreadyUsed} color="primary" autoFocus>
                                OK
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    {/* Dialog to add a friend to new group */}
                    <div>
                        <Dialog
                            open={openAddFriendsToGroup}
                            onClose={handleCloseAddFriendsToGroup}
                        >
                            <DialogTitle>
                                {"Pick your friend from your list of friends you have bet with in the past by clicking the 'Friend' button below, or enter your friend's username, email or phone number. We don't need all three, but we need at least one:"}
                            </DialogTitle>

                            <DialogActions>

                            </DialogActions>
                        </Dialog>
                    </div>

                    {/* Dialog for entering friend info +++++++++++++++++++++++ */}
                    <div>
                        <Dialog
                            open={openAddFriendsToGroup}
                            onClose={handleCloseAddFriendsToGroup}
                        >
                            <DialogTitle >{"Pick your friend from your list of friends you have bet with in the past by clicking the 'Friend' button below, or enter your friend's username, email or phone number. We don't need all three, but we need at least one:"}</DialogTitle>

                            <TextField
                                required
                                id="friendUserame"
                                label="Friend's Username"
                                name="friendUserame"
                                variant="outlined"
                                value={friendUsername}
                                placeholder="Enter Your Friend's Username"
                                onChange={ async e => await setFriendUsername(e.target.value)}
                            />
                            <br/>

                            <TextField
                                required
                                id="friendEmail"
                                label="Friend's Email"
                                name="friendEmail"
                                variant="outlined"
                                value={friendEmail}
                                placeholder="Enter Your Friend's Email"
                                onChange={ async e => await setFriendEmail(e.target.value) }
                            />
                            <br/>

                            <TextField
                                required
                                id="friendCountryCode"
                                label="Friend's Phone Country Code"
                                name="friendCountryCode"
                                variant="outlined"
                                value={friendCountryCode}
                                placeholder="Enter Your Friend's Country Code"
                                onChange={ async e => await setFriendCountryCode(e.target.value) }
                            />
                            <br/>

                            <TextField
                                required
                                id="friendPhone"
                                label="Friend's Phone Number"
                                name="friendPhone"
                                variant="outlined"
                                value={friendPhone}
                                placeholder="Enter Your Friend's Phone Number"
                                onChange={ async e => await setFriendPhone(e.target.value) }
                            />
                            <br/>

                            <DialogActions>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Button onClick={handleCloseAddFriendsToGroup} align="center" color="secondary" variant="outlined" autoFocus>
                                            X
                                        </Button>
                                    </Grid>
                                    
                                    <Grid item xs={4}>
                                        <Button onClick={handleOpenListOfFriends} align="center" color="primary" variant="outlined" autoFocus>
                                            Friend
                                        </Button>
                                    </Grid>
                                
                                    <Grid item xs={4}>
                                        <Button onClick={handleAgreeInviteFriendToGroup} align="center" color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                            Invite
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    </div>
                    {/* Dialog for entering friend info end ======================= */}

                    {/* Dialog for selecting friend using selector start +++++++++++++++++ */}
                    <div>
                    <Dialog
                        open={openFriendSelector}
                        onClose={handleCloseListOfFriends}
                    >
                        <br/>
                        <div>
                            <TableContainer component={Paper} align="center">
                                <Table className={classes.table} aria-label="game table" style={{justifyContent: 'center'}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" className={classes.tableHeaderName}>Select a Friend</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows2.map((row) => (
                                            <TableRow 
                                            hover 
                                            key={row._id}
                                            onClick={ (event) => handleFriendPick(event, row._id)}
                                            >
                                                <TableCell component="th" scope="row" align="center" className={classes.tableCellName}>
                                                    {row.friendName}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                            
                    </Dialog>
                    </div>
                    {/* Dialog for selecting friend using selector end =============== */}

                    {/* Dialog for no friends yet start ++++++++++++++++++++++++++++++ */}
                            <div>
                        <Dialog
                            open={openNoFriendsYet}
                            onClose={handleCloseNoFriendsYet}
                        >
                            <DialogTitle>{"There are no friends associated with this account yet. Please close this popup and enter your friends information manually.  If the phone or email are associated with a registered user, that user will automatically be added to your friend list after the invitation to join your group is sent."}</DialogTitle>

                            <DialogActions>
                            <Button onClick={handleCloseNoFriendsYet} color="primary" autoFocus>
                                X
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    {/* Dialog for no friends yet end =========================== */}

                    {/* Dialog for user not in system start +++++++++++++++++++++++ */}
                    <div>
                        <Dialog
                            open={openUserNotThere}
                            agree={agreeUserNotThere}
                            onClose={handleAgreeUserNotThere}
                        >
                            <DialogTitle >{"We are sorry, but that username is not in our system.  Check the spelling (case sensitive) or use a phone number or email to bet your friend."}</DialogTitle>

                            <DialogActions>
                            <Button onClick={handleAgreeUserNotThere} color="primary" autoFocus>
                                OK
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    {/* Dialog for user not in system end ======================= */}

                    {/* Dialog for invite another friend start+++++++++++++++++++++ */}
                                        <div>
                        <Dialog
                            open={openInviteMoreFriends}
                        >
                            <DialogTitle >{"Your friend has been invited successfully.  Would you like to invite more friends? If so click 'More' below.  If you are done inviting friends, click 'Done'"}</DialogTitle>

                            <DialogActions>
                            <Grid container spacing ={1}>
                                <Grid item xs={4}>
                                    <Button onClick={handleDoneInvitingFriends} align="center" color="secondary" variant="outlined" autoFocus>
                                    Done
                                    </Button>
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={4}>
                                    <Button onClick={handleAddMoreFriends} color="primary" variant="outlined" className={classes.buttonBetBlue} align="center" autoFocus>
                                    More
                                    </Button>
                                </Grid>
                            
                            </Grid>
                            </DialogActions>
                        </Dialog>
                    </div>
                    {/* Dialog for invite another friend end ===================== */}

                </CardContent>
            </Card>
        </div>
    )
}

