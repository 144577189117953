import React from 'react';

import Container from '@material-ui/core/Container';
import MainAppBar from '../../components/MainAppBar';
import BetsByTeam from '../../components/gameComponents/PostedBetsByTeam';

import Footer from '../../components/Footer';
import Copyright from '../../components/Copyright';
import checkAuthenticated from '../../utils/checkAuthenticated';
import {abbreviations} from '../../utils/abbreviations';


export default function LandingPage() {
  checkAuthenticated();
  let league = 'nfl';
  let title = 'NFL';
  
  let names = []  
  for ( const item of abbreviations) {
    if (item.league === league) {
      names.push( item.name );
    }
  }
  // let names = [
  //   'Arizona Cardinals',
  //   'Atlanta Falcons',
  //   'Baltimore Ravens',
  //   'Buffalo Bills',
  //   'Carolina Panthers',
  //   'Chicago Bears',
  //   'Cincinnati Bengals',
  //   'Cleveland Browns',
  //   'Dallas Cowboys',
  //   'Denver Broncos',
  //   'Detroit Lions',
  //   'Green Bay Packers',
  //   'Houston Texans',
  //   'Indianapolis Colts',
  //   'Jacksonville Jaguars',
  //   'Kansas City Chiefs',
  //   'Las Vegas Raiders',
  //   'Los Angeles Chargers',
  //   'Los Angeles Rams',
  //   'Miami Dolphins',
  //   'Minnesota Vikings',
  //   'New England Patriots',
  //   'New Orleans Saints',
  //   'New York Giants',
  //   'New York Jets',
  //   'Philadelphia Eagles',	
  //   'Pittsburgh Steelers',	
  //   'San Francisco 49ers',	
  //   'Seattle Seahawks',
  //   'Tampa Bay Buccaneers',
  //   'Tennessee Titans',
  //   'Washington Commanders',
  // ]



  return (
    < Container maxWidth="sm">
        <MainAppBar></MainAppBar>
        <BetsByTeam
          league={league}
          title={title}
          abbreviations={abbreviations}
          names={names}
        ></BetsByTeam>
        <br/>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
