import React from 'react';

import Container from '@material-ui/core/Container';
import MainAppBar from '../../components/MainAppBar';
import ChoosePath from '../../components/gameComponents/ChoosePath';

import Footer from '../../components/Footer';
import Copyright from '../../components/Copyright';
import checkAuthenticated from '../../utils/checkAuthenticated';


export default function LandingPage() {
  checkAuthenticated();
  let league = 'mlb';
  let title = "MLB";

  return (
    < Container maxWidth="sm">
        <MainAppBar></MainAppBar>
        <ChoosePath
          league={league}
          title={title}
          ></ChoosePath>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
