import React from 'react';

import Container from '@material-ui/core/Container';
import SignUpAppBar from '../components/SignUpAppBar';
import MfaForm from '../components/MfaForm';
import Footer from '../components/Footer';
import Copyright from '../components/Copyright';
import checkAuthenticated from '../utils/checkAuthenticated';


export default function MfaPage() {
 
  return (
    < Container maxWidth="sm">
        <SignUpAppBar></SignUpAppBar>
        <MfaForm></MfaForm>
        <br/>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
