import React from 'react';

import Container from '@material-ui/core/Container';
import MainAppBar from '../../components/MainAppBar';
import BetsByTeam from '../../components/gameComponents/PostedBetsByTeam';

import Footer from '../../components/Footer';
import Copyright from '../../components/Copyright';
import checkAuthenticated from '../../utils/checkAuthenticated';
import {abbreviations} from '../../utils/abbreviations';

export default function LandingPage() {
  checkAuthenticated();
  let league = 'premier';
  let title = 'Premier League';

  let names = []  
  for ( const item of abbreviations) {
    if (item.league === league) {
      names.push( item.name );
    }
  }
  // let names = [
  //   'Arsenal',
  //   'Aston Villa',	
  //   'Bournemouth',
  //   'Brighton',
  //   'Burnley',
  //   'Chelsea',
  //   'Crystal Palace',
  //   'Everton',
  //   'Leicester City',	
  //   'Liverpool',
  //   'Manchester City',
  //   'Manchester Utd	',
  //   'Newcastle Utd',	
  //   'Norwich City',
  //   'Sheffield Utd',	
  //   'Southampton',
  //   'Tottenham',
  //   'Watford',
  //   'West Ham',
  //   'Wolves',
  // ]



  return (
    < Container maxWidth="sm">
        <MainAppBar></MainAppBar>
        <BetsByTeam
          league={league}
          title={title}
          abbreviations={abbreviations}
          names={names}
        ></BetsByTeam>
        <br/>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
