import React, { useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import NumberFormat from 'react-number-format';

import axios from 'axios';


const useStyles = makeStyles({

  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    maxHeight: 36,
    paddingTop: 6,
    paddingBottom: 0
    
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 40,
    fontFamily: 'Anton',
    color: '#0510e6',
    fontStyle: 'italic',
  },
  welcome: {
    fontSize: 22,
    fontStyle: 'bold',
    fontFamily: 'Anton'
  },
  pos: {
    marginBottom: 2,
  },
  buttonblue: {
      background: '#0510e6',
      color: 'white'
  },
  buttonclear: {
      color: "#0510e6",
      borderColor: "#0510e6"
  },
  question: {
      fontSize:  16,
  },
  answer: {
      fontSize: 14,
      fontFamily: 'Anton',
      color: 'grey',
      display: 'inline-block'
  },
});

let currentUser;
if (sessionStorage.getItem('loginAttempt') === undefined ) {
    currentUser = sessionStorage.getItem('currentUser')
} else { currentUser = sessionStorage.getItem('loginAttempt') };

export default function FundsSummary(props){
    const classes = useStyles();
    const currentFundsAvailable = props.fundsAvailable;
    const currentFundsAtRisk = props.fundsAtRisk;
    const [ fundsAvailable, setFundsAvailable ] = React.useState(currentFundsAvailable);
    const [ fundsAtRisk, setFundsAtRisk ] = React.useState(currentFundsAtRisk);
    
    useEffect(() =>{
        (async () => {
            const userInfo = await axios.get('/api/user/alluserdata/' + currentUser)
            .then(function(res) {
                return {
                    fundsAvailable: res.data[0].fundsAvailable,
                    fundsAtRisk: res.data[0].fundsAtRisk,
                }
            })
            .catch( error => { console.log(error) } )
            if (userInfo == undefined ) {
                setFundsAvailable(0);
                setFundsAtRisk(0);
            } else {
                setFundsAvailable(userInfo.fundsAvailable);
                setFundsAtRisk(userInfo.fundsAtRisk);
            }
        })();
    },[]);

    return (
        < Container maxWidth="sm">
        <br/>
        <Card className={classes.root}>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography className={classes.answer} color="textSecondary" style={{ textAlign: 'left'}}>
                             Available: &nbsp;
                        </Typography><NumberFormat 
                            value={(1 * fundsAvailable).toFixed(2)}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={2}
                            prefix={'$'}
                            className={classes.answer}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.answer} color="textSecondary" style={{ textAlign: 'left'}}>
                            In Play: &nbsp;
                        </Typography><NumberFormat 
                            value={(1 * fundsAtRisk).toFixed(2)}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={2}
                            prefix={'$'}
                            className={classes.answer}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            
        </Card>
    </Container>
    )



}