import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles({

  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    paddingTop: 5,
    paddingBottom: 30
    
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 40,
    fontFamily: 'Anton',
    color: '#0510e6',
    fontStyle: 'italic',
  },
  welcome: {
    fontSize: 22,
    fontStyle: 'bold',
    fontFamily: 'Anton'
  },
  pos: {
    marginBottom: 12,
  },
  buttonblue: {
      background: '#0510e6',
      color: 'white'
  },
  buttonclear: {
      color: "#0510e6",
      borderColor: "#0510e6"
  },
  question: {
      fontSize:  16,
  },
  answer: {
      fontSize: 16,
  },
});

export default function SimpleCard() {
  const classes = useStyles();
  
  return (
      < Container maxWidth="sm">
        <br/>
        <br/>
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                Updating
                </Typography>
                <Typography className={classes.title} style={{ textAlign: 'center'}}>
                iBetU
                </Typography>
                <br />
                <Typography components='div'>
                  
                  <Box fontWeight="fontWeightRegular" m={1}>
                    We are currently updating our servers.  Please come back in a few minutes and our website will be up again. 
                  </Box>

                  
                </Typography>

            </CardContent>
            
        </Card>
    </Container>
  );
}
