import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import NumberFormat from 'react-number-format';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
//  import MuiPhoneNumber from 'material-ui-phone-number';


import axios from 'axios';


const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    formControl: {
        margin: 1,
        minWidth: 220,
        maxWidth: 220,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
      },
      answer: {
        fontSize: 14,
        fontFamily: 'Anton',
        color: 'grey',
        display: 'inline-block'
    },
    table: {
        minWidth: 220,
        maxWidth: 220,
    },
    tableHeader: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        color: 'grey',
    },
    tableHeaderNarrow: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        fixedHeader: false,
        width: 'auto',
        tableLayout: 'auto',
    },
    tableCell: {
        padding: 2,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        color: 'grey'
    },
    tableCellNarrow: {
        padding: 1,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        fixedHeader: false,
        width: 'auto',
        tableLayout: 'auto',
    },
});

let cushion = 0.01;

export default function WithdrawFromBank(props) {
    let currentUsername = sessionStorage.getItem('currentUser');
    const classes = useStyles();
    const currentFundsAvailable = props.fundsAvailable;
    const currentFundsAtRisk = props.fundsAtRisk;

    let possibleMaxWithdraw = currentFundsAvailable;

    const [ withdrawlAmount, setWithdrawlAmount ] = React.useState('');
    const [ withdrawRequestDate, setWithdrawRequestDate ] = React.useState('');
    const [ withdrawType, setWithdrawType ] = React.useState('bank-ACH');
    const [ feeEstimate, setFeeEstimate ] = React.useState('');
    const [ maxWithdrawl, setMaxWithdrawl ] = React.useState('');
    const [ fundsAvailable, setFundsAvailable ] = React.useState('');
    const [ fundsAtRisk, setFundsAtRisk ] = React.useState(currentFundsAtRisk);
    const [ accountSelectedName, setAccountSelectedName ] = React.useState('');
    const [ accountSelectedURL, setAccountSelectedURL ] = React.useState('');
    const [ adjWithdrawlAmount, setAdjWithdrawlAmount ] = React.useState('');
    const [ rows, setRows ] = React.useState([]);
    const [ accountPicked, setAccountPicked ] = React.useState([]);


    // For dialog confirming amount
    const [ openVerifyWithdrawl, setOpenVerifyWithdrawl ] = React.useState('');
    const [ agreeVerifyWithdrawl, setAgreeVerifyWithdrawl ] = React.useState('');
    const handleAgreeVerifyWithdrawl = () => {
        setAgreeVerifyWithdrawl(true);
        setOpenVerifyWithdrawl(false);
        // Add code to move to backend
        
        let withdrawDetails = {
            username: sessionStorage.getItem('currentUser'),
            userID: sessionStorage.getItem('userID'),
            withdrawRequestDate: new Date(),
            typeOfWithdraw: withdrawType,
            feeEstimate: feeEstimate,
            withdrawAmount: adjWithdrawlAmount,
            withdrawAmountPlusFees: withdrawlAmount,
            dwollaAccountURL: accountSelectedURL,
            dwollaAccountName: accountSelectedName,
            cushion: cushion,
        }

        if ( withdrawlAmount > maxWithdrawl ) {
            setOpenRequestTooLarge(true);
        } else {
            axios.post('/api/user/withdrawtobank', withdrawDetails, {})
            .then( res => {
                console.log("Withdraw was successful");
                setOpenSuccessfulRequest(true);
            })
            .catch( error => {
                console.log("Error making withdrawl")
                console.log(error);
            })
        }
    }

    const handleNotAgreeWithdrawl = () => {
        setAgreeVerifyWithdrawl(false);
        setOpenVerifyWithdrawl(false);
    }

    const handleWithdrawFundsRequest = () =>{
        let lowerBound = Math.max(0.01 + cushion, withdrawlAmount * .005 + cushion).toFixed(2);
        let fee = (Math.min(lowerBound, 5 + cushion) * 1.5).toFixed(2); // Multiplying by 1.5 to get half of deposit fee back
        let withdrawlLessFee = withdrawlAmount - fee;
        setFeeEstimate(fee);
        setAdjWithdrawlAmount(withdrawlLessFee);
        setOpenVerifyWithdrawl(true);
        console.log(accountSelectedName);
    }

    // For dialog saying there was an error
    const [ openErrorWithdrawing, setOpenErrorWithdrawing ] = React.useState('');
    const [ agreeErrorWithdrawing, setAgreeErrorWithDrwaing ] = React.useState('');
    const handleAgreeErrorWithdrawing = () => {
        setAgreeErrorWithDrwaing(true);
        setOpenErrorWithdrawing(false);
    }

    // For dialog saying successful withdrawl request
    const [ openSuccessfulRequest, setOpenSuccessfulRequest ] = React.useState('');
    const [ agreeSuccessfulRequest, setAgreeSuccessfulRequest ] = React.useState('');
    const handleAgreeSuccessfulRequest = () => {
        setAgreeSuccessfulRequest(true);
        setOpenSuccessfulRequest(false);
        window.location.pathname = '/home'
    }

    // For dialog saying that amount is larger than what you can currently withdraw
    const [ openRequestTooLarge, setOpenRequestTooLarge ] = React.useState('');
    const [ agreeRequestTooLarge, setAgreeRequestTooLarge ] = React.useState('');
    const handleAgreeRequestToolarge = () => {
        setAgreeRequestTooLarge(true);
        setOpenRequestTooLarge(false);
    }

    // Dialog for no bank yet added
    const [openNoBankYet, setOpenNoBankYet ] = React.useState('');
    const [ agreeNoBankYet, setAgreeNoBankYet ] = React.useState('');
    const handleAgreeNoBankYet = () => {
        setAgreeNoBankYet(true);
        setOpenNoBankYet(false);
        window.location.pathname = '/depositchoice'
    }

    //  Gets all bank accounts
    useEffect(() => {
        (async () => {
            const array = await axios.get('/api/user/accounts/' + sessionStorage.getItem('userID'))
                .then(async function(res){
                    if (res.data[0].fundingSourceURLdwolla.length === 0 ) {
                        setOpenNoBankYet(true);
                    } else {
                        let accountObjects = await res.data[0].fundingSourceURLdwolla;
                        setAccountSelectedName(accountObjects[0].accountName);
                        setAccountSelectedURL(accountObjects[0].accountURL);
                        return accountObjects;
                    }

                    if (res.data[0].withdrawHoldBack == undefined || res.data[0].withdrawHoldBack == ""){
                        setFundsAvailable(parseFloat(currentFundsAvailable));
                        setMaxWithdrawl(parseFloat(currentFundsAvailable));
                    } else {
                        setFundsAvailable(parseFloat(currentFundsAvailable) - res.data[0].withdrawHoldBack);
                        setMaxWithdrawl(parseFloat(currentFundsAvailable) - res.data[0].withdrawHoldBack);
                    }

                })
                .catch(error => {
                    console.log(error);
                });
            
            const result = [];
            const map = new Map();  //  This makes sure only posting distinct games
            if ( array !== undefined ){
                for (const item of array) {
                    if(!map.has(item._id)){
                        map.set(item._id, true); // set any value to Map
                        result.push({
                            _id: item._id,
                            accountName: item.accountName,
                            accountURL: item.accountURL,
                        });
                    }
                }
            }
            if ( result === undefined ) { console.log('no accounts')} else {
                setRows(result)
            }
            // console.log(result)
        })();
    }, []);   

    const handleAccountPick = (event, key) => {
        setAccountPicked(key);
        let accountChosen = rows.find(({_id}) => _id === key);
        setAccountSelectedName(accountChosen.accountName);
        setAccountSelectedURL(accountChosen.accountURL);
    }

    return(
        <div>
            < Container maxWidth="sm">
            <br/>

                <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                            Withdraw Via Bank ACH
                        </Typography>

                        <br />
                        <form noValidate autoComplete="off">
                            <Grid container direction='column' justify="center" alignItems='center'>
                                <div>
                                    <TextField
                                        required
                                        id="withdrawlAmount"
                                        label="Amount to Withdraw (USD)"
                                        name="withdrawlAmount"
                                        variant="outlined"
                                        value={withdrawlAmount}
                                        placeholder="Enter Amount to Withdraw"
                                        onChange={ e => setWithdrawlAmount(e.target.value) }
                                        InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                $
                                              </InputAdornment>
                                            ),
                                          }}
                                    />
                                </div>
                                <br />
                           

                                
                                <div>
                                <TableContainer component={Paper} align="center">
                                    <Table className={classes.table} aria-label="game table" style={{justifyContent: 'center'}}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" className={classes.tableHeader}>Choose Account</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow 
                                                hover 
                                                key={row._id}
                                                onClick={ (event) => handleAccountPick(event, row._id)}
                                                >
                                                    <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                                                        {row.accountName}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                </div>



                                <div>
                                    <Dialog
                                        open={openVerifyWithdrawl}
                                        agree={agreeVerifyWithdrawl}
                                        onClose={handleAgreeVerifyWithdrawl}
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            Our processor charges a small fee for each deposit and withdrawl.  Though you are withdrawing {<NumberFormat 
                                                value={withdrawlAmount}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                fixedDecimalScale={2}
                                                prefix={'$'}
                                            ></NumberFormat>}, you will only see {<NumberFormat 
                                                value={adjWithdrawlAmount}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                fixedDecimalScale={2}
                                                prefix={'$'}
                                            ></NumberFormat>} come into your account.
                                        </DialogTitle>

                                        <DialogActions>
                                            <Grid container spacing ={1}>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleNotAgreeWithdrawl} align="center" color="secondary" variant="outlined" autoFocus>
                                                    Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={4}></Grid>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleAgreeVerifyWithdrawl} color="primary" variant="outlined" className={classes.buttonBetBlue} 
                                                    type='submit'
                                                    align="center" autoFocus>
                                                    OK
                                                    </Button>
                                                </Grid>
                                            
                                            </Grid>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                    open={openErrorWithdrawing}
                                    agree={agreeErrorWithdrawing}
                                    onClose={handleAgreeErrorWithdrawing}
                                    >
                                        <DialogTitle align="center">
                                            We were unable to transfer funds to your your bank account at this time. Please try again later, and if the problem persists, please email help@ibetu.co.
                                        </DialogTitle>
                                        <DialogActions>
                                            <Grid container spacing={1}>
                                                <Grid item xs={8}></Grid>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleAgreeErrorWithdrawing} color="primary" variant="outlined" className={classes.buttonBetBlue}
                                                    align="center" autofocus>
                                                        OK
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                    open={openRequestTooLarge}
                                    agree={agreeRequestTooLarge}
                                    onClose={handleAgreeRequestToolarge}
                                    >
                                        <DialogTitle align="center">
                                            Sorry, the amount you are requesting is more than the sum of your requested withdrawl and your estimated fees for this transfer of ${feeEstimate}.
                                        </DialogTitle>
                                        <DialogActions>
                                            <Grid container spacing={1}>
                                                <Grid item xs={8}></Grid>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleAgreeRequestToolarge} color="primary" variant="outlined" className={classes.buttonBetBlue}
                                                    align="center" autofocus>
                                                        OK
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </DialogActions>
                                    </Dialog>
                                </div>


                                <div>
                                    <Dialog
                                    open={openSuccessfulRequest}
                                    agree={agreeSuccessfulRequest}
                                    onClose={handleAgreeSuccessfulRequest}
                                    >
                                        <DialogTitle align="center">
                                            We have registered your withdrawl request.  Your "Funds Available" will show this immediately, but it may take a few days before you see the deposit in your bank account.  For future withdrawls, consider Paypal for faster processing, but note that their transfer fees will be higher.
                                        </DialogTitle>
                                        <DialogActions>
                                            <Grid container spacing={1}>
                                                <Grid item xs={8}></Grid>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleAgreeSuccessfulRequest} color="primary" variant="outlined" className={classes.buttonBetBlue}
                                                    align="center" autofocus>
                                                        OK
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                    open={openNoBankYet}
                                    agree={agreeNoBankYet}
                                    onClose={handleAgreeNoBankYet}
                                    >
                                        <DialogTitle align="center">
                                            We no longer have a bank linked to your account. Please link a bank account so that we can deposit funds in your account. Click 'OK' below and then select 'Add Bank Account'.
                                        </DialogTitle>
                                        <DialogActions>
                                            <Grid container spacing={1}>
                                                <Grid item xs={8}></Grid>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleAgreeNoBankYet} color="primary" variant="outlined" className={classes.buttonBetBlue}
                                                    align="center" autofocus>
                                                        OK
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <CardActions style={{ justifyContent: 'center'}}>
                                    <Button size="small" variant="contained" className={classes.buttonblue}
                                    onClick={handleWithdrawFundsRequest}
                                    >Withdraw</Button>
                                </CardActions>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
}