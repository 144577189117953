import React from 'react';

import Container from '@material-ui/core/Container';
import BasicAppBar from '../components/BasicAppBar';
import FAQ from '../components/FAQ';
import Footer from '../components/Footer';
import Copyright from '../components/Copyright';


export default function LandingPage() {
  
  return (
    < Container maxWidth="sm">
        <BasicAppBar></BasicAppBar>
        <FAQ></FAQ>
        <br/>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
