import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// import Link from '@material-ui/core/Link';
// import { Redirect } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckRegion from '../utils/checkRegion';

import axios from 'axios';


const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    }
  });

let currentUsername = sessionStorage.getItem("loginAttempt");

export default function MfaForm() {

    const classes = useStyles();
    const [ mfaCode, setMfaCode ] = React.useState('');

    const handleSendViaEmail = (event) => {
        event.preventDefault();
        const userDetails = {
            username: currentUsername,
        }
        axios.post('/api/user/newmfacodeemail', userDetails,{})
            .then( response => {
                console.log(response);
                setOpenCodeSent(true);
            })
            .catch( error => { console.log(error); setOpen(true) })
    }

    const handleSendViaText = (event) => {
        event.preventDefault();
        const userDetails = {
            username: currentUsername,
        }
        axios.post('/api/user/newmfacodesms', userDetails,{})
            .then( response => {
                console.log(response);
                setOpenCodeSent(true);
            })
            .catch( error => { console.log(error); setOpen(true) })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const userDetails = {
            username: currentUsername,
            MFAcode: mfaCode
        }
        axios.post('/api/user/checkmfa', userDetails, {})
            .then( response => {
                if (response.data.MFAcheck === true ) {
                    window.location.pathname = "/home";
                } else {
                    setOpen(true);
                }
            })
            .catch( error => { console.log(error); setOpen(true) })

    }

    const [ open, setOpen ] = React.useState(false);
    const [ agree, setAgree ] = React.useState(false);
    const handleAgree = () => {
        setAgree(true);
        setOpen(false);
        window.location.pathname = "/login";
    }

    const [ openCodeSent, setOpenCodeSent ] = React.useState(false);
    const [ agreeCodeSent, setAgreeCodeSent ] = React.useState(false);
    const handleAgreeCodeSent = () => {
        setAgreeCodeSent(true);
        setOpenCodeSent(false);
    }

    return(
        <div>
            < Container maxWidth="sm">
            <br/>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                            Multi-Factor Authentication
                        </Typography>
                        <Typography className={classes.title} style={{ textAlign: 'center'}}>
                            iBetU
                        </Typography>
                        <br />
                        
                        <form noValidate autoComplete="off">
                            <Grid container direction='column' justify="center" alignItems='center'>
                                <div>
                                    <Button size="small" variant="outlined" className={classes.buttonclear}
                                    type='submit'
                                    onClick={handleSendViaEmail}
                                    >Send Code Via Email</Button>
                                </div>
                                <br />
                                <div>
                                    <Button size="small" variant="outlined" className={classes.buttonclear}
                                    type='submit'
                                    onClick={handleSendViaText}
                                    >Send Code Via SMS</Button>
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="code"
                                        label="Enter Code Received"
                                        name="code"
                                        variant="outlined"
                                        value={mfaCode}
                                        placeholder="Enter code here"
                                        onChange={ e => setMfaCode(e.target.value)}
                                        
                                    />
                                </div>
                                <br />

                                

                                <CardActions style={{ justifyContent: 'center'}}>
                                    <div>
                                        <Button size="small" variant="contained" className={classes.buttonblue}
                                        type='submit'
                                        onClick={handleSubmit}
                                        >Submit</Button>
                                    </div>
                                    
                                </CardActions>
                                    <br/>
                                
                                <div>
                                    <Dialog
                                        open={open}
                                        agree={agree}
                                        onClose={handleAgree}
                                    >
                                        <DialogTitle id="alert-dialog-wrongcode">{"That code is incorrect.  Please try again."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgree} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openCodeSent}
                                        agree={agreeCodeSent}
                                        onClose={handleAgreeCodeSent}
                                    >
                                        <DialogTitle id="alert-dialog-wrongcode">{"A new code has been sent. Please click 'OK' below and enter the code you received."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreeCodeSent} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </div>
    )


  }