import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import axios from 'axios';

const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        minWidth: 260,
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    table: {
        minWidth: 280,
        maxWidth: 280,
    },
    tableHeader: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        fontSize: 18,
    },
    tableCell: {
        padding: 3,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    tableHeaderName: {
        fontStyle: 'bold',
        fontFamily: 'Anton',
        fontSize: 18,
    },
    tableCellName: {
        padding: 8,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        fontSize: 18,
    },
    buttonBetBlue: {
        background: '#0510e6',
        color: 'white',
        minWidth: 80
    },
    formControl: {
        display: "flex",
    },
    buttonGet: {
        background: '#0510e6',
        color: 'white'
    },
});


export default function SeeYourGroups() {
    let currentUserID = sessionStorage.getItem('userID');
    let currentUsername = sessionStorage.getItem('currentUser');
    
    const classes = useStyles();
    const [ rows, setRows ] = React.useState([]);
    const [ rowsFriends, setRowsFriends ] = React.useState([]);
    const [ openFriendsInGroup, setOpenFriendsInGroup ] = React.useState(false);
    const [ openFriendsInGroupAdmin, setOpenFriendsInGroupAdmin ] = React.useState(false);
    const [ openConfirmLeaveGroup, setOpenConfirmLeaveGroup ] = React.useState(false);
    const [ groupToLeave, setGroupToLeave ] = React.useState('');
    const [ openLeftGroupSuccessfully, setOpenLeftGroupSuccessfully ] = React.useState(false);
    const [ openConfirmDeleteGroup, setOpenConfirmDeleteGroup ] = React.useState(false);
    const [ friendToDelete, setFriendToDelete ] = React.useState('');
    const [ nameOfFriendToDelete, setNameOfFriendToDelete ] = React.useState('');
    const [ usernameOfFriendToDelete, setUsernameOfFriendToDelete ] = React.useState('');
    const [ openConfirmRemoveFriend, setOpenConfirmRemoveFriend ] = React.useState(false);
    const [ openRemovedFriendSuccessfully, setOpenRemovedFriendSuccessfully ] = React.useState(false);
    const [ openConfirmSuccessfullGroupDelete, setOpenConfirmSuccessfullGroupDelete ] = React.useState(false);

    // Variables to add friend
    const [ friendToAddUsername, setFriendToAddUsername ] = React.useState('');
    const [ friendToAddEmail, setFriendToAddEmail ] = React.useState('');
    const [ friendToAddCountryCode, setFriendToAddCountryCode ] = React.useState('');
    const [ friendToAddPhone, setFriendToAddPhone ] = React.useState('');
    const [ openAddFriendsToGroup, setOpenAddFriendsToGroup ] = React.useState(false);
    const [ openFriendSelector, setOpenFriendSelector ] = React.useState(false);
    const [ rows2, setRows2 ] = React.useState([]);
    const [ friendPicked, setFriendPicked ] = React.useState([]);
    const [ noFriends, setNoFriends ] = React.useState(false);
    const [ openNoFriendsYet, setOpenNoFriendsYet ] = React.useState(false);
    const [ groupToAddCode, setGroupToAddCode ] = React.useState('');
    const [ openFriendInvitedSuccess, setOpenFriendInvitedSuccess ] = React.useState(false);
    const [ openUserNotThere, setOpenUserNotThere ] = React.useState(false);

    // Get all User's Groups
    useEffect(() => {
        (async () => {
            const array = await axios.get('/api/user/mygroups/' + currentUserID)
            .then( function (res) {
                if ( res.data.length === 0 ) {
                    let noGroups = []
                    return noGroups;
                } else {
                    return res.data
                }
            })
            .catch(error => { console.log(error)})

            const result = [];
            const map = new Map();
            if (array.length > 0){
                for(const item of array) {
                    if(!map.has(item._id)){
                        map.set(item._id, true);
                        result.push({
                            _id: item._id,
                            friendGroupName: item.friendGroupName,
                        })
                    }
                }
            } else {
                // Enter code for open no groups
            }
            //  Below makes them come out sorted alphabetically
            if ( result === undefined ) { console.log('no friend groups') } else {
                function compare(a, b) {
                    const bandA = a.friendGroupName.toLowerCase();
                    const bandB = b.friendGroupName.toLowerCase();
                    let comparison = 0;
                    if (bandA > bandB) {
                        comparison = 1;
                      } else if (bandA < bandB) {
                        comparison = -1;
                      }
                      return comparison;
                }
                let sortedResult = result.sort(compare);
                setRows(sortedResult);
            }

        })();
    }, [])

    // Get all Users Friends
    useEffect(() => {
        (async () => {
            const array = await axios.get('/api/user/accounts/' + sessionStorage.getItem('userID'))
                .then(async function(res){
                    if (res.data[0].friends.length === 0 ) {
                        setNoFriends(true);
                    } else {
                        let friendObjects = await res.data[0].friends;
                        return friendObjects;
                    }
                })
                .catch(error => {
                    console.log(error);
                });

            const result = [];
            const map = new Map();
            if ( array !== undefined ){
                for ( const item of array ) {
                    if(!map.has(item._id)){
                        map.set(item._id, true);
                        result.push({
                            _id: item._id,
                            friendName: item.friendName,
                            friendUsername: item.friendUsername,
                            friendEmail: item.friendEmail,
                        })
                    }
                }
            }
            if ( result === undefined ) { console.log('no friends') } else {
                function compare(a, b) {
                    const bandA = a.friendName.toLowerCase();
                    const bandB = b.friendName.toLowerCase();
                    let comparison = 0;
                    if (bandA > bandB) {
                        comparison = 1;
                      } else if (bandA < bandB) {
                        comparison = -1;
                      }
                      return comparison;
                }
                let sortedResult = result.sort(compare);
                let key = 'friendName';
                let uniqueArray = [...new Map(sortedResult.map(item => [item[key],item])).values()];
                setRows2(uniqueArray);
            }

        })();
    }, []);    

    const [ groupPicked, setGroupPicked ] = React.useState('');

    const handleGroupPick = async (event, key) =>{
        console.log("CODE GOT to GROUP PICk")
        setGroupPicked(key);
        let groupChosen = rows.find(({_id}) => _id === key);
        let groupChosenName = groupChosen.friendGroupName;
        setGroupToLeave(groupChosenName);
        let groupDetails = await axios.get('/api/group/' + groupChosenName)
        .then( async function(res) {
            return res.data;
        })
        .catch(error => {
            console.log(error)
        })
        let groupAdmin = groupDetails.groupAdmin;
        let groupMembersArray = groupDetails.friends;
        setGroupToAddCode(groupDetails.groupCode);

        const result = [];
        const map = new Map();
        if ( groupMembersArray !== undefined ){
            for ( const item of groupMembersArray ) {
                if(!map.has(item._id)){
                    map.set(item._id, true);
                    result.push({
                        _id: item._id,
                        friendName: item.friendName,
                        friendUsername: item.friendUsername,
                        friendEmail: item.friendEmail,
                        friendPhone: item. friendPhone
                    })
                }
            }
        }

        if ( result === undefined ) { console.log('no friend in group') } else {
            function compare(a, b) {
                const bandA = a.friendName.toLowerCase();
                const bandB = b.friendName.toLowerCase();
                let comparison = 0;
                if (bandA > bandB) {
                    comparison = 1;
                  } else if (bandA < bandB) {
                    comparison = -1;
                  }
                  return comparison;
            }
            let sortedResult = result.sort(compare);
            let key = 'friendName';
            let uniqueArray = [...new Map(sortedResult.map(item => [item[key],item])).values()];
            setRowsFriends(uniqueArray);
        }

        if (groupAdmin === currentUsername ){
            //Dialog with ability to delete & invite members
            setOpenFriendsInGroupAdmin(true);
        } else {
            //Dialog with list and ability to remove self
            setOpenFriendsInGroup(true);
        }

    }

    const handleConfirmLeaveGroup = () => {
        console.log("CODE GOT TO LEAVE REQUEST")
        let leaveGroupRequestDetails = {
            friendGroup: groupToLeave,
            currentUsername: currentUsername,
        }
        axios.post('/api/group/leaveGroup', leaveGroupRequestDetails, {})
        .then( res => {
            console.log(res);
            setOpenConfirmLeaveGroup(false);
            setOpenFriendsInGroup(false);
            setOpenLeftGroupSuccessfully(true);
        })
        .catch( error => { console.log(error) })
    }

    const handleLeftGroupSuccessfully = () => {
        setOpenLeftGroupSuccessfully(false);
        window.location.pathname = '/your_groups'
    }

    const handleFriendToDelete = (key) =>{
        setFriendToDelete(key);
        let friendDetails = rowsFriends.find(({_id}) => _id === key);
        setNameOfFriendToDelete(friendDetails.friendName);
        setUsernameOfFriendToDelete(friendDetails.friendUsername);
        setOpenConfirmRemoveFriend(true);
    }

    const handleConfirmRemoveFriend = () =>{
        console.log("will delete " + nameOfFriendToDelete);
        let removeFriendRequestDetails = {
            friendGroup: groupToLeave,
            friendUsername: usernameOfFriendToDelete,
        }
        axios.post('/api/group/removeFriend', removeFriendRequestDetails, {})
        .then( res => {
            console.log(res);
            setOpenRemovedFriendSuccessfully(true);
        })
        .catch( error => { console.log(error) })
    }

    const handleRemovedFriendSuccessfully = () =>{
        setOpenConfirmRemoveFriend(false);
        setOpenRemovedFriendSuccessfully(false);
        window.location.pathname="/your_groups";
    }

    const handleConfirmDeleteGroup = () => {
        let friendUsernamesArray = [];
        for (let i=0; i<rowsFriends.length; i++){
            friendUsernamesArray.push(rowsFriends[i].friendUsername)
        }
        let groupDetails = {
            friendGroup: groupToLeave,
            friendsArray: friendUsernamesArray,
        }
        console.log(groupDetails);
        axios.post('/api/group/deleteGroup', groupDetails, {})
        .then(res => {
            console.log(res);
            setOpenConfirmDeleteGroup(false);
            setOpenFriendsInGroupAdmin(false);
            setOpenConfirmSuccessfullGroupDelete(true);
        })
        .catch( error => { console.log(error)})
    }

    const handleConfirmGroupSuccessfullyDeleted = () => {
        setOpenConfirmSuccessfullGroupDelete(false);
        window.location.pathname = '/your_groups'
    }

    const handleOpenListOfFriends = () => {
        if (noFriends === true ) {
            setOpenNoFriendsYet(true);
        } else {
            setOpenFriendSelector(true);
        }
    }

    const handleAgreeInviteFriendToGroup = async () => {
        let validUsername = await axios.get('/api/user/validfriend/' + friendToAddUsername)
        .then (res => {
            if (friendToAddUsername.length > 0 ){
                if (res.data.username === friendToAddUsername && res.data.username !==currentUsername) {
                    return true
                } else { return false }
            } else {
                return true;
            }
        })
        .catch( (err) => {console.log(err); return false})

        if (validUsername === true ){
            let friendPhoneWCC = friendToAddCountryCode + friendToAddPhone;
            let friendPhoneNumOnly = friendPhoneWCC.replace(/\D/g, '');
            let friendDetails = {
                friendUsername: friendToAddUsername,
                friendEmail: friendToAddEmail,
                friendPhone: friendPhoneNumOnly,
                currentUser: currentUsername,
                groupName: groupToLeave,
                groupCode: groupToAddCode,
            }

            let inviteFriendToGroup = await axios.post('/api/group/inviteToGroup', friendDetails, {} )
            .then( res => {
                console.log("Friend Invited Success")
                setOpenFriendInvitedSuccess(true);
            })
            .catch( err => console.log(err))
        } else {
            setOpenUserNotThere(true);
        }
    }

    const handleAgreeFriendInviteSuccess = () => {
        setOpenFriendInvitedSuccess(false);
        window.location.pathname = '/your_groups';
    }

    const handleFriendPick = (event, key) => {
        setFriendPicked(key);
        let friendChosen = rows2.find(({_id}) => _id === key);

        setFriendToAddUsername(friendChosen.friendUsername);
        setFriendToAddEmail(friendChosen.friendEmail);
        setOpenFriendSelector(false);
    }

    return(
        <div>
            <Card className={classes.root}>
                <CardContent>
                    <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center' }} gutterBottom>
                            Your Friend Groups
                    </Typography>

                </CardContent>

                <TableContainer component={Paper} align="center">
                    <Table className={classes.table} aria-label="game table" style={{ justifyContent: 'center'}}>

                        <TableBody>
                            {rows.map((row) =>(
                                <TableRow
                                    hover
                                    key={row._id}
                                    onClick={ e => handleGroupPick(e, row._id)}
                                >
                                    <TableCell component="th" scope="row" align="center" className={classes.tableCellName}>
                                        {row.friendGroupName}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                </TableContainer>

                <div>
                <Dialog
                    open={openFriendsInGroup}
                >
                    <TableContainer component={Paper} align="center">
                        <Table className={classes.table} aria-label="game table" style={{ justifyContent: 'center'}}>
                            <TableHead>
                            <TableRow>
                                <TableCell align="center" className={classes.tableHeader}>Friends in Group</TableCell>
                            </TableRow>
                            </TableHead>

                            <TableBody>
                                {rowsFriends.map((row) =>(
                                    <TableRow
                                    hover
                                    key={row._id}>
                                        <TableCell component="th" scope="row" align="center" className={classes.tableCellName}>
                                            {row.friendName}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </TableContainer>

                    <DialogActions>
                    <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <Button onClick={async e => await setOpenFriendsInGroup(false)} align="center" color="primary" variant="outlined">
                                    X
                                </Button>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8}>
                                <Button onClick={async e => await setOpenConfirmLeaveGroup(true)} align="center" color="secondary" variant="outlined">
                                    Leave Group
                                </Button>
                            </Grid>
                        </Grid>                        
                    </DialogActions>
                </Dialog>
                </div>

                <div>
                    <Dialog
                        open={openConfirmLeaveGroup}
                    >
                        <DialogTitle id="alert-dialog-title">{"Are you sure you want to leave this group?"}</DialogTitle>

                        <DialogActions>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <Button onClick={async e => await setOpenConfirmLeaveGroup(false)} align="center" color="primary" variant="outlined">
                                    X
                                </Button>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8}>
                                <Button onClick={handleConfirmLeaveGroup} align="center" color="secondary" variant="outlined">
                                    Leave Group
                                </Button>
                            </Grid>
                        </Grid>        
                        </DialogActions>
                    </Dialog>
            </div>

            <div>
                    <Dialog
                        open={openLeftGroupSuccessfully}
                    >
                        <DialogTitle id="alert-dialog-title">{"You have left the group successfully."}</DialogTitle>

                        <DialogActions>
                        <Grid container spacing={1}>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={4}>
                                <Button onClick={handleLeftGroupSuccessfully} align="center" color="primary" variant="outlined">
                                    OK
                                </Button>
                            </Grid>
                        </Grid>        
                        </DialogActions>
                    </Dialog>
            </div>

            <div>
                <Dialog
                    open={openFriendsInGroupAdmin}
                    className={classes.root}
                >
                    <TableContainer component={Paper} align="center">
                        <Table className={classes.table} aria-label="game table" style={{ justifyContent: 'center'}}>
                            <TableHead>
                            <TableRow>
                                <TableCell align="center" className={classes.tableHeader}>Friends</TableCell>
                                <TableCell align="center" className={classes.tableHeader}>Remove</TableCell>
                            </TableRow>
                            </TableHead>

                            <TableBody>
                                {rowsFriends.map((row) =>(
                                    <TableRow
                                    hover
                                    key={row._id}>
                                        <TableCell component="th" scope="row" align="center" className={classes.tableCellName}>
                                            {row.friendName}
                                        </TableCell>
                                        <TableCell value={row._id} align="center" className={classes.tableCellName}>
                                            <IconButton
                                                value={row._id}
                                                color="secondary"
                                                onClick={ e => handleFriendToDelete(e.currentTarget.value) }
                                                > X
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </TableContainer>

                    <DialogActions style={{ justifyContent: 'center'}}>
                    <Button
                        align='center'
                        className = {classes.buttonblue}
                        onClick={async e => await setOpenAddFriendsToGroup(true)}
                    >
                        Add Friend
                    </Button>
                    
                    </DialogActions>
                    <br/>
                    <DialogActions style={{ justifyContent: 'center'}}>

                    <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <Button onClick={async e => await setOpenFriendsInGroupAdmin(false)} align="center" color="primary" variant="outlined">
                                    X
                                </Button>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8}>
                                <Button onClick={async e => await setOpenConfirmDeleteGroup(true)} align="center" color="secondary" variant="outlined">
                                    Delete Group
                                </Button>
                            </Grid>
                        </Grid>                        
                    </DialogActions>
                </Dialog>
                </div>

                <div>
                    <Dialog
                        open={openConfirmRemoveFriend}
                    >
                        <DialogTitle id="alert-dialog-title">{"Are you sure you want to remove " + nameOfFriendToDelete + " from " + groupToLeave + "?"}</DialogTitle>

                        <DialogActions>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <Button onClick={async e => await setOpenConfirmRemoveFriend(false)} align="center" color="primary" variant="outlined">
                                    X
                                </Button>
                            </Grid>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={4}>
                                <Button onClick={handleConfirmRemoveFriend} align="center" color="secondary" variant="outlined">
                                    Remove
                                </Button>
                            </Grid>
                        </Grid>        
                        </DialogActions>
                    </Dialog>
                </div>

                <div>
                    <Dialog
                        open={openRemovedFriendSuccessfully}
                    >
                        <DialogTitle>{nameOfFriendToDelete + " has been successfully removed from " + groupToLeave +"."}</DialogTitle>

                        <DialogActions>
                        <Grid container spacing={1}>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={4}>
                                <Button onClick={handleRemovedFriendSuccessfully} align="center" color="primary" variant="outlined">
                                    OK
                                </Button>
                            </Grid>
                        </Grid>        
                        </DialogActions>
                    </Dialog>
                </div>

                <div>
                    <Dialog
                        open={openConfirmDeleteGroup}
                    >
                        <DialogTitle id="alert-dialog-title">{"Are you sure you want to permanently delete " + groupToLeave + "?  \n\nThis cannot be reversed.  All open unaccepted bets for this group will be unaccessable to accept.  Please make sure all outstanding bets are cancelled."}</DialogTitle>

                        <DialogActions>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <Button onClick={async e => await setOpenConfirmDeleteGroup(false)} align="center" color="primary" variant="outlined">
                                    X
                                </Button>
                            </Grid>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={4}>
                                <Button onClick={handleConfirmDeleteGroup} align="center" color="secondary" variant="outlined">
                                    Delete
                                </Button>
                            </Grid>
                        </Grid>        
                        </DialogActions>
                    </Dialog>
                </div>

                <div>
                    <Dialog
                        open={openConfirmSuccessfullGroupDelete}
                    >
                        <DialogTitle>{groupToLeave + " has been deleted."}</DialogTitle>

                        <DialogActions>
                        <Grid container spacing={1}>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={4}>
                                <Button onClick={handleConfirmGroupSuccessfullyDeleted} align="center" color="primary" variant="outlined">
                                    OK
                                </Button>
                            </Grid>
                        </Grid>        
                        </DialogActions>
                    </Dialog>
                </div>

                {/* Dialog for entering friend info +++++++++++++++++++++++ */}
                    <div>
                    <Dialog
                        open={openAddFriendsToGroup}
                    >
                        <DialogTitle >{"Pick your friend from your list of friends you have bet with in the past by clicking the 'Friend' button below, or enter your friend's username, email or phone number. We don't need all three, but we need at least one:"}</DialogTitle>

                        <TextField
                            required
                            id="friendUserame"
                            label="Friend's Username"
                            name="friendUserame"
                            variant="outlined"
                            value={friendToAddUsername}
                            placeholder="Enter Your Friend's Username"
                            onChange={ async e => await setFriendToAddUsername(e.target.value)}
                        />
                        <br/>

                        <TextField
                            required
                            id="friendEmail"
                            label="Friend's Email"
                            name="friendEmail"
                            variant="outlined"
                            value={friendToAddEmail}
                            placeholder="Enter Your Friend's Email"
                            onChange={ async e => await setFriendToAddEmail(e.target.value) }
                        />
                        <br/>

                        <TextField
                            required
                            id="friendCountryCode"
                            label="Friend's Phone Country Code"
                            name="friendCountryCode"
                            variant="outlined"
                            value={friendToAddCountryCode}
                            placeholder="Enter Your Friend's Country Code"
                            onChange={ async e => await setFriendToAddCountryCode(e.target.value) }
                        />
                        <br/>

                        <TextField
                            required
                            id="friendPhone"
                            label="Friend's Phone Number"
                            name="friendPhone"
                            variant="outlined"
                            value={friendToAddPhone}
                            placeholder="Enter Your Friend's Phone Number"
                            onChange={ async e => await setFriendToAddPhone(e.target.value) }
                        />
                        <br/>

                        <DialogActions>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Button onClick={ async e => await setOpenAddFriendsToGroup(false)} align="center" color="secondary" variant="outlined" autoFocus>
                                        X
                                    </Button>
                                </Grid>
                                
                                <Grid item xs={4}>
                                    <Button onClick={handleOpenListOfFriends} align="center" color="primary" variant="outlined" autoFocus>
                                        Friend
                                    </Button>
                                </Grid>
                            
                                <Grid item xs={4}>
                                    <Button onClick={handleAgreeInviteFriendToGroup} align="center" color="primary" variant="outlined" className={classes.buttonBetBlue} autoFocus>
                                        Invite
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                </div>
                {/* Dialog for entering friend info end ======================= */}

                {/* Dialog for selecting friend using selector start +++++++++++++++++ */}
                <div>
                    <Dialog
                        open={openFriendSelector}
                    >
                        <br/>
                        <div>
                            <TableContainer component={Paper} align="center">
                                <Table className={classes.table} aria-label="game table" style={{justifyContent: 'center'}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" className={classes.tableHeaderName}>Select a Friend</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows2.map((row) => (
                                            <TableRow 
                                            hover 
                                            key={row._id}
                                            onClick={ (event) => handleFriendPick(event, row._id)}
                                            >
                                                <TableCell component="th" scope="row" align="center" className={classes.tableCellName}>
                                                    {row.friendName}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                            
                    </Dialog>
                    </div>
                    {/* Dialog for selecting friend using selector end ============ */}

                    {/* Dialog for no friends yet start +++++++++++++++++++++++++++ */}
                    <div>
                        <Dialog
                            open={openNoFriendsYet}
                        >
                            <DialogTitle>{"There are no friends associated with this account yet. Please close this popup and enter your friends information manually.  If the phone or email are associated with a registered user, that user will automatically be added to your friend list after the invitation to join your group is sent."}</DialogTitle>

                            <DialogActions>
                            <Button onClick={async e => await setOpenNoFriendsYet(false)} color="primary" autoFocus>
                                X
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    {/* Dialog for no friends yet end =========================== */}

                    {/* Dialog for user not in system start +++++++++++++++++++++++ */}
                    <div>
                        <Dialog
                            open={openUserNotThere}
                            onClose={async e => await setOpenUserNotThere(false)}
                        >
                            <DialogTitle >{"We are sorry, but that username is not in our system.  Check the spelling (case sensitive) or use a phone number or email to add your friend."}</DialogTitle>

                            <DialogActions>
                            <Button onClick={async e => await setOpenUserNotThere(false)} color="primary" autoFocus>
                                OK
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    {/* Dialog for user not in system end ======================= */}

                    {/* Dialog for success invite start+++++++++++++++++++++++ */}
                    <div>
                        <Dialog
                            open={openFriendInvitedSuccess}
                            onClose={handleAgreeFriendInviteSuccess}
                        >
                            <DialogTitle >{"Your friend has been invited and successfully notified."}</DialogTitle>

                            <DialogActions>
                            <Button onClick={handleAgreeFriendInviteSuccess} color="primary" autoFocus>
                                OK
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    {/* Dialog for success invite end ======================= */}                

            </Card>
        </div>
    )

}

