export default function encryptAccountDetails( number ) {
    let factor = 8888888888;
    let numberV1 = (parseInt(number) * factor ).toFixed(0);

    let letterArray = [ 'c', 'v', 'r', 'x', 'p', 'y', 'b', 's', 'k', 'e'];
    let numberV2= '';

    for (let i = 0; i < numberV1.length; i ++ ) {
        let nextLetter = letterArray[numberV1.charAt(i)];
        numberV2 = numberV2 + nextLetter; 
    }

    return numberV2;
}