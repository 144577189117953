import React from 'react';

import Container from '@material-ui/core/Container';
import BasicAppBar from '../../components/BasicAppBar';
import ResponsibleGaming from '../../components/ResponsibleGaming';

import Footer from '../../components/FooterNotLogged';
import Copyright from '../../components/Copyright';


export default function LandingPage() {

  return (
    < Container maxWidth="sm">
        <BasicAppBar></BasicAppBar>
        <ResponsibleGaming></ResponsibleGaming>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
