import React from 'react';

import Container from '@material-ui/core/Container';
import MainAppBar from '../components/MainAppBar';
import InviteFriend from '../components/inviteFriend';
import Footer from '../components/Footer';
import Copyright from '../components/Copyright';
import checkAuthenticated from '../utils/checkAuthenticated';


export default function LandingPage() {
  checkAuthenticated();
  return (
    < Container maxWidth="sm">
        <MainAppBar></MainAppBar>
        <InviteFriend></InviteFriend>
        <br/>
        <Footer></Footer>
        <Copyright></Copyright>
    </Container>
  );
}
