import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
//  import MuiPhoneNumber from 'material-ui-phone-number';


import axios from 'axios';


const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    formControl: {
        margin: 1,
        minWidth: 220,
        maxWidth: 220,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
      },
  });

const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 2,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#0510e6',
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

let uniqueEmail;

export default function SignUpForm() { 
    
    const classes = useStyles();
    const [ firstName, setFirstName ] = React.useState('');
    const [ lastName, setLastName ] = React.useState('');
    const [ selectedUsername, setSelectedUsername ] = React.useState('');
    const [ selectedEmail, setSelectedEmail ] = React.useState('');
    const [ phone, setPhone ] = React.useState('');
    const [ selectedPassword, setSelectedPassword ] = React.useState('');
    const [ question, setQuestion ] = React.useState('');
    const [ secret, setSecret ] = React.useState('');
    const [ confirmPassword, setConfirmPassword ] = React.useState('');
    const [ countryCode, setCountryCode ] = React.useState('+1');
    const [ selectedCountry, setSelectedCountry ] = React.useState('');

    // const handlePhoneChange = (value) => {
    //     setPhone({phone: value })
    // }

    // For Dialog Pop Up on Passwords not matching
    const [open, setOpen] = React.useState(false);
    const [agree, setAgree] = React.useState(false);

    const handleAgree = () => {
        setAgree(true);
        setOpen(false);
      };

    // For Dialog Pop Up on Valid Email
    const [openValidEmail, setOpenValidEmail] = React.useState(false);
    const [agreeValidEmail, setAgreeValidEmail] = React.useState(false);

    const handleAgreeValidEmail = () => {
        setAgreeValidEmail(true);
        setOpenValidEmail(false);
      };

    // For Dialog Pop Up on User Already Exists
    const [openUserExists, setOpenUserExists] = React.useState(false);
    const [agreeUserExists, setAgreeUserExists] = React.useState(false);

    const handleAgreeUserExists = () => {
        setAgreeUserExists(true);
        setOpenUserExists(false);
      };

    // For Dialog Pop Up on Password requirements
    const [openPasswordReq, setOpenPasswordReq] = React.useState(false);
    const [agreePasswordReq, setAgreePasswordReq] = React.useState(false);

    const handleAgreePasswordReq = () => {
        setAgreePasswordReq(true);
        setOpenPasswordReq(false);
      };

    // For Dialog PopUp on Successful Update
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [agreeSuccess, setAgreeSuccess] = React.useState(false);

    const handleAgreeSuccess = () => {
        setAgreeSuccess(true);
        setOpenSuccess(false);
        window.location.pathname = "/login";
      };

    // For Dialog on Needing Full Name
    const [ openNeedName, setOpenNeedName ] = React.useState(false);
    const [ agreeNeedName, setAgreeNeedName ] = React.useState(false);

    const handleAgreeNeedName = () => {
        setAgreeNeedName(true);
        setOpenNeedName(false);
    }

    // For Dialog on Needing Phone and Country Code
    const [ openNeedValidPhoneAndCode, setOpenNeedValidPhoneAndCode ] = React.useState(false);
    const handleCloseNeedValidPhoneAndCode = () => {
        setOpenNeedValidPhoneAndCode(false);
    }

    // For Dialog confirming info is correct
    const [ openConfirmDetails, setOpenConfirmDetails ] = React.useState(false);
    const [ agreeConfirmDetails, setAgreeConfirmDetails ] = React.useState(false);

    const handleAgreeConfirmDetails = () => {
        setAgreeConfirmDetails(false);
        setOpenConfirmDetails(false);
    }

    const handleCheckDetails = () => {
        setOpenConfirmDetails(true);
        setAgreeConfirmDetails(true);
        CheckUniqueEmail(selectedEmail);
    }
    
    
    // Functions to Check registration details==================================
    const CheckPasswordReq = (inputtxt) => {
        let lengthCheck = false;
        let characterCheck = false;
        let numberCheck = false;
        let regex3 = /[!@#$%^&*]/;

        if ( inputtxt.length > 7) { lengthCheck = true }
        if ( /\d/.test(inputtxt) && /[a-zA-Z]/.test(inputtxt) ) { numberCheck = true }
        if ( regex3.test(inputtxt) ) { characterCheck = true }

        if (lengthCheck && characterCheck && numberCheck) {
            return true;
        }
    }

    const CheckEmail = (input) => {
        let atSignCheck = false;
        let periodCheck = false;
        let regex1 = /[@]/;
        let regex2 = /[.]/;

        if ( regex1.test(input) ) { atSignCheck = true }
        if ( regex2.test(input) ) { periodCheck = true }

        if ( atSignCheck && periodCheck ) { return true }
    }

    const CheckUniqueEmail = async (input) =>{
        uniqueEmail = await axios.get('/api/user/checkuniqueemail/'+ input).then( response => {
            if (response.data.length > 0 ) { return false } else { return true }
        })
        .catch( err => { console.log(err) })
        console.log("Email unique: " + uniqueEmail)
    }


    const CheckName = (firstInput, lastInput) => {
        let firstCheck = false;
        let lastCheck = false;
        if ( firstInput.length > 0 ) { firstCheck = true };
        if ( lastInput.length > 0 ) { lastCheck = true };

        if ( firstCheck && lastCheck ) { return true }
    }

    const CheckNumber = (input) => {
        let numberNumOnly = input.replace(/\D/g, '');
        if ( parseInt(numberNumOnly) > 0) { return true } else { return false }
    }

    
    const handleSubmit = (event) => {
        event.preventDefault();
        setOpenConfirmDetails(false);
        let phoneWCC = countryCode + phone;
        let phoneNumOnly = phoneWCC.replace(/\D/g, '');
        const userDetails = {
            firstName: firstName,
            lastName: lastName,
            username: selectedUsername,
            email: selectedEmail.toLowerCase(),
            phone: phoneNumOnly,
            password: selectedPassword,
            question: question,
            secret: secret,
            country: selectedCountry,
            MFAon: mfaOn.checkedC,
        }
        
        if ( CheckName(userDetails.firstName, userDetails.lastName) === true ) {
            if ( CheckNumber(phone) === true && CheckNumber(countryCode)=== true ){
                if( selectedPassword === confirmPassword ) {
                    if ( CheckEmail(userDetails.email) !== true || uniqueEmail === false) { 
                        // window.location.pathname = "/signupemail";
                        setOpenValidEmail(true);
                    } else {
                        if (CheckPasswordReq(userDetails.password)) { 
                            console.log(CheckPasswordReq(userDetails.password));      
                            axios.post('/api/user/signup', userDetails, {
                                }).then(response => {
                                    console.log(response)
                                    if (response.data.error === 'User already exists') {
                                        // window.location.pathname = "/signup2";
                                        setOpenUserExists(true);
                                    } else {
                                        setOpenSuccess(true);
                                    }

                                                    
                                }).catch(error => {
                                    console.log('signup error: ')
                                    console.log(error)

                                })
                        } else {
                            //  window.location.pathname = "/signuppassword"
                            setOpenPasswordReq(true);
                        }
                    }
                } else {
                    setOpen(true);
                }
            } else {
                setOpenNeedValidPhoneAndCode(true);
            }
        } else {
            setOpenNeedName(true);
        }
    }
    
    // For Switch for MFA
    const [mfaOn, setMfaOn] = React.useState({
        checkedC: true,
      });

      const handleChange = (event) => {
        setMfaOn({ ...mfaOn, [event.target.name]: event.target.checked });
      };

    return(
        <div>
            < Container maxWidth="sm">
            <br/>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                            Sign Up
                        </Typography>
                        <Typography className={classes.title} style={{ textAlign: 'center'}}>
                            iBetU
                        </Typography>
                        <br />
                        <form noValidate autoComplete="off">
                            <Grid container direction='column' justify="center" alignItems='center'>
                                <div>
                                    <TextField
                                        required
                                        id="firstName"
                                        label="First Name"
                                        name="firstName"
                                        variant="outlined"
                                        value={firstName}
                                        placeholder="First Name"
                                        onChange={ e => setFirstName(e.target.value) }
                                        
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        variant="outlined"
                                        value={lastName}
                                        placeholder="Last Name"
                                        onChange={ e => setLastName(e.target.value) }
                                        
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="username"
                                        label="Username"
                                        name="username"
                                        variant="outlined"
                                        value={selectedUsername}
                                        placeholder="Username"
                                        onChange={ e => setSelectedUsername(e.target.value) }
                                        
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="email"
                                        label="Email"
                                        name="email"
                                        variant="outlined"
                                        value={selectedEmail}
                                        placeholder="Email"
                                        onChange={ e => setSelectedEmail(e.target.value) }
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="countryCode"
                                        label="Country Code"
                                        name="countryCode"
                                        variant="outlined"
                                        value={countryCode}
                                        placeholder="Country Code for Phone"
                                        onChange={ e => setCountryCode(e.target.value) }
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="phone"
                                        label="Phone w/ Area Code"
                                        name="phone"
                                        variant="outlined"
                                        value={phone}
                                        placeholder="Phone w/ Local Area Code"
                                        onChange={ e => setPhone(e.target.value) }
                                    />
                                </div>
                                {/* <br />
                                <div>
                                    <MuiPhoneNumber
                                        defaultCountry={'us'}
                                        className={classes.formControl}
                                        id="phone"
                                        label="Phone Number"
                                        name="phone"
                                        variant="outlined"
                                        placeholder="Phone Number"
                                        onChange={handlePhoneChange}
                                    />
                                </div> */}
                                <br />
                                <div>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel>Your Country</InputLabel>
                                        <Select
                                            required
                                            label="Country Where You Will Play"
                                            value={selectedCountry}
                                            onChange={ e => setSelectedCountry(e.target.value)}
                                        >
                                            <MenuItem value={"US"}>United States</MenuItem>
                                            <MenuItem value={"UK"}>United Kingdom</MenuItem>
                                            <MenuItem value={"MX"}>Mexico</MenuItem>
                                            <MenuItem value={"Other Country"}>Other Country</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <br />
                                
                                <div>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">Security Question</InputLabel>
                                        <Select
                                            required
                                            labelId="demo-simple-select-label"
                                            label="Security Question"
                                            id="security-question-select"
                                            value={question}
                                            onChange={ e => setQuestion(e.target.value)}
                                        >
                                            <MenuItem value={"What is your favorite movie?"}>What is your favorite movie?</MenuItem>
                                            <MenuItem value={"What was the name of your first pet?"}>What was the name of your fist pet?</MenuItem>
                                            <MenuItem value={"In what city did your parents meet?"}>In what city did your parents meet?</MenuItem>
                                            <MenuItem value={"Paternal grandfather's first name?"}>Fraternal grandfather's first name?</MenuItem>
                                            <MenuItem value={"Maternal grandmother's first name?"}>Maternal grandmother's first name?</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="secret"
                                        label="Answer to Question"
                                        name="secret"
                                        variant="outlined"
                                        value={secret}
                                        placeholder="Answer to Question"
                                        onChange={ e => setSecret(e.target.value) }
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="password"
                                        label="Password"
                                        name="password"
                                        type="password"
                                        variant="outlined"
                                        value={selectedPassword}
                                        placeholder="Password"
                                        onChange={ e => setSelectedPassword(e.target.value) }
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="confirmPassword"
                                        label="Confirm Password"
                                        name="confirmPassword"
                                        type="password"
                                        variant="outlined"
                                        value={confirmPassword}
                                        placeholder="Confirm Password"
                                        onChange={ e => setConfirmPassword(e.target.value) }
                                    />
                                </div>
                                <br />
                                <FormGroup>
                                    <div>
                                    <Typography  style={{ textAlign: 'left'}}>
                                        Multi-Factor Authentication:
                                    </Typography>
                                      
                                    <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item>Off</Grid>
                                        <Grid item>
                                            <AntSwitch checked={mfaOn.checkedC} onChange={handleChange} name="checkedC" />
                                        </Grid>
                                        <Grid item>On</Grid>
                                        </Grid>
                                    </div>
                                    <br />
                                </FormGroup>


                                <div>
                                    <Dialog
                                        open={open}
                                        agree={agree}
                                        onClose={handleAgree}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Passwords do not match"}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgree} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openUserExists}
                                        agree={agreeUserExists}
                                        onClose={handleAgreeUserExists}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Username already exists. Pick a new user name."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreeUserExists} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openValidEmail}
                                        agree={agreeValidEmail}
                                        onClose={handleAgreeValidEmail}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Please enter a valid email address.  Your entry is either not in email format, or is an email address already associated with our users."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreeValidEmail} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openPasswordReq}
                                        agree={agreePasswordReq}
                                        onClose={handleAgreePasswordReq}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Password must contain more than 7 characters, at least one letter, at least one number, and at least one special character."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreePasswordReq} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openSuccess}
                                        agree={agreeSuccess}
                                        onClose={handleAgreeSuccess}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"You have successfully registered.  Click 'OK' and you will redirected to the login page."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreeSuccess} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openNeedName}
                                        agree={agreeNeedName}
                                        onClose={handleAgreeNeedName}
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Please enter your first name and last name. Without a full name, we won't be able to fund your account."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreeNeedName} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openNeedValidPhoneAndCode}
                                        onClose={handleCloseNeedValidPhoneAndCode}
                                    >
                                        <DialogTitle>{"Please enter a valid country code and a valid phone number."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleCloseNeedValidPhoneAndCode} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div>
                                    <Dialog
                                        open={openConfirmDetails}
                                        agree={agreeConfirmDetails}
                                        onClose={handleAgreeConfirmDetails}
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Is the following information correct:"}
                                        </DialogTitle>

                                        <DialogTitle align="left">
                                            { firstName + " " + lastName}
                                        </DialogTitle>

                                        <DialogTitle align="left">
                                            { selectedUsername }
                                        </DialogTitle>

                                        <DialogTitle align="left">
                                            { selectedEmail }
                                        </DialogTitle>
                                        
                                        <DialogTitle align="left">
                                            { countryCode + phone }
                                        </DialogTitle>
                            

                                        <DialogActions>
                                            <Grid container spacing ={1}>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleAgreeConfirmDetails} align="center" color="secondary" variant="outlined" autoFocus>
                                                    Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={4}></Grid>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleSubmit} color="primary" variant="outlined" className={classes.buttonBetBlue} 
                                                    type='submit'
                                                    align="center" autoFocus>
                                                    Submit
                                                    </Button>
                                                </Grid>
                                            
                                            </Grid>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <CardActions style={{ justifyContent: 'center'}}>
                                    <Button size="small" variant="contained" className={classes.buttonblue}
                                    onClick={handleCheckDetails}
                                    >Sign Up</Button>
                                </CardActions>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
        
}


