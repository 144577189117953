import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
//  import MuiPhoneNumber from 'material-ui-phone-number';


import axios from 'axios';


const useStyles = makeStyles({

    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center',
      paddingTop: 5,
      paddingBottom: 30
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 60,
      fontFamily: 'Anton',
      color: '#0510e6',
      fontStyle: 'italic',
    },
    welcome: {
      fontSize: 26,
      fontStyle: 'bold',
      fontFamily: 'Anton'
    },
    pos: {
      marginBottom: 12,
    },
    buttonblue: {
        background: '#0510e6',
        color: 'white'
    },
    buttonclear: {
        color: "#0510e6",
        borderColor: "#0510e6"
    },
    inputBox: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
    },
    formControl: {
        margin: 1,
        minWidth: 220,
        maxWidth: 220,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
      },
  });

const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 2,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#0510e6',
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

let uniquePromotion;

export default function SignUpForm() { 
    
    const classes = useStyles();
    const [ promotionName, setPromotionName ] = React.useState('');
    const [ promotionCode, setPromotionCode ] = React.useState('');
    const [ groupAdminID, setGroupAdminID ] = React.useState('');
    const [ perUserAmount, setPerUserAmount ] = React.useState('');
    const [ initialBalance, setInitialBalance ] = React.useState('');
    const [ newAcceptsNeeded, setNewAcceptsNeeded ] = React.useState('');
    const [ needCode, setNeedCode ] = React.useState('');
    const [ availableToWithdraw, setAvailableToWithdraw ] = React.useState('');
    const [ daysToUseFunds, setDaysToUseFunds ] = React.useState('');
    const [ promoExpirationDate, setPromoExpirationDate ] = React.useState('');
    const [ useExpiration, setUseExpiration ] = React.useState('');
    const [ message, setMessage ] = React.useState('');


    // For Dialog Pop Up on Promo Already Exists
    const [openPromoExists, setOpenPromoExists] = React.useState(false);
    const [agreePromoExists, setAgreePromoExists] = React.useState(false);

    const handleAgreePromoExists = () => {
        setAgreePromoExists(true);
        setOpenPromoExists(false);
      };


    // For Dialog PopUp on Successful Creation
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [agreeSuccess, setAgreeSuccess] = React.useState(false);

    const handleAgreeSuccess = () => {
        setAgreeSuccess(true);
        setOpenSuccess(false);
        window.location.pathname = "/home";
      };



    // For Dialog confirming info is correct
    const [ openConfirmDetails, setOpenConfirmDetails ] = React.useState(false);
    const [ agreeConfirmDetails, setAgreeConfirmDetails ] = React.useState(false);

    const handleAgreeConfirmDetails = () => {
        setAgreeConfirmDetails(false);
        setOpenConfirmDetails(false);
    }

    const handleCheckDetails = () => {
        setOpenConfirmDetails(true);
        setAgreeConfirmDetails(true);
        CheckUniquePromo(promotionName);
    }
    
    

    const CheckUniquePromo = async (input) =>{
        uniquePromotion = await axios.get('/api/promotion/checkuniquepromo/'+ input).then( response => {
            if (response.data.length > 0 ) { return false } else { return true }
        })
        .catch( err => { console.log(err) })
        console.log("Promotion unique: " + uniquePromotion)
    }


    
    const handleSubmit = (event) => {
        event.preventDefault();
        setOpenConfirmDetails(false);

        const promotionDetails = {
            promotionName: promotionName,
            promotionCode: promotionCode,
            groupAdminID: groupAdminID,
            initialBalance: initialBalance,
            perUserAmount: perUserAmount,
            newAcceptsNeeded: newAcceptsNeeded,
            availableToWithdraw: availableToWithdraw,
            daysToUseFunds: daysToUseFunds,
            promoExpirationDate: promoExpirationDate,
            needCode: needCode,
            useExpiration: useExpiration,
            message: message,
        }
                
        if ( uniquePromotion === false) { 
            setOpenPromoExists(true);
        } else {      
            axios.post('/api/promotion/create', promotionDetails, {
                }).then(response => {
                    console.log(response)
                    if (response.data.error === 'Promotion already exists') {
                        setOpenPromoExists(true);
                    } else {
                        setOpenSuccess(true);
                    }
                         
                }).catch(error => {
                    console.log('promotion error: ')
                    console.log(error)

                })
        }
    }
    

    return(
        <div>
            < Container maxWidth="sm">
            <br/>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.welcome} color="textSecondary" style={{ textAlign: 'center'}} gutterBottom>
                            Create Promotion
                        </Typography>
                        <Typography className={classes.title} style={{ textAlign: 'center'}}>
                            iBetU
                        </Typography>
                        <br />
                        <form noValidate autoComplete="off">
                            <Grid container direction='column' justify="center" alignItems='center'>
                                <div>
                                    <TextField
                                        required
                                        id="promotionName"
                                        label="Promotion Name"
                                        name="promotionName"
                                        variant="outlined"
                                        value={promotionName}
                                        placeholder="Promotion Name"
                                        onChange={ e => setPromotionName(e.target.value) }
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        id="promotionCode"
                                        label="Promotion Code"
                                        name="promotionCode"
                                        variant="outlined"
                                        value={promotionCode}
                                        placeholder="Promotion Code"
                                        onChange={ e => setPromotionCode(e.target.value) }
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="groupAdminID"
                                        label="Group Admin"
                                        name="groupAdminID"
                                        variant="outlined"
                                        value={groupAdminID}
                                        placeholder="Admin ID"
                                        onChange={ e => setGroupAdminID(e.target.value) }
                                        
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="initialBalance"
                                        label="Initial Balance $"
                                        name="initialBalance"
                                        variant="outlined"
                                        value={initialBalance}
                                        placeholder="1000"
                                        onChange={ e => setInitialBalance(e.target.value) }
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        required
                                        id="perUserAmount"
                                        label="Per User Amount $"
                                        name="perUserAmount"
                                        variant="outlined"
                                        value={perUserAmount}
                                        placeholder="20"
                                        onChange={ e => setPerUserAmount(e.target.value) }
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        id="newAcceptsNeeded"
                                        label="New Acceptances Needed to Earn"
                                        name="newAcceptsNeeded"
                                        variant="outlined"
                                        value={newAcceptsNeeded}
                                        placeholder="2"
                                        onChange={ e => setNewAcceptsNeeded(e.target.value) }
                                    />
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        id="daysToUseFunds"
                                        label="Days to Use or Lose"
                                        name="daysToUseFunds"
                                        variant="outlined"
                                        value={daysToUseFunds}
                                        placeholder="30"
                                        onChange={ e => setDaysToUseFunds(e.target.value) }
                                    />
                                </div>
                                <br />
                                <div>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel>Expiration Date?</InputLabel>
                                        <Select
                                            required
                                            label="Set Expiration Date?"
                                            value={useExpiration}
                                            onChange={ e => setUseExpiration(e.target.value)}
                                        >
                                            <MenuItem value={true}>True</MenuItem>
                                            <MenuItem value={false}>False</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <br />
                                <div>
                                    <form className={classes.container} noValidate>
                                        <TextField
                                            label="Expiration Date"
                                            type="date"
                                            className={classes.textField}
                                            value={promoExpirationDate}
                                            onChange={ e => setPromoExpirationDate(e.target.value)}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        >
                                        </TextField>
                                    </form>
                                </div>
                                <br/>
                                <div>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel>Available to Withdraw?</InputLabel>
                                        <Select
                                            required
                                            label="Avaialable to Withdraw?"
                                            value={availableToWithdraw}
                                            onChange={ e => setAvailableToWithdraw(e.target.value)}
                                        >
                                            <MenuItem value={true}>True</MenuItem>
                                            <MenuItem value={false}>False</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <br />
                                
                                <div>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel>Need Code?</InputLabel>
                                        <Select
                                            label="Need Code?"
                                            value={needCode}
                                            onChange={ e => setNeedCode(e.target.value)}
                                        >
                                            <MenuItem value={true}>True</MenuItem>
                                            <MenuItem value={false}>False</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        id="message"
                                        label="Message"
                                        name="message"
                                        variant="outlined"
                                        value={message}
                                        placeholder="Enter Message"
                                        onChange={ e => setMessage(e.target.value) }
                                    />
                                </div>
                                <br />


                                <div>
                                    <Dialog
                                        open={openPromoExists}
                                        agree={agreePromoExists}
                                        onClose={handleAgreePromoExists}
                                    >
                                        <DialogTitle>{"Promotion name is already in use. Pick a new user name."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreePromoExists} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>



                                <div>
                                    <Dialog
                                        open={openSuccess}
                                        agree={agreeSuccess}
                                        onClose={handleAgreeSuccess}
                                    >
                                        <DialogTitle id="alert-dialog-title">{"You have successfully created a promotion.  Click 'OK' and you will be redirected to the home page."}</DialogTitle>

                                        <DialogActions>
                                        <Button onClick={handleAgreeSuccess} color="primary" autoFocus>
                                            OK
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>



                                <div>
                                    <Dialog
                                        open={openConfirmDetails}
                                        agree={agreeConfirmDetails}
                                        onClose={handleAgreeConfirmDetails}
                                    >
                                        <DialogTitle>{"Is the following information correct:"}
                                        </DialogTitle>

                                        <DialogTitle align="left">
                                            { "Promo: " + promotionName }
                                        </DialogTitle>

                                        <DialogTitle align="left">
                                            { "Code: " + promotionCode }
                                        </DialogTitle>

                                        <DialogTitle align="left">
                                            {  "Admin: " + groupAdminID }
                                        </DialogTitle>
                                        
                                        <DialogTitle align="left">
                                            { "Balance: $" + initialBalance }
                                        </DialogTitle>
                            
                                        <DialogTitle align="left">
                                            { "Per User Amount: $" + perUserAmount }
                                        </DialogTitle>

                                        <DialogTitle align="left">
                                            { "Accepts Needed: " + newAcceptsNeeded }
                                        </DialogTitle>

                                        <DialogTitle align="left">
                                            { "Available: " + availableToWithdraw }
                                        </DialogTitle>

                                        <DialogTitle align="left">
                                            { "Days to Use: " + daysToUseFunds }
                                        </DialogTitle>

                                        <DialogTitle align="left">
                                            { "Expiration Date: " + promoExpirationDate }
                                        </DialogTitle>

                                        <DialogTitle align="left">
                                            { "Need Code: " + needCode }
                                        </DialogTitle>

                                        <DialogTitle align="left">
                                            { "Message: " + message }
                                        </DialogTitle>

                                        <DialogActions>
                                            <Grid container spacing ={1}>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleAgreeConfirmDetails} align="center" color="secondary" variant="outlined" autoFocus>
                                                    Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={4}></Grid>
                                                <Grid item xs={4}>
                                                    <Button onClick={handleSubmit} color="primary" variant="outlined" className={classes.buttonBetBlue} 
                                                    type='submit'
                                                    align="center" autoFocus>
                                                    Submit
                                                    </Button>
                                                </Grid>
                                            
                                            </Grid>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <CardActions style={{ justifyContent: 'center'}}>
                                    <Button size="small" variant="contained" className={classes.buttonblue}
                                    onClick={handleCheckDetails}
                                    >Create</Button>
                                </CardActions>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
        
}


